<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t(`modals.rolesAndActionsManager.${hasRole ? 'updateTitle' : 'createTitle'}`)"
    @close="$emit('close')"
    >
    <template #body="">
      <validation-observer ref="form">
        <div
          class="max-w-4xl min-w-xl"
          >
          <cc-text
            color="text-gray-600"
            class="pb-4 max-w-full"
            >
            {{ $t('modals.rolesAndActionsManager.createBody') }}
          </cc-text>

          <cc-input
            id="name"
            v-model="name"
            name="name"
            :label="$t('modals.rolesAndActionsManager.name')"
            class="pb-6"
            :disabled="hasRole"
            validation-rules="required"
            autofocus
            />

          <!-- Assigned Actions -->
          <div
            v-if="hasRole"
            class="pb-8"
            >
            <cc-heading
              tag="h2"
              weight="semibold"
              color="text-gray-600"
              class="pb-4"
              >
              {{ $t('modals.rolesAndActionsManager.assignedActionsTitle') }}  {{ `(${currentRoleActions.length})` }}
            </cc-heading>

            <div
              v-if="currentRoleActions.length"
              class="flex flex-wrap"
              >
              <cc-chip
                v-for="(actionId, i) in currentRoleActions"
                :key="i"
                variant="secondary"
                :label="actionId"
                class="mr-2 mb-2 select-none "
                icon-class="order-last ml-2 cursor-pointer w-2.5 h-2.5"
                icon-path="icons/icon-close"
                @icon-click="updateRoleActions('removeActionsFromRole', false, actionId)"
                />
            </div>

            <cc-text
              v-else
              color="text-gray-600"
              >
              {{ $t('modals.rolesAndActionsManager.noActionsAssigned') }}
            </cc-text>
          </div>

          <!-- Available actions -->
          <cc-section-table
            v-if="hasRole"
            :title="`${$t('modals.rolesAndActionsManager.actionsTitle')}  (${dataItems && dataItems.length})`"
            :actions="sectionActions"
            :has-search="true"
            variant="slim"
            @search-query="($event) => (query = $event.toLowerCase())"
            >
            <cc-data-table
              :class="dataItems.length ? 'h-56' : 'h-16'"
              :headers="headers"
              :items="dataItems"
              :placeholder="$t('modals.rolesAndActionsManager.actionsPlaceholder')"
              :is-loading="isLoadingDataTable"
              @row-click="updateRoleActions('addActionsToRole', false, $event.actionId)"
              />
          </cc-section-table>
        </div>
      </validation-observer>

      <cc-error-message
        v-if="errors.length"
        :message="errors[0]"
        class="mt-4"
        />
    </template>

    <template #footer="{ close }">
      <div
        class="grid gap-x-4"
        :class="hasRole ? 'grid-cols-1' : 'grid-cols-2'"
        >
        <cc-button
          variant="secondary"
          :text="$t('commons.close')"
          @click.native="close"
          />
        <cc-button
          v-if="!hasRole"
          :text="$t('commons.create')"
          :is-loading="isLoading"
          :disabled="isLoading"
          :block="false"
          @click.native="updateRoleActions('addActionsToRole', true, currentRoleActions)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { isEmpty } from 'lodash';
import { camelToUnderscore } from '@/mixins/utils';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    role: {
      type: Object,
      required: false,
      default: () => {},
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      query: '',
      isLoading: false,
      isLoadingDataTable: false,
      roleName: null,
      updatedRole: null,
      errors: [],
      sectionActions: [],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.name'),
          value: 'actionId',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.description'),
          value: 'actionDescription',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
          class: 'w-24',
        },
      ],
    };
  },
  computed: {
    dataItems() {
      return this.availableActions
        ?.filter((action) => {
          if (this.query) {
            return (
              action?.actionId?.toLowerCase().includes(this.query)
              || action?.actionDescription?.includes(this.query)
            );
          }
          return action;
        })
        .sort((a, b) => a?.actionId.localeCompare(b?.actionId))
        .map((action, index) => ({
          index: index + 1,
          actionId: action.actionId || '–',
          actionDescription: action.actionDescription || '–',
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-add',
              text: this.$t('commons.add'),
              event: 'row-click',
            },
          ],
          isRowClickable: true,
        }));
    },
    hasRole() {
      return !this.isEmpty(this.currentRole);
    },
    currentRole: {
      get() {
        return this.updatedRole || this.role;
      },
      set(value) {
        this.updatedRole = value;
      },
    },
    currentRoleActions() {
      return this.updatedRole?.roleActions || this.role?.roleActions;
    },
    availableActions() {
      return this.actions.filter(
        action => !this.currentRole?.roleActions?.includes(action.actionId),
      );
    },
    name: {
      get() {
        return this.roleName || this.role?.name;
      },
      set(value) {
        this.roleName = this.camelToUnderscore(value);
      },
    },
  },
  async created() {
    this.$log.info('Created Roles And Actions Manager Modal');
  },
  methods: {
    isEmpty,
    camelToUnderscore,
    async updateRoleActions(actionHandle, emitSuccess, roleActions) {
      const { name } = this;

      try {
        this.errors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;


        this.currentRole = await this.$store.dispatch(`role/${actionHandle}`, {
          roleId: name,
          payload: { actions: [roleActions] },
        });

        if (emitSuccess) {
          this.$emit('success');
        }
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: updateRoleActions', actionHandle, err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
