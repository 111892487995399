import ApiService from '@/services/ApiService';

const version = '/v1';
const resource = `${version}/company`;

const resourceAdminCompany = `${version}/admin/company`;
const resourceAdminWallet = `${version}/admin/wallet`;
const resourceAdminJuoco = `${version}/admin/juoco`;
const resourceAdminUser = `${version}/admin/user`;

const resourceCompany = `${version}/company`;
const resourceWallet = `${version}/wallet`;

export default {
  async invite(payload) {
    return ApiService.securePost(`${resourceAdminCompany}/invite`, payload);
  },

  async resendInvite(payload) {
    return ApiService.securePost(`${resourceAdminCompany}/resendInvite`, payload);
  },

  async getAllCompanies() {
    return ApiService.get(`${resourceAdminCompany}/getAllWithTransactionCount`);
  },

  // Also used by user via frontend app
  async getAllCompaniesWithActiveOffers() {
    return ApiService.get(`${resource}/getWithActiveOffers`);
  },

  async getWithWallets() {
    return ApiService.get(`${resourceAdminCompany}/getWithWallets`);
  },

  async updateThresholds(payload, isAdmin) {
    return ApiService.securePost(`${isAdmin ? resourceAdminWallet : resourceWallet}/updateThresholds`, payload);
  },

  async createVirtualGood(companyId, payload, isAdmin) {
    return ApiService.securePost(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/virtualGood`, payload);
  },

  async updateVirtualGood(companyId, virtualGoodId, payload, isAdmin) {
    return ApiService.put(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/virtualGood/${virtualGoodId}`, payload);
  },

  async deleteVirtualGood(companyId, virtualGoodId, isAdmin) {
    return ApiService.delete(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/virtualGood/${virtualGoodId}`);
  },

  async addVouchers(virtualGoodId, payload, fileName, override) {
    const formData = new FormData();

    formData.append('file', payload, fileName);
    formData.append('virtualGoodId', virtualGoodId);
    formData.append('override', override || false);

    return ApiService.doSecureFileUpload(`${resourceAdminJuoco}/addVouchers`, formData);
  },

  async getVouchers(virtualGoodId, csvFormat) {
    return ApiService.get(`${resourceAdminJuoco}/getVouchers/${virtualGoodId}?csvFormat=${csvFormat || false}`);
  },

  async deleteVouchers(virtualGoodId) {
    return ApiService.delete(`${resourceAdminJuoco}/deleteVouchers/${virtualGoodId}`);
  },

  async createOffer(companyId, payload, isAdmin) {
    return ApiService.securePost(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/offer`, payload);
  },

  async updateOffer(companyId, offerId, payload, isAdmin) {
    return ApiService.put(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/offer/${offerId}`, payload);
  },

  async deleteOffer(companyId, offerId, isAdmin) {
    return ApiService.delete(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/offer/${offerId}`);
  },

  async getOffer(companyId, offerId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/offer/${offerId}`);
  },

  async getOffersByCompany(companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/offer`);
  },

  async getOnboardingCodes(companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/onboardingCode`);
  },
  async createOnboardingCode(companyId, payload, isAdmin) {
    return ApiService.securePost(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/onboardingCode`, payload);
  },
  async deleteOnboardingCode(companyId, onboardingCode, isAdmin) {
    return ApiService.delete(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/onboardingCode/${onboardingCode}`);
  },
  async getReceiptsByCompany(companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/receipts`);
  },

  async getCompany(companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}`);
  },

  async updateCompany(companyId, payload, isAdmin) {
    return ApiService.put(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}`, payload);
  },

  async deleteCompany(companyId) {
    return ApiService.delete(`${resourceAdminCompany}/${companyId}`);
  },

  async getWallets(companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminCompany : resourceCompany}/${companyId}/getWallets`);
  },

  async getPlatformWallets() {
    return ApiService.get(`${resourceAdminWallet}/platformWallets`);
  },

  async getTransactions(walletId, companyId, isAdmin) {
    return ApiService.get(`${isAdmin ? resourceAdminWallet : resourceWallet}/getTransactions/${isAdmin ? '' : 'company/' }${isAdmin ? walletId : companyId}`);
  },

  async redeem(companyId, payload) {
    return ApiService.securePost(`${resourceAdminCompany}/${companyId}/wallet/redeem`, payload);
  },

  async topUpCompanySponsorWallet(payload) {
    return ApiService.securePost(`${resourceAdminCompany}/topUpCompanySponsorWallet`, payload);
  },

  async transferSponsorWalletLeftOver(companyId) {
    return ApiService.securePost(`${resourceAdminCompany}/transferSponsorWalletLeftOver?companyId=${companyId}`);
  },

  async getPendingUsers(companyId) {
    return ApiService.get(`${resourceAdminUser}/pending/company/${companyId}`);
  },

};
