<template>
  <div>
    <label
      v-if="label"
      :for="id || name"
      :class="labelClass"
      class="flex"
      >
      <slot>{{ label }}</slot>
      <cc-required-label v-if="isRequired" />
    </label>

    <span
      v-if="svg"
      class="absolute inset-y-0 left-0 flex items-center px-3"
      >
      <cc-svg-base
        :svg="svg"
        class="text-gray-500 fill-current"
        />
    </span>

    <validation-provider
      v-slot="{ classes, errors }"
      :ref="validationRef"
      :rules="validationRules"
      :name="validationName || label"
      :vid="validationId"
      :mode="validationInteractionMode"
      >
      <div class="relative">
        <div
          v-if="hasPasswordToggle"
          class="absolute inset-y-0 right-0 flex items-center px-3"
          >
          <cc-svg-base
            svg="icons/icon-eye"
            :class="isPasswordToggled ? 'text-gray-500' : 'text-gray-300'"
            class="cursor-pointer hover:text-gray-500 select-none"
            @click.native="togglePasswordField"
            />
        </div>

        <input
          :id="id"
          :name="name"
          :type="getType"
          :class="inputClass(classes)"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :autocapitalize="autocapitalize"
          :autocorrect="autocorrect"
          :readonly="readonly"
          :disabled="disabled"
          :data-ruid="ruid"
          :data-has-error="handleErrors(errors)"
          :value="value"
          :step="step"
          :min="min"
          :max="max"
          :maxlength="maxlength"
          :minlength="minlength"
          :required="required"
          :autofocus="autofocus"
          :passwordrules="passwordrules"
          :pattern="pattern"
          @input="inputHandler($event)"
          >
      </div>

      <cc-error-message
        v-if="hasErrorMessage"
        :message="errors[0]"
        />
    </validation-provider>
  </div>
</template>

<script>
import { camelCase } from '@/mixins/utils';
import commonProps from '@/mixins/commonProps';
import validator from '@/mixins/validator';

// Valid variants
const variantProps = ['regular', 'slim'];

export default {
  name: 'CcInput',
  mixins: [commonProps, validator],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    variant: {
      type: String,
      required: false,
      default: 'regular',
      validator: value => variantProps.includes(value),
    },
    masked: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'text-left',
    },
    emitCamelCase: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRef: {
      type: String,
      required: false,
      default: null,
    },
    hasErrorMessage: {
      type: Boolean,
      default: true,
    },
    validationState: {
      type: String,
      required: false,
      default: null,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSteper: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPasswordToggled: false,
    };
  },
  computed: {
    getType() {
      return this.isPasswordToggled ? 'text' : this.type;
    },
    labelClass() {
      return ['w-full pb-1 font-body text-sm', 'inline-block text-gray-600'];
    },
  },
  created() {
    this.$emit('created');
  },
  methods: {
    handleErrors(errors) {
      if (!this.hasErrorMessage) {
        this.$emit('errors', errors);
      }
    },
    camelCase,
    inputHandler(event, errors) {
      const { emitCamelCase } = this;

      if (emitCamelCase) {
        return this.$emit('input', camelCase(event.target.value));
      }

      if (errors?.length) {
        this.$emit('errors', errors);
      }
      return this.$emit('input', event.target.value);
    },
    togglePasswordField() {
      this.isPasswordToggled = !this.isPasswordToggled;
    },
    inputClass(validationFlags) {
      return [
        this.svg ? 'pl-10' : null,
        this.align,
        validationFlags?.invalid ? 'border-secondary-red' : null,
        this.validationState,
        this.disabled ? 'disabled:opacity-50 cursor-not-allowed bg-gray-200' : 'hover:border-primary-dark',
        this.variant === 'slim' ? 'py-2 px-3' : null,
        this.variant === 'regular' ? 'p-3 ' : null,
        this.bgColor ? [this.bgColor, 'border-gray-100'] : 'border-gray-300',
        'w-full',
        'font-body text-gray-800',
        'rounded-md border',
        'focus:outline-none focus:border-primary-dark',
        'transition duration-200 ease-in',
        'appearance-none',
        this.showSteper ? 'show-stepper' : null,
      ];
    },
  },
};
</script>
