<template>
  <div>
    <cc-section-table
      :title="$t('pages.messageDrafts.title')"
      :has-search="false"
      @search-query="($event) => (query = $event.toLowerCase())"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @row-click="handleRowClick"
        >
        <template #name="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline"
            :data-ruid="item.id"
            >
            {{ item.name }}
          </cc-text>
        </template>

        <template #deliveryMethods="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline capitalize"
            :data-ruid="item.deliveryMethods"
            >
            {{ item.deliveryMethods }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>
  </div>
</template>

<script>
import { sentenceCase, getDeliveryMethods } from '@/mixins/utils';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoadingDataTable: false,
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.campaignName'),
          value: 'name',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.draftId'),
          value: 'id',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.deliveryMethods'),
          value: 'deliveryMethods',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.category'),
          value: 'category',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.deliveryTag'),
          value: 'deliveryTag',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.messageLinksTo'),
          value: 'href',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.userSegments'),
          value: 'userTags',
          isCSVField: true,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      drafts: [],
    };
  },
  computed: {
    ...mapGetters({
      getTagsForDataTable: 'message/getTagsForDataTable',
    }),
    dataItems() {
      /**
       * @todo at some point search input might become a necessity (query handler)
       */
      return this.drafts
        ?.slice()
        .filter(message => message)
        .map((m, index) => {
          let message;

          try {
            message = JSON.parse(m.content);
          } catch (error) {
            message = null;
            this.$log.error('Drafts Error:', error);
          }

          return {
            index: index + 1,
            id: m?.id,
            name: message?.name || '–',
            href: this.sentenceCase(
              message?.payload?.[
                Object.keys(message.payload).filter(e => message.payload[e])?.[0]
              ]?.meta.href?.split(':')[1] || '–',
            ),
            category: this.sentenceCase(message?.tag?.split(':')[0]) || '–',
            deliveryTag: this.sentenceCase(message?.tag?.split(':')[1]) || '–',
            deliveryMethods: this.getDeliveryMethods(message) || '–',
            userTags: this.getTagsForDataTable(message),
            actions: [{ icon: 'icon-arrow-right' }],
            isRowClickable: true,
          };
        })
        .reverse();
    },
  },
  async created() {
    await this.getDrafts();
  },
  methods: {
    sentenceCase,
    getDeliveryMethods,
    async getDrafts() {
      try {
        this.isLoadingDataTable = true;

        const response = await this.$store.dispatch('draft/getDrafts');

        this.$log.info('getDrafts', response);

        this.drafts = response;

        this.isLoadingDataTable = false;
      } catch (err) {
        this.$log.error('Error: getDrafts', err);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    handleRowClick(draft) {
      /**
       * Find the original object because the received `draftMessage` object parameter is a modified
       * version for data-table rendering purposes.
       */
      const draftMessage = this.drafts.find(d => d.id === draft.id);

      let payload;

      try {
        payload = JSON.parse(draftMessage.content);
      } catch (error) {
        payload = {};
      }

      payload.draftId = draftMessage.id;

      this.$log.info('draftMessagePayload', payload);

      return this.$router.push({
        name: 'message',
        params: { type: 'draft-message', payload },
      }).catch(() => {});
    },
  },
};
</script>

<style></style>
