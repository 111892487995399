<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t('modals.addCompanyAddress.title')"
    @close="$emit('close')"
    >
    <template #body="">
      <validation-observer ref="form">
        <cc-company-address-item
          :has-focus-on-label="true"
          :address="address"
          />
      </validation-observer>

      <cc-error-message
        v-if="errors.length"
        :message="errors[0]"
        class="mt-4"
        />
    </template>

    <template #footer="{ close }">
      <div class="grid grid-cols-2 gap-x-4">
        <cc-button
          variant="secondary"
          :text="$t('commons.cancel')"
          @click.native="close"
          />
        <cc-button
          :text="$t('commons.add')"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="($emit('add-address', address), close())"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import validator from '@/mixins/validator';
import { v4 as uuidv4 } from 'uuid';

export default {
  mixins: [validator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    invitee: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      address: {
        id: uuidv4(),
        label: '',
        street: '',
        number: '',
        zipCode: '',
        city: '',
        state: '',
        country: 'Switzerland',
        isOffice: true,
      },
      errors: [],
    };
  },
  created() {
    this.$log.info('created AddCompanyAddressModal');
  },
  methods: {
    uuidv4,
  },
};
</script>
