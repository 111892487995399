<template>
  <cc-modal
    :is-open="isOpen"
    transition-name="slide-up"
    :heading="$t('modals.voucherInventory.title')"
    @close="$emit('close')"
    >
    <template #body>
      <cc-data-table
        :headers="generatedHeaders"
        :items="paginate(dataItems, pageSize, pageNumber)"
        class="h-96"
        />
      <div class="flex items-center pt-4 justify-between">
        <div>
          <cc-text
            variant="body-sm"
            class="inline"
            >
            {{ $t('commons.showing') }}
          </cc-text>
          <cc-text
            variant="body-sm-bold"
            class="inline"
            >
            {{ pageNumber * pageSize - pageSize + 1 }}
          </cc-text>
          <cc-text
            variant="body-sm"
            class="inline lowercase"
            >
            {{ $t('commons.to') }}
          </cc-text>
          <cc-text
            variant="body-sm-bold"
            class="inline"
            >
            {{
              pageSize === dataItems.length
                ? dataItems.length :
                  pageAmount === pageNumber
                    ? (pageAmount * pageSize) - pageSize + (dataItems.length % pageSize)
                    : pageNumber * pageSize
            }}
          </cc-text>
          <cc-text
            variant="body-sm"
            class="inline"
            >
            {{ $t('commons.of') }}
          </cc-text>
          <cc-text
            variant="body-sm-bold"
            class="inline"
            >
            {{ dataItems.length }}
          </cc-text>
        </div>

        <cc-pagination
          :page-amount="pageAmount"
          :page-number="pageNumber"
          @page-number="($event) => (pageNumber = $event)"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <div class="flex justify-end">
        <cc-button
          :text="$t('commons.close')"
          :block="false"
          class="flex-shrink"
          @click.native="close"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    voucherInventory: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      email: null,
      name: null,
      onboardingCode: null,
      errors: [],
      tag: null,
      pageSize: 50,
      pageNumber: 1,
    };
  },
  computed: {
    pageAmount() {
      return Math.ceil(this.dataItems.length / this.pageSize);
    },
    generatedHeaders() {
      let voucherToExtractHeaderItems = cloneDeep(this.voucherInventory?.[0]);

      voucherToExtractHeaderItems = {
        index: null,
        ...voucherToExtractHeaderItems,
        ...voucherToExtractHeaderItems?.content,
      };

      delete voucherToExtractHeaderItems?.content;

      return Object.keys(voucherToExtractHeaderItems || {}).map(key => ({
        text: this.$te(`commons.fieldNames.${key}`) ? this.$t(`commons.fieldNames.${key}`) : key,
        value: key,
        isCSVField: true,
      }));
    },
    dataItems() {
      return cloneDeep(this.voucherInventory)?.map((voucher, index) => {
        const { content } = voucher;
        const cleanedVoucher = voucher;

        Object.keys(cleanedVoucher).forEach((el) => {
          cleanedVoucher[el] = cleanedVoucher[el] || '–';
        });

        const dataItems = {
          index: index + 1,
          ...cleanedVoucher,
          ...content,
        };

        return dataItems;
      });
    },
  },
  methods: {
    paginate(array, pageSize, pageNumber) {
      return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
  },
};
</script>

<style>
</style>
