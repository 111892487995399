/* eslint-disable camelcase */
import {
  extend, //
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from 'vee-validate';

import {
  // min,
  required,
  email,
  // length,
  alpha_num,
  min_value,
  integer,
  is,
  // is_not,
  regex,
} from 'vee-validate/dist/rules';

import i18n from '@/i18n';

setInteractionMode('eager');


extend('min_value', min_value);

extend('required', {
  ...required,
  message: fieldName => i18n.t('validations.requiredField', { fieldName }),
});

extend('length', {
  params: ['length'],
  validate(value, { length }) {
    return value.length === parseInt(length, 10);
  },
  message: (fieldName, params) => i18n.t('validations.invalidLength', { fieldName, length: params.length }),
});

extend('password', {
  ...required,
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: () => i18n.t('validations.invalidPasswordConfirmation'),
});

extend('email', {
  ...email,
  message: () => i18n.t('validations.invalidEmailAddress'),
});

extend('is', {
  ...is,
  message: () => i18n.t('validations.invalidEmailAddress'),
});

extend('validURL', {
  validate(value) {
    const validURL = /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;
    return validURL.test(value);
  },
  message: () => i18n.t('validations.invalidURL'),
});

extend('onlyLowerCaseLettersOrNumbers', {
  validate(value) {
    const isLowerCaseLettersAndNumbers = /^[a-z0-9]*$/;
    return isLowerCaseLettersAndNumbers.test(value);
  },
  message: fieldName => i18n.t('validations.lowercaseLettersAndNumbers', { fieldName }),
});
extend('onlyUpperCaseLettersOrNumbers', {
  validate(value) {
    const isUpperCaseLettersAndNumbers = /^[A-Z0-9]*$/;
    return isUpperCaseLettersAndNumbers.test(value);
  },
  message: fieldName => i18n.t('validations.uppercaseLettersAndNumbers', { fieldName }),
});
extend('validUUID', {
  validate(value) {
    const validUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return validUUID.test(value);
  },
  message: fieldName => i18n.t('validations.validUUID', { fieldName }),
});

extend('integer', {
  ...integer,
  message: () => i18n.t('validations.invalidInteger'),
});

extend('passwordComplexity', {
  validate(value) {
    const strongPassword = new RegExp(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
    );

    return strongPassword.test(value);
  },
  message: () => i18n.t('validations.invalidPasswordComplexity'),
});

extend('alpha_num', {
  ...alpha_num,
  message: () => i18n.t('validations.alphanumericOnly'),
});

extend('minRedeemAmount', {
  ...min_value,
  message: () => i18n.t('validations.minRedeemAmount'),
});

extend('hasUniqueRuleName', {
  ...required,
  validate(value, hasUniqueRuleName) {
    return {
      valid: hasUniqueRuleName.allowFalse !== 'false',
    };
  },
  message: fieldName => i18n.t('validations.uniqueField', { fieldName }),
});

extend('isUnique', {
  ...required,
  validate(value, isUniqueValue) {
    return {
      valid: isUniqueValue.allowFalse !== 'false',
    };
  },
  message: fieldName => i18n.t('validations.uniqueField', { fieldName }),
});

extend('checkbox', {
  ...required,
  validate(value, state) {
    return {
      valid: state.allowFalse !== 'false',
    };
  },
  message: fieldName => i18n.t('validations.requiredField', { fieldName }),
});

extend('hasValidIBAN', {
  ...required,
  validate(value, hasValidIBAN) {
    return {
      valid: hasValidIBAN.allowFalse !== 'false',
    };
  },
  message: () => i18n.t('validations.invalidIBAN'),
});

extend('minRuleAmount', {
  validate: (value, amount) => ({
    required: true,
    valid: parseInt(amount, 10) > 0,
  }),
  computesRequired: true,
  message: () => i18n.t('validations.minRuleAmount'),
});

extend('greaterThanZero', {
  validate: (value) => {
    if (value > 0) {
      return true;
    }
    return false;
  },
  message: fieldName => i18n.t('validations.greaterThanZero', { fieldName }),
});

extend('ruleConfig', {
  validate: value => !!Object.keys(value).length,
  message: () => i18n.t('validations.challengeRuleConfigurator'),
});

extend('regex', {
  ...regex,
  message: fieldName => i18n.t('validations.alphanumericOnly', { fieldName }),
});


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
