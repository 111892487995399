<template>
  <div>
    <cc-section-table
      :title="$t('pages.challenges.title')"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => (query = $event.toLowerCase())"
      @create-challenge="handleCreateChallenge"
      @export="exportHandler"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @row-click="handleRowClick"
        @clone="cloneChallenge"
        >
        <template #name="{ item }">
          <span class="h-8 w-8 mr-3">
            <cc-image
              :has-placeholder="true"
              class="rounded-full inline-block"
              :src="item.imageUrl"
              />
          </span>
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline"
            :data-ruid="item.id"
            >
            {{ item.name }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  convertMilliseconds,
  getTimeFrame,
  sortArrayByCreatedAt,
  formatDuration,
} from '@/mixins/utils';
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';

export default {
  data() {
    return {
      query: '',
      isLoadingDataTable: false,
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'create-challenge',
          dataRuid: 'challenges__create-challenge',
          variant: 'primary',
          text: this.$t('pages.challenges.newChallenge'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.challengeName'),
          value: 'name',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.challengeId'),
          value: 'id',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.expirationDate'),
          value: 'expireDate',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.runningTime'),
          value: 'durationInMillis',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.autoSubscribe'),
          value: 'autoSubscribe',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.greenCoins'),
          value: 'credits',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.greenPoints'),
          value: 'points',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.status'),
          value: 'status',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      challenges: st => st.challenge.challenges,
    }),
    dataItems() {
      return sortArrayByCreatedAt(this.challenges?.slice())
        .filter((challenge) => {
          if (this.query) {
            return (
              challenge?.id?.toLowerCase().includes(this.query)
              || challenge?.achievement.name?.toLowerCase().includes(this.query)
              || challenge.achievement?.resources?.de?.title?.toLowerCase().includes(this.query)
              || challenge.achievement?.resources?.en?.title?.toLowerCase().includes(this.query)
            );
          }
          return challenge;
        })
        .map((challenge, index) => ({
          index: index + 1,
          name: challenge.achievement?.name || challenge.achievement?.resources?.en?.title,
          id: challenge.id,
          imageUrl:
            challenge?.achievement?.resources?.de?.imageUrl
            || challenge?.achievement?.resources?.en?.imageUrl,
          expireDate: new Date(challenge.expireDate).toLocaleDateString('en-US'),
          durationInMillis: this.getChallengeDuration(challenge.durationInMillis),
          autoSubscribe: this.$t(challenge.autoSubscribe ? 'commons.yes' : 'commons.no'),
          credits: challenge.achievement.reward.credits,
          points: challenge.achievement.reward.points,
          status: this.$t(challenge.active ? 'commons.active' : 'commons.inactive'),
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-clone',
              text: this.$t('commons.duplicate'),
              event: 'clone',
            },
            { icon: 'icon-arrow-right', event: 'row-click' },
          ],
          isRowClickable: true,
        }));
    },
  },
  async created() {
    await this.getAllChallenges();
  },
  methods: {
    getTimeFrame,
    convertMilliseconds,
    cloneChallenge(challenge) {
      /**
       * Find the original object because the received `challenge` object parameter is a modified
       * version for data-table rendering purposes.
       */
      const payload = this.challenges.find(c => c.id === challenge.id);
      delete payload?.id;
      delete payload?.achievement?.id;
      delete payload?.messages?.challengeNewBroadcast?.createdAt;
      delete payload?.messages?.challengeSuccess?.createdAt;

      return this.$router.push({
        name: 'challenge',
        params: { type: 'clone-challenge', payload },
      });
    },
    async getAllChallenges() {
      try {
        this.isLoadingDataTable = true;

        await this.$store.dispatch('challenge/getAllChallenges');

        // this.$log.info('getAllChallenges', this.companyProducts);

        this.isLoadingDataTable = false;
      } catch (err) {
        this.$log.error('Error: getAllChallenges', err);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    handleRowClick(challenge) {
      this.$log.info('challenge', challenge.id);

      return this.$router
        .push({
          name: 'challenge',
          params: { type: 'challenge', challengeId: challenge.id },
        })
        .catch(() => {});
    },
    handleCreateChallenge() {
      return this.$router
        .push({ name: 'challenge', params: { type: 'new-challenge' } })
        .catch(() => {});
    },
    getChallengeDuration(ms) {
      const { timeFrame, duration } = formatDuration(ms);
      return this.$tc(`commons.timeFrames.${[timeFrame]}`, duration, { duration });
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const filename = generateTimeStampedFilename('scc_challenges');

        this.dataItems.forEach((item, index) => {
          itemsFormatted.push({
            index: index + 1,
            name: item.name,
            id: item.id,
            expireDate: item.expireDate.replace(/,/g, ''),
            durationInMillis: item.durationInMillis,
            autoSubscribe: item.autoSubscribe,
            credits: item.credits,
            points: item.points,
            status: item.status,
          });
        });

        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>
