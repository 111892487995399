import Vue from 'vue';
import CompanyService from '@/services/CompanyService';
import TagService from '@/services/TagService';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
  companies: [],
  sponsorCompanies: [],
});


const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};


const actions = {
  async getVouchers(context, payload) {
    try {
      const { virtualGoodId, csvFormat } = payload;
      const response = await CompanyService.getVouchers(virtualGoodId, csvFormat);

      // Vue.$log.info('Response: Vuex: getVouchers', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getVouchers', err);
      throw err;
    }
  },

  async deleteVouchers(context, payload) {
    try {
      const { virtualGoodId } = payload;
      const response = await CompanyService.deleteVouchers(virtualGoodId);

      Vue.$log.info('Response: Vuex: deleteVouchers', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteVouchers', err);
      throw err;
    }
  },

  async createOffer(context, payload) {
    try {
      const { companyId, offer } = payload;
      const response = await CompanyService.createOffer(companyId, offer, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: createOffer', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: createOffer', err);
      throw err;
    }
  },

  async updateOffer(context, payload) {
    try {
      const { companyId, offerId, offer } = payload;
      const response = await CompanyService.updateOffer(companyId, offerId, offer, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: updateOffer', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateOffer', err);
      throw err;
    }
  },

  async deleteOffer(context, payload) {
    try {
      const { companyId, offerId } = payload;
      const response = await CompanyService.deleteOffer(companyId, offerId, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: updateOffer', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteOffer', err);
      throw err;
    }
  },

  async createVirtualGood(context, payload) {
    try {
      const { companyId, virtualGood } = payload;
      const response = await CompanyService.createVirtualGood(companyId, virtualGood, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: createVirtualGood', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: createVirtualGood', err);
      throw err;
    }
  },

  async updateVirtualGood(context, payload) {
    try {
      const { companyId, virtualGoodId, virtualGood } = payload;

      const response = await CompanyService.updateVirtualGood(companyId, virtualGoodId, virtualGood, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: updateVirtualGood', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateVirtualGood', err);
      throw err;
    }
  },

  async deleteVirtualGood(context, payload) {
    try {
      const { companyId, virtualGoodId } = payload;

      const response = await CompanyService.deleteVirtualGood(companyId, virtualGoodId, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: updateVirtualGood', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteVirtualGood', err);
      throw err;
    }
  },

  async getAllCompanies({ commit }) {
    try {
      const response = await CompanyService.getAllCompanies();
      commit('setState', ['companies', response]);

      // Vue.$log.info('Response: Vuex: getAllCompanies', response);
    } catch (err) {
      Vue.$log.error('Error: Vuex: getAllCompanies', err);
      throw err;
    }
  },

  async getAllCompaniesWithActiveOffers() {
    try {
      const response = await CompanyService.getAllCompaniesWithActiveOffers();

      return response;
      // Vue.$log.info('Response: Vuex: getAllCompaniesWithActiveOffers', response);
    } catch (err) {
      Vue.$log.error('Error: Vuex: getAllCompaniesWithActiveOffers', err);
      throw err;
    }
  },

  async getWithWallets({ commit }) {
    try {
      const response = await CompanyService.getWithWallets();
      commit('setState', ['sponsorCompanies', response]);

      // Vue.$log.info('Response: Vuex: getAllCompanies', response);
    } catch (err) {
      Vue.$log.error('Error: Vuex: getWithWallets', err);
      throw err;
    }
  },

  async getOffer(context, { companyId, offerId }) {
    try {
      const response = await CompanyService.getOffer(companyId, offerId, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: getOffer', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getOffer', err);
      throw err;
    }
  },

  async getOffersByCompany(context, companyId) {
    try {
      const response = await CompanyService.getOffersByCompany(companyId, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: getOffersByCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getOffersByCompany', err);
      throw err;
    }
  },

  async getOnboardingCodes(context, companyId) {
    try {
      const response = await CompanyService.getOnboardingCodes(companyId, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: getOnboardingCode', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getOnboardingCode', err);
      throw err;
    }
  },

  async createOnboardingCode(context, payload) {
    try {
      const response = await CompanyService.createOnboardingCode(payload.companyId, payload.body, context.rootGetters['user/isContextAdmin']);
      Vue.$log.info('Response: Vuex: createOnboardingCode', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: createOnboardingCode', err);
      throw err;
    }
  },

  async deleteOnboardingCode(context, payload) {
    const { companyId, onboardingCodeId } = payload;
    try {
      const response = await CompanyService.deleteOnboardingCode(companyId, onboardingCodeId, context.rootGetters['user/isContextAdmin']);
      Vue.$log.info('Response: Vuex: deleteOnboardingCode', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteOnboardingCode', err);
      throw err;
    }
  },

  async getReceiptsByCompany(context, companyId) {
    try {
      const response = await CompanyService.getReceiptsByCompany(companyId, context.rootGetters['user/isContextAdmin']);

      // Vue.$log.info('Response: Vuex: getReceiptsByCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getReceiptsByCompany', err);
      throw err;
    }
  },

  async findCompanyUsersByTag(context, payload) {
    try {
      const response = await TagService.findCompanyUsersByTag(payload.companyId, payload.tags);

      Vue.$log.info('Response: Vuex: findCompanyUsersByTag', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: findCompanyUsersByTag', err);
      throw err;
    }
  },

  async getCompany(context, payload) {
    try {
      const response = await CompanyService.getCompany(payload.companyId, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: getCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getCompany', err);
      throw err;
    }
  },

  async updateCompany(context, payload) {
    try {
      const response = await CompanyService.updateCompany(payload.companyId, payload.body, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: updateCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateCompany', err);
      throw err;
    }
  },

  async deleteCompany(context, companyId) {
    try {
      const response = await CompanyService.deleteCompany(companyId);

      Vue.$log.info('Response: Vuex: deleteCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteCompany', err);
      throw err;
    }
  },

  async getWallets(context, companyId) {
    try {
      const response = await CompanyService.getWallets(companyId, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: getWallets', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getWallets', err);
      throw err;
    }
  },

  async getPlatformWallets() {
    try {
      const response = await CompanyService.getPlatformWallets();

      // Vue.$log.info('Response: Vuex: getPlatformWallets', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getPlatformWallets', err);
      throw err;
    }
  },

  async getTransactions(context, { walletId, companyId }) {
    try {
      const response = await CompanyService.getTransactions(walletId, companyId, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: getTransactions', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getTransactions', err);
      throw err;
    }
  },

  async redeem(context, { companyId, payload }) {
    try {
      const response = await CompanyService.redeem(companyId, payload);

      Vue.$log.info('Response: Vuex: redeem', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: redeem', err);
      throw err;
    }
  },

  async topUpCompanySponsorWallet(context, payload) {
    try {
      const response = await CompanyService.topUpCompanySponsorWallet(payload);

      Vue.$log.info('Response: Vuex: topUpCompanySponsorWallet', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: topUpCompanySponsorWallet', err);
      throw err;
    }
  },

  async transferSponsorWalletLeftOver(context, companyId) {
    try {
      const response = await CompanyService.transferSponsorWalletLeftOver(companyId);

      Vue.$log.info('Response: Vuex: transferSponsorWalletLeftOver', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: transferSponsorWalletLeftOver', err);
      throw err;
    }
  },

  async updateThresholds(context, payload) {
    try {
      const response = await CompanyService.updateThresholds(payload, context.rootGetters['user/isContextAdmin']);

      Vue.$log.info('Response: Vuex: updateThresholds', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateThresholds', err);
      throw err;
    }
  },

  async invite({ state }, payload) {
    const {
      email, //
      name,
      companyId,
      onboardingCode,
      resend,
      alias,
    } = payload;

    Vue.$log.info('invite', state);

    const lang = 'en';

    // Empty address fields are required by Custodian, otherwise it is not necessary at this stage
    const body = {
      emailRequest: {
        email,
        lang,
      },
      company: {
        name,
        id: companyId,
        context: process.env.VUE_APP_CONTEXT,
        alias,
      },
      onboardingCode,
      address: {
        street: '',
        city: '',
        zip: '',
        phone: '',
      },
    };

    if (resend) {
      delete body.onboardingCode;
      delete body.address;
    }

    try {
      const response = await CompanyService[resend ? 'resendInvite' : 'invite'](body);
      Vue.$log.info('Response: Vuex: invite', response);
    } catch (err) {
      Vue.$log.error('Error: Vuex: invite', err);
      throw err;
    }
  },

  async addFeatureTagsToCompany(ctx, payload) {
    try {
      const response = await TagService.addFeatureTagsToCompany(payload);

      Vue.$log.info('Response: Vuex: addFeatureTagsToCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: addFeatureTagsToCompany', err);
      throw err;
    }
  },

  async deleteFeatureTagsFromCompany(ctx, payload) {
    try {
      const response = await TagService.deleteFeatureTagsFromCompany(payload);

      Vue.$log.info('Response: Vuex: deleteFeatureTagsFromCompany', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteFeatureTagsFromCompany', err);
      throw err;
    }
  },
};


const getters = {
  /**
   *
   * @param {string} companyId – Expects a companyId
   */
  getCompanyName: st => (companyId) => {
    if (!companyId) {
      return false;
    }
    // Vue.$log.info('Vuex: companyId', companyId, st);

    const company = st.companies?.find(rec => rec?.company?.id === companyId)?.company;

    // Vue.$log.info('Vuex: getCompanyName', company?.metaPublic?.resources?.en?.name || company?.name);

    return company?.metaPublic?.resources?.en?.name || company?.metaPublic?.resources?.de?.name || company?.name;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
