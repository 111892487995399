<template>
  <div class="flex overflow-auto pb-20 mb-12">
    <div
      v-for="lang in supportedLangs"
      :key="lang"
      class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
      >
      <cc-heading
        tag="h5"
        class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
        >
        {{ $t(`commons.langs.${lang}`) }}
      </cc-heading>

      <div class="grid gap-6 grid-cols-1">
        <!-- :value="virtualGoodResources[lang].details[i].data" -->
        <!-- :class="virtualGoodResources[lang] ? null : 'pointer-events-none opacity-50'" -->
        <cc-editor
          :id="`editor-${lang}-${index}`"
          :key="lang"
          :lang="lang"
          :value="value"
          @input="editorJSInputHandler"
          />
      </div>
      <!-- {{ virtualGoodResources[lang].details[i].data }} -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
    }),
  },
  methods: {
    uuidv4,
    editorJSInputHandler(data) {
      // const lang = e.id.split('-')[1];

      this.$log.info('event', data, data.id, data.lang);
      this.$emit('input', data);

      // if (!this.virtualGoodResources?.[lang]?.details?.length) {
      //   this.$log.info('inserting empty details');
      //   this.$set(this.virtualGoodResources[lang], 'details', []);
      // }
      // this.$log.info('index', this.virtualGoodResources[lang].details[index]);
      // this.$set(this.virtualGoodResources[lang].details, index, { data: e.data });
      // this.$set(this.virtualGoodResources[lang].details[index], 'data', e.data);

      // if (!e.data.blocks.length) {
      //   this.$delete(this.virtualGoodResources[lang].details, index);
      // }
    },
  },
};
</script>

<style>
</style>
