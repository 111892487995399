import Vue from 'vue';
// import i18n from '@/i18n';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
  counter: 0,
  appState: {
    isProd: process.env.VUE_APP_ENV === 'production',
    isStaging: process.env.VUE_APP_ENV === 'staging',
    isDev: process.env.VUE_APP_ENV === 'dev',
    isDevelopment: process.env.VUE_APP_ENV === 'development',
  },
  featureFlags: {
    messages: process.env.VUE_APP_FEATURE_MESSAGES === 'true',
    roles: process.env.VUE_APP_FEATURE_ROLES === 'true',
  },
  supportedLangs: ['en', 'de', 'fr', 'it'],
});

const mutations = {
  setAppState(state, [prop, value]) {
    Vue.set(state.appState, prop, value);
  },
  increment(state) {
    state.counter += 1;
  },
};

const actions = {
  increment({ commit }) {
    commit('increment');
  },


  setTimeout(ctx, duration) {
    /**
     * Use this to simulate network delays
     * by calling it within async calls in debug time
     * ie. await ctx.dispatch('setTimeout', 3000);
     */
    return new Promise((resolve) => {
      setTimeout(() => {
        Vue.$log.info('Timeout is resolving after', duration);
        resolve(duration);
      }, duration);
    });
  },
};

const getters = {
  supportedLangs: st => st.supportedLangs,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
