<template>
  <div>
    <form
      v-if="(isInitial || isSaving) && images.length === 0"
      enctype="multipart/form-data"
      novalidate
      >
      <label
        class="dropbox"
        >
        <input
          type="file"
          :name="uploadFieldName"
          :disabled="isSaving || disabled"
          accept="image/png,image/jpeg"
          class="hidden w-full opacity-0 cursor-pointer h-24"
          @change="fileHandler($event)"
          >
        <div class="w-5 h-5 mb-2">
          <cc-svg-base
            svg="icons/icon-plus"
            class="text-primary-light"
            />
        </div>

        <cc-text
          v-if="isInitial"
          variant="body"
          align="center"
          color="text-primary-light"
          >
          {{ placeholder }}
        </cc-text>

        <cc-text v-if="isSaving">
          {{ $t('configs.uploadSaving', {
            uploadFileCount: $tc('configs.uploadFileCount', fileCount, { fileCount })
          }) }}
        </cc-text>
      </label>
    </form>

    <!--SUCCESS-->
    <div v-if="isSuccess || images.length">
      <ul class="list-unstyled pb-4">
        <li
          v-for="(item, i) in images || uploadedFiles"
          :key="i"
          class="flex justify-center"
          >
          <img
            :src="item"
            class="h-24 w-full object-cover rounded-2xl"
            >
        </li>
      </ul>

      <cc-text
        v-if="isSuccess"
        class="pb-4 text-center flex items-center justify-center"
        >
        {{ $t('configs.uploadSuccess', { amount: uploadedFiles.length }) }}
      </cc-text>

      <cc-button
        variant="secondary"
        :text="$t('commons.delete')"
        @click.native="reset(), $emit('reset')"
        />
    </div>


    <div v-if="isFailed">
      <cc-text
        class="text-secondary-red text-center mb-4"
        >
        {{ $t('configs.uploadFail') }}
      </cc-text>

      <cc-button
        variant="secondary"
        :text="$t('commons.tryAgain')"
        @click.native="reset"
        />
    </div>
  </div>
</template>

<script>
import commonProps from '@/mixins/commonProps';
import FileService from '@/services/FileService';
import { mapGetters } from 'vuex';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  mixins: [commonProps],
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      uploadedFiles: null,
      uploadError: null,
      currentStatus: null,
      fileCount: 0,
      uploadFieldName: 'image',
      maxFileSize: 1048576,
    };
  },
  computed: {
    ...mapGetters({
      isContextAdmin: 'user/isContextAdmin',
    }),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    // TODO: This whole component can be refactored a bit to tidy it up
    async fileHandler(event) {
      const fileList = event.target.files;

      if (!fileList.length) return;

      this.currentStatus = STATUS_SAVING;

      this.$log.info('fileList', fileList);

      // `/file` endpoint does not accept file names that includes `.`
      // const fileName = fileList[0].name.slice(0, fileList[0].name.lastIndexOf('.'));
      // const fileExtension = fileList[0].name.slice(fileList[0].name.lastIndexOf('.'));
      // const newFileName = `${fileName.replaceAll('.', '-')}${fileExtension}`;


      try {
        if (fileList[0].size >= this.maxFileSize) {
          throw Error('File is too big.');
        }

        this.fileCount = fileList.length;

        const response = await FileService.uploadFile(fileList[0], this.isContextAdmin);

        this.$log.info('after upload', response);

        this.uploadedFiles = [].concat(response.payload.location);

        this.$emit('upload-complete', this.uploadedFiles);

        this.currentStatus = STATUS_SUCCESS;
      } catch (error) {
        // this.$log.info('Upload error', error?.name, error?.message);
        // this.uploadError = error?.message;
        this.currentStatus = STATUS_FAILED;
      } finally {
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      // this.$log.info('Reset currentStatus', this.currentStatus);
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropbox {
  @apply relative bg-primary-light cursor-pointer bg-opacity-10 flex flex-col
    items-center h-24 transition-all duration-500 ease-out rounded-2xl
    justify-center hover:bg-primary-light hover:bg-opacity-20 border-dashed border-2
    border-primary-light;
}

.input-file {
  /* opacity: 0; */
  /* width: 100%; */
  /* height: 200px; */
  position: absolute;
  cursor: pointer;
}

/* .dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
} */
</style>
