<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col justify-center items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <cc-heading
      tag="h1"
      align="center"
      color="text-white"
      >
      {{ $t('pages.notAuthorized.title') }}
    </cc-heading>

    <cc-text
      v-if="intentedPreviousRoute"
      color="text-white"
      class="py-3"
      >
      {{ $t('commons.pageIsNotAccessible') }}: {{ intentedPreviousRoute }}
    </cc-text>
  </div>
</template>

<script>
export default {
  computed: {
    intentedPreviousRoute() {
      return this.$route?.params?.previousRoute?.path;
    },
  },

};
</script>
