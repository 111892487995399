<template>
  <div>
    <cc-section-table
      :title="$t('pages.roles.title')"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => (query = $event.toLowerCase())"
      @create-role="
        $router
          .push({
            name: 'roles-manager',
            params: { type: 'new-role' },
          })
          .catch(() => {})
      "
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @row-click="handleRowClick"
        @delete-role="($event) => ((isDeleteConfirmationOpen = true), (roleToDelete = $event))"
        />
    </cc-section-table>


    <cc-delete-confirmation-modal
      v-if="isDeleteConfirmationOpen"
      :is-open="isDeleteConfirmationOpen"
      :heading="$t('modals.deleteRoleConfirmation.title')"
      :body="$t('modals.deleteRoleConfirmation.body', { roleName: roleToDelete.name })"
      :error-message="deleteRequestErrors[0]"
      :is-deleting="isDeleting"
      @close="(isDeleteConfirmationOpen = false), (deleteRequestErrors = [])"
      @granted-delete-confirmation="(close) => deleteRole(roleToDelete.name, close)"
      />
  </div>
</template>

<script>
import { getDateAndTimeString } from '@/mixins/utils';

export default {
  data() {
    return {
      query: '',
      isLoadingDataTable: false,
      isDeleteConfirmationOpen: false,
      isDeleting: false,
      rolesAndActions: [],
      availableActions: [],
      deleteRequestErrors: [],
      roleToDelete: null,
      sectionActions: [
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'create-role',
          dataRuid: 'access-management__create-role',
          variant: 'primary',
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.name'),
          value: 'name',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.createdAt'),
          value: 'createdAt',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.updatedAt'),
          value: 'updatedAt',
          isCSVField: true,
          class: 'w-auto',
        },
        // {
        //   text: this.$t('commons.fieldNames.actions'),
        //   value: 'roleActions',
        //   isCSVField: true,
        //   class: 'w-24 overflow-hidden',
        // },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
          class: 'w-32',
        },
      ],
    };
  },
  computed: {
    dataItems() {
      return this.rolesAndActions
        ?.filter((role) => {
          if (this.query) {
            return (
              role?.name?.toLowerCase().includes(this.query)
              || role?.roleActions?.includes(this.query)
            );
          }
          return role;
        })
        .map((role, index) => ({
          index: index + 1,
          name: role.name || '–',
          createdAt: this.getDateAndTimeString(role?.createdAt) || '–',
          updatedAt: this.getDateAndTimeString(role?.updatedAt) || '–',
          roleActions: role.roleActions,
          originalPayload: role,
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-delete',
              text: this.$t('commons.delete'),
              event: 'delete-role',
            },
          ],
          isRowClickable: true,
        }));
    },
  },
  async created() {
    try {
      this.availableActions = await this.$store.dispatch('role/getAllActions');
      await this.getRolesAndActions();
    } catch (error) {
      this.$log.error('Error: Created Roles', error);
    }
  },
  methods: {
    getDateAndTimeString,
    async getRolesAndActions(withLoading = true) {
      try {
        this.$log.info('Will getRolesAndActions');
        this.isLoadingDataTable = withLoading;

        this.rolesAndActions = await this.$store.dispatch('role/getRolesAndActions');
      } catch (error) {
        this.$log.error('Error: getRolesAndActions', error);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    async deleteRole(roleId, close) {
      this.$log.info('delete role', roleId);
      try {
        this.deleteRequestErrors = [];
        this.isDeleting = true;

        await this.$store.dispatch('role/deleteRole', roleId);

        close();

        await this.getRolesAndActions();
      } catch (error) {
        this.$log.error('Error: deleteRole', error);
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          this.deleteRequestErrors.push(this.$t('errors.roleInUse'));
        } else {
          this.deleteRequestErrors.push(this.$t('errors.genericError'));
        }
      } finally {
        this.isDeleting = false;
      }
    },
    handleRowClick(role) {
      this.$log.info('role', role);

      this.$router.push({
        name: 'roles-manager',
        params: {
          type: 'role',
          roleName: role.name,
          roleToUpdate: role.originalPayload,
          availableActions: this.availableActions,
        },
      });
    },
  },
};
</script>
