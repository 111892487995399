<template>
  <div>
    <cc-invite-company-modal
      v-if="isInviteMerchantOpen"
      :is-open="isInviteMerchantOpen"
      :invitee="invitee"
      @close="(isInviteMerchantOpen = false), (invitee = null)"
      @success="inviteSuccessHandler"
      />

    <cc-section-table
      :title="$t('pages.companies.title')"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => (query = $event.toLowerCase())"
      @invite-merchant="isInviteMerchantOpen = true"
      @export="exportHandler"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @resend-invite="resendInvite"
        @row-click="handleRowClick($event)"
        >
        <template #name="{ item }">
          <span
            class="h-8 w-8 mr-3 bg-cover bg-center rounded-full overflow-hidden"
            :class="!item.imageUrl ? 'bg-gray-200' : ''"
            :style="item.imageUrl ? `background-image: url(${item.imageUrl})` : ''"
            />

          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline"
            >
            {{ item.name }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>

    <cc-feedback-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      :feedback-heading="feedbackMessage"
      icon="icon-cross-circle"
      :timeout-duration="2000"
      @close="isFeedbackOpen = false"
      />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ccInviteCompanyModal from '@/views/Companies/InviteCompanyModal.vue';
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { sortArrayByCreatedAt } from '../../mixins/utils';

export default {
  components: {
    ccInviteCompanyModal,
  },
  data() {
    return {
      query: '',
      invitee: null,
      isInviteMerchantOpen: false,
      isLoadingDataTable: false,
      isFeedbackOpen: false,
      feedbackMessage: 'Unauthorized',
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'invite-merchant',
          variant: 'primary',
          text: this.$t('pages.companies.newCompany'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.companyName'),
          value: 'name',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.companyId'),
          value: 'id',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.merchant'),
          value: 'merchant',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.email'),
          value: 'email',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.totalTransactions'),
          value: 'totalTransactions',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.companyStatus'),
          value: 'companyStatus',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.merchantStatus'),
          value: 'merchantStatus',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.companyTag'),
          value: 'alias',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      companies: st => st.company.companies,
    }),
    ...mapGetters({
      getUserActions: 'user/getUserActions',
    }),
    dataItems() {
      return sortArrayByCreatedAt(
        this.companies?.slice().map(company => ({ ...company, createdAt: company.company.createdAt })),
      )
        .filter((company) => {
          if (this.query) {
            return (
              company.company?.id?.toLowerCase().includes(this.query)
              || company.company?.name?.toLowerCase().includes(this.query)
              || company.company?.metaPublic?.resources?.en?.name
                ?.toLowerCase()
                .includes(this.query)
              || company.companyUsers[0]?.email?.address?.toLowerCase()?.includes(this.query)
            );
          }
          return company;
        })
        .map((company, index) => ({
          index: index + 1,
          id: company.company.id,
          name: company.company?.metaPublic?.resources?.en?.name || company.company?.name,
          merchant: company.companyUsers?.[0]?.name || '–',
          email:
            company.companyUsers?.[0]?.email?.address
            || company.companyUsers?.[0]?.email?.pendingAddress
            || '–',
          imageUrl:
            company?.company?.metaPublic?.resources?.en?.logo
            || company?.company?.metaPublic?.resources?.de?.logo,
          totalTransactions: company.transactionCount || '–',
          companyStatus: this.$t(company.company?.active ? 'commons.active' : 'commons.inactive'),
          merchantStatus: this.$t(
            company?.companyUsers?.[0]?.requireNewPassword ? 'commons.pending' : 'commons.active',
            ),
          alias: company.company.alias || '–',
          actions: [
            this.isOnboardedMerchant(company),
            { icon: 'icon-arrow-right', class: 'justify-end' },
          ],
          isRowClickable: true,
        }));
    },
  },
  async created() {
    if (!this.companies?.length) {
      await this.getAllCompanies();
    }
  },
  methods: {
    handleRowClick(event) {
      // this.$log.info('options.routes', this.$router.options.routes);

      // const availableRoute = store.getters['user/fallbackRouteResolver'](routes);

      const availableRoute = this.$router.options.routes
        .find(r => r.path === '/companies')
        .children.filter(
          child => child.path && child.meta.isNavigationItem && !child.meta.isNotAuthorizedRoute,
        )
        .find(route => route.meta.isSubNavigationFor.some(entry => this.getUserActions?.includes(entry)));

      this.$log.info('availableRoute', availableRoute);

      if (availableRoute?.name) {
        return this.$router
          .push({ name: availableRoute.name, params: { companyId: event.id } })
          .catch(() => {});
      }

      this.isFeedbackOpen = true;
      return false;
    },
    resendInvite(companyRow) {
      this.invitee = companyRow;
      this.isInviteMerchantOpen = true;
    },
    isOnboardedMerchant(company) {
      if (company?.companyUsers?.[0]?.requireNewPassword) {
        return {
          text: this.$t('pages.companies.resendInvite'),
          event: 'resend-invite',
        };
      }
      return null;
    },
    async getAllCompanies() {
      try {
        this.isLoadingDataTable = true;

        await this.$store.dispatch('company/getAllCompanies');
      } catch (error) {
        this.$log.info('Companies: getAllCompanies', error);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    async inviteSuccessHandler() {
      await this.getAllCompanies();
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const filename = generateTimeStampedFilename('scc_companies');

        this.dataItems.forEach((item, index) => {
          itemsFormatted.push({
            index: index + 1,
            name: item.name,
            id: item.id,
            merchant: item.merchant.replace(/,/g, ''),
            email: item.email,
            totalTransactions: item.totalTransactions,
            companyStatus: item.companyStatus,
            merchantStatus: item.merchantStatus,
          });
        });

        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>
