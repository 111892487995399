<template>
  <div class="flex flex-col">
    <nav
      class="relative z-0 inline-flex rounded-md -space-x-px"
      >
      <cc-text
        class="page-number first"
        :class="isFirstPage ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click.native="isFirstPage ? null : $emit('page-number', 1)"
        >
        <cc-svg-base
          svg="icons/icon-chevron-first"
          class="inlinew-5 h-5"
          :class="isFirstPage ? 'text-gray-200' : 'text-gray-500'"
          />
      </cc-text>

      <cc-text
        class="page-number prev"
        :class="isFirstPage ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click.native="isFirstPage ? null : $emit('page-number', pageNumber - 1)"
        >
        <cc-svg-base
          svg="icons/icon-chevron-left"
          class="inlinew-5 h-5"
          :class="isFirstPage ? 'text-gray-200' : 'text-gray-500'"
          />
      </cc-text>

      <cc-text
        v-for="page in rangedPageAmount"
        :key="page"
        class="page-number cursor-pointer"
        :class="page === pageNumber ? 'page-number-selected' : null"
        @click.native="$emit('page-number', page)"
        >
        {{ page }}
      </cc-text>

      <cc-text
        class="page-number next"
        :class="isLastPage ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click.native="isLastPage ? null : $emit('page-number', pageNumber + 1)"
        >
        <cc-svg-base
          svg="icons/icon-chevron-right"
          class="inline w-5 h-5"
          :class="isLastPage ? 'text-gray-200' : 'text-gray-500'"
          />
      </cc-text>

      <cc-text
        class="page-number last"
        :class="isLastPage ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click.native="isLastPage ? null : $emit('page-number', pageAmount)"
        >
        <cc-svg-base
          svg="icons/icon-chevron-last"
          class="inline w-5 h-5"
          :class="isLastPage ? 'text-gray-200' : 'text-gray-500'"
          />
      </cc-text>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    pageAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: 1,
    },
    pageRange: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  computed: {
    isFirstPage() {
      return this.pageNumber === 1;
    },
    isLastPage() {
      return this.pageNumber === this.pageAmount;
    },
    rangedPageAmount() {
      const start = () => {
        if (this.pageNumber > this.pageRange / 2) {
          return this.pageNumber - this.pageRange / 2;
        }
        return 1;
      };

      const end = () => {
        if (this.pageAmount - this.pageNumber < this.pageRange / 2) {
          return this.pageNumber + this.pageAmount - this.pageNumber + 1;
        }
        if (this.pageNumber > this.pageRange / 2) {
          return this.pageNumber + this.pageRange / 2;
        }
        return this.pageRange + 1;
      };

      this.$log.info('start()', start());
      this.$log.info('end()', end());

      return [...Array(this.pageAmount + 1).keys()].slice(
        start(),
        end(),
      );
    },
  },
};
</script>

<style lang="postcss" scoped>
.page-number {
  @apply bg-white border-gray-200 text-gray-500 hover:bg-gray-50 relative inline-flex items-center
  px-4 py-2 border text-sm font-medium select-none min-w-10 justify-center;
}
.page-number-selected {
  @apply z-10 bg-indigo-50 hover:bg-indigo-50 border-primary-dark text-primary-dark rounded-sm;
}

.first {
  @apply px-2 rounded-l-md;
}
.last {
  @apply px-2 rounded-r-md;
}

.next, .prev {
  @apply px-2 rounded-sm;
}
</style>
