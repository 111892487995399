<template>
  <div>
    <cc-heading
      tag="h3"
      weight="semibold"
      class="flex-grow w-full"
      color="text-gray-600"
      :has-border="true"
      >
      {{ $t('pages.createMessage.pushNotification') }}
    </cc-heading>


    <div class="flex flex-col overflow-auto pb-8 mb-12">
      <div
        v-if="!previewOnly"
        class="flex pb-8"
        >
        <div
          v-for="lang in supportedLangs"
          :key="lang"
          class="flex-shrink-0 w-96 mr-8 bg-gray-100 rounded-lg p-4"
          >
          <cc-heading
            tag="h5"
            class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
            >
            {{ $t(`commons.langs.${lang}`) }}
          </cc-heading>

          <div class="grid gap-6 grid-cols-1">
            <div class="flex justify-center">
              <cc-image
                :has-placeholder="true"
                :data-ruid="`create-message__image-${lang}`"
                class="h-24 w-24 rounded-full"
                :src="getResourceValue(pushMeta, 'imageUrl')"
                />
            </div>

            <cc-select
              id="push-meta-image"
              :label="$t('pages.createMessage.inAppImageSelection')"
              :ruid="`create-message__image-url-${lang}`"
              :validation-id="`push-${lang}${$t('pages.createMessage.imageSelection')}`"
              :validation-rules="lang === 'de' ? 'required' : null"
              :options="defaultImages"
              :disabled="disabled"
              :value="getResourceValue(pushMeta, 'imageUrl')"
              @input="setResourceValue(pushMeta, 'imageUrl', $event)"
              @change="setResourceValue(pushMeta, 'imageUrl', $event)"
              />

            <cc-input
              :label="$t('pages.createMessage.messageTitle')"
              :placeholder="$t('pages.createMessage.messageTitlePlaceholder')"
              :ruid="`create-message__title-${lang}`"
              :validation-id="`push-${lang}${$t('pages.createMessage.messageTitle')}`"
              :disabled="disabled"
              :validation-rules="lang === 'de' ? 'required' : null"
              :value="getResourceValue(pushResources, 'title', lang)"
              @input="setResourceValue(pushResources, 'title', $event, lang)"
              />
            <cc-text-area
              :label="$t('pages.createMessage.messageBody')"
              :label-right="`${getMessageBodyLength(pushResources, lang)} / ${maxCharOnPushBody}`"
              :placeholder="$t('pages.createMessage.messageBodyPlaceholder')"
              :maxlength="maxCharOnPushBody"
              :disabled="disabled"
              :ruid="`create-message__description-${lang}`"
              :validation-id="`push-${lang}${$t('pages.createMessage.messageBody')}`"
              :validation-rules="lang === 'de' ? 'required' : null"
              :value="getResourceValue(pushResources, 'body', lang)"
              @input="setResourceValue(pushResources, 'body', $event, lang)"
              />
          </div>
        </div>
      </div>

      <cc-text
        v-if="previewOnly"
        class="pb-4"
        color="text-gray-600"
        >
        {{ $t('pages.challenges.dispatchedPushNotification') }}
      </cc-text>

      <!-- iOS Push notification preview -->
      <div>
        <cc-heading
          tag="h3"
          weight="semibold"
          class="flex-grow w-96"
          color="text-gray-600"
          :has-border="false"
          >
          {{ $t('pages.createMessage.iosPreview') }}
        </cc-heading>
        <div
          class="flex mt-4 items-start"
          >
          <div
            v-for="(lang, i) in supportedLangs"
            :key="lang + i"
            class="ios-notification-card"
            >
            <div class="flex-shrink-0">
              <cc-image
                :data-ruid="`create-message__image-${lang}`"
                class="h-12 w-12"
                file-path="icons/icon-ios-app.png"
                />
            </div>

            <div class="flex flex-col w-full pl-4">
              <div class="flex justify-between">
                <p
                  class="ios-notification-title text-15 font-sf-semi-bold"
                  >
                  {{ truncateString(getResourceValue(pushResources, 'title', lang), iosMaxCharPushTitle) }}
                </p>

                <p class="text-13 font-medium leading-5 font-sf">
                  {{ $t('commons.now') }}
                </p>
              </div>

              <p class="text-15 font-medium leading-5 font-sf tracking-tight">
                {{ truncateString(getResourceValue(pushResources, 'body', lang), iosMaxCharPushBody) }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- Android Push notification preview -->
      <div>
        <cc-heading
          tag="h3"
          weight="semibold"
          class="flex-grow w-96 pt-8"
          color="text-gray-600"
          :has-border="false"
          >
          {{ $t('pages.createMessage.androidPreview') }}
        </cc-heading>

        <div class="flex mt-4 items-start">
          <div
            v-for="(lang, i) in supportedLangs"
            :key="lang + i"
            class="android-notification-card min-h-20"
            >
            <div class="flex justify-center items-center flex-shrink-0">
              <cc-image
                :data-ruid="`create-message__image-${lang}`"
                class="h-3 w-3"
                file-path="icons/icon-android-app.png"
                />
              <p class="text-xs font-roboto pl-2 text-primary-light">
                Climate Challenge
              </p>
              <p class="text-xs font-roboto pl-2 text-gray-400">
                {{ $t('commons.now') }}
              </p>
            </div>

            <div class="flex flex-col w-full pl-5">
              <div class="flex justify-between">
                <p class="android-notification-title text-15 font-roboto">
                  {{ truncateString(getResourceValue(pushResources, 'title', lang), androidMaxCharPushTitle) }}
                </p>
              </div>

              <p class="text-gray-300 text-13 font-medium leading-5 font-roboto">
                {{ truncateString(getResourceValue(pushResources, 'body', lang), androidMaxCharPushBody - 1) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncateString } from '@/mixins/utils';
import defaultImages from '@/mixins/defaultImages';

export default {
  props: {
    pushResources: {
      type: Object,
      required: true,
    },
    pushMeta: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      androidMaxCharPushTitle: 65,
      androidMaxCharPushBody: 240,
      iosMaxCharPushTitle: 36,
      iosMaxCharPushBody: 178,
      maxCharOnPushBody: '240',
    };
  },
  computed: {
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
    }),
    defaultImages() {
      return defaultImages;
    },
  },
  methods: {
    truncateString,
    getMessageBodyLength(messageResources, lang) {
      return this.getResourceValue(messageResources, 'body', lang)?.length.toString() || '0';
    },
    getResourceValue(target, key, lang) {
      if (lang) {
        return target?.[lang]?.[key];
      }
        return target?.[key];
    },
    setResourceValue(target, key, value, lang) {
      if (lang) {
        this.$set(target, lang, { ...target[lang], [key]: value });
      } else {
        this.$set(target, key, value);
      }
    },
  },
};
</script>

<style>
</style>
