<template>
  <div>
    <cc-section-table
      v-if="true"
      :title="$t('pages.product.title')"
      >
      <validation-observer ref="form">
        <!-- Product internal name -->
        <div class="grid gap-6 grid-cols-1 mb-8 max-w-sm">
          <cc-input
            :label="$t('pages.product.internalName')"
            :value="offer.virtualGood.name"
            validation-rules="required"
            @created="offer.virtualGood.name ? undefined : setValue(offer.virtualGood, 'name', '')"
            @input="setValue(offer.virtualGood, 'name', $event), insertBroadcastName($event)"
            />
        </div>

        <!-- Image upload hints -->
        <div class="bg-gray-100 max-w-lg p-3 rounded-lg mb-8 flex">
          <div class="pr-2">
            <cc-svg-base
              svg="icons/icon-info"
              class="inline text-gray-300"
              />
          </div>

          <cc-text
            variant="body-sm"
            color="text-gray-600"
            class="whitespace-pre-line"
            >
            <i18n path="pages.product.imageRequirements">
              <template #link>
                <a
                  class="underline"
                  href="https://www.tinypng.com/"
                  target="_blank"
                  >
                  www.tinypng.com
                </a>
              </template>
            </i18n>
          </cc-text>
        </div>

        <!-- Product resources -->
        <div class="flex overflow-auto pb-8 mb-12">
          <div
            v-for="lang in supportedLangs"
            :key="lang"
            class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
            >
            <cc-heading
              tag="h5"
              class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
              >
              {{ $t(`commons.langs.${lang}`) }}
            </cc-heading>

            <div class="grid gap-6 grid-cols-1">
              <cc-image-upload
                :placeholder="$t('configs.selectImage')"
                :images="
                  getResourceValue(virtualGoodResources, 'images', lang)
                    ? [getResourceValue(virtualGoodResources, 'images', lang)]
                    : []
                "
                @reset="setResourceValue(virtualGoodResources, 'images', [], lang)"
                @upload-complete="
                  setResourceValue(virtualGoodResources, 'images', $event, lang),
                  $refs.productImage[1].reset()
                "
                />
              <validation-provider
                v-slot="{ errors }"
                ref="productImage"
                name="Product image"
                :vid="`${lang}-product-image`"
                :rules="lang === 'de' && !hasProductImage ? 'required' : null"
                >
                <cc-error-message
                  v-if="errors && errors[0]"
                  class="flex-grow w-full"
                  :message="errors[0]"
                  />
              </validation-provider>

              <cc-input
                :label="$t('configs.productName')"
                :validation-id="`${lang}${$t('configs.productName')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :value="getResourceValue(virtualGoodResources, 'title', lang)"
                @input="setResourceValue(virtualGoodResources, 'title', $event, lang)"
                />

              <cc-text-area
                :label="$t('configs.description')"
                :validation-id="`${lang}${$t('configs.description')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :value="getResourceValue(virtualGoodResources, 'description', lang)"
                @input="setResourceValue(virtualGoodResources, 'description', $event, lang)"
                />
            </div>
          </div>
        </div>

        <!-- Product properties -->
        <div class="py-12">
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.product.productProperties') }}
          </cc-heading>

          <!-- Product Categories -->
          <div>
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.productCategory') }}
            </cc-heading>

            <div class="flex items-center flex-wrap">
              <cc-check-box
                v-for="(category, i) in productCategories"
                :key="i"
                :model-value="getProductCategory(category)"
                :text="category.name"
                class="flex-shrink-0 mr-4 my-2"
                :disabled="handleProductCategoriesDisabledState(category)"
                @change="handleProductCategories($event, category)"
                @created="productCategory ? undefined : setValue(metaPublic, 'categories', [])"
                />
            </div>

            <validation-provider
              v-slot="{ errors }"
              ref="productCategories"
              :name="$t('commons.fieldNames.productCategory')"
              :rules="`${hasProductCategorySelected ? '' : 'required'}`"
              mode="aggressive"
              >
              <cc-error-message
                v-if="errors && errors[0]"
                class="flex-grow w-full"
                :message="errors[0]"
                />
            </validation-provider>
          </div>

          <!-- Product Type  -->
          <div
            v-if="isContextAdmin"
            class="flex flex-col flex-wrap items-start pt-6"
            >
            <!-- Upon virtual good type change, check if virtual good belongs to this offer
            has inventory vouchers available -->
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.productType') }}
            </cc-heading>

            <cc-text
              class="pt-2 pb-4 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.product.productTypeBody') }}
            </cc-text>

            <cc-select
              v-if="false"
              id="virtual-good-types"
              v-model="virtualGoodTypeHandler"
              ruid="create-challenge__virtual-good-types__select"
              :options="generateOptionsFromObject('virtualGoodTypes')"
              class="pr-2"
              :has-de-select="false"
              />

            <div class="flex items-center flex-wrap">
              <cc-check-box
                v-for="(type, i) in virtualGoodTypes"
                :key="i"
                :model-value="offer.virtualGood.type === type.key"
                :text="type.name"
                class="flex-shrink-0 mr-4 my-2"
                @change.native="
                  setValue(offer.virtualGood, 'type', type.key),
                  type.key === 'INVENTORY_VOUCHER' ? setValue(offer, 'stock', 1) : undefined
                "
                @created="type.value ? setValue(offer.virtualGood, 'type', type.key) : undefined"
                />
            </div>
          </div>

          <!-- Product Stock  -->
          <div
            v-if="!isInventoryVoucher"
            class="flex flex-col flex-wrap items-start pt-8"
            >
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.stock') }}
            </cc-heading>

            <cc-text
              class="pt-2 pb-4 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.product.stockBody') }}
            </cc-text>

            <cc-input
              class="w-36 mr-4"
              type="number"
              align="text-center"
              min="-1"
              step="1"
              show-steper
              :value="offer.stock"
              :validation-name="$t('commons.fieldNames.stock')"
              @created="offer.stock ? undefined : setValue(offer, 'stock', 1)"
              @input="($event) => (offer.stock = parseInt($event, 10))"
              />
          </div>

          <!-- Product Price  -->
          <div class="flex flex-col flex-wrap items-start pt-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.priceInGreenCoins') }}
            </cc-heading>

            <cc-text
              class="pt-2 pb-4 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.product.priceInGreenCoinsBody') }}
            </cc-text>

            <cc-input
              class="w-36"
              type="number"
              align="text-center"
              min="0"
              step="1"
              :value="offer.price"
              :validation-name="$t('commons.fieldNames.priceInGreenCoins')"
              validation-rules="greaterThanZero"
              :has-error-message="false"
              @created="offer.price ? undefined : setValue(offer, 'price', 0)"
              @input="($event) => (offer.price = parseInt($event, 10))"
              @errors="($event) => (priceErrors = $event)"
              />

            <cc-error-message
              v-if="priceErrors && priceErrors[0]"
              class="flex-grow w-full"
              :message="priceErrors[0]"
              />
          </div>

          <!-- Product Visibility -->
          <div class="flex flex-col flex-wrap items-start pt-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.productVisibility') }}
            </cc-heading>

            <cc-text
              class="pt-2 pb-4 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.product.productVisibilityBody') }}
            </cc-text>

            <cc-check-box
              v-model="offer.active"
              :text="$t('configs.active')"
              class="flex-shrink-0"
              />
          </div>

          <!-- Product Delivery -->
          <div
            v-if="!isInventoryVoucher"
            class="flex flex-col flex-wrap items-start pt-8"
            >
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('commons.fieldNames.productDelivery') }}
            </cc-heading>

            <cc-text
              class="pt-2 pb-4 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.product.productDeliveryBody') }}
            </cc-text>

            <cc-check-box
              v-model="metaPublic.deliverableOffer"
              :text="$t('configs.deliverable')"
              class="flex-shrink-0"
              @created="
                metaPublic.deliverableOffer
                  ? undefined
                  : setValue(metaPublic, 'deliverableOffer', false)
              "
              />
          </div>
        </div>
      </validation-observer>

      <!-- Manage Voucher Inventory -->
      <div
        v-if="isInventoryVoucher"
        class="py-12"
        >
        <cc-heading
          tag="h3"
          weight="semibold"
          class="flex-grow w-full flex flex-col"
          color="text-gray-600"
          :has-border="true"
          >
          {{ $t('configs.manageVoucherInventory') }}
        </cc-heading>

        <div v-if="!isVirtualGoodCreated">
          <cc-hint
            class="mb-6 w-96"
            :hint="$t('pages.product.createInventoryVoucherHint')"
            />

          <cc-button
            color="text-gray-700"
            :block="false"
            :text="$t('pages.product.createInventoryVoucher')"
            @click.native="createOffer"
            />
        </div>

        <div v-if="isVirtualGoodCreated">
          <cc-heading
            tag="h3"
            weight="bold"
            class="flex-grow w-full capitalize"
            color="text-gray-600"
            >
            {{ $t('commons.fieldNames.uploadCsvFile') }}
          </cc-heading>

          <cc-text
            class="pt-2 pb-4 flex-grow max-w-4xl"
            color="text-gray-600"
            >
            {{ $t('pages.product.uploadCsvFileBody') }}
          </cc-text>

          <cc-voucher-manager
            :placeholder="$t('configs.selectFileCsv')"
            :has-icon="false"
            :can-delete="false"
            class="pb-4"
            :voucher-inventory="voucherInventory"
            :virtual-good-id="offer.virtualGood.id"
            @deleted="getVouchers"
            @successful-upload="getVouchers"
            />
        </div>

        <div
          v-if="hasVoucherInventory"
          class="flex flex-col flex-wrap items-start pt-8"
          >
          <cc-heading
            tag="h3"
            weight="bold"
            class="flex-grow w-full"
            color="text-gray-600"
            >
            {{ $t('commons.fieldNames.linkToWebsite') }}
          </cc-heading>

          <cc-text
            class="pt-2 pb-4 flex-grow max-w-4xl"
            color="text-gray-600"
            >
            {{ $t('pages.product.linkToWebsiteBody') }}
          </cc-text>

          <cc-input
            class="w-96"
            :placeholder="$t('commons.fieldNames.linkToWebsitePlaceholder')"
            validation-rules="validURL"
            :value="metaPublic.inventoryVoucherUrl"
            @created="
              metaPublic.inventoryVoucherUrl
                ? undefined
                : setValue(metaPublic, 'inventoryVoucherUrl', null)
            "
            @input="setValue(metaPublic, 'inventoryVoucherUrl', $event || null)"
            />
        </div>
      </div>


      <!-- @TODO Check with Thiago to decide which action to rely on in such cases -->
      <cc-check-actions :has-some="['CONTEXT_CREATE_COMPANY']">
        <div
          v-if="isInventoryVoucher"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.sendMessageOnGrantConfirmation') }}
          </cc-heading>

          <div class="flex items-end flex-wrap">
            <cc-check-box
              :label="$t('commons.fieldNames.deliveryMethod')"
              ruid="create-challenge__send-message-as-inbox"
              :text="$t('configs.sendMessageAsInboxMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(virtualGood, 'messages.grantConfirmation.payload.inbox.resources')"
              @change="handleDeliveryMethodSelection($event, 'inbox', 'grantConfirmation')"
              />
            <cc-check-box
              ruid="create-challenge__send-message-as-mail"
              :text="$t('configs.sendMessageAsMailMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(virtualGood, 'messages.grantConfirmation.payload.mail.resources')"
              @change="handleDeliveryMethodSelection($event, 'mail', 'grantConfirmation'),
                       prefillGrantConfirmationMailResources()"
              />
          </div>

          <!-- Inbox Message resources -->
          <cc-message-composer
            v-if="has(virtualGood, 'messages.grantConfirmation.payload.inbox.resources')"
            id="inbox-message-composer"
            class="pt-8"
            message-type="inbox"
            :heading="$t('pages.createMessage.inboxMessage')"
            :image="true"
            :body-url="false"
            :body="true"
            :has-preview="true"
            :message-resources="messageResources('grantConfirmation', 'inbox')"
            />

          <!-- Mail Message resources -->
          <cc-message-composer
            v-if="has(virtualGood, 'messages.grantConfirmation.payload.mail.resources')"
            id="mail-message-composer"
            class="pt-8"
            message-type="mail"
            :heading="$t('pages.createMessage.mailMessage')"
            :image="false"
            :body-url="true"
            :body="false"
            :has-preview="false"
            :message-resources="messageResources('grantConfirmation', 'mail')"
            />
        </div>
      </cc-check-actions>

      <!-- TODO Outsource this to component to use here and in company information? -->
      <!-- Product additional information -->
      <div class="py-12">
        <cc-heading
          tag="h3"
          weight="semibold"
          class="flex-grow w-full flex flex-col"
          color="text-gray-600"
          :has-border="true"
          >
          {{ $t('pages.product.additionalInformation') }}
        </cc-heading>

        <div
          v-for="(section, i) in productDetails"
          :key="section.key"
          class="pb-20"
          >
          <div class="w-48">
            <cc-heading
              tag="h3"
              weight="semibold"
              class="flex-grow w-full flex flex-col"
              color="text-gray-600"
              :has-border="true"
              >
              {{ $t('pages.product.detailSection') }} {{ i + 1 }}
            </cc-heading>
          </div>

          <div class="flex overflow-y-hidden pb-48 mb-4">
            <div
              v-for="lang in supportedLangs"
              :key="lang"
              class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
              >
              <cc-heading
                tag="h5"
                class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
                >
                {{ $t(`commons.langs.${lang}`) }}
              </cc-heading>

              <cc-input
                :label="$t('pages.product.sectionTitle')"
                :value="virtualGoodResources[lang] && virtualGoodResources[lang].details[i].label"
                class="pb-4"
                @input="setValue(virtualGoodResources[lang].details[i], 'label', $event)"
                />
              <cc-heading
                tag="h4"
                color="text-gray-600"
                class="pb-1"
                >
                {{ $t('pages.product.sectionContent') }}
              </cc-heading>

              <div class="grid gap-6 grid-cols-1">
                <cc-editor
                  :id="`editor-${lang}-${i}`"
                  :lang="lang"
                  :value="getEditorValue(lang, i)"
                  @input="editorJSInputHandler"
                  />
              </div>
            </div>
          </div>
          <cc-button
            variant="secondary"
            :block="false"
            :text="`${$t('pages.product.deleteSection')} ${i + 1}`"
            @click.native="deleteSectionHandler(i)"
            />
        </div>

        <cc-button
          :text="$t('pages.product.addNewSection')"
          :block="false"
          @click.native="addSectionHandler"
          />
      </div>

      <!-- Action buttons -->
      <div class="flex flex-wrap justify-end">
        <!-- TODO: There's something wrong with m- clsases on the button -->
        <cc-button
          v-if="$route.params.type !== 'new-product'"
          variant="red"
          :text="$t('commons.delete')"
          :block="false"
          class="m-2 text-secondary-red"
          :is-loading="isDeleting"
          :disabled="isDeleting"
          @click.native="isDeleteConfirmationOpen = true"
          />

        <cc-button
          variant="secondary"
          :text="$t('commons.cancel')"
          :block="false"
          class="m-2"
          @click.native="$router.push({ name: 'products' }).catch(() => {})"
          />
        <cc-button
          v-if="$route.params.type === 'new-product'"
          class="m-2"
          :block="false"
          :text="$t('commons.create')"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="createOffer"
          />
        <cc-button
          v-else-if="$route.params.type === 'product'"
          class="m-2"
          :block="false"
          :text="$t('commons.save')"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="updateOffer"
          />
      </div>

      <cc-error-message
        v-if="requestErrors.length"
        :message="requestErrors[0]"
        class="mr-2 justify-end"
        />

      <cc-delete-confirmation-modal
        v-if="isDeleteConfirmationOpen"
        :is-open="isDeleteConfirmationOpen"
        :heading="$t('modals.deleteProductConfirmation.title')"
        :body="$t('modals.deleteProductConfirmation.body')"
        :error-message="deleteRequestErrors[0]"
        @close="isDeleteConfirmationOpen = false, deleteRequestErrors = []"
        @granted-delete-confirmation="
          (close) => (
            deleteOffer($route.params.companyId), deleteRequestErrors.length ? close() : undefined
          )
        "
        />

      <cc-code
        title="Offer"
        class="mt-36"
        >
        {{ offer }}
      </cc-code>

      <cc-code
        v-if="false"
        title="Voucher Inventory"
        class="mt-16"
        >
        {{ voucherInventory }}
      </cc-code>
    </cc-section-table>

    <cc-section-table
      v-if="true"
      class="mt-8 flex flex-wrap-reverse"
      >
      <div class="md:pr-8">
        <iframe
          id="sandbox"
          ref="sandbox"
          :src="frontendURL"
          frameborder="0"
          width="375"
          height="812"
          class="shadow-gray-200 shadow-2xl rounded-xl"
          />
      </div>

      <div>
        <cc-heading
          tag="h2"
          weight="semibold"
          color="text-gray-600"
          class="pb-4"
          >
          {{ $t('pages.product.previewTitle') }}
        </cc-heading>

        <cc-button
          :text="$t('pages.product.updatePreview')"
          :block="false"
          class="mb-4"
          @click.native="
            sendMessageToClient(
              {
                company: companyProfile,
                offers: [offer],
              },
              'company',
            )
          "
          />
      </div>
    </cc-section-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isEmptyObject } from '@/mixins/utils';
import { isEmpty, has } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import validator from '@/mixins/validator';
import sandbox from '@/mixins/sandbox';

export default {
  mixins: [validator, sandbox],
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      isDeleteConfirmationOpen: false,
      priceErrors: [],
      requestErrors: [],
      deleteRequestErrors: [],
      productDetailsAmount: 0,
      companyProfile: {},
      voucherInventory: [],
      productCategories: [
        {
          name: this.$t('commons.productCategories.food'),
          key: 'food',
          value: false,
        },
        {
          name: this.$t('commons.productCategories.engagement'),
          key: 'engagement',
          value: false,
        },
        {
          name: this.$t('commons.productCategories.lifestyle'),
          key: 'lifestyle',
          value: false,
        },
        {
          name: this.$t('commons.productCategories.mobility'),
          key: 'mobility',
          value: false,
        },
        {
          name: this.$t('commons.productCategories.discount'),
          key: 'discount',
          value: false,
        },
        {
          name: this.$t('commons.productCategories.co2'),
          key: 'co2',
          value: false,
        },
      ],
      availableMessages: {
        grantConfirmation: {
          name: '',
          payload: {},
          meta: {},
          userTags: [],
          userIds: [],
          deliveryTag: 'voucher-inventory:new',
        },
      },
      offer: {
        active: false,
        currencyId: process.env.VUE_APP_SPONSOR_CURRENCY_ID,
        consumeUponPurchase: true,
        resources: {},
        /**
         *  `id: "dummy-id"` below is there only for the Sandbox Preview purposes
         *  to display a product that has not been created on the backend yet
         *  and if it is an already created product, it is overridden in the created hook
         */
        id: 'dummy-id',
        /**
         * @todo here the key `storeId` stays as it is as it depends on a refactoring on Juoco
         */
        storeId: this.$route.params.companyId,
        virtualGood: {
          id: 'dummy-id',
          storeId: this.$route.params.companyId,
          type: 'VOUCHER',
          defaultLang: 'en',
          private: false,
          metaPublic: {},
          resources: {},
          messages: {},
        },
      },
    };
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
    }),
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
      isContextAdmin: 'user/isContextAdmin',
    }),
    virtualGoodTypeHandler: {
      get() {
        return this.offer.virtualGood.type;
      },
      set(value) {
        if (value === 'INVENTORY_VOUCHER') {
          this.offer.stock = 1;
        }
        this.offer.virtualGood.type = value;
      },
    },
    virtualGoodTypes() {
      return [
        {
          name: this.$t('configs.product'),
          key: 'VOUCHER',
          value: true,
        },
        ...(this.isContextAdmin
          ? [
              {
                name: this.$t('configs.voucherInventory'),
                key: 'INVENTORY_VOUCHER',
                value: false,
              },
            ]
          : []),
      ];
    },
    isInventoryVoucher() {
      return this.offer.virtualGood.type === 'INVENTORY_VOUCHER';
    },
    isVirtualGoodCreated() {
      return this.offer.virtualGood.id !== 'dummy-id';
    },
    frontendURL() {
      return process.env.VUE_APP_FRONTEND;
    },
    hasProductCategorySelected() {
      return this.productCategory?.length > 0;
    },
    hasProductImage() {
      return this.getResourceValue(this.virtualGoodResources, 'images', 'de')?.length > 0;
    },
    productDetails() {
      return this.virtualGoodResources?.en?.details;
    },
    virtualGood() {
      return this.offer.virtualGood;
    },
    virtualGoodResources() {
      return this.offer.virtualGood?.resources;
    },
    metaPublic() {
      return this.offer.virtualGood?.metaPublic;
    },
    productCategory() {
      return this.offer.virtualGood?.metaPublic?.categories;
    },
    hasVoucherInventory() {
      return this.voucherInventory?.length > 0;
    },
  },
  async created() {
    this.companyProfile = await this.$store.dispatch('company/getCompany', {
      companyId: this.$route.params.companyId,
    });

    if (this.$route.params.offer) {
      this.offer = { ...this.$route.params.offer };
    }

    if (this.$route.params.voucherInventory) {
      this.voucherInventory = [...this.$route.params.voucherInventory];
    }

    if (!this.offer.virtualGood.metaPublic) {
      this.$set(this.offer.virtualGood, 'metaPublic', {});
    }

    if (this.isEmpty(this.offer.virtualGood.messages)) {
      this.offer.virtualGood.messages = this.availableMessages;
    }
  },
  methods: {
    isEmpty,
    has,
    async getVouchers() {
      try {
        this.voucherInventory = await this.$store.dispatch('company/getVouchers', {
          virtualGoodId: this.offer.virtualGood.id,
          csvFormat: false,
        });
      } catch (err) {
        this.$log.error('Error: getVouchers', err);
      }
    },
    async createOffer() {
      // TODO: get companyId to as arguement
      const { companyId } = this.$route.params;
      const { offer } = this;

      try {
        this.requestErrors = [];

        // await this.$refs.productCategories.validate();
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        delete offer.id;
        delete offer.virtualGood.id;

        const newVirtualGood = await this.$store.dispatch('company/createVirtualGood', {
          companyId,
          virtualGood: offer.virtualGood,
        });

        /**
         * @todo here `storeId` stays as it is as it depends on a refactoring on Juoco
         */
        const newOffer = await this.$store.dispatch('company/createOffer', {
          companyId,
          offer: {
            virtualGoodId: newVirtualGood.id,
            offer: {
              ...this.offer,
              storeId: companyId,
            },
          },
        });

        this.$log.info('newOffer', newOffer);

        if (!this.isVirtualGoodCreated && this.isInventoryVoucher) {
          // Following router push will not "reload" the page, so we already overwrite the offer
          this.offer = newOffer;

          this.$router.push({
            name: 'product',
            // `offer` param is added only to have the `navigation` prop in router refreshed
            // Only if inventory voucher, we stay on the page unlike else clause
            params: { type: 'product', offerId: newOffer.id, offer: newOffer },
          });
        } else {
          this.$router.push({
            name: 'products',
            params: { companyId: this.$route.params.companyId },
          });
        }

        this.$log.info('Response: newVirtualGood', newVirtualGood);
        this.$log.info('Response: newOffer', newOffer);
      } catch (err) {
        this.requestErrors.push(this.$t('errors.genericError'));

        this.$log.error('Error: createOffer', err);
      } finally {
        this.isLoading = false;
      }
    },
    async updateOffer() {
      // TODO: get companyId to as arguement
      const { companyId } = this.$route.params;
      const { offer } = this;

      try {
        this.requestErrors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        const updatedVirtualGood = await this.$store.dispatch('company/updateVirtualGood', {
          companyId,
          virtualGoodId: this.offer.virtualGood.id,
          virtualGood: this.offer.virtualGood,
        });
        const updatedOffer = await this.$store.dispatch('company/updateOffer', {
          companyId,
          offerId: offer.id,
          offer: {
            virtualGoodId: updatedVirtualGood.id,
            offer: {
              ...this.offer,
              companyId,
            },
          },
        });

        this.$router.push({
          name: 'products',
          params: { companyId: this.$route.params.companyId },
        });

        this.$log.info('Response: updatedVirtualGood', updatedVirtualGood);
        this.$log.info('Response: updatedOffer', updatedOffer);
      } catch (err) {
        this.requestErrors.push(this.$t('errors.genericError'));

        this.$log.error('Error: createOffer', err);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteOffer(companyId) {
      // TODO: Get offerId as param rather reading directly from `this`
      try {
        this.deleteRequestErrors = [];
        this.isDeleting = true;

        await this.$store.dispatch('company/deleteVirtualGood', {
          companyId,
          virtualGoodId: this.offer.virtualGood.id,
        });

        await this.$store.dispatch('company/deleteOffer', {
          companyId,
          offerId: this.offer.id,
        });

        this.$router.push({
          name: 'products',
          params: { companyId: this.$route.params.companyId },
        });
      } catch (err) {
        this.deleteRequestErrors.push(this.$t('errors.genericError'));

        this.$log.error('Error: deleteOffer', err);
      } finally {
        this.isDeleting = false;
      }
    },
    insertBroadcastName(event) {
      Object.keys(this.offer.virtualGood.messages).forEach((broadcastKey) => {
        if (this.offer.virtualGood.messages[broadcastKey]) {
          this.offer.virtualGood.messages[broadcastKey].name = event;
        }
      });
    },
    messageResources(messageKey, deliveryMethod) {
      return this.offer.virtualGood.messages[messageKey]?.payload[deliveryMethod]?.resources;
    },
    handleDeliveryMethodSelection(event, deliveryMethod, messageKey) {
      if (event) {
        this.$set(this.offer?.virtualGood?.messages?.[messageKey]?.payload, deliveryMethod, {
          resources: {},
          meta: {},
        });
      } else {
        this.$delete(this.offer?.virtualGood?.messages?.[messageKey]?.payload, deliveryMethod);
      }
    },
    prefillGrantConfirmationMailResources() {
      const { appState } = this;

      if (
        has(this.virtualGood, 'messages.grantConfirmation.payload.mail.resources')
        && isEmpty(this.virtualGood?.messages?.grantConfirmation?.payload?.mail?.resources)
      ) {
        const title = this.$t('pages.product.voucherMailSubject');
        const appUrl = process.env.VUE_APP_FRONTEND_URL;
        const path = 'email-templates';
        const env = appState.isDev || appState.isDevelopment ? 'staging' : '';
        const resources = {};

        const getFileName = lang => `voucher-${lang || 'en'}-inlined.html`;
        const getBodyUrl = (lang, templateEnv) => `${appUrl}/${path}${templateEnv ? `-${templateEnv}` : ''}/${getFileName(lang)}`;

        Object.values(this.supportedLangs).forEach((lang) => {
          resources[lang] = {
            title,
            bodyUrl: getBodyUrl(lang, env),
          };
        });

        this.virtualGood.messages.grantConfirmation.payload.mail.resources = resources;
      }
    },
    getEditorValue(lang, i) {
      return this.virtualGoodResources?.[lang]?.details?.[i]?.data || {};
    },
    deleteSectionHandler(i) {
      this.supportedLangs.forEach((lang) => {
        if (this.virtualGoodResources?.[lang]?.details[i]) {
          this.$log.info('deleting section', this.virtualGoodResources?.[lang]?.details, i);
          return this.virtualGoodResources?.[lang]?.details.splice(i, 1);
        }
        return false;
      });
    },
    addSectionHandler() {
      this.supportedLangs.forEach((lang) => {
        if (!this.virtualGoodResources?.[lang]) {
          this.$log.info('inserting empty lang', lang);
          this.$set(this.virtualGoodResources, lang, {});
        }

        if (!this.virtualGoodResources?.[lang]?.details?.length) {
          this.$log.info('inserting empty details');
          return this.$set(this.virtualGoodResources[lang], 'details', [
            { data: {}, key: uuidv4(), label: '' },
          ]);
        }

        if (this.virtualGoodResources?.[lang]?.details?.length) {
          this.$log.info('adding new section', lang, this.virtualGoodResources?.[lang]?.details);
          return this.virtualGoodResources?.[lang]?.details.push({
            data: {},
            key: uuidv4(),
            label: '',
          });
        }

        return false;
      });
    },
    editorJSInputHandler(payload) {
      const index = payload.id.split('-')[2];
      const lang = payload.id.split('-')[1];

      this.$log.info('payload', payload, index, lang);

      if (this.virtualGoodResources?.[lang]?.details?.[index]) {
        this.$log.info('Details exists, will set data', lang);
        this.$set(this.virtualGoodResources[lang].details[index], 'data', payload.data);
      }

      if (!payload.data.blocks.length) {
        this.$log.info('Empty blocks, will clear data');
        this.$set(this.virtualGoodResources[lang].details[index], 'data', {});
      }
    },
    getProductCategory(category) {
      return this.productCategory?.includes(category.key);
    },
    handleProductCategoriesDisabledState(category) {
      if (this.productCategory?.length) {
        return !this.productCategory?.includes(category.key);
      }
      return false;
    },
    async handleProductCategories(event, category) {
      if (event) {
        this.productCategory.push(category.key);
        await this.$refs.productCategories.reset();
        return;
      }

      this.productCategory.splice(this.productCategory.indexOf(category.key), 1);
      await this.$refs.productCategories.validate();
    },
    generateOptionsFromObject(source) {
      return this[source]
        .slice()
        .map(el => [{ value: Object.keys(el)[0], text: Object.values(el)[0] }])
        .flat();
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
    getResourceValue(target, key, lang) {
      return target?.[lang]?.[key];
    },
    setResourceValue(target, key, value, lang) {
      if (value.length === 0) {
        this.$log.info('Will delete key');

        this.$delete(target[lang], key);

        if (isEmptyObject(target[lang])) {
          this.$log.info('Will delete lang');
          this.$delete(target, lang);
        }
      } else {
        this.$set(target, lang, { ...target[lang], [key]: value });
      }
    },
  },
};
</script>
