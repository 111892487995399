<template>
  <div :id="id">
    <!-- {{ value }} – {{ id }} -->
  </div>
</template>


<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import Hyperlink from 'editorjs-hyperlink';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
      // default: () => uuidv4(),
    },
    lang: {
      type: String,
      required: false,
      default: 'en',
    },
    addHeaderBlock: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      editorJS: null,
    };
  },
  watch: {
    addHeaderBlock() {
      // this.$log.info('addHeaderBlock');
    },
  },
  mounted() {
    // this.$log.info('Mounted EditorJS');

    this.editorJS = new EditorJS({
      holder: this.id,
      tools: {
        header: {
          class: Header,
          inlineToolbar: [],
          config: {
            placeholder: 'Enter a header',
            levels: [1, 2, 3],
            defaultLevel: 3,
          },
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: ['hyperlink'],
        },
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: 'CMD+L',
            target: 'internal',
            rel: 'inline',
            availableTargets: ['_blank', '_self', 'internal', 'external'],
            availableRels: ['author', 'noreferrer', 'block', 'inline'],
            validate: false,
          },
        },
      },

      data: this.value,

      onReady: () => {
        // this.$log.info('Ready!');
      },

      onChange: async () => {
        const data = await this.editorJS.save();

        this.$emit('input', { data, id: this.id, lang: this.lang });
      },
    });
  },
};
</script>

<style lang="postcss">
.ce-block__content,
.ce-toolbar__content {
  max-width: initial;
}
.codex-editor__redactor {
  padding-bottom: initial !important;
  margin-right: initial !important;
}

.ce-header {
  @apply font-bold;
}

.ce-paragraph,
.ce-header {
  @apply p-3 mb-2 bg-white border-gray-300 text-gray-800 rounded-md border
  transition duration-200 ease-in !important;
}
.ce-header,
.ce-paragraph {
  @apply focus:outline-none focus:border-primary-dark !important;
}
.ce-header,
.ce-paragraph {
  @apply hover:border-primary-dark !important;
}

.ce-conversion-toolbar--showed {
  left: 62px;
  top: -34px;
}

.ce-toolbar__actions {
  right: 6px !important;
  transform: translate3d(0px, calc(21px - 50%), 0px);
}

.ce-toolbar__settings-btn {
  @apply w-6 h-6 bg-gray-100 rounded-md border border-gray-300;
}

.codex-editor__loader {
  @apply h-10 pb-0 !important;
}
.codex-editor__loader:before {
  @apply border-primary-dark border-opacity-50 border-4 w-6 h-6;
  border-top-color: transparent;
}

.ce-toolbox,
.ce-toolbox--opened {
  @apply ml-2 bg-gray-100 rounded-md border border-gray-300 !important;
}

/*
.cdx-block {
  padding: initial;
}
.cdx-block:focus {
  @apply focus:outline-none focus:border-primary-dark;
}
 */
</style>
