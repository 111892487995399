<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-md w-full">
      <cc-heading
        tag="h1"
        class="text-2xl pb-6"
        >
        {{ $t('pages.completeRegistration.title') }}
      </cc-heading>

      <validation-observer ref="form">
        <form @submit.prevent="completeRegistration">
          <cc-input
            v-model="userEmail"
            class="mb-4"
            name="email"
            :label="$t('commons.fieldNames.merchantEmail')"
            :disabled="true"
            />

          <cc-input
            id="name"
            v-model="name"
            class="mb-4"
            name="name"
            :label="$t('commons.fieldNames.merchantName')"
            validation-rules="required"
            autofocus
            />

          <cc-input
            id="phoneNumber"
            v-model="phoneNumber"
            name="tel"
            :label="$t('commons.fieldNames.merchantMobilePhoneNumber')"
            :placeholder="$t('pages.completeRegistration.phoneNumberPlaceholder')"
            class="mb-4"
            validation-rules="required"
            />

          <cc-input
            id="address"
            v-model="address"
            name="address-line1"
            :label="$t('commons.fieldNames.companyAddress')"
            class="mb-4"
            validation-rules="required"
            />

          <div class="flex">
            <cc-input
              id="city"
              v-model="city"
              name="address-level1"
              :label="$t('commons.fieldNames.city')"
              class="mb-4 pr-2"
              validation-rules="required"
              />
            <cc-input
              id="postal-code"
              v-model="postalCode"
              name="postal-code"
              :label="$t('commons.fieldNames.postalCode')"
              class="mb-4 pl-2"
              validation-rules="required"
              />
          </div>

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            padding="py-2"
            />

          <cc-button
            class="mt-4"
            :has-min-width="false"
            :is-loading="isLoading"
            :disabled="isLoading"
            :text="$t('pages.completeRegistration.primaryButton')"
            type="submit"
            />
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      email: null,
      name: null,
      phoneNumber: null,
      address: null,
      city: null,
      postalCode: null,
      type: null,
      sessionId: null,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user.user,
    }),
    userEmail() {
      return this.user?.email?.address;
    },
  },
  async created() {
    this.type = this.$route?.query?.type || null;
    this.sessionId = this.$route?.query?.sessionId || null;

    if (this.user) {
      this.name = this.user.name;
      this.phoneNumber = this.user.phone.number;
    }

    if (this.user.metaPrivate) {
      this.address = this.user.metaPrivate.address;
      this.city = this.user.metaPrivate.city;
      this.postalCode = this.user.metaPrivate.postalCode;
    }
    // if (this.sessionId) {
    //   await this.$store.dispatch('user/getUser', atob(this.sessionId));
    // }
  },
  methods: {
    ...mapMutations({
      setState: 'user/setState',
    }),
    async completeRegistration() {
      try {
        this.$log.info('completeRegistration');

        this.errors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        const updatedProfile = await this.$store.dispatch('user/updateProfile', {
          name: this.name,
          metaPrivate: {
            ...this.user.metaPrivate,
            address: this.address,
            city: this.city,
            postalCode: this.postalCode,
          },
        });

        this.setState(['user', updatedProfile]);

        const hasUpdatedPhoneNumber = this.user.phone.number !== this.phoneNumber;

        if (!this.user.phone.verified || hasUpdatedPhoneNumber) {
          const nonSpacedPhoneNumber = this.phoneNumber.replace(/\s+/g, '');

          await this.$store.dispatch('user/requestVerification', nonSpacedPhoneNumber);

          this.$router.push({ name: 'phone-verification', params: { phoneNumber: nonSpacedPhoneNumber } });
        } else {
          this.$router.push({ name: 'create-password' });
        }
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: completeRegistration', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
