<template>
  <div>
    <label
      v-if="label"
      :for="`select-${id}`"
      :class="labelClass"
      class="flex"
      >
      {{ label }}
      <cc-required-label v-if="isRequired" />
    </label>

    <div class="relative">
      <cc-svg-base
        v-if="!multiple"
        class="w-2 h-2 absolute top-0 right-0 mx-4 my-5 pointer-events-none"
        svg="icons/icon-dropdown-arrow"
        />
      <validation-provider
        v-slot="{ classes, errors }"
        :rules="validationRules"
        :name="label"
        :vid="validationId"
        :mode="validationInteractionMode"
        >
        <select
          :id="`select-${id}`"
          v-model="selected"
          :class="selectClass(classes)"
          :multiple="multiple"
          :disabled="disabled"
          :data-ruid="ruid"
          @input="inputHandler($event)"
          >
          <option
            v-if="hasDeSelect"
            :value="null"
            >
            {{ deselectText || $t('commons.select') }}
          </option>

          <option
            v-for="(option, index) in options"
            :key="index"
            :value="option.value"
            :selected="selectedOption(option)"
            >
            {{ option.text }}
          </option>
        </select>
        <cc-error-message :message="errors[0]" />
      </validation-provider>
    </div>
  </div>
</template>

<script>
// import commonProps from '@/mixins/commonProps';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    id: {
      type: String,
      required: true,
    },
    ruid: {
      type: String,
      required: false,
      default: undefined,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: [Array, String, Number],
      required: false,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    hasDeSelect: {
      type: Boolean,
      default: true,
      required: false,
    },
    deselectText: {
      type: String,
      default: null,
      required: false,
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    validationId: {
      type: String,
      required: false,
      default: null,
    },
    validationRules: {
      type: String,
      required: false,
      default: null,
    },
    validationInteractionMode: {
      type: String,
      required: false,
      default: 'eager',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        return val;
      },
    },
    labelClass() {
      return [
        'w-full pb-1 font-body rounded-md text-sm',
        'inline-block text-gray-600',
        this.disabled ? 'opacity-70' : null,
      ];
    },
  },
  created() {
    this.$emit('created');
  },
  methods: {
    selectClass(validationFlags) {
      return [
        validationFlags?.invalid ? 'border-secondary-red' : null,
        this.disabled
          ? 'disabled:opacity-50 cursor-not-allowed bg-gray-200'
          : 'hover:border-primary-dark',
        'w-full',
        'border border-gray-300',
        'transition duration-200 ease-in',
        'rounded-md text-gray-600 pl-5 pr-8 py-3 bg-white font-body font-medium',
        'focus:outline-none appearance-none focus:border-primary-dark',
      ];
    },
    changeHandler(event) {
      this.$emit('change', event.target.value);
    },
    inputHandler(event) {
      const isMultiple = event.target.hasAttribute('multiple');

      if (isMultiple) {
        const selectedOptions = Array.from(event.target.options)
          .filter(option => option.selected)
          .map(option => option.value)
          .filter(option => option);

        // this.$log.info('isMultiple', selectedOptions);
        return this.$emit('input', selectedOptions);
      }

      // this.$log.info('isSingle', event.target.value || null);
      return this.$emit('input', event.target.value || null);
    },
    selectedOption(option) {
      if (this.value) {
        if (Array.isArray(this.value)) {
          // this.$log.info('selectedOption 1', this.value, this.value.includes(option.value), option.value);
          return this.value.includes(option.value);
        }
        // this.$log.info('selectedOption 2', this.value, option, (option.value || option) === this.value);
        return (option.value || option) === this.value;
      }

      return false;
    },
  },
};
</script>

<style></style>
