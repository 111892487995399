<template>
  <div>
    <cc-section-table
      class="mb-4"
      :title="sectionTitle"
      :is-loading="isLoading"
      >
      <cc-text
        v-if="sectionBody"
        color="text-gray-600"
        class="pb-4 max-w-full xl:max-w-3xl"
        >
        {{ sectionBody }}
      </cc-text>

      <div
        class="grid grid-cols-1 xl:grid-cols-2 gap-4"
        >
        <cc-wallet-balance
          v-if="hasStoreWallet"
          :balance="storeBalance"
          :button-one-label="$t('commons.redeem')"
          :wallet-title="$t('pages.greenCoins.wallet')"
          :has-button-one="canRedeem"
          :has-button-two="false"
          :is-button-one-disabled="!storeBalance"
          :wallet="storeWallet"
          class="max-w-full xl:max-w-none"
          @click:button-one="storeBalance && canRedeem ? (isRequestRedeemOpen = true) : undefined"
          />

        <cc-wallet-balance
          v-if="hasSponsorWallet"
          class="max-w-full xl:max-w-none"
          :balance="sponsorBalance"
          :wallet-title="$t('pages.greenCoins.sponsorWallet')"
          :wallet="sponsorWallet"
          :button-one-label="$t('commons.transferGreenCoins')"
          :button-two-label="$t('commons.transferToGreenCoins')"
          :has-button-one="canTransfer"
          :has-button-two="canTransfer"
          :is-button-one-disabled="isTransferringSponsorWalletLeftOver || !storeBalance"
          :is-button-two-disabled="!sponsorBalance || isTransferringSponsorWalletLeftOver"
          :is-button-two-loading="isTransferringSponsorWalletLeftOver"
          @click:button-one="storeBalance ? (isTransferOpen = true) : undefined"
          @click:button-two="sponsorBalance ? transferSponsorWalletLeftOver() : undefined"
          />

        <!-- Balance Threshold -->
        <div
          v-if="hasBalanceThreshold"
          class="px-4 xl:px-0 py-4 flex-1 max-w-full xl:max-w-none"
          >
          <cc-text
            :text="$t('pages.sponsorWallet.balanceThreshold')"
            variant="body-lg-bold"
            color="text-primary-darker"
            class="flex-grow"
            :class="canTransfer ? 'pt-1' : null"
            />

          <div>
            <cc-text
              color="text-gray-700"
              class="pb-1"
              variant="body-sm"
              >
              {{ $t('pages.sponsorWallet.balancethresholdBody') }}
            </cc-text>

            <div class="flex items-end">
              <div class="pr-1">
                <cc-input
                  v-model.trim="minBalanceThreshold"
                  :label="$t('commons.thresholdAmount')"
                  type="number"
                  min="0"
                  />
              </div>

              <div class="pl-1">
                <cc-button
                  :text="$t('commons.update')"
                  :disabled="Number(minBalanceThresholdMirror) === Number(minBalanceThreshold)"
                  :is-loading="isUpdatingSponsorMinBalanceThreshold"
                  @click.native="updateSponsorMinBalanceThreshold"
                  />
              </div>
            </div>
            <cc-text
              color="text-gray-700"
              class="pt-2"
              variant="body-sm"
              >
              {{ $t('pages.sponsorWallet.balancethresholdDescription') }}
            </cc-text>
          </div>
        </div>
      </div>
    </cc-section-table>

    <cc-modal
      v-if="isTransferOpen"
      :is-open="isTransferOpen"
      transition-name="slide-up"
      :can-backdrop-dismiss="!isToppingUpSponsorWallet"
      :heading="$t('modals.transferToWallet.title')"
      @close="isTransferOpen = false"
      >
      <template #body>
        <validation-observer ref="form">
          <div class="flex">
            <div class="flex-1">
              <cc-text
                variant="body-sm"
                class="flex-1 pb-1"
                color="text-gray-500"
                >
                {{ $t('commons.fieldNames.from') }}
              </cc-text>

              <cc-text variant="body-bold">
                {{ $t('pages.greenCoins.wallet') }}
              </cc-text>

              <cc-text
                variant="body-xs"
                color="text-gray-500"
                >
                {{ storeBalance }} {{ $t('commons.fieldNames.greenCoins') }}
              </cc-text>
            </div>

            <cc-input
              id="top-up-amount"
              v-model="topUpAmount"
              type="number"
              min="1"
              step="1"
              name="name"
              :label="$t('commons.amount')"
              :disabled="storeBalance === 0 || isToppingUpSponsorWallet"
              :placeholder="$t('modals.transferToWallet.amountPlaceholder')"
              class="flex-1 mx-4 min-w-36"
              validation-rules="required|integer"
              autofocus
              />

            <div class="flex-1">
              <cc-text
                variant="body-sm"
                class="flex-1 pb-1"
                color="text-gray-500"
                >
                {{ $t('commons.fieldNames.to') }}
              </cc-text>

              <cc-text variant="body-bold">
                {{ $t('pages.sponsorWallet.wallet') }}
              </cc-text>

              <cc-text
                variant="body-xs"
                color="text-gray-500"
                >
                {{ sponsorBalance }} {{ $t('commons.fieldNames.greenCoins') }}
              </cc-text>
            </div>
          </div>
        </validation-observer>

        <cc-error-message
          v-if="errors.length"
          :message="errors[0]"
          class="mt-4"
          />
      </template>

      <template #footer="{ close }">
        <div class="grid grid-cols-2 gap-x-4">
          <cc-button
            variant="secondary"
            :text="$t('commons.cancel')"
            :disabled="isToppingUpSponsorWallet"
            @click.native="close"
            />
          <cc-button
            :text="$t('commons.transfer')"
            :is-loading="isToppingUpSponsorWallet"
            :disabled="topUpAmount <= 0 || storeBalance === 0 || isToppingUpSponsorWallet"
            @click.native="topUpCompanySponsorWallet(close)"
            />
        </div>
      </template>
    </cc-modal>

    <cc-modal
      v-if="isRequestRedeemOpen"
      :is-open="isRequestRedeemOpen"
      transition-name="slide-up"
      :heading="$t('modals.requestRedeem.title')"
      @close="isRequestRedeemOpen = false"
      >
      <template #body>
        <validation-observer ref="form">
          <div class="max-w-lg">
            <cc-hint
              class="mb-8"
              :hint="$t('pages.greenCoins.redeemWarning')"
              />

            <cc-input
              id="amount"
              v-model="amount"
              type="number"
              min="15"
              step="1"
              name="name"
              :label="$t('modals.requestRedeem.amount')"
              :placeholder="$t('modals.requestRedeem.amountPlaceholder')"
              class="pb-4"
              validation-rules="required|integer|minRedeemAmount:15"
              autofocus
              />
            <cc-input
              id="ibanOwnerFullName"
              v-model="ibanOwnerFullName"
              :label="$t('modals.requestRedeem.ibanOwnerFullName')"
              class="pb-4"
              validation-rules="required"
              />
            <cc-input
              id="ibanCode"
              v-model.trim="ibanCode"
              :label="$t('modals.requestRedeem.iban')"
              :placeholder="$t('modals.requestRedeem.ibanPlaceholder')"
              :validation-rules="`required|hasValidIBAN:${hasValidIBAN}`"
              />
          </div>
        </validation-observer>
      </template>

      <template #footer="{ close }">
        <div class="grid grid-cols-2 gap-x-4">
          <cc-button
            variant="secondary"
            :text="$t('commons.cancel')"
            @click.native="close"
            />
          <cc-button
            :text="$t('commons.continue')"
            @click.native="requestRedeemAction(close)"
            />
        </div>
      </template>
    </cc-modal>

    <cc-modal
      v-if="isConfirmRedeemOpen"
      :is-open="isConfirmRedeemOpen"
      transition-name="slide-up"
      :heading="$t('modals.confirmRedeem.title')"
      @close="isConfirmRedeemOpen = false"
      >
      <template #body>
        <div class="max-w-lg">
          <cc-input
            id="from"
            v-model="companyName"
            name="from"
            :label="$t('commons.from')"
            class="pb-4"
            disabled
            />
          <cc-input
            id="ibanCode"
            v-model.trim="ibanCode"
            :label="$t('commons.to')"
            class="pb-4"
            disabled
            />
          <cc-input
            id="ibanOwnerFullName"
            v-model="ibanOwnerFullName"
            :label="$t('modals.requestRedeem.ibanOwnerFullName')"
            class="pb-4"
            disabled
            />

          <cc-input
            id="amount"
            v-model="amount"
            name="name"
            :label="$t('commons.amount')"
            disabled
            />

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            class="mt-4"
            />
        </div>
      </template>

      <template #footer="{ close }">
        <div class="grid grid-cols-2 gap-x-4">
          <cc-button
            variant="secondary"
            :text="$t('commons.back')"
            @click.native="confirmRedeemBack(close)"
            />
          <cc-button
            :text="$t('modals.confirmRedeem.primaryButton')"
            :disabled="isRedeeming"
            :is-loading="isRedeeming"
            type="submit"
            @click.native="redeem(close)"
            />
        </div>
      </template>
    </cc-modal>

    <cc-feedback-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      :feedback-heading="feedbackMessage"
      :timeout-duration="2000"
      @close="isFeedbackOpen = false"
      />
  </div>
</template>

<script>
import { isValidIBANNumber } from '@/mixins/utils';
import validator from '@/mixins/validator';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [validator],
  props: {
    hasStoreWallet: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasSponsorWallet: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasBalanceThreshold: {
      type: Boolean,
      required: false,
      default: false,
    },
    wallets: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    canRedeem: {
      type: Boolean,
      required: false,
      default: true,
    },
    canTransfer: {
      type: Boolean,
      required: false,
      default: true,
    },
    sectionTitle: {
      type: String,
      required: false,
      default: null,
    },
    sectionBody: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isFeedbackOpen: false,
      isToppingUpSponsorWallet: false,
      isTransferringSponsorWalletLeftOver: false,
      isUpdatingSponsorMinBalanceThreshold: false,
      isTransferOpen: false,
      isRequestRedeemOpen: false,
      isConfirmRedeemOpen: false,
      isRedeeming: false,
      ibanOwnerFullName: '',
      topUpAmount: null,
      minBalanceThresholdMirror: null,
      amount: null,
      ibanCode: '',
      feedbackMessage: this.$t('modals.successfulRedeem.title'),
      errors: [],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user,
    }),
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    hasValidIBAN() {
      return this.isValidIBANNumber(this.ibanCode) === 1;
    },
    storeWallet() {
      return this.wallets?.find(w => w.type === 'STORE');
    },
    storeBalance() {
      return this.storeWallet?.balance || 0;
    },
    sponsorWallet() {
      return this.wallets?.find(w => w.type === 'SPONSOR') || {};
    },
    sponsorBalance() {
      return this.sponsorWallet?.balance || 0;
    },
    minBalanceThreshold: {
      get() {
        return this.sponsorWallet?.minBalanceThreshold || 0;
      },
      set(val) {
        this.sponsorWallet.minBalanceThreshold = val;
      },
    },
    companyName() {
      return this.getCompanyName(this.$route.params.companyId);
    },
  },
  watch: {
    sponsorBalance() {
      this.minBalanceThresholdMirror = this.minBalanceThreshold;
    },
  },
  created() {
    this.ibanOwnerFullName = this.user.user.name;
  },
  methods: {
    isValidIBANNumber,
    async updateSponsorMinBalanceThreshold() {
      try {
        this.isUpdatingSponsorMinBalanceThreshold = true;

        const payload = {
          walletId: this.sponsorWallet.id,
          minBalanceThreshold: Number(this.sponsorWallet.minBalanceThreshold),
          maxBalanceThreshold: null,
        };

        await this.$store.dispatch('company/updateThresholds', payload);
        this.minBalanceThresholdMirror = this.minBalanceThreshold;

        this.feedbackMessage = this.$t('modals.balanceThresholdUpdate.title');

        this.isUpdatingSponsorMinBalanceThreshold = false;
        this.isFeedbackOpen = true;
      } catch (error) {
        this.$log.info('Error: updateSponsorMinBalanceThreshold', error);
        this.isUpdatingSponsorMinBalanceThreshold = false;
      }
    },
    async transferSponsorWalletLeftOver() {
      try {
        const payload = {
          companyId: this.$route.params.companyId,
        };

        this.isTransferringSponsorWalletLeftOver = true;

        await this.$store.dispatch('company/transferSponsorWalletLeftOver', payload.companyId);

        this.$emit('fetch-wallets');

        this.feedbackMessage = this.$t('modals.successfulTransfer.title');

        setTimeout(() => {
          this.isFeedbackOpen = true;
        }, 300);
      } catch (error) {
        this.$log.info('transferSponsorWalletLeftOver', error);

        this.errors.push(this.$t('errors.genericError'));
      } finally {
        this.isTransferringSponsorWalletLeftOver = false;
      }
    },
    async topUpCompanySponsorWallet(close) {
      try {
        this.errors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        const payload = {
          companyId: this.$route.params.companyId,
          amount: Number(this.topUpAmount),
        };

        this.isToppingUpSponsorWallet = true;

        await this.$store.dispatch('company/topUpCompanySponsorWallet', payload);

        this.$emit('fetch-wallets');

        this.feedbackMessage = this.$t('modals.successfulTransfer.title');

        this.topUpAmount = null;

        close();

        setTimeout(() => {
          this.isFeedbackOpen = true;
        }, 300);
      } catch (error) {
        this.$log.info('topUpCompanySponsorWallet', error);

        this.errors.push(this.$t('errors.genericError'));
      } finally {
        this.isToppingUpSponsorWallet = false;
      }
    },
    async requestRedeemAction(close) {
      const success = await this.$refs.form.validate();

      if (!success) {
        this.$log.info('Form !success', success);
        return;
      }

      close();
      setTimeout(() => {
        this.isConfirmRedeemOpen = true;
      }, 300);
    },
    async redeem(closeModal) {
      try {
        this.isRedeeming = true;
        this.errors = [];

        this.$log.info('redeem handler');

        await this.$store.dispatch('company/redeem', {
          companyId: this.$route.params.companyId,
          payload: {
            walletId: this.storeWallet.id,
            amount: this.amount?.toString(),
            ibanCode: this.ibanCode,
            ibanOwnerFullName: this.ibanOwnerFullName,
          },
        });

        this.$emit('fetch-wallets');

        closeModal();

        setTimeout(() => {
          this.isFeedbackOpen = true;
        }, 300);

        this.amount = null;
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: redeem', err);
      } finally {
        this.isRedeeming = false;
      }
    },
    confirmRedeemBack(close) {
      close();

      this.errors = [];

      setTimeout(() => {
        this.isRequestRedeemOpen = true;
      }, 300);
    },
  },
};
</script>
