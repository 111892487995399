<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t('modals.onboardingCode.title')"
    @close="$emit('close')"
    >
    <template #body="">
      <div class="max-w-2xl">
        <cc-text
          color="text-gray-600"
          class="pb-4 max-w-full"
          >
          {{ $t('modals.onboardingCode.body') }}
        </cc-text>
        <validation-observer ref="form">
          <div class="flex flex-col sm:flex-row items-start gap-4">
            <div class="flex items-start gap-2 w-full">
              <cc-input
                v-model="onboardingCode"
                name="onboardingCode"
                :label="$t('modals.onboardingCode.onboardingCode')"
                :validation-rules="onboardingCode ? `onlyUpperCaseLettersOrNumbers|length:${6}` : null"
                validation-interaction-mode="lazy"
                class="w-full"
                autofocus
                />
              <cc-button
                variant="secondary"
                :has-min-width="false"
                :block="false"
                class="mt-6"
                svg-class="w-6 h-6"
                svg="icons/icon-retry"
                svg-align="right"
                @click.native="setRandomValueForOnboardingCode()"
                />
            </div>
            <cc-input
              v-model="department"
              name="department"
              :label="$t('modals.onboardingCode.department')"
              validation-rules="required"
              class="w-full"
              validation-interaction-mode="lazy"
              autofocus
              />
          </div>
        </validation-observer>
      </div>
      <cc-error-message
        v-if="errors.length"
        :message="errors[0]"
        class="max-w-2xl mt-4"
        />
    </template>

    <template #footer="{ close }">
      <div class="flex gap-4">
        <cc-button
          variant="secondary"
          :text="$t('commons.close')"
          :disabled="isLoading"
          :has-min-width="false"
          @click.native="close()"
          />
        <cc-button
          variant="primary"
          :text="$t('commons.save')"
          :disabled="isLoading"
          :has-min-width="false"
          :is-loading="isLoading"
          @click.native="createOnboardingCode(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import { generateRandomAlphaNumericString } from '@/mixins/utils';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    onboardingCodes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      query: '',
      isLoading: false,
      errors: [],
      department: null,
      onboardingCode: null,
    };
  },
  computed: {
  },
  created() {
    this.setRandomValueForOnboardingCode();
  },
  methods: {
    setRandomValueForOnboardingCode() {
      this.onboardingCode = generateRandomAlphaNumericString().toUpperCase();
    },
    async createOnboardingCode(close) {
      const { onboardingCode, department } = this;

      try {
        this.errors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;
        this.$log.info(`department ${department} onboardingCode ${onboardingCode}`);
        const payload = {
          companyId: this.$route.params.companyId,
          body: {
            department: this.department,
            onboardingCode: this.onboardingCode,
          },
        };

        await this.$store.dispatch('company/createOnboardingCode', payload);

        this.$emit('success');

        close();
      } catch (err) {
        this.$log.info(err);
        if (err?.code === 409) {
          this.errors.push(this.$t('errors.existingOnboardingCode'));
        } else {
          this.errors.push(this.$t('errors.genericError'));
        }

        this.$log.error('Error: createOnboardingCode', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
