<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-md">
      <cc-heading
        tag="h1"
        class="text-3xl"
        >
        {{ $t('pages.merchantAgreement.title') }}
      </cc-heading>

      <div class="pt-8">
        <validation-observer ref="form">
          <form @submit.prevent="createAccountHandler">
            <cc-input
              class="mb-4"
              type="text"
              autocomplete="email"
              autofocus
              hidden
              />

            <cc-input
              id="new-password"
              v-model="password"
              :placeholder="$t('commons.password')"
              type="password"
              autocomplete="new-password"
              class="mb-4 relative"
              :has-password-toggle="true"
              :validation-rules="`required|passwordComplexity|password:@${$t('commons.passwordRepeat')}`"
              :validation-name="$t('commons.password')"
              :passwordrules="user.passwordrules"
              autofocus
              />

            <cc-input
              v-model="passwordRepeat"
              :placeholder="$t('commons.passwordRepeat')"
              type="password"
              autocomplete="new-password"
              class="mb-4 relative"
              :has-password-toggle="true"
              validation-rules="required"
              :validation-name="$t('commons.passwordRepeat')"
              :passwordrules="user.passwordrules"
              />

            <div class="flex items-start pt-4">
              <cc-check-box
                v-model="isSCAgreementAccepted"
                :has-border="false"
                variant="plain"
                class="pr-4 pt-1"
                :validation-rules="`checkbox:${isSCAgreementAccepted}`"
                :validation-name="$t('pages.merchantAgreement.SCAgreement')"
                :has-error-message="false"
                @errors="$event => SCAgreementErrors = $event"
                />
              <cc-text class="flex-grow">
                <i18n
                  path="pages.merchantAgreement.SCAgreementCheckbox"
                  >
                  <template #terms>
                    <a
                      class="underline"
                      :href="swisscomTermsAndConditionsLink"
                      target="_blank"
                      >
                      {{ $t('pages.merchantAgreement.termsAndConditions') }}
                    </a>
                  </template>
                </i18n>
              </cc-text>
            </div>

            <cc-error-message
              v-if="SCAgreementErrors && SCAgreementErrors[0]"
              class="flex-grow w-full"
              :message="SCAgreementErrors[0]"
              />

            <div class="flex items-start pt-4">
              <cc-check-box
                v-model="isPFAgreementAccepted"
                :has-border="false"
                variant="plain"
                :validation-rules="`checkbox:${isPFAgreementAccepted}`"
                validation-name="PostFinance agreement"
                :has-error-message="false"
                class="pr-4 pt-1"
                @errors="$event => PFAgreementErrors = $event"
                />
              <cc-text class="flex-grow">
                <i18n
                  path="pages.merchantAgreement.PFAgreementCheckbox"
                  >
                  <template #terms>
                    <a
                      class="underline"
                      :href="postFinanceTermsAndConditionsLink"
                      target="_blank"
                      >
                      {{ $t('pages.merchantAgreement.termsAndConditions') }}
                    </a>
                  </template>
                </i18n>
              </cc-text>
            </div>

            <cc-error-message
              v-if="PFAgreementErrors && PFAgreementErrors[0]"
              class="flex-grow w-full pb-2"
              :message="PFAgreementErrors[0]"
              />

            <cc-error-message
              v-if="errors.length"
              :message="errors[0]"
              padding="pt-4"
              />


            <div class="flex pt-6">
              <cc-button
                class="mr-2"
                variant="secondary"
                :text="$t('commons.back')"
                :has-min-width="false"
                @click.native="$router.push({ name: 'complete-registration'})"
                />
              <cc-button
                class="ml-2"
                :text="$t('pages.merchantAgreement.primaryButton')"
                :has-min-width="false"
                :is-loading="isLoading"
                :disabled="isLoading"
                type="submit"
                />
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      password: null,
      passwordRepeat: null,
      isPFAgreementAccepted: false,
      isSCAgreementAccepted: false,
      isLoading: false,
      errors: [],
      SCAgreementErrors: [],
      PFAgreementErrors: [],
      swisscomTermsAndConditionsLink: 'https://www.swissclimatechallenge.ch/de/merchants-swisscom',
      postFinanceTermsAndConditionsLink: 'https://www.swissclimatechallenge.ch/de/merchants-postfinance',
    };
  },
  computed: {
    ...mapState({
      user: st => st.user,
    }),
  },
  methods: {
    ...mapMutations({
      setState: 'user/setState',
    }),
    async createAccountHandler() {
      this.$log.info('createAccountHandler');

      this.errors = [];

      const success = await this.$refs.form.validate();

      if (!success) {
        this.$log.info('Form !success', success);
        return;
      }

      const { password, passwordRepeat } = this;

      const isAgreementsAccepted = this.isPFAgreementAccepted && this.isSCAgreementAccepted;

      const { phone, metaPrivate } = this.user.user;

      const hasCompletedRegistration = phone.verified
        && metaPrivate?.address
        && metaPrivate?.city
        && metaPrivate?.postalCode;

      try {
        this.isLoading = true;

        if (!hasCompletedRegistration) {
          throw Error('hasNotCompletedRegistration');
        }

        if (password === passwordRepeat && isAgreementsAccepted) {
          await this.$store.dispatch('user/updatePassword', { password });

          // TODO: Might need to spread existing metaPublic here for users who already have an SCC account
          const metaPublic = {
            PFAgreementAcceptedAt: new Date().toISOString(),
            SCAgreementAcceptedAt: new Date().toISOString(),
          };

          const updatedProfile = await this.$store.dispatch('user/updateProfile', {
            requireNewPassword: false,
            metaPublic,
          });

          this.setState(['user', updatedProfile]);

          this.$router.push({ name: 'dashboard' }).catch(() => {});
        }
      } catch (err) {
        if (err.message === 'hasNotCompletedRegistration') {
          this.errors.push(this.$t('errors.hasNotCompletedRegistration'));
        } else {
          this.errors.push(this.$t('errors.genericError'));
        }

        this.$log.error('Error: createAccountHandler', err.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
