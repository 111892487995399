import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin/role`;

export default {
  async getRolesAndActions() {
    return ApiService.get(`${resourceAdmin}`);
  },

  async getRoleById(roleId) {
    return ApiService.get(`${resourceAdmin}/${roleId}`);
  },

  async addActionsToRole(roleName, payload) {
    return ApiService.securePost(`${resourceAdmin}/${roleName}/actions/add`, payload);
  },

  async removeActionsFromRole(roleId, payload) {
    return ApiService.delete(`${resourceAdmin}/${roleId}/actions/remove`, payload);
  },

  async deleteRole(roleId) {
    return ApiService.delete(`${resourceAdmin}/${roleId}`);
  },

  async getAllActions() {
    return ApiService.get(`${resourceAdmin}/getAllActions`);
  },

  async addRolesToUser(userId, payload) {
    return ApiService.securePost(`${resourceAdmin}/user/${userId}`, payload);
  },

  async removeRolesFromUser(userId, payload) {
    return ApiService.delete(`${resourceAdmin}/user/${userId}`, payload);
  },

  async getRolesOfUser(userId) {
    return ApiService.get(`${resourceAdmin}/user/${userId}`);
  },

  async fetchByUserIds(payload) {
    return ApiService.securePost(`${resourceAdmin}/fetchByUserIds`, payload);
  },
};
