<template>
  <div class="flex items-center flex-wrap">
    <cc-input
      v-model="getDuration"
      :label="label"
      class="w-32 mr-2"
      ruid="create-challenge__duration"
      :validation-rules="hasValidation ? 'greaterThanZero' : null"
      :validation-name="validationName"
      type="number"
      align="text-center"
      min="0"
      step="1"
      :has-error-message="false"
      @errors="$event => errors = $event"
      />
    <cc-select
      id="time-frame"
      v-model="getTimeFrame"
      :label="selectLabel"
      :has-de-select="false"
      ruid="create-challenge__time-frame"
      class="w-28"
      :options="getTimeFrames"
      />
    <cc-error-message
      v-if="errors && errors[0]"
      class="flex-grow w-full"
      :message="errors[0]"
      />
  </div>
</template>

<script>
import { convertMilliseconds, getMilliseconds, formatDuration } from '@/mixins/utils';


export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    validationName: {
      type: String,
      required: false,
      default: null,
    },
    selectLabel: {
      type: String,
      required: false,
      default: null,
    },
    durationInMillis: {
      type: Number,
      required: false,
      default: 0,
    },
    hasValidation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      errors: [],
      duration: formatDuration(this.durationInMillis).duration || 0,
      timeFrame: formatDuration(this.durationInMillis).timeFrame || 'day',
      timeFrames: ['second', 'minute', 'hour', 'day', 'week'],
    };
  },
  computed: {
    getTimeFrames() {
      return this.timeFrames
        .map(timeFrame => [{ value: timeFrame, text: this.$tc(`commons.timeFrames.${timeFrame}`) }])
        .flat();
    },
    getDuration: {
      get() {
        return this.duration;
      },
      set(val) {
        this.duration = val;
        this.$emit('milliseconds', this.getMilliseconds(this.timeFrame, val));
      },
    },
    getTimeFrame: {
      get() {
        return this.timeFrame;
      },
      set(val) {
        this.timeFrame = val;
        this.$emit('milliseconds', this.getMilliseconds(this.timeFrame, this.getDuration));
      },
    },
  },
  methods: {
    convertMilliseconds,
    getMilliseconds,
  },
};
</script>

<style>
</style>
