import Vue from 'vue';
import RoleService from '@/services/RoleService';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
});

const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};

const actions = {
  async getRolesAndActions() {
    try {
      const response = await RoleService.getRolesAndActions();

      // Vue.$log.info('Response: Vuex: getRolesAndActions', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getRolesAndActions', err);
      throw err;
    }
  },

  async addActionsToRole(context, { roleId, payload }) {
    try {
      const response = await RoleService.addActionsToRole(roleId, payload);

      // Vue.$log.info('Response: Vuex: addActionsToRole', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: addActionsToRole', err);
      throw err;
    }
  },

  async removeActionsFromRole(context, { roleId, payload }) {
    try {
      const response = await RoleService.removeActionsFromRole(roleId, payload);

      Vue.$log.info('Response: Vuex: removeActionsFromRole', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: removeActionsFromRole', err);
      throw err;
    }
  },

  async addRolesToUser(context, { userId, payload }) {
    try {
      const response = await RoleService.addRolesToUser(userId, payload);

      Vue.$log.info('Response: Vuex: addRolesToUser', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: addRolesToUser', err);
      throw err;
    }
  },

  async removeRolesFromUser(context, { userId, payload }) {
    try {
      const response = await RoleService.removeRolesFromUser(userId, payload);

      Vue.$log.info('Response: Vuex: removeRolesFromUser', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: removeRolesFromUser', err);
      throw err;
    }
  },

  async getAllActions() {
    try {
      const response = await RoleService.getAllActions();

      // Vue.$log.info('Response: Vuex: getAllActions', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getAllActions', err);
      throw err;
    }
  },

  async deleteRole(context, roleId) {
    try {
      const response = await RoleService.deleteRole(roleId);

      Vue.$log.info('Response: Vuex: deleteRole', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteRole', err);
      throw err;
    }
  },

  async getRoleById(context, roleId) {
    try {
      const response = await RoleService.getRoleById(roleId);

      Vue.$log.info('Response: Vuex: getRoleById', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getRoleById', err);
      throw err;
    }
  },

  async fetchByUserIds(context, userIds) {
    try {
      const response = await RoleService.fetchByUserIds(userIds);

      // Vue.$log.info('Response: Vuex: fetchByUserIds', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: fetchByUserIds', err);
      throw err;
    }
  },
};

const getters = {
  // eslint-disable-next-line no-return-assign, no-sequences, no-param-reassign
  // actions: st => st.actions.reduce((map, obj) => {
  //   // eslint-disable-next-line no-param-reassign
  //   map[obj.actionId] = obj.actionId;
  //   return map;
  // }, {}),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
