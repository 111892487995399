import { authHeader } from '@/services/SecurityService';
// import * as Sentry from '@sentry/vue';


const apiUrl = process.env.VUE_APP_API_URL;


async function handleResponse(response) {
  const text = await response.text();

  if (!response.ok) {
    let parsedBody;

    try {
      parsedBody = JSON.parse(text);
    } catch (e) {
      parsedBody = text;
    }

    const responseError = {
      type: 'Error',
      message: response.statusText || 'Something went wrong',
      response,
      code: response.status || '',
      body: parsedBody,
    };

    let error = new Error();

    error = { ...error, ...responseError };

    // console.log('response', response);
    // console.log('error', error);
    // console.log('text', text);
    // Sentry.captureException(error);

    throw error;
  }


  try {
    const data = JSON.parse(text);

    return Promise.resolve(data);
  } catch (ex) {
    return Promise.resolve(text);
  }
}


export default {
  async get(path, headers) {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...authHeader(),
        ...headers,
      },
    };

    const response = await fetch(`${apiUrl}${path}`, requestOptions);

    return handleResponse(response);
  },


  async post(path, payload, headers) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${apiUrl}${path}`, requestOptions);

    return handleResponse(response);
  },


  async securePost(path, payload, headers) {
    return this.post(path, payload, {
      ...authHeader(),
      ...headers,
    });
  },


  async doSecureFileUpload(path, payload) {
    const requestOptions = {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: payload,
    };

    const response = await fetch(`${apiUrl}${path}`, requestOptions);

    return handleResponse(response);
  },


  async delete(path, payload, headers) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
        ...headers,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${apiUrl}${path}`, requestOptions);

    return handleResponse(response);
  },


  async put(path, payload, headers) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
        ...headers,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`${apiUrl}${path}`, requestOptions);

    return handleResponse(response);
  },

};
