<template>
  <div class="and-or-rule flex flex-wrap items-start p-2 bg-gray-100 border border-gray-100 rounded-lg">
    <cc-select
      id="fields"
      v-model="key"
      class="p-2"
      :label="$t('configs.fields')"
      :has-de-select="true"
      :options="keysOptions"
      :validation-rules="'required'"
      :validation-id="`${ruleId}-field`"
      />

    <cc-select
      id="operators"
      v-model="operator"
      :disabled="!key"
      class="p-2 min-w-56"
      :label="$t('configs.operators')"
      :has-de-select="true"
      :options="operatorsOptions"
      validation-interaction-mode="passive"
      :validation-rules="'required'"
      :validation-id="`${ruleId}-operator`"
      />

    <cc-input
      v-model="value"
      class="p-2"
      :label="$t('configs.value')"
      :placeholder="$t('configs.valuePlaceholder')"
      :validation-id="`${ruleId}-value`"
      :validation-rules="'required'"
      />


    <div class="p-2">
      <cc-button
        variant="secondary"
        class="mt-6"
        :block="false"
        :has-min-width="false"
        svg="icons/icon-delete"
        svg-color="text-gray-500"
        svg-align="left"
        @click.native="deleteSelf()"
        />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rule',
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    ruleId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      key: null,
      operator: null,
      value: null,
    };
  },
  computed: {
    keys() {
      return this.options.keys;
    },
    operators() {
      return this.options.operators;
    },
    operatorsByKey() {
      return this.options.operatorsByKey;
    },
    operatorsByKeyOptions() {
      if (this.operatorsByKey?.[this.key]) {
        return this.operators.filter(op => this.operatorsByKey?.[this.key]?.includes(op.id));
      }
      return this.operators;
    },
    keysOptions() {
      return this.generateOptionsFromObject('keys');
    },
    operatorsOptions() {
      return this.generateOptionsFromObject('operatorsByKeyOptions');
    },
  },
  watch: {
    key(newVal, oldVal) {
      if (oldVal) {
        this.operator = null;
      }
    },
  },
  updated() {
    this.$log.warn('Filter Builder Rule updated');
    this.$emit('rule-update');
  },
  methods: {
    deleteSelf() {
      this.$emit('delete-rule');
    },

    queryFormStatus() {
      return {
        key: this.key,
        operator: this.operator,
        value: this.handleValue(this.value, this.operator),
      };
    },

    handleValue(value, operator) {
      if (value && (operator === 'in' || operator === 'nin')) {
        return value
          ?.split(',')
          ?.filter(item => item)
          ?.map(item => item?.trim());
      }
      return value || null;
    },

    fillRuleStatus(data) {
      this.key = data.key;
      this.operator = data.operator;
      this.value = data.value;
    },

    generateOptionsFromObject(source) {
      return this[source]
        ?.slice()
        .map(el => [{ value: el.id, text: el.name }])
        .flat();
    },
  },
};
</script>

<style lang="postcss" scoped>
</style>
