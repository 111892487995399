<template>
  <label>
    <!-- <input
      v-model="model"
      type="checkbox"
      :value="inputValue"
      > -->

    <input
      v-model="model"
      type="checkbox"
      :value="value"
      >

    <!-- <span>{{ label }}</span> -->
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
  },
};
</script>
