const mobileAppRoutes = [
  { footprint: 'Footprint' }, //
  { challenge: 'Challenge Detail' },
  { challenges: 'Challenges' },
  { leaderboards: 'Leaderboards' },
  { shop: 'Shop' },
  { points: 'Points' },
  { wallet: 'Wallet' },
  { 'climate-change': 'Climate Change' },
  { journeys: 'Journeys' },
  { 'news-feed': 'News Feed' },
  { achievements: 'Achievements' },
  { 'friends-leaderboard': 'Friends Leaderboard' },
  { 'canton-leaderboard': 'Canton Leaderboard' },
  { 'group-leaderboard': 'Group Leaderboard' },
  { messages: 'Messages' },
  { settings: 'Settings' },
  { 'join-the-challenge': 'Join the Challenge' },
  { 'vehicle-settings': 'Vehicle Settings' },
  { 'language-settings': 'Language Settings' },
  { 'getting-started': 'Getting Started' },
];

export default mobileAppRoutes;
