import Vue from 'vue';
import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
// import router from '@/router';

const isDev = process.env.VUE_APP_ENV === 'development';
const dsn = 'https://8e89d5c525174e8cb3ebb28d1bc16990@o228293.ingest.sentry.io/5870732';

const initSentry = () => Sentry.init({
  Vue,
  dsn: isDev ? '' : dsn,
  integrations(integrations) {
    // const browserTracing = new Integrations.BrowserTracing({
    //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //   tracingOrigins: ['localhost', 'api.stg.rastro.ch', 'api.rastro.ch', /^\//],
    // });

    // integrations.push(browserTracing);
    /**
     * Since Sentry 6.8.0 Dedupe integration became default which prevents
     * duplicate looking like events from being dispatched
     * e.g. dispatching a static string multiple times
     * As we do not want this, filter out Dedupe integration.
     */
    return integrations.filter(integration => integration.name !== 'Dedupe');
  },
  tracingOptions: {
    trackComponents: true,
  },
  release: `climate-challenge-dashboard@${process.env.VERSION}`,
  environment: process.env.VUE_APP_ENV,
  // sampleRate: 1.0,
  attachProps: true,
  logErrors: true,
  // tracesSampleRate: 1.0,
  debug: false,
});

export default initSentry;
