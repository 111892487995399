<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col justify-center items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <cc-heading
      tag="h1"
      align="center"
      color="text-white"
      >
      {{ $t('pages.pageNotFound.title') }}
    </cc-heading>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
    }),
  },
  created() {
  },
};
</script>
