<template>
  <div class="flex flex-col">
    <label
      v-if="label"
      :for="id"
      :class="labelClass"
      >
      <slot>{{ label }}</slot>
    </label>

    <validation-provider
      v-slot="{ classes, errors }"
      :ref="validationRef"
      :rules="validationRules"
      :name="label || validationName"
      :vid="validationId"
      :mode="validationInteractionMode"
      >
      <div
        class="flex rounded-md transition duration-200 ease-in"
        :class="checkboxWrapperClass"
        >
        <label
          class="flex justify-center items-center
        cursor-pointer select-none relative group text-gray-600"
          :class="text ? 'pr-2' : ''"
          >
          <input
            class="absolute opacity-0 h-0 w-0 cursor-pointer"
            :class="checkboxClass(classes)"
            type="checkbox"
            :data-ruid="ruid"
            :checked="isChecked"
            :data-has-error="handleErrors(errors)"
            :value="value"
            :disabled="disabled"
            @change="updateInput"
            >
          <span
            :class="checkboxClass(classes)"
            class="flex justify-center items-center  w-5 h-5 rounded-md
        bg-gray-200 border border-gray-300"
            >
            <cc-svg-base
              v-if="modelValue"
              svg="icons/icon-checkmark"
              class="fill-current text-white"
              />
            <cc-svg-base
              v-else-if="isDashed"
              svg="icons/icon-dash"
              class="fill-current text-white"
              />
          </span>

          <span
            v-if="text"
            class="pl-3"
            :class="textClass"
            >
            {{ text }}
          </span>
        </label>
      </div>

      <cc-error-message
        v-if="hasErrorMessage"
        :message="errors[0]"
        />
    </validation-provider>
  </div>
</template>

<script>
import commonProps from '@/mixins/commonProps';
import validator from '@/mixins/validator';

// Valid variants
const variantProps = [
  '',
  'plain', //
];

export default {
  mixins: [commonProps, validator],
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Boolean],
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    isDashed: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    hasErrorMessage: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      required: false,
      default: '',
      validator: value => variantProps.includes(value),
    },
  },
  computed: {
    textClass() {
      return [this.disabled ? 'opacity-50 cursor-not-allowed' : null];
    },
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
    labelClass() {
      return ['w-full pb-1 font-body rounded-md text-sm', 'inline-block text-gray-600'];
    },
    checkboxWrapperClass() {
      return [
        this.variant === '' ? 'p-3' : '',
        this.hasBorder ? 'border border-gray-300 hover:border-primary-dark' : ''];
    },
  },
  created() {
    this.$emit('created');
  },
  methods: {
    handleErrors(errors) {
      if (!this.hasErrorMessage) {
        this.$emit('errors', errors);
      }
    },
    checkboxClass(validationFlags) {
      return [
        validationFlags?.invalid ? 'border-secondary-red' : null,
        this.disabled ? 'opacity-50 cursor-not-allowed' : null,
        this.modelValue || this.isDashed
          ? 'bg-primary-light border-primary-light group-hover:bg-primary-darkest'
          : 'group-hover:bg-gray-300',
      ];
    },
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>
