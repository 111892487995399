import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin/tag`;
const resourceCompany = `${version}/company`;

export default {
  async getTags() {
    return ApiService.get(`${resourceAdmin}/user`);
  },

  async getFeatureTagsOfUsers(payload) {
    return ApiService.securePost(`${resourceAdmin}/users/feature/fetchByUserIds`, payload);
  },

  async addFeatureTagsToUsers(payload) {
    return ApiService.securePost(`${resourceAdmin}/users/feature`, payload);
  },

  async deleteFeatureTagsFromUsers(payload) {
    return ApiService.delete(`${resourceAdmin}/users/feature`, payload);
  },

  async addFeatureTagsToCompany(payload) {
    return ApiService.securePost(`${resourceAdmin}/company/feature`, payload);
  },

  async deleteFeatureTagsFromCompany(payload) {
    return ApiService.delete(`${resourceAdmin}/company/feature`, payload);
  },

  async getUsersByTag(payload) {
    return ApiService.securePost(`${resourceAdmin}/getUsersByTag`, payload);
  },

  async findCompanyUsersByTag(companyId, payload) {
    return ApiService.securePost(`${resourceCompany}/${companyId}/findUsersByTag`, payload);
  },
};
