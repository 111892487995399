<template>
  <cc-dropdown
    :is-open="isOpen"
    :dropdown-class="dropdownClass"
    @close="$emit('close')"
    >
    <router-link
      v-for="(item, i) in items"
      :key="i"
      :to="item.route ? { ...item.route } : {}"
      @click.native.stop="item.route
        ? null
        : $emit(item.event)"
      >
      <a
        v-if="item.label"
        href="#"
        class="block text-gray-800 hover:bg-primary-dark"
        >
        <cc-text
          :text="item.label"
          variant="body-sm"
          class="px-4 py-2 hover:text-white"
          />
      </a>
    </router-link>
  </cc-dropdown>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    dropdownClass: {
      type: String,
      required: true,
      default: '',
    },
  },
  created() {
    // this.$log.info('Created: dropdown');
  },
  methods: {
  },
};
</script>
