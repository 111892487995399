<template>
  <div>
    <label
      v-if="label"
      :for="id || name"
      :class="labelClass"
      class="flex flex-row justify-between"
      >
      <span>
        <slot>{{ label }}</slot>
        <cc-required-label v-if="isRequired" />
      </span>
      <span>{{ labelRight }}</span>
    </label>

    <span
      v-if="svg"
      class="absolute inset-y-0 left-0 flex items-center px-3"
      >
      <cc-svg-base
        :svg="svg"
        class="text-gray-500 fill-current"
        />
    </span>

    <validation-provider
      v-slot="{ classes, errors }"
      :ref="validationRef"
      :rules="validationRules"
      :name="label || validationName"
      :vid="validationId"
      :mode="validationInteractionMode"
      >
      <div class="relative">
        <textarea
          :id="id"
          :name="name"
          :class="inputClass(classes)"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
          :data-ruid="ruid"
          :data-has-error="handleErrors(errors)"
          :value="value"
          :rows="rows"
          :maxlength="maxlength"
          :required="required"
          :autofocus="autofocus"
          @input="inputHandler($event)"
          />
      </div>

      <cc-error-message
        v-if="hasErrorMessage"
        :message="errors[0]"
        />
    </validation-provider>
  </div>
</template>

<script>
import { camelCase } from '@/mixins/utils';
import commonProps from '@/mixins/commonProps';
import validator from '@/mixins/validator';

// Valid variants
const variantProps = ['regular', 'slim'];

export default {
  name: 'CcTextArea',
  mixins: [commonProps, validator],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    variant: {
      type: String,
      required: false,
      default: 'regular',
      validator: value => variantProps.includes(value),
    },
    align: {
      type: String,
      required: false,
      default: 'text-left',
    },
    rows: {
      type: String,
      required: false,
      default: '4',
    },
    emitCamelCase: {
      type: Boolean,
      required: false,
      default: false,
    },
    validationRef: {
      type: String,
      required: false,
      default: null,
    },
    hasErrorMessage: {
      type: Boolean,
      default: true,
    },
    validationState: {
      type: String,
      required: false,
      default: null,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    labelClass() {
      return ['w-full pb-1 font-body text-sm', 'inline-block text-gray-600'];
    },
  },
  created() {
    this.$emit('created');
  },
  methods: {
    handleErrors(errors) {
      if (!this.hasErrorMessage) {
        this.$emit('errors', errors);
      }
    },
    camelCase,
    inputHandler(event, errors) {
      const { emitCamelCase } = this;

      if (emitCamelCase) {
        return this.$emit('input', camelCase(event.target.value));
      }

      if (errors?.length) {
        this.$emit('errors', errors);
      }
      this.$emit('input', event.target.value);
      return event.target.value;
    },
    inputClass(validationFlags) {
      return [
        this.svg ? 'pl-10' : null,
        this.align,
        validationFlags?.invalid ? 'border-secondary-red' : null,
        this.validationState,
        this.disabled ? 'disabled:opacity-50 cursor-not-allowed bg-gray-200' : 'hover:border-primary-dark',
        this.variant === 'slim' ? 'py-2 px-3' : null,
        this.variant === 'regular' ? 'p-3 min-h-32' : null,
        this.bgColor ? [this.bgColor, 'border-gray-100'] : 'border-gray-300',
        'w-full',
        'font-body text-gray-800',
        'rounded-md border',
        'focus:outline-none focus:border-primary-dark',
        'transition duration-200 ease-in',
        'appearance-none',
      ];
    },
  },
};
</script>
