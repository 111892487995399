import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin`;

export default {
  async getEmissionsTable() {
    return ApiService.get(`${resourceAdmin}/emissionsTable`);
  },

  async getWalletsByPlayers(userIds) {
    return ApiService.securePost(`${resourceAdmin}/wallet/getByPlayers`, userIds);
  },

  async inviteTechnicalUser(payload) {
    return ApiService.securePost(`${resourceAdmin}/user/invite/technical`, payload);
  },

  async deleteThrottle(payload) {
    return ApiService.delete(`${resourceAdmin}/throttle/key`, payload);
  },

  async getAssuredLogs(env) {
    return ApiService.get(`${resourceAdmin}/assured/logs/${env === 'staging' ? 'stg' : 'prod'}`);
  },
  async getPointsAggregateByCompany(companyId) {
    return ApiService.get(`${resourceAdmin}/juoco/pointsAggregateByCompany/${companyId}`);
  },
};
