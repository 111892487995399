<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t('modals.userRoleManager.title')"
    @close="$emit('close')"
    >
    <template #body="">
      <div class="max-w-2xl">
        <cc-text
          color="text-gray-600"
          class="pb-4 max-w-full"
          >
          {{ $t('modals.userRoleManager.body') }}
        </cc-text>


        <!-- User -->
        <div class="bg-gray-100 rounded-lg p-4 mb-8">
          <div class="flex items-center">
            <div class="bg-white p-3 mr-4 rounded-lg">
              <div class="flex items-center justify-center w-8 h-8">
                <cc-svg-base
                  svg="icons/icon-user"
                  class="inline text-gray-300"
                  />
              </div>
            </div>
            <div>
              <span class="flex">
                <cc-text
                  variant="body-sm-bold"
                  color="text-gray-600"
                  >
                  {{ $t('commons.fieldNames.userId') }}:
                </cc-text>

                <cc-text
                  variant="body-sm"
                  color="text-gray-600"
                  class="pl-1"
                  >
                  {{ user.id }}
                </cc-text>
              </span>
              <span class="flex">
                <cc-text
                  variant="body-sm-bold"
                  color="text-gray-600"
                  >
                  {{ $t('commons.fieldNames.name') }}:
                </cc-text>
                <cc-text
                  variant="body-sm"
                  color="text-gray-600"
                  class="pl-1"
                  >
                  {{ user.name || '–' }}
                </cc-text>
              </span>
            </div>
          </div>
        </div>

        <!-- Assigned Roles -->
        <div class="pb-8">
          <cc-heading
            tag="h2"
            weight="semibold"
            color="text-gray-600"
            class="pb-4"
            >
            {{ $t('modals.userRoleManager.assignedRolesTitle') }} {{ `(${currentRoles.length})` }}
          </cc-heading>


          <div
            v-if="currentRoles.length"
            class="flex flex-wrap"
            >
            <cc-chip
              v-for="(role, i) in currentRoles"
              :key="i"
              variant="secondary"
              :label="role"
              class="mr-2 mb-2 select-none"
              icon-class="order-last ml-2 cursor-pointer w-2.5 h-2.5"
              icon-path="icons/icon-close"
              @icon-click="updateUserRoles('removeRolesFromUser', user.id, [role])"
              />
          </div>

          <cc-text
            v-else
            color="text-gray-600"
            >
            {{ $t('modals.userRoleManager.noRolesAssigned') }}
          </cc-text>
        </div>

        <!-- Available Roles -->
        <cc-section-table
          :title="`${$t('modals.userRoleManager.availableRolesTitle')}  (${dataItems && dataItems.length})`"
          :actions="sectionActions"
          :has-search="true"
          variant="slim"
          @search-query="($event) => (query = $event.toLowerCase())"
          >
          <cc-data-table
            :class="dataItems.length ? 'h-56' : 'h-16'"
            :headers="headers"
            :items="dataItems"
            :placeholder="$t('modals.userRoleManager.rolesPlaceholder')"
            :is-loading="isLoadingDataTable"
            @row-click="updateUserRoles('addRolesToUser', user.id, [$event.roleName])"
            />
        </cc-section-table>

        <cc-error-message
          v-if="errors.length"
          :message="errors[0]"
          class="mt-4"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <div class="grid grid-cols-1">
        <cc-button
          variant="secondary"
          :text="$t('commons.close')"
          :disabled="isLoading"
          :is-loading="isLoading"
          @click.native="close()"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: null,
    },
    rolesAndActions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      query: '',
      isLoading: false,
      isLoadingDataTable: false,
      sectionActions: [],
      availableRoles: [],
      currentRoles: [],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.name'),
          value: 'roleName',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.description'),
          value: 'roleDescription',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
          class: 'w-24',
        },
      ],
      errors: [],
    };
  },
  computed: {
    dataItems() {
      return this.availableRoles
        ?.filter((role) => {
          if (this.query) {
            return role.name?.toLowerCase().includes(this.query);
          }
          return role;
        })
        .sort((a, b) => a?.name.localeCompare(b?.name))
        .map((role, index) => ({
          index: index + 1,
          roleName: role.name || '–',
          roleDescription: role.roleDescription || '–',
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-add',
              text: this.$t('commons.add'),
              event: 'row-click',
            },
          ],
          isRowClickable: true,
        }));
    },
  },
  created() {
    this.$log.info('Created User Role Manager Modal');
    this.init();
  },
  methods: {
    init() {
      this.availableRoles = this.rolesAndActions.filter(
        role => !this.user?.roles?.includes(role.name),
      );

      this.currentRoles = this.user.roles || [];
    },
    async updateUserRoles(actionHandle, userId, roleNames) {
      try {
        this.isLoading = true;
        const userRoles = await this.$store.dispatch(`role/${actionHandle}`, {
          userId,
          payload: { roles: roleNames },
        });

        this.currentRoles = userRoles.map(role => role.name);

        this.availableRoles = this.rolesAndActions.filter(
          role => !userRoles?.find(userRole => role.name === userRole.name),
        );

        this.$emit('success');
      } catch (error) {
        this.$log.info('Error: addRolesToUser', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
