export function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  let str = '';

  for (let i = 0; i < array.length; i += 1) {
    let line = '';

    Object.keys(array[i]).forEach((key) => {
      if (line !== '') line += ',';

      line += array[i][key];
    });

    str += `${line}\r\n`;
  }

  return str;
}

export function generateTimeStampedFilename(filename) {
  if (!filename) {
    throw Error('Missing filename');
  }

  return `${filename}_${new Date()
    .toISOString()
    .split('.')[0]
    .replaceAll(':', '_')
    .replaceAll('-', '_')}`;
}

export function formatHeaders(headers) {
  if (!headers) {
    throw Error('Missing headers');
  }

  const formattedHeaders = {};

  headers
    .filter(header => header.isCSVField)
    .forEach((item) => {
      formattedHeaders[item.value] = item.text;
  });
  return formattedHeaders;
}


export function exportCSVFile(headers, items, filename) {
  if (!headers || !items) {
    throw Error('Missing payload');
  }

  if (headers) {
    items.unshift(headers);
  }

  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const filenameWithExtension = `${filename}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filenameWithExtension);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filenameWithExtension);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
