var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(true)?_c('cc-section-table',{attrs:{"title":_vm.getTitle,"actions":_vm.sectionActions,"has-search":true},on:{"search-query":function ($event) { return (_vm.query = $event.toLowerCase()); },"export":_vm.exportHandler}},[(false)?_c('cc-code',[_vm._v(" "+_vm._s(_vm.tagSelectionsObj)+" "+_vm._s(_vm.userSelection)+" ")]):_vm._e(),_c('div',{staticClass:"grid gap-4 items-end pb-6",class:_vm.hasUsersSelected
        ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
        : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'},[(false)?_c('cc-multi-select',{staticClass:"mr-4",attrs:{"list":_vm.headers}}):_vm._e(),(_vm.rolesView)?_c('cc-select',{staticClass:"flex-grow",attrs:{"id":"tag-selection","options":_vm.generateOptionsForSegments(_vm.company),"label":_vm.$t('commons.fieldNames.companyTag'),"deselect-text":_vm.$t('commons.select'),"has-de-select":true,"value":_vm.getValue(_vm.tagSelections, 'company')},on:{"input":function($event){(_vm.tagSelectionsObj = {}),
          (_vm.userSelection = {}),
          _vm.setValue(_vm.tagSelections, 'company', $event),
          _vm.getUsersByTag(_vm.tagSelections)}}}):_vm._e(),(_vm.rolesView)?_c('cc-select',{staticClass:"flex-grow",attrs:{"id":"tag-selection__mail-domain","options":_vm.generateOptionsForSegments(_vm.mailDomain),"label":_vm.$t('commons.fieldNames.mailDomainTag'),"deselect-text":_vm.$t('commons.select'),"has-de-select":true,"value":_vm.getValue(_vm.tagSelections, 'mail_domain')},on:{"input":function($event){(_vm.tagSelectionsObj = {}),
          (_vm.userSelection = {}),
          _vm.setValue(_vm.tagSelections, 'mail_domain', $event),
          _vm.getUsersByTag(_vm.tagSelections)}}}):_vm._e(),(_vm.rolesView)?_c('cc-select',{staticClass:"flex-grow",attrs:{"id":"tag-selection__system-feature","options":_vm.generateOptionsForSegments(_vm.systemFeature),"label":_vm.$t('commons.fieldNames.systemFeatureTag'),"deselect-text":_vm.$t('commons.select'),"has-de-select":true,"value":_vm.getValue(_vm.tagSelections, 'system_feature')},on:{"input":function($event){(_vm.tagSelectionsObj = {}),
          (_vm.userSelection = {}),
          _vm.setValue(_vm.tagSelections, 'system_feature', $event),
          _vm.getUsersByTag(_vm.tagSelections)}}}):_vm._e(),(_vm.hasUsersSelected)?_c('cc-button',{attrs:{"text":_vm.$t('pages.users.manageFeatureTags'),"variant":"primary","block":false},nativeOn:{"click":function($event){_vm.isFeatureTagsManagerModalOpen = true}}}):_vm._e()],1),_c('cc-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataItems,"is-loading":_vm.isLoadingDataTable,"selected-items":_vm.userSelection,"select-key":"userId"},on:{"row-click":function ($event) { return ((_vm.userToManage = $event.originalPayload), (_vm.isUserRoleManagerOpen = true)); },"assign-role":function ($event) { return ((_vm.userToManage = $event.originalPayload), (_vm.isUserRoleManagerOpen = true)); },"select":function($event){return _vm.handleUserSelection($event)}},scopedSlots:_vm._u([{key:"roles",fn:function(ref){
          var item = ref.item;
return [_c('cc-text',{staticClass:"inline capitalize",attrs:{"variant":"body-sm","color":"text-gray-700","data-ruid":item.roles}},[_vm._v(" "+_vm._s(item.roles)+" ")])]}}],null,false,452988071)})],1):_vm._e(),(_vm.isFeatureTagsManagerModalOpen)?_c('cc-feature-tags-manager-modal',{attrs:{"is-open":_vm.isFeatureTagsManagerModalOpen,"user-ids":Object.keys(_vm.userSelection)},on:{"add-success":function($event){return _vm.getUsersByTag(_vm.tagSelections, false)},"delete-success":function($event){return _vm.getUsersByTag(_vm.tagSelections, false)},"close":function($event){_vm.isFeatureTagsManagerModalOpen = false}}}):_vm._e(),(_vm.isUserRoleManagerOpen)?_c('cc-user-role-manager-modal',{attrs:{"is-open":_vm.isUserRoleManagerOpen,"user":_vm.userToManage,"roles-and-actions":_vm.rolesAndActions},on:{"success":function($event){return _vm.getUsersByTag(_vm.tagSelections, false)},"close":function($event){(_vm.isUserRoleManagerOpen = false), (_vm.userToManage = null)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }