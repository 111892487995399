<template>
  <div>
    <cc-section-table
      v-if="true"
      :title="getTitle"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => (query = $event.toLowerCase())"
      @export="exportHandler"
      >
      <cc-code v-if="false">
        {{ tagSelectionsObj }}
        {{ userSelection }}
      </cc-code>

      <div
        class="grid gap-4 items-end pb-6"
        :class="hasUsersSelected
          ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
          : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3'"
        >
        <cc-multi-select
          v-if="false"
          :list="headers"
          class="mr-4"
          />

        <cc-select
          v-if="rolesView"
          id="tag-selection"
          :options="generateOptionsForSegments(company)"
          class="flex-grow"
          :label="$t('commons.fieldNames.companyTag')"
          :deselect-text="$t('commons.select')"
          :has-de-select="true"
          :value="getValue(tagSelections, 'company')"
          @input="
            (tagSelectionsObj = {}),
            (userSelection = {}),
            setValue(tagSelections, 'company', $event),
            getUsersByTag(tagSelections)
          "
          />

        <cc-select
          v-if="rolesView"
          id="tag-selection__mail-domain"
          :options="generateOptionsForSegments(mailDomain)"
          class="flex-grow"
          :label="$t('commons.fieldNames.mailDomainTag')"
          :deselect-text="$t('commons.select')"
          :has-de-select="true"
          :value="getValue(tagSelections, 'mail_domain')"
          @input="
            (tagSelectionsObj = {}),
            (userSelection = {}),
            setValue(tagSelections, 'mail_domain', $event),
            getUsersByTag(tagSelections)
          "
          />

        <cc-select
          v-if="rolesView"
          id="tag-selection__system-feature"
          :options="generateOptionsForSegments(systemFeature)"
          class="flex-grow"
          :label="$t('commons.fieldNames.systemFeatureTag')"
          :deselect-text="$t('commons.select')"
          :has-de-select="true"
          :value="getValue(tagSelections, 'system_feature')"
          @input="
            (tagSelectionsObj = {}),
            (userSelection = {}),
            setValue(tagSelections, 'system_feature', $event),
            getUsersByTag(tagSelections)
          "
          />

        <cc-button
          v-if="hasUsersSelected"
          :text="$t('pages.users.manageFeatureTags')"
          variant="primary"
          :block="false"
          @click.native="isFeatureTagsManagerModalOpen = true"
          />
      </div>

      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        :selected-items="userSelection"
        select-key="userId"
        @row-click="
          ($event) => ((userToManage = $event.originalPayload), (isUserRoleManagerOpen = true))
        "
        @assign-role="
          ($event) => ((userToManage = $event.originalPayload), (isUserRoleManagerOpen = true))
        "
        @select="handleUserSelection($event)"
        >
        <template #roles="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline capitalize"
            :data-ruid="item.roles"
            >
            {{ item.roles }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>

    <cc-feature-tags-manager-modal
      v-if="isFeatureTagsManagerModalOpen"
      :is-open="isFeatureTagsManagerModalOpen"
      :user-ids="Object.keys(userSelection)"
      @add-success="getUsersByTag(tagSelections, false)"
      @delete-success="getUsersByTag(tagSelections, false)"
      @close="isFeatureTagsManagerModalOpen = false"
      />

    <cc-user-role-manager-modal
      v-if="isUserRoleManagerOpen"
      :is-open="isUserRoleManagerOpen"
      :user="userToManage"
      :roles-and-actions="rolesAndActions"
      @success="getUsersByTag(tagSelections, false)"
      @close="(isUserRoleManagerOpen = false), (userToManage = null)"
      />
  </div>
</template>

<script>
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase, sentenceCase } from '@/mixins/utils';
import { mapGetters, mapState } from 'vuex';
import AdminService from '@/services/AdminService';
import TagService from '@/services/TagService';

export default {
  props: {
    purpose: {
      type: String,
      required: false,
      default: 'roles',
    },
  },
  data() {
    return {
      query: '',
      isLoadingDataTable: false,
      isFeatureTagsManagerModalOpen: false,
      isUserRoleManagerOpen: false,
      userToManage: null,
      tagSelectionsObj: {},
      greenPointsData: [],
      userSelection: {
        // '84214717-83d1-44da-9d15-ffe1bd5bdc74': {},
        // '02870e09-10ea-4c11-afee-96a209a90d16': {},
        // '7bd47179-e56a-49f8-98d8-eb6f0de65275': {},
        // '4df2ee88-ec6f-423c-bc0c-95295cd27cc0': {},
        // '5f738c5c-8fcb-4777-ba74-62ddd14642c5': {},
      },
      rolesAndActions: [],
      users: [],
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
      ],
      headers: [
        {
          text: null,
          value: 'select',
          isCSVField: false,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          isVisible: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.createdAt'),
          value: 'createdAt',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.userId'),
          value: 'userId',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.email'),
          value: 'email',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.verifiedEmail'),
          value: 'emailVerified',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.name'),
          value: 'name',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.phoneNumber'),
          value: 'phoneNumber',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.language'),
          value: 'language',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.greenPoints'),
          value: 'points',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.onboardingCode'),
          value: 'onboardingCode',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.roles'),
          value: 'roles',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.featureTags'),
          value: 'tags',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.walletBalance'),
          value: 'walletBalance',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
          isVisible: true,
          class: 'w-24',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      tags: st => st.message.tags,
    }),
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    getTitle() {
      let amount;
      if (this.userSelectionAmount === 0) {
        amount = this.$tc('pages.users.amountOfUsers', this.dataItems?.length, {
          amount: this.dataItems?.length,
        });
      } else {
        amount = this.$tc('pages.users.selectedUsers', this.userSelectionAmount, {
          amount: this.userSelectionAmount,
        });
      }
      return `${this.$t('pages.users.title')} ${amount}`;
    },
    userSelectionAmount() {
      return Object.keys(this.userSelection)?.length;
    },
    hasUsersSelected() {
      return Object.keys(this.userSelection).length > 0;
    },
    company() {
      return this.tags?.classes?.company;
    },
    mailDomain() {
      return this.tags?.classes?.mail_domain;
    },
    systemFeature() {
      return this.tags?.classes?.system_feature;
    },
    rolesView() {
      return this.purpose === 'roles';
    },
    companyView() {
      return this.purpose === 'company';
    },
    tagSelections() {
      return this.tagSelectionsObj;
    },
    dataItems() {
      return this.users
        ?.filter((user) => {
          if (this.query) {
            return (
              user?.id?.toLowerCase().includes(this.query)
              || user?.email?.address?.toLowerCase().includes(this.query)
              || user?.name?.toLowerCase().includes(this.query)
              || user?.roles?.find(r => r.toLowerCase().includes(this.query))
              || user?.onboardCode?.value?.toString().includes(this.query)
              || user?.tags?.find(tag => tag.includes(this.query))
            );
          }
          return user;
        })
        .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        .map((user, index) => ({
          index: index + 1,
          createdAt: new Date(user?.createdAt).toLocaleString('en-US') || '–',
          userId: user.id || '–',
          email: user?.email?.address || '–',
          emailVerified: !!user?.email?.address,
          name: user?.name || '–',
          phoneNumber: !!user?.phone?.number || '–',
          language: user?.metaPublic?.language || '–',
          points: this.getPointsByUserId(user.id),
          onboardingCode: user?.onboardCode?.value || '–',
          roles: this.getShortRolesList(user.roles) || '–',
          tags: user?.tags?.join(' / ') || '–',
          originalPayload: user,
          walletBalance: user?.wallets?.[0]?.balance,
          // Assign Role should become conditional as Users is shared component
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-add',
              text: this.$t('pages.users.assignRole'),
              event: 'assign-role',
            },
          ],
          isRowClickable: true,
        }));
    },
  },
  async created() {
    // await this.findCompanyUsersByTag();
    try {
      this.rolesAndActions = await this.$store.dispatch('role/getRolesAndActions');
      await this.getUsersByTag();
      await this.getPointsAggregateByCompany();
      // if (this.$route.query.userIds) {
      //   const selectedUserIdsFromQueryParam = this.$route.query.userIds
      //     .slice()
      //     .split(',')
      //     .reduce((ac, item) => ({ ...ac, [item]: {} }), {});

      //     this.userSelection = selectedUserIdsFromQueryParam;
      // }
    } catch (error) {
      this.$log.error('Error: Users created', error);
    }
  },
  methods: {
    sentenceCase,
    async getUsersByTag(tagSelections = {}, withLoading = true) {
      this.$log.info('Will getUsersByTag', tagSelections);

      try {
        this.isLoadingDataTable = withLoading;

        let company;
        let response;

        // If company id is present, fetch the company and get users of that company by its alias/tag
        if (this.$route?.params?.companyId) {
          company = await this.$store.dispatch('company/getCompany', {
            companyId: this.$route.params.companyId,
          });

          if (company?.alias) {
            response = await this.$store.dispatch('user/getUsersByTag', [
              {
                class: 'company',
                ids: [company.alias],
              },
            ]);

            /**
             * Store as tag selectoin in case we route from Users to Access Management. Vue Router
             * will not update the component as it is the same in both routes.
             */
            this.tagSelectionsObj.company = company?.alias;
          }
        }

        const promises = [];

        Object.keys(tagSelections)?.forEach((tagSelection) => {
          promises.push(
            this.$store.dispatch('user/getUsersByTag', [
              {
                class: tagSelection,
                ids: [tagSelections[tagSelection]],
              },
            ]),
          );
        });

        if (promises?.length) {
          const result = await Promise.all(promises);
          response = result.flat();
        }

        this.users = response || [];

        const userIds = this.users.map(u => u.id);

        // this could be outsourced to an individual function
        if (userIds?.length) {
          // Enrich user with roles
          const userRoles = await this.$store.dispatch('role/fetchByUserIds', { userIds });
          const userWallets = await AdminService.getWalletsByPlayers(userIds);
          const userTags = await TagService.getFeatureTagsOfUsers({ ids: userIds });

          this.users = this.users.map(user => ({
            ...user,
            roles: userRoles[user.id],
            tags: userTags[user.id],
            wallets: userWallets[user.id],
          }));
        }
      } catch (error) {
        this.$log.error('Error: getUsersByTag', error);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    async getPointsAggregateByCompany() {
      if (!this.$route.params.companyId) return;
      try {
        const response = await AdminService.getPointsAggregateByCompany(this.$route.params.companyId);
        this.greenPointsData = response.data;
      } catch (error) {
        this.$log.info('getPointsAggregateByCompany', error);
      }
    },
    getPointsByUserId(userId) {
      if (!this.greenPointsData?.length) return 0;
      return this.greenPointsData?.find(g => g.playerId === userId)?.points || 0;
    },
    generateOptionsForSegments(source) {
      return (
        source
          ?.slice()
          ?.sort((a, b) => a?.id?.localeCompare(b?.id))
          ?.map(el => [
            { value: Object.values(el)[0], text: this.sentenceCase(Object.values(el)[1]) },
          ])
          ?.flat() || []
      );
    },
    getShortRolesList(roles) {
      if (roles) {
        const trimmedRoles = roles?.slice(0, 3);
        return `${trimmedRoles?.join(' / ')}${
          roles?.length > 3 ? `... +${roles?.length - 3} more` : ''
        }`;
      }
      return false;
    },
    handleUserSelection(event) {
      this.userSelection = event;
    },
    getValue(target, key) {
      return target?.[key];
    },
    setValue(target, key, value) {
      if (!value) {
        this.$log.info('Will delete', key, value);
        this.$delete(target, key);
      } else {
        this.$set(target, key, value);
      }
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyNameOrTag = toKebapCase(
          this.getCompanyName(this.$route.params.companyId) || Object.keys(this.tagSelections)?.[0],
        );
        const filename = generateTimeStampedFilename(`scc_users_of_${companyNameOrTag}`);

        this.dataItems.forEach((item) => {
          // Remove some properties that should not be in the CSV
          // eslint-disable-next-line no-param-reassign
          ['originalPayload', 'isRowClickable', 'actions'].forEach(e => delete item[e]);

          itemsFormatted.push({
            ...item,
            createdAt: item.createdAt.replace(/,/g, ''),
          });
        });
        this.$log.info('headers', headersFormatted);
        this.$log.info('items', itemsFormatted);
        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>

<style></style>
