const {
 colors, //
 spacing,
 screens,
 fontSize,
//  borderRadius,
} = require('tailwindcss/defaultTheme');

module.exports = {
  purge: {
    content: [
      './src/**/*.html',
      './src/**/*.js',
      './src/**/*.vue',
    ],
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      width: {
        ...spacing,
        xxxs: '12rem',
        xxs: '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
      },
      minWidth: {
        ...spacing,
        xxxs: '12rem',
        xxs: '16rem',
        xs: '20rem',
        sm: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
      },
      maxWidth: {
        ...spacing,
      },
      minHeight: {
        ...spacing,
      },
      fontFamily: {
        body: 'TheSans, sans-serif',
        sans: 'TheSans, sans-serif',
        sf: 'SFProText, sans-serif',
        'sf-semi-bold': 'SFProTextSemibold, sans-serif',
        roboto: 'Roboto, sans-serif',
      },
      fontSize: {
        ...fontSize,
        15: '0.9375rem',
        13: '0.8125rem',
      },
      colors: {
        ...colors,
        primary: {
          darkest: '#003FC4',
          darker: '#144EC8',
          dark: '#0057DF',
          light: '#0A6EFA',
          lighter: '#4B97FF',
          lightest: '#B8D4FF',
        },
        secondary: {
          red: '#FF7777',
          green: {
            dark: '#007F13',
            light: '#63D374',
            lighter: '#85ff97',
          },
          yellow: '#FFC800',
        },
        gray: {
          100: '#F7F7F7',
        },
      },
      transitionDuration: {
        10000: '10000ms',
      },
      screens: {
        xs: '375px',
        ...screens,
      },
    },
  },
  variants: {
    extend: {},
    scale: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    opacity: ['disabled'],
  },
  plugins: [],
};
