<template>
  <cc-section-table
    :title="$t('pages.dashboard.assuredLogs',{env: env.toUpperCase()})"
    class="mb-4"
    >
    <cc-data-table
      :headers="assuredLogsHeader"
      :items="assuredLogs"
      :is-loading="isLoadingAssuredLogs"
      class="max-h-96"
      >
      <template #link="{ item }">
        <cc-anchor-base :href="item.link">
          {{ item.link }}
        </cc-anchor-base>
      </template>
      <template #createdAt="{ item }">
        <cc-text
          variant="body-sm"
          color="text-gray-700"
          class="inline"
          :data-ruid="item.id"
          >
          {{ new Date(item.createdAt).toLocaleDateString('en-US') }}
          {{ new Date(item.createdAt).toLocaleTimeString('en-US') }}
        </cc-text>
      </template>
    </cc-data-table>
  </cc-section-table>
</template>

<script>
import { sortArrayByCreatedAt } from '@/mixins/utils';
import AdminService from '@/services/AdminService';

export default {
  name: 'AssuredLogs',
  props: {
    env: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingAssuredLogs: false,
      assuredLogs: [],
      assuredLogsHeader: [
        {
          text: this.$t('commons.fieldNames.link'),
          value: 'link',
        },
        {
          text: this.$t('commons.fieldNames.createdAt'),
          value: 'createdAt',
        },
      ],
    };
  },
  created() {
    this.getAssuredLogs();
  },
  methods: {
    async getAssuredLogs() {
      try {
        const assuredLogsData = await AdminService.getAssuredLogs(this.env);
        const logs = assuredLogsData?.logs || [];
        this.assuredLogs = sortArrayByCreatedAt(logs);
      } catch (error) {
        this.$log.info('getEmissionsTable', error);
      } finally {
        this.isLoadingEmissionsTable = false;
      }
    },
  },
};
</script>
