<template>
  <div
    class="flex items-center rounded-full"
    :class="getChipClass"
    >
    <cc-svg-base
      v-if="iconPath"
      :svg="iconPath"
      :class="iconClass"
      @click.native="$emit('icon-click')"
      />
    <cc-text
      variant="body-sm"
      color=""
      >
      {{ label }}
    </cc-text>
  </div>
</template>

<script>
import commonProps from '@/mixins/commonProps';

// Valid variants
const variantProps = [
  'primary-muted', //
  'secondary',
  'transparent',
  'food',
  'mobility',
  'lifestyle',
  'engagement',
];

export default {
  mixins: [commonProps],
  props: {
    variant: {
      type: String,
      required: false,
      default: 'primary-muted',
      validator: value => variantProps.includes(value),
    },
    iconPath: {
      type: String,
      required: false,
      default: null,
    },
    iconClass: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    getChipClass() {
      return [
        'chip',
        'px-3',
        'chip-transition',
        this.variant === 'primary-muted' ? 'chip-primary-muted' : null,
        this.variant === 'secondary' ? 'chip-secondary' : null,
        this.variant === 'transparent' ? 'chip-transparent' : null,
        this.variant === 'food' ? 'chip-food' : null,
        this.variant === 'mobility' ? 'chip-mobility' : null,
        this.variant === 'lifestyle' ? 'chip-lifestyle' : null,
        this.variant === 'engagement' ? 'chip-engagement' : null,
      ];
    },
  },
};
</script>

<style lang="postcss" scoped>
.chip-transition {
  @apply transition-all ease-in-out duration-300;
}

.chip-primary-muted {
  @apply bg-primary-dark text-primary-dark bg-opacity-30;
}

.chip-secondary {
  @apply bg-gray-200 text-gray-500;
}

.chip-secondary:hover {
  @apply hover:bg-gray-300 text-gray-600;
}

.chip-transparent {
  @apply bg-transparent text-gray-700;
}

.chip-food {
  @apply bg-secondary-red text-secondary-red bg-opacity-30;
}

.chip-mobility {
  @apply bg-primary-dark text-primary-dark bg-opacity-30;
}

.chip-engagement {
  @apply bg-secondary-green-light text-secondary-green-light bg-opacity-30;
}

.chip-lifestyle {
  @apply bg-secondary-yellow text-secondary-yellow bg-opacity-30;
}
</style>
