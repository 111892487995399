<template>
  <div>
    <cc-hint
      v-if="hasVouchers"
      :hint="$t('pages.product.overrideCsvBody')"
      class="mb-6 max-w-4xl"
      text-variant="body"
      />
    <div
      v-if="canUpload"
      class="flex flex-1 flex-wrap min-w-40 items-start"
      >
      <div>
        <form
          class="h-12 min-w-72"
          enctype="multipart/form-data"
          novalidate
          >
          <label class="dropbox">
            <input
              type="file"
              :name="uploadFieldName"
              :disabled="isSaving || disabled"
              accept="text/csv"
              class="hidden w-full opacity-0 cursor-pointer h-24"
              @change="fileHandler($event)"
              >
            <div
              v-if="hasIcon"
              class="w-5 h-5 mb-2"
              >
              <cc-svg-base
                svg="icons/icon-plus"
                class="text-primary-light"
                />
            </div>

            <cc-text
              v-if="isInitial"
              variant="body"
              align="center"
              color="text-primary-light"
              >
              {{ placeholder }}
            </cc-text>

            <cc-text
              v-if="isSuccess || isFailed"
              variant="body"
              align="center"
              color="text-primary-light"
              >
              {{ $t('configs.uploadAgain') }}
            </cc-text>

            <cc-text v-if="isSaving">
              {{
                $t('configs.uploadSaving', {
                  uploadFileCount: $tc('configs.uploadFileCount', fileCount, { fileCount }),
                })
              }}
            </cc-text>
          </label>
        </form>

        <!-- PREVENT VOUCHER MODAL WIDTH FROM JUMPING AROUND DUE TO CONTENT WIDTH  -->

        <!--SUCCESS-->
        <div v-if="isSuccess && hasVouchers">
          <cc-text
            class="pt-4 flex items-center"
            color="text-secondary-green-dark"
            >
            {{ $t('configs.uploadSuccess', { amount: uploadedFiles }) }}
          </cc-text>

          <cc-button
            v-if="canDelete"
            variant="secondary"
            :text="$t('commons.delete')"
            @click.native="reset(), $emit('reset')"
            />
        </div>

        <!--FAILURE-->
        <div v-if="isFailed">
          <cc-text class="pt-4 text-secondary-red mb-4">
            {{ $t('configs.uploadFail') }}
          </cc-text>

          <cc-button
            variant="secondary"
            :text="$t('commons.tryAgain')"
            @click.native="reset"
            />
        </div>
      </div>

      <div>
        <cc-check-box
          v-if="hasVouchers"
          v-model="overrideCsv"
          :disabled="isDeleting || isSaving"
          :text="$t('configs.overrideCsv')"
          class="flex-shrink-0 pl-4"
          />
      </div>
    </div>


    <div
      v-if="hasVouchers"
      class="flex flex-col flex-wrap items-start pt-8"
      >
      <cc-heading
        tag="h3"
        weight="bold"
        class="flex-grow w-full capitalize"
        color="text-gray-600"
        >
        {{ $t('commons.fieldNames.voucherInventory') }}
      </cc-heading>

      <cc-text
        class="pt-2 pb-4 flex-grow max-w-4xl"
        color="text-gray-600"
        >
        <!-- TODO: Highlight value -->
        {{ $t('pages.product.voucherInventoryBody', { amount: voucherInventory.length }) }}
      </cc-text>

      <div class="flex">
        <cc-button
          variant="secondary"
          class="mr-4"
          :block="false"
          :disabled="isDeleting || isSaving"
          :text="$t('configs.displayVouchers')"
          @click.native="isDisplayVouchersOpen = true"
          />

        <cc-button
          v-if="hasVouchers && canDeleteInventory"
          variant="secondary"
          :block="false"
          :disabled="isDeleting || isSaving"
          :is-loading="isDeleting"
          :text="$t('commons.delete')"
          @click.native="isDeleteConfirmationOpen = true"
          />
      </div>
    </div>

    <cc-delete-confirmation-modal
      :is-open="isDeleteConfirmationOpen"
      :heading="$t('modals.deleteVoucherInventoryConfirmation.title')"
      :body="$t('modals.deleteVoucherInventoryConfirmation.body')"
      @close="isDeleteConfirmationOpen = false"
      @granted-delete-confirmation="(close) => (deleteVouchers(virtualGoodId), close())"
      />


    <cc-voucher-inventory-modal
      v-if="isDisplayVouchersOpen"
      :is-open="isDisplayVouchersOpen"
      :voucher-inventory="voucherInventory"
      @close="isDisplayVouchersOpen = false"
      />
  </div>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import ccVoucherInventoryModal from '@/views/Companies/VoucherInventoryModal.vue';

import commonProps from '@/mixins/commonProps';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

export default {
  components: {
    ccVoucherInventoryModal,
  },
  mixins: [commonProps],
  props: {
    hasIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    canUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    canDeleteInventory: {
      type: Boolean,
      required: false,
      default: true,
    },
    virtualGoodId: {
      type: String,
      required: false,
      default: null,
    },
    voucherInventory: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      overrideCsv: true,
      isDeleteConfirmationOpen: false,
      isDisplayVouchersOpen: false,
      isDeleting: false,
      uploadedFiles: null,
      uploadError: null,
      currentStatus: null,
      fileCount: 0,
      uploadFieldName: 'csv',
      maxFileSize: 5242880,
    };
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    hasVouchers() {
      return this.voucherInventory?.length;
    },
  },
  mounted() {
    this.reset();
  },
  created() {
    this.$emit('created');
  },
  methods: {
    async deleteVouchers(virtualGoodId) {
      try {
        this.isDeleting = true;
        await this.$store.dispatch('company/deleteVouchers', { virtualGoodId });
        this.$emit('deleted');
      } catch (error) {
        this.$log.error('Error: deleteVouchers', error);
      } finally {
        this.isDeleting = false;
      }
    },
    async fileHandler(event) {
      const fileList = event.target.files;

      if (!fileList.length) return;

      this.currentStatus = STATUS_SAVING;

      this.$log.info('fileList', fileList);

      // `/file` endpoint does not accept file names that includes `.`
      // const fileName = fileList[0].name.slice(0, fileList[0].name.lastIndexOf('.'));
      // const fileExtension = fileList[0].name.slice(fileList[0].name.lastIndexOf('.'));
      // const newFileName = `${fileName.replaceAll('.', '-')}${fileExtension}`;

      try {
        if (fileList[0].size >= this.maxFileSize) {
          throw Error('File is too big.');
        }

        this.fileCount = fileList.length;

        const response = await CompanyService.addVouchers(
          this.virtualGoodId,
          fileList[0],
          null,
          this.overrideCsv,
        );

        this.$log.info('after upload', response, this.fileCount);

        this.$emit('successful-upload');
        // this.voucherInventory = response;

        this.uploadedFiles = this.fileCount;

        // this.$emit('upload-complete', this.uploadedFiles);

        this.currentStatus = STATUS_SUCCESS;
      } catch (error) {
        this.$log.info('Upload error', error);
        // this.$log.info('Upload error', error?.name, error?.message);
        // this.uploadError = error?.message;
        this.currentStatus = STATUS_FAILED;
      } finally {
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      // this.$log.info('Reset currentStatus', this.currentStatus);
      this.uploadedFiles = null;
      this.uploadError = null;
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropbox {
  @apply relative bg-primary-light cursor-pointer bg-opacity-10 flex flex-col
    items-center h-full transition-all duration-500 ease-out rounded-2xl
    justify-center hover:bg-primary-light hover:bg-opacity-20 border-dashed border-2
    border-primary-light;
}

.input-file {
  /* opacity: 0; */
  /* width: 100%; */
  /* height: 200px; */
  position: absolute;
  cursor: pointer;
}

/* .dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
} */
</style>
