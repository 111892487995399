<template>
  <div class="relative inline group">
    <cc-svg-base
      svg="icons/icon-info"
      class="inline text-gray-300"
      />
    <div
      class="max-w-sm origin-top-left transform group-hover:scale-100 scale-0 transition z-10
            duration-500 relative mt-1 ml-4"
      >
      <div class="absolute bg-white border border-gray-300 rounded-md p-3">
        <cc-text
          variant="body-sm"
          >
          {{ text }}
        </cc-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      requied: true,
      default: null,
    },
  },
};
</script>

<style>
</style>
