<template>
  <cc-modal
    :is-open="isOpen"
    transition-name="slide-up"
    :heading="heading"
    @close="$emit('close')"
    >
    <template #body>
      <div class="max-w-md whitespace-pre-line">
        {{ body }}
      </div>
    </template>

    <template #footer="{ close }">
      <cc-error-message
        v-if="errorMessage"
        :message="errorMessage"
        class="mb-8"
        padding="p-0"
        />

      <div class="grid grid-cols-2 gap-x-4">
        <cc-button
          variant="secondary"
          :text="$t('commons.cancel')"
          :disabled="isDeleting"
          @click.native="close"
          />
        <cc-button
          variant="red"
          :text="$t('modals.deleteProductConfirmation.primaryButton')"
          :is-loading="isDeleting"
          :disabled="isDeleting"
          @click.native="$emit('granted-delete-confirmation', close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import commonProps from '@/mixins/commonProps';

export default {
  mixins: [commonProps],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isDeleting: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
      required: false,
    },
  },
};
</script>
