<template>
  <cc-text
    v-if="message"
    variant="body-sm"
    class="flex flex-row items-center"
    :class="[padding]"
    color="text-secondary-red"
    >
    <div class="flex">
      <cc-svg-base
        class="inline mr-2"
        svg="icons/icon-exclamation"
        />
    </div>

    {{ message }}
  </cc-text>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: null,
    },
    padding: {
      type: String,
      required: false,
      default: 'pt-2',
    },
  },
};
</script>
