<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col justify-center items-center p-4">
    <div class="card-blue max-w-sm w-full">
      <div class="pt-4 pb-8 flex justify-center">
        <cc-svg-base
          svg="illustration/email-success"
          />
      </div>
      <cc-heading
        tag="h1"
        variant="h2"
        class="font-semibold"
        color="text-white"
        align="center"
        >
        {{ $t('pages.email.success.title') }}
      </cc-heading>
      <!-- <cc-text align="center">
        {{ $t('pages.email.error.body') }}
      </cc-text> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 'email-success',
      type: null,
    };
  },
  created() {
    // this.type = this.$route?.query?.type || null;
  },
};
</script>

<style>

</style>
