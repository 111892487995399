<template>
  <div>
    <cc-section-table
      :title="$t('pages.createChallenge.title')"
      :is-loading="isLoadingSection"
      >
      <div class="mb-8 border-b border-gray-300" />

      <validation-observer ref="form">
        <!-- Achievement internal name -->
        <div
          v-if="true"
          class="grid gap-6 grid-cols-1 mb-8 max-w-sm"
          >
          <cc-input
            :label="$t('pages.createChallenge.internalName')"
            ruid="create-challenge__internal-name"
            :placeholder="$t('pages.createChallenge.internalNamePlaceholder')"
            validation-rules="required"
            :is-required="true"
            :value="achievement.name"
            @created="achievement.name ? undefined : setValue(achievement, 'name', '')"
            @input="setValue(achievement, 'name', $event), insertBroadcastName($event)"
            />
        </div>

        <div
          v-if="true"
          class="pb-8"
          >
          <cc-heading
            tag="h3"
            weight="bold"
            class="flex-grow w-full capitalize"
            color="text-gray-600"
            >
            {{ $t('pages.createChallenge.publish') }}
          </cc-heading>

          <cc-text
            class="py-3 flex-grow max-w-2xl"
            color="text-gray-600"
            >
            {{ $t('pages.createChallenge.publishBody') }}
          </cc-text>

          <cc-check-box
            v-model="challenge.active"
            ruid="create-challenge__active"
            :text="$t('configs.publish')"
            class="items-start"
            />
        </div>

        <!-- Achievement resources -->
        <div
          v-if="true"
          class="flex overflow-auto pb-8 mb-12"
          >
          <div
            v-for="lang in supportedLangs"
            :key="lang"
            class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
            >
            <cc-heading
              tag="h5"
              class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
              >
              {{ $t(`commons.langs.${lang}`) }}
            </cc-heading>

            <div class="grid gap-6 grid-cols-1">
              <div class="flex justify-center">
                <cc-image
                  :has-placeholder="true"
                  :data-ruid="`create-challenge__image-${lang}`"
                  class="h-24 w-24 rounded-full"
                  :src="getResourceValue(achievementResources, 'imageUrl', lang)"
                  />
              </div>

              <cc-select
                id="achievement-image"
                :label="$t('pages.createChallenge.imageSelection')"
                :ruid="`create-challenge__image-url-${lang}`"
                :validation-id="`${lang}${$t('pages.createChallenge.imageSelection')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :options="defaultImages"
                :is-required="lang === 'de'"
                :value="getResourceValue(achievementResources, 'imageUrl', lang)"
                @input="setResourceValue(achievementResources, 'imageUrl', $event, lang)"
                @change="setResourceValue(achievementResources, 'imageUrl', $event, lang)"
                />
              <cc-input
                :label="$t('pages.createChallenge.challengeTitle')"
                :placeholder="$t('pages.createChallenge.challengeTitlePlaceholder')"
                :ruid="`create-challenge__title-${lang}`"
                :validation-id="`${lang}${$t('pages.createChallenge.challengeTitle')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :is-required="lang === 'de'"
                :value="getResourceValue(achievementResources, 'title', lang)"
                @input="setResourceValue(achievementResources, 'title', $event, lang)"
                />
              <cc-input
                :label="$t('pages.createChallenge.description')"
                :placeholder="$t('pages.createChallenge.descriptionPlaceholder')"
                :ruid="`create-challenge__description-${lang}`"
                :validation-id="`${lang}${$t('pages.createChallenge.description')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :is-required="lang === 'de'"
                :value="getResourceValue(achievementResources, 'description', lang)"
                @input="setResourceValue(achievementResources, 'description', $event, lang)"
                />

              <cc-text-area
                :label="$t('pages.createChallenge.completionMessage')"
                :placeholder="$t('pages.createChallenge.completionMessagePlaceholder')"
                :ruid="`create-challenge__completion-message-${lang}`"
                :validation-id="`${lang}${$t('pages.createChallenge.completionMessage')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                :is-required="lang === 'de'"
                :value="getResourceValue(achievementResources, 'completionMessage', lang)"
                @input="setResourceValue(achievementResources, 'completionMessage', $event, lang)"
                />

              <cc-text-area
                :label="$t('pages.createChallenge.progressMessage')"
                :ruid="`create-challenge__progress-message-${lang}`"
                :validation-id="`${lang}${$t('pages.createChallenge.progressMessage')}`"
                :validation-rules="null"
                :value="getResourceValue(achievementResources, 'progressMessage', lang)"
                @input="setResourceValue(achievementResources, 'progressMessage', $event, lang)"
                />
            </div>
          </div>
        </div>

        <!-- Reward -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.rewards') }}
          </cc-heading>

          <cc-hint
            v-if="reward.credits && sponsoringCompanies.length === 0"
            class="mb-8"
            :hint="$t('pages.createChallenge.sponsorSelectionHint')"
            />

          <!-- Points, Coins, XP, Sponsor selectiong -->
          <div class="flex flex-wrap max-w-lg">
            <cc-input
              :label="$t('configs.points')"
              class="w-24 mr-2 pb-4"
              ruid="create-challenge__points"
              type="number"
              align="text-center"
              min="0"
              step="1"
              :value="reward.points"
              @created="reward.points ? undefined : setValue(reward, 'points', 0)"
              @input="($event) => (reward.points = parseInt($event, 10))"
              />

            <cc-input
              :label="$t('configs.credits')"
              class="w-24 mr-2"
              ruid="create-challenge__credits"
              type="number"
              align="text-center"
              min="0"
              step="1"
              :value="reward.credits"
              @created="reward.credits ? undefined : setValue(reward, 'credits', 0)"
              @input="($event) => (reward.credits = parseInt($event, 10))"
              />

            <cc-input
              v-if="false"
              :label="$t('configs.xp')"
              class="w-24 mr-2"
              ruid="create-challenge__xp"
              type="number"
              align="text-center"
              min="0"
              step="1"
              :value="reward.xp"
              @created="reward.xp ? undefined : setValue(reward, 'xp', 0)"
              @input="($event) => (reward.xp = parseInt($event, 10))"
              />

            <cc-select
              id="sponsor-selection"
              v-model="getSponsor"
              :validation-rules="reward.credits ? 'required' : null"
              ruid="create-challenge__sponsor-selection"
              :label="$t('configs.sponsor')"
              class="pb-4"
              :options="sponsoringCompanies"
              />
          </div>

          <!-- Inventory Voucher selection  -->
          <div
            v-if="appState.isDevelopment || appState.isDev"
            class="flex max-w-md items-end"
            >
            <cc-select
              id="voucher-inventory-selection"
              v-model="selectedOfferWithInventoryVoucher"
              ruid="create-challenge__voucher-inventory-selection"
              :label="$t('configs.addVoucherInventory')"
              class="w-full pr-2"
              :has-de-select="true"
              :options="optionsForOffersWithInventoryVoucherVirtualGoods"
              />

            <cc-button
              :text="$t('commons.add')"
              :block="false"
              class="ml-3"
              variant="secondary"
              :has-min-width="false"
              @click.native="
                handleInventoryVoucherSelect(selectedOfferWithInventoryVoucher),
                (selectedOfferWithInventoryVoucher = null)
              "
              />
          </div>

          <!-- Inventory Voucher list -->
          <div
            v-if="hasInventoryVoucher"
            class="pt-12"
            >
            <cc-heading
              tag="h3"
              weight="semibold"
              class="flex-grow w-full flex flex-col"
              color="text-gray-600"
              :has-border="true"
              >
              {{ $t('configs.inventoryVouchers') }}
            </cc-heading>

            <div class="flex flex-wrap">
              <div
                v-for="(good, i) in getInventoryVoucherVirtualGoods"
                :key="`iv-virtual-goods-${i}`"
                :data-ruid="`create-challenge__iv-virtual-good-${i}`"
                class="mb-12 md:mb-8 mr-8"
                >
                <div class="">
                  <cc-heading
                    tag="h3"
                    weight="semibold"
                    class="flex-grow w-full flex flex-col"
                    color="text-gray-600"
                    :has-border="true"
                    >
                    {{ getCompanyName(good.storeId) }} –
                    {{ getInventoryVoucherOfferName(good.resources) }}
                  </cc-heading>
                </div>

                <div class="flex flex-col md:flex-row">
                  <div class="w-3/4 md:w-36 min-w-36">
                    <cc-image
                      :key="`iv-virtual-goods-${i}`"
                      :has-placeholder="true"
                      class="rounded-lg"
                      :src="getInventoryVoucherOfferImage(good.resources)"
                      />
                  </div>

                  <div class="mt-2 md:mt-0 md:ml-2 flex flex-col items-start justify-between">
                    <cc-button
                      v-if="voucherInventory[good.id] && voucherInventory[good.id].length"
                      variant="secondary"
                      :block="false"
                      class="mb-2"
                      :text="
                        $t('configs.displayVouchers', { amount: voucherInventory[good.id].length })
                      "
                      @click.native="
                        $set(voucherInventoryModal, good.id, { isDisplayVouchersOpen: true })
                      "
                      />

                    <cc-hint
                      v-else
                      text-variant="body"
                      class="mb-2"
                      :hint="$t('pages.createChallenge.noInventoryVoucher')"
                      />

                    <cc-button
                      :text="`${$t('commons.remove')} Virtual Good`"
                      variant="secondary"
                      :block="false"
                      :ruid="`create-challenge__iv-virtual-good-${i}__delete-button`"
                      @click.native="
                        $delete(
                          virtualGoods,
                          virtualGoods.findIndex((vg) => vg.id === good.id),
                        )
                      "
                      />
                  </div>
                </div>

                <cc-voucher-inventory-modal
                  v-if="
                    voucherInventoryModal[good.id] &&
                      voucherInventoryModal[good.id].isDisplayVouchersOpen
                  "
                  :is-open="
                    voucherInventoryModal[good.id] &&
                      voucherInventoryModal[good.id].isDisplayVouchersOpen
                  "
                  :voucher-inventory="voucherInventory[good.id]"
                  @close="$set(voucherInventoryModal, good.id, { isDisplayVouchersOpen: false })"
                  />
              </div>
            </div>
          </div>
        </div>

        <!-- Challenge Availability -->
        <div
          v-if="true"
          class="py-12"
          >
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="semibold"
              class="flex-grow w-full"
              color="text-gray-600"
              :has-border="true"
              >
              {{ $t('pages.createChallenge.challengeAvailability') }}
            </cc-heading>

            <cc-text
              class="pb-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.challengeAvailabilityBody') }}
            </cc-text>

            <cc-check-box
              ruid="create-challenge__active"
              :text="$t('pages.createChallenge.createTimeBasedChallenge')"
              class="items-start"
              :model-value="
                isTimeBasedChallengeChecked || !!challenge.availabilityDate || !!challenge.startDate
              "
              @change="setTimeBasedChallengeCheckbox($event)"
              @created="
                isTimeBasedChallengeChecked = !!challenge.availabilityDate || !!challenge.startDate
              "
              />
          </div>

          <!-- Availability Date and Time -->
          <div
            v-if="isTimeBasedChallengeChecked"
            class="pb-8"
            >
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              :is-required="true"
              >
              {{ $t('pages.createChallenge.availabilityDateAndTime') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.availabilityDateAndTimeBody') }}
            </cc-text>

            <cc-date-and-time-picker
              :has-validation="isTimeBasedChallengeChecked"
              :date="challenge.availabilityDate && challenge.availabilityDate.split('T')[0]"
              :date-label="$t('configs.availabilityDate')"
              :time="getTime(challenge.availabilityDate)"
              :time-label="$t('configs.availabilityTime')"
              @iso-format="($event) => (challenge.availabilityDate = $event)"
              />
          </div>

          <!-- Starting Date and Time -->
          <div
            v-if="isTimeBasedChallengeChecked"
            class="pb-8"
            >
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              :is-required="true"
              >
              {{ $t('pages.createChallenge.startingDateAndTime') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.startingDateAndTimeBody') }}
            </cc-text>

            <cc-date-and-time-picker
              :has-validation="isTimeBasedChallengeChecked"
              :date="challenge.startDate && challenge.startDate.split('T')[0]"
              :date-label="$t('configs.startDate')"
              :time="getTime(challenge.startDate)"
              :time-label="$t('configs.startTime')"
              @iso-format="($event) => (challenge.startDate = $event)"
              />
          </div>

          <!-- Expiry Date and Time -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              :is-required="true"
              >
              {{ $t('pages.createChallenge.expiryDateAndTime') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.expiryDateAndTimeBody') }}
            </cc-text>

            <cc-date-and-time-picker
              :date="challenge.expireDate && challenge.expireDate.split('T')[0]"
              :date-label="$t('configs.expireDate')"
              :time="getTime(challenge.expireDate)"
              :time-label="$t('configs.expireTime')"
              @iso-format="($event) => (challenge.expireDate = $event)"
              />
          </div>
        </div>

        <!-- Challenge Duration -->
        <div
          v-if="true"
          class="py-12"
          >
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="semibold"
              class="flex-grow w-full"
              color="text-gray-600"
              :has-border="true"
              >
              {{ $t('pages.createChallenge.challengeDuration') }}
            </cc-heading>

            <cc-text
              class="flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.challengeDurationBody') }}
            </cc-text>
          </div>

          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              :is-required="true"
              >
              {{ $t('pages.createChallenge.duration') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.durationBody') }}
            </cc-text>

            <cc-time-frame-selector
              :validation-name="$t('configs.duration')"
              :duration-in-millis="parseInt(challenge.durationInMillis, 10)"
              @milliseconds="($event) => (challenge.durationInMillis = $event)"
              />
          </div>

          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.evaluationDuration') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.evaluationDurationBody') }}
            </cc-text>

            <cc-time-frame-selector
              :has-validation="false"
              :duration-in-millis="parseInt(challenge.evaluationWindowInMillis, 10)"
              @milliseconds="($event) => (challenge.evaluationWindowInMillis = $event || null)"
              />
          </div>
        </div>

        <!-- Participation Options -->
        <div
          v-if="true"
          class="py-12"
          >
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="semibold"
              class="flex-grow w-full"
              color="text-gray-600"
              :has-border="true"
              >
              {{ $t('pages.createChallenge.participationOptions') }}
            </cc-heading>

            <cc-text
              class="flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.participationOptionsBody') }}
            </cc-text>
          </div>

          <!-- Auto Subscribe -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.autoSubscribe') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.autoSubscribeBody') }}
            </cc-text>

            <cc-check-box
              v-model="challenge.autoSubscribe"
              ruid="create-challenge__auto-subscribe"
              :text="$t('configs.autoSubscribe')"
              class="items-start"
              />
          </div>

          <!-- Recurring -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.recurring') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.recurringBody') }}
            </cc-text>

            <cc-check-box
              v-model="challenge.recurrent"
              ruid="create-challenge__recurrent"
              :text="$t('configs.recurrent')"
              class="items-start"
              />
          </div>

          <!-- Users can join after cycle starts -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.canJoinAfterCycleStarts') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.canJoinAfterCycleStartsBody') }}
            </cc-text>

            <cc-check-box
              v-model="challenge.canJoinAfterCycleStarts"
              ruid="create-challenge__canJoinAfterCycleStarts"
              :text="$t('configs.canJoinAfterCycleStarts')"
              class="items-start"
              />
          </div>

          <!-- Hidden -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.hidden') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.hiddenBody') }}
            </cc-text>

            <cc-check-box
              v-model="challenge.hidden"
              ruid="create-challenge__hidden"
              :text="$t('configs.hidden')"
              class="items-start"
              />
          </div>

          <!-- Accumulate User Efforts -->
          <div class="pb-8">
            <cc-heading
              tag="h3"
              weight="bold"
              class="flex-grow w-full capitalize"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.accumulate') }}
            </cc-heading>

            <cc-text
              class="py-3 flex-grow max-w-2xl"
              color="text-gray-600"
              >
              {{ $t('pages.createChallenge.accumulateBody') }}
            </cc-text>

            <cc-check-box
              v-model="challenge.accumulateGlobal"
              ruid="create-challenge__accumulate"
              :text="$t('pages.createChallenge.accumulate')"
              class="items-start"
              />
          </div>
        </div>

        <!-- Tags -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.challengeTags') }}
          </cc-heading>

          <div class="flex flex-col max-w-lg">
            <div class="flex items-end">
              <cc-select
                id="challenge-tag-class"
                v-model="challengeTagClass"
                ruid="create-challenge__tag-class__select"
                :label="$t('commons.fieldNames.tagClass')"
                :options="generateOptionsFromObject('challengeTagClasses')"
                class="w-full pr-2"
                :has-de-select="false"
                />

              <cc-input
                ref="challengeTagId"
                v-model.trim="challengeTagId"
                :label="$t('commons.fieldNames.tagId')"
                class="w-full pl-2"
                name="challenge-tag-id"
                validation-ref="challengeTag"
                :validation-rules="`isUnique:${hasUniqueChallengeTag}`"
                :has-error-message="false"
                ruid="create-challenge__tag-id__input"
                @keyup.enter.native="handleChallengeTag(challengeTagClass, challengeTagId)"
                @errors="($event) => (challengeTagIdErrors = $event)"
                />

              <cc-button
                :text="$t('commons.add')"
                :block="false"
                class="ml-3"
                variant="secondary"
                :has-min-width="false"
                :disabled="challengeTagId.length === 0"
                ruid="create-challenge__add-user-id__button"
                @click.native="handleChallengeTag(challengeTagClass, challengeTagId)"
                />
            </div>

            <cc-error-message
              v-if="challengeTagIdErrors && challengeTagIdErrors[0]"
              class="flex-grow w-full"
              :message="challengeTagIdErrors[0]"
              />

            <div
              v-for="(id, i) in challengeTags"
              :key="i"
              class="pt-4"
              >
              <div class="flex">
                <cc-text
                  class="lowercase flex-grow bg-gray-100 p-3 rounded-lg font-mono"
                  variant="code-sm"
                  >
                  {{ id }}
                </cc-text>

                <cc-button
                  :block="false"
                  svg="icons/icon-cross-thin"
                  svg-color="text-gray-500"
                  svg-align="left"
                  variant="plain"
                  class="ml-3 bg-gray-100 hover:bg-gray-300"
                  :has-min-width="false"
                  ruid="create-challenge__add-user-ids-button"
                  @click.native="challengeTags.splice(i, 1)"
                  />
              </div>
            </div>
          </div>
        </div>

        <cc-audience-selection
          v-if="true"
          class="pb-12"
          :has-context-selection="false"
          :has-user-id-selection="false"
          :validate-fields="false"
          :tag-classes-to-skip="['context', 'challenge', 'feature']"
          :user-tags="challenge.userTags"
          @user-tags="challenge.userTags = $event"
          />

        <!-- Messages -->
        <!-- Challenge New  -->
        <div
          v-if="false"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.sendMessageOnCreation') }}
          </cc-heading>

          <div class="flex items-end flex-wrap">
            <cc-check-box
              :label="$t('commons.fieldNames.deliveryMethod')"
              ruid="create-challenge__challenge-new-broadcast__send-message-as-inbox"
              :text="$t('configs.sendMessageAsInboxMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="
                has(challenge, 'messages.challengeNewBroadcast.payload.inbox.resources')
              "
              @change="handleDeliveryMethodSelection($event, 'inbox', 'challengeNewBroadcast')"
              />
            <cc-check-box
              ruid="create-challenge__challenge-new-broadcast__send-message-as-push"
              :text="$t('configs.sendMessageAsPushNotification')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeNewBroadcast.payload.push.resources')"
              @change="handleDeliveryMethodSelection($event, 'push', 'challengeNewBroadcast')"
              />
          </div>

          <!-- Challenge New / Backwards compatibility / Display message composer only if relevent resources exists  -->
          <!-- Inbox Message resources -->
          <cc-message-composer
            v-if="has(challenge, 'messages.challengeNewBroadcast.payload.inbox.resources')"
            id="challenge-new-broadcast__inbox-message-composer"
            class="pt-8"
            message-type="inbox"
            :heading="$t('pages.createMessage.inboxMessage')"
            :message-resources="messageResources('challengeNewBroadcast', 'inbox')"
            />

          <!-- Challenge New / Backwards compatibility / Display message composer only if relevent resources exists  -->
          <!-- Push Notification resources -->
          <cc-push-message-composer
            v-if="has(challenge, 'messages.challengeNewBroadcast.payload.push.resources')"
            id="challenge-new-broadcast__push-message-composer"
            class="pt-8"
            :push-resources="messageResources('challengeNewBroadcast', 'push')"
            :push-meta="messageMeta('challengeNewBroadcast', 'push')"
            />
        </div>

        <!-- Challenge Success  -->
        <div
          v-if="true"
          class="py-12 overflow-hidden"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.sendMessageOnSuccess') }}
          </cc-heading>

          <cc-hint
            :hint="$t('pages.createChallenge.creationMessageHint')"
            class="mb-3"
            />

          <div class="flex items-end flex-wrap">
            <cc-check-box
              :label="$t('commons.fieldNames.deliveryMethod')"
              ruid="create-challenge__challenge-success__send-message-as-inbox"
              :text="$t('configs.sendMessageAsInboxMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeSuccess.payload.inbox.resources')"
              @change="handleDeliveryMethodSelection($event, 'inbox', 'challengeSuccess')"
              />
            <cc-check-box
              ruid="create-challenge__challenge-success__send-message-as-push"
              :text="$t('configs.sendMessageAsPushNotification')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeSuccess.payload.push.resources')"
              @change="handleDeliveryMethodSelection($event, 'push', 'challengeSuccess')"
              />

            <cc-check-box
              ruid="create-challenge__challenge-success__send-message-as-mail"
              :text="$t('configs.sendMessageAsMailMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeSuccess.payload.mail.resources')"
              @change="handleDeliveryMethodSelection($event, 'mail', 'challengeSuccess')"
              />
          </div>

          <div class="w-80 pr-4">
            <cc-select
              id="message-routes-challenge-success"
              :disabled="!hasDeliveryMethodsFor('challengeSuccess')"
              class="pb-4 pt-3"
              ruid="create-challenge__success__message-routes"
              :label="$t('pages.createMessage.messageRouteLabel')"
              :options="generateOptionsFromObject('mobileAppRoutes')"
              :has-de-select="true"
              :value="getSuccessMessageHref"
              @input="setMessageHref('challengeSuccess', $event)"
              />
          </div>

          <!-- Inbox Message resources -->
          <cc-message-composer
            v-if="has(challenge, 'messages.challengeSuccess.payload.inbox.resources')"
            id="challenge-success__inbox-message-composer"
            class="pt-8"
            message-type="inbox"
            :heading="$t('pages.createMessage.inboxMessage')"
            :message-resources="messageResources('challengeSuccess', 'inbox')"
            />

          <!-- Push Notification resources -->
          <cc-push-message-composer
            v-if="has(challenge, 'messages.challengeSuccess.payload.push.resources')"
            id="challenge-success__push-message-composer"
            class="pt-8"
            :push-resources="messageResources('challengeSuccess', 'push')"
            :push-meta="messageMeta('challengeSuccess', 'push')"
            />

          <!-- Mail Message resources -->
          <cc-message-composer
            v-if="has(challenge, 'messages.challengeSuccess.payload.mail.resources')"
            id="challenge-success__mail-message-composer"
            class="pt-8"
            message-type="mail"
            :heading="$t('pages.createMessage.mailMessage')"
            :image="false"
            :body-url="true"
            :body="true"
            :has-preview="false"
            :message-resources="messageResources('challengeSuccess', 'mail')"
            />
        </div>

        <!-- Challenge Failure  -->
        <div
          v-if="true"
          class="py-12 overflow-hidden"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.sendMessageOnFailure') }}
          </cc-heading>

          <div class="flex items-end flex-wrap">
            <cc-check-box
              :label="$t('commons.fieldNames.deliveryMethod')"
              ruid="create-challenge__challenge-failure__send-message-as-inbox"
              :text="$t('configs.sendMessageAsInboxMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeFailure.payload.inbox.resources')"
              @change="handleDeliveryMethodSelection($event, 'inbox', 'challengeFailure')"
              />
            <cc-check-box
              ruid="create-challenge__challenge-failure__challenge-failure__send-message-as-push"
              :text="$t('configs.sendMessageAsPushNotification')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeFailure.payload.push.resources')"
              @change="handleDeliveryMethodSelection($event, 'push', 'challengeFailure')"
              />

            <cc-check-box
              ruid="create-challenge__challenge-failure__challenge-failure__send-message-as-mail"
              :text="$t('configs.sendMessageAsMailMessage')"
              class="flex-shrink-0 mr-4 my-2"
              :model-value="has(challenge, 'messages.challengeFailure.payload.mail.resources')"
              @change="handleDeliveryMethodSelection($event, 'mail', 'challengeFailure')"
              />
          </div>

          <div class="w-80 pr-4">
            <cc-select
              id="message-routes-challenge-failure"
              :disabled="!hasDeliveryMethodsFor('challengeFailure')"
              class="pb-4 pt-3"
              ruid="create-challenge__failure__message-routes"
              :label="$t('pages.createMessage.messageRouteLabel')"
              :options="generateOptionsFromObject('mobileAppRoutes')"
              :has-de-select="true"
              :value="getFailureMessageHref"
              @input="setMessageHref('challengeFailure', $event)"
              />
          </div>

          <!-- Inbox Message resources -->
          <cc-message-composer
            v-if="has(challenge, 'messages.challengeFailure.payload.inbox.resources')"
            id="challenge-failure__inbox-message-composer"
            class="pt-8"
            message-type="inbox"
            :heading="$t('pages.createMessage.inboxMessage')"
            :message-resources="messageResources('challengeFailure', 'inbox')"
            />

          <!-- Push Notification resources -->
          <cc-push-message-composer
            v-if="has(challenge, 'messages.challengeFailure.payload.push.resources')"
            id="challenge-failure__push-message-composer"
            class="pt-8"
            :push-resources="messageResources('challengeFailure', 'push')"
            :push-meta="messageMeta('challengeFailure', 'push')"
            />

          <!-- Mail Message resources -->
          <cc-message-composer
            v-if="has(challenge, 'messages.challengeFailure.payload.mail.resources')"
            id="challenge-failure__mail-message-composer"
            class="pt-8"
            message-type="mail"
            :heading="$t('pages.createMessage.mailMessage')"
            :image="false"
            :body-url="true"
            :body="true"
            :has-preview="false"
            :message-resources="messageResources('challengeFailure', 'mail')"
            />
        </div>

        <cc-subscription-filters
          v-if="true"
          class="py-12"
          :raw-query="get(challenge, 'resources.subscriptionFilterQuery')"
          @fetched-json-path="challenge.subscriptionFilter = $event"
          @fetched-query="setSubscriptionFilterQuery($event)"
          />

        <!-- Challenge Rule configurator -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('configs.rules') }}
          </cc-heading>

          <validation-provider
            v-slot="{ classes, errors }"
            ref="ruleName"
            :name="$t('configs.ruleName')"
            :rules="`required|alpha_num|hasUniqueRuleName:${hasUniqueRuleName}`"
            mode="aggressive"
            >
            <div class="flex flex-wrap items-end">
              <cc-input
                v-model="ruleName"
                :label="$t('configs.ruleName')"
                validation-ref="validationRef"
                ruid="create-challenge__rule__name"
                :has-error-message="false"
                :validation-rules="`minRuleAmount:${getValueParamsAmount}`"
                :validation-state="classes.invalid ? 'border-secondary-red' : null"
                validation-interaction-mode="passive"
                class="w-48 mr-4"
                @errors="($event) => (ruleNameErrors = $event)"
                />

              <cc-button
                :text="$t('configs.addNewRule')"
                :block="false"
                ruid="create-challenge__rule__add-new-button"
                @click.native="addRuleHandler"
                />
              <!-- {{ ruleNameErrors }} -->
              <cc-error-message
                v-if="ruleNameErrors && ruleNameErrors[0]"
                class="flex-grow w-full"
                :message="ruleNameErrors[0]"
                />
              <!-- {{ errors }} -->
              <cc-error-message
                v-if="errors && errors[0]"
                class="flex-grow w-full"
                :message="errors[0]"
                />
            </div>
          </validation-provider>

          <div
            v-for="(value, key, i) in challenge.params.valueParams"
            :key="`valueParams-${i}`"
            :data-ruid="`create-challenge__rule-${i}`"
            >
            <cc-divider />

            <cc-challenge-rule-configurator
              :name="key"
              :rule="value"
              :index="i"
              @delete="($event) => $delete(challenge.params.valueParams, $event)"
              />
          </div>
        </div>
      </validation-observer>

      <!-- Action buttons -->
      <div class="flex flex-wrap justify-end">
        <cc-button
          v-if="!nonDeleteButtonRouteTypes.includes($route.params.type)"
          variant="red"
          :text="$t('commons.delete')"
          :block="false"
          class="m-2 text-secondary-red"
          :is-loading="isDeleting"
          :disabled="isDeleting"
          @click.native="isDeleteConfirmationOpen = true"
          />

        <cc-button
          variant="secondary"
          :text="$t('commons.cancel')"
          :block="false"
          class="m-2"
          ruid="create-challenge__cancel-button"
          @click.native="$router.push({ name: 'challenges' })"
          />

        <cc-button
          v-if="$route.params.type !== 'challenge'"
          :text="$t('commons.create')"
          class="m-2"
          :block="false"
          ruid="create-challenge__create-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="createChallenge"
          />

        <cc-button
          v-else-if="$route.params.type === 'challenge'"
          class="m-2"
          :block="false"
          ruid="challenge__save-button"
          :text="$t('commons.save')"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="updateChallenge($route.params.challengeId, challenge)"
          />
      </div>

      <cc-modal
        v-if="isDeleteConfirmationOpen"
        :is-open="isDeleteConfirmationOpen"
        transition-name="slide-up"
        :heading="$t('modals.deleteChallengeConfirmation.title')"
        @close="isDeleteConfirmationOpen = false"
        >
        <template #body>
          <div class="max-w-md">
            {{ $t('modals.deleteChallengeConfirmation.body') }}
          </div>
        </template>

        <template #footer="{ close }">
          <div class="grid grid-cols-2 gap-x-4">
            <cc-button
              variant="secondary"
              :text="$t('commons.cancel')"
              @click.native="close"
              />
            <cc-button
              variant="red"
              :text="$t('modals.deleteProductConfirmation.primaryButton')"
              @click.native="deleteChallenge($route.params.challengeId)"
              />
          </div>
        </template>
      </cc-modal>

      <cc-code class="mt-36">
        {{ challenge }}
      </cc-code>
    </cc-section-table>
  </div>
</template>

<script>
import validator from '@/mixins/validator';
// import { validate } from 'vee-validate';

import { camelCase } from '@/mixins/utils';
import {
 cloneDeep, isEmpty, has, get,
} from 'lodash';
import { mapGetters, mapState } from 'vuex';
import mobileAppRoutes from '@/mixins/mobileAppRoutes';
import defaultImages from '@/mixins/defaultImages';

import ccVoucherInventoryModal from '@/views/Companies/VoucherInventoryModal.vue';
/**
 * TODO: We might need delete empty language keys before posting the payload.
 * Maybe a watcher?
 */
export default {
  components: {
    ccVoucherInventoryModal,
  },
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      isLoadingSection: true,
      isDeleting: false,
      companiesWithActiveOffers: [],
      voucherInventory: {},
      voucherInventoryModal: {},
      selectedOfferWithInventoryVoucher: null,
      sendMessageOnCreation: false,
      sendMessageOnSuccess: false,
      nonDeleteButtonRouteTypes: ['new-challenge', 'clone-challenge'],
      challengeNewBroadcast: 'challenges',
      challengeSuccessMessageHref: 'footprint',
      challengeFailureMessageHref: 'footprint',
      isDeleteConfirmationOpen: false,
      ruleName: null,
      ruleNameErrors: [],
      challengeTagId: '',
      challengeTagIdErrors: [],
      challengeTagClass: 'company',
      challengeTagClasses: [
        { company: 'Company' }, //
        { group: 'Group' },
        { category: 'Category' },
        { achievement: 'Achievement' },
      ],
      isTimeBasedChallengeChecked: false,
      rewardTypes: ['BADGE', 'INVENTORY_VOUCHER'],
      availableMessages: {
        challengeNewBroadcast: {
          name: '',
          payload: {},
          meta: {},
          userTags: [],
          userIds: [],
          deliveryTag: 'challenge:new',
        },
        /**
         * `challengeCompletion` was renamed to `challengeSuccess` around Aug/Sep 2022
         *  Delivery tag should stay same, if that changes mind that it should also change in web app
         */
        challengeSuccess: {
          name: '',
          payload: {},
          meta: {},
          userTags: [],
          userIds: [],
          deliveryTag: 'challenge:completion',
        },
        challengeFailure: {
          name: '',
          payload: {},
          meta: {},
          userTags: [],
          userIds: [],
          deliveryTag: 'challenge:completion',
        },
      },
      levels: [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
      challenge: {
        /** @deprecated `name` is no longer used in favor of `contract` */
        name: null,
        contract: 'PeriodAccumulatedDelayedTrips',
        evaluationWindowInMillis: 14400000,
        durationInMillis: 0,
        startDate: null,
        availabilityDate: null,
        expireDate: null,
        active: false,
        recurrent: false,
        hidden: false,
        accumulateGlobal: false,
        challengeTags: [],
        userTags: [],
        autoSubscribe: false,
        canJoinAfterCycleStarts: true,
        level: 5,
        challengeGroup: 'public',
        defaultLang: 'en',
        sponsorId: null,
        resources: {},
        params: {
          valueParams: {},
        },
        subscriptionFilter: null,
        achievement: {
          description: '',
          reward: {
            virtualGoods: [],
          },
          level: 5,
          defaultLang: 'en',
          resources: {},
        },
        messages: {},
      },
    };
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
      challenges: st => st.challenge.challenges,
      companies: st => st.company.companies,
      sponsorCompanies: st => st.company.sponsorCompanies,
    }),
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
      getCompanyName: 'company/getCompanyName',
    }),
    defaultImages() {
      return defaultImages;
    },
    mobileAppRoutes() {
      return mobileAppRoutes;
    },
    getValueParamsAmount() {
      return Object.keys(this.challenge.params.valueParams)?.length;
    },
    hasUniqueRuleName() {
      return !Object.keys(this.challenge.params.valueParams)?.includes(this.ruleName);
    },
    hasUniqueChallengeTag() {
      return !this.challengeTags?.includes(`${this.challengeTagClass}:${this.challengeTagId}`);
    },
    hasInventoryVoucher() {
      return this.getInventoryVoucherVirtualGoods?.length > 0;
    },
    getLevel: {
      get() {
        return this.challenge.level;
      },
      set(val) {
        this.challenge.level = parseInt(val, 10);
        this.challenge.achievement.level = parseInt(val, 10);
      },
    },
    getSponsor: {
      get() {
        return this.challenge.sponsorId;
      },
      set(val) {
        this.challenge.sponsorId = val;
      },
    },
    getOffersWithInventoryVoucher() {
      const allInventoryVoucherVirtualGoods = [];

      this.companiesWithActiveOffers.forEach((company) => {
        company.offers.forEach((offer) => {
          if (offer.virtualGood.type === 'INVENTORY_VOUCHER') {
            allInventoryVoucherVirtualGoods.push(offer);
          }
        });
      });

      // this.$log.info('allInventoryVoucherVirtualGoods', allInventoryVoucherVirtualGoods);

      return allInventoryVoucherVirtualGoods;
    },
    achievement() {
      return this.challenge?.achievement;
    },
    reward() {
      return this.challenge?.achievement?.reward;
    },
    achievementResources() {
      return this.challenge?.achievement?.resources;
    },
    virtualGoods() {
      return this.challenge?.achievement?.reward?.virtualGoods;
    },
    inventoryVoucherVirtualGoods() {
      return this.challenge?.achievement?.reward?.virtualGoods.filter(
        good => good.type === 'INVENTORY_VOUCHER',
      );
    },
    challengeTags() {
      return this.challenge?.challengeTags;
    },
    sponsoringCompanies() {
      const sponsorCompanies = this.sponsorCompanies
        .filter((company) => {
          const sponsorWallet = company.wallets.find(wallet => wallet.type === 'SPONSOR');

          if (sponsorWallet?.balance > 0) {
            return company;
          }
          return false;
        })
        .map((company) => {
          const balance = company.wallets[0]?.balance;

          return {
            value: company.company.id,
            text: `${company.company.name} / ${company.company.id} / Balance: ${balance}`,
          };
        });

      return sponsorCompanies;
    },
    optionsForOffersWithInventoryVoucherVirtualGoods() {
      return this.getOffersWithInventoryVoucher.map((offer) => {
        const companyName = this.getCompanyName(offer.storeId);

        return {
          value: offer.id,
          text: `${companyName} – ${offer.virtualGood.name}`,
        };
      });
    },
    getInventoryVoucherVirtualGoods() {
      // Get individual voucher inventories
      this.inventoryVoucherVirtualGoods.forEach(async (iv) => {
        if (!this.voucherInventory[iv.id]) {
          await this.getVouchers(iv.id);
          this.$log.info('iv', iv);
        }
      });

      return this.inventoryVoucherVirtualGoods;
    },
    getSuccessMessageHref() {
      return this.getMessageHref('challengeSuccess');
    },
    getFailureMessageHref() {
      return this.getMessageHref('challengeFailure');
    },
  },
  async created() {
    if (!this.sponsorCompanies?.length) {
      await this.$store.dispatch('company/getWithWallets');
    }

    if (this.$route.params.challenge) {
      this.challenge = cloneDeep(this.$route.params.challenge);
    }

    if (!this.challenge.achievement.resources) {
      this.$set(this.challenge.achievement, 'resources', {});
    }

    if (this.$route.params.payload) {
      this.challenge = cloneDeep(this.$route.params.payload);
    }

    if (this.isEmpty(this.challenge.messages)) {
      this.challenge.messages = this.availableMessages;
    }

    this.companiesWithActiveOffers = await this.$store.dispatch(
      'company/getAllCompaniesWithActiveOffers',
    );

    this.isLoadingSection = false;
  },
  methods: {
    isEmpty,
    camelCase,
    has,
    get,
    setSubscriptionFilterQuery(query) {
      this.challenge.resources = this.challenge.resources || {};
      this.challenge.resources.subscriptionFilterQuery = query;
    },
    hasDeliveryMethodsFor(messageKey) {
      const payload = this.challenge?.messages?.[messageKey]?.payload;
      const result = Object.keys(payload || {})?.filter(e => payload?.[e])?.length > 0;

      // this.$log.info('hasDeliveryMethodsFor', result);

      return result;
    },
    getMessageHref(messageKey) {
      // this.$log.info('getMessageHref: messageKey', messageKey);
      const payload = this.challenge?.messages?.[messageKey]?.payload;
      const messageTypes = Object.keys(payload || {}).filter(e => payload[e]);

      const messageHref = this.getValue(payload?.[messageTypes[0]]?.meta, 'href')
        ?.split(':')?.[1]
        .split('/')[0];

      const returnValue = messageHref || this[`${messageKey}MessageHref`];

      this[`${messageKey}MessageHref`] = returnValue;

      // this.$log.info('getMessageHref: messageKey', messageKey, returnValue);
      return returnValue;
    },
    setMessageHref(messageKey, routeName) {
      if (routeName) {
        const payload = this.challenge?.messages?.[messageKey]?.payload;

        Object.keys(payload)
          .filter(e => payload[e])
          .forEach((messageType) => {
            payload[messageType].meta.href = `internal:${routeName}`;
          });
      }
    },
    setTimeBasedChallengeCheckbox(event) {
      this.isTimeBasedChallengeChecked = event;
      this.challenge.availabilityDate = null;
      this.challenge.startDate = null;
    },
    getInventoryVoucherOfferName(resources) {
      return resources?.en?.title || resources?.de?.title;
    },
    getInventoryVoucherOfferImage(resources) {
      return resources?.en?.images[0] || resources?.de?.images[0];
    },
    async getVouchers(virtualGoodId) {
      this.$log.info('Will Get vouchers for challenge');
      try {
        const response = await this.$store.dispatch('company/getVouchers', {
          virtualGoodId,
          csvFormat: false,
        });

        this.$set(this.voucherInventory, virtualGoodId, response);
      } catch (err) {
        this.$log.error('Error: getVouchers', err);
      }
    },
    async handleChallengeTag(tagClass, tagId) {
      const success = await this.$refs.challengeTagId.$refs.challengeTag.validate();

      if (!success.valid) {
        this.$log.info('challengeTag !success', success);
        return;
      }

      if (tagId.length !== 0) {
        if (!this.challengeTags) {
          this.$set(this.challenge, 'challengeTags', []);
        }
        this.challengeTags.push(`${tagClass}:${tagId}`);
        this.challengeTagId = '';
      }
    },
    hasMessageCreatedAtDate(messageKey) {
      return this.challenge?.messages?.[messageKey]?.createdAt;
    },
    messageResources(messageKey, deliveryMethod) {
      return this.challenge.messages[messageKey]?.payload[deliveryMethod]?.resources;
    },
    messageMeta(messageKey, deliveryMethod) {
      return this.challenge.messages[messageKey]?.payload[deliveryMethod]?.meta;
    },
    handleDeliveryMethodSelection(event, deliveryMethod, messageKey) {
      if (event) {
        let href;

        if (messageKey === 'challengeNewBroadcast') {
          href = `internal:${this.challengeNewBroadcast}`;
          // this.setMessageHref(messageKey, this.challengeNewBroadcast);
        }

        if (messageKey === 'challengeSuccess') {
          href = `internal:${this.challengeSuccessMessageHref}`;
          // this.setMessageHref(messageKey, this.challengeSuccessMessageHref);
        }

        if (messageKey === 'challengeFailure') {
          href = `internal:${this.challengeFailureMessageHref}`;
          // this.setMessageHref(messageKey, this.challengeFailureMessageHref);
        }

        /**
         * If somehow the message key was not initialized and to support backwards compatibility
         * check if the intended messageKey is not truthy and intended key object model available
         * then set/init the message key
         */
        if (!this.challenge?.messages?.[messageKey] && this.availableMessages[messageKey]) {
          this.$set(this.challenge?.messages, messageKey, this.availableMessages[messageKey]);
        }

        this.$set(this.challenge?.messages?.[messageKey]?.payload, deliveryMethod, {
          resources: {},
          meta: {
            href,
          },
        });
      } else {
        this.$delete(this.challenge?.messages?.[messageKey]?.payload, deliveryMethod);
      }
    },
    processChallengeDetailMessage(challengeId) {
      const messages = this.challenge?.messages;
      Object.keys(messages).forEach((messageKey) => {
        const payload = messages[messageKey]?.payload;
        Object.keys(payload)
          .filter(e => payload[e])
          .forEach((messageType) => {
            const isAChallengeHref = payload[messageType].meta.href.split(':')[1] === 'challenge';
            payload[messageType].meta.href = isAChallengeHref
              ? `internal:challenge/${challengeId}`
              : payload[messageType].meta.href;
          });
      });
      return messages;
    },
    async createChallenge() {
      try {
        const { isValid } = await this.$refs.form.validateWithInfo();

        if (!isValid) {
          this.$log.info('Form !isValid', isValid);
          return;
        }

        this.$log.info('Form has been submitted!');

        this.isLoading = true;
        const response = await this.$store.dispatch('challenge/createChallenge', this.challenge);

        if (
          this.getSuccessMessageHref === 'challenge'
          || this.getFailureMessageHref === 'challenge'
        ) {
          await this.updateChallenge(response.id, this.challenge, false);
        }
        this.$log.info('Response: createChallenge', response);

        this.$router.push({ name: 'challenges' });
      } catch (err) {
        this.$log.error('Error: createChallenge', err);
      } finally {
        this.isLoading = false;
      }
    },
    async updateChallenge(challengeId, challenge, shouldPushRoute = true) {
      try {
        const { isValid } = await this.$refs.form.validateWithInfo();

        if (!isValid) {
          this.$log.info('Form !isValid', isValid);
          return;
        }

        this.isLoading = true;

        await this.$store.dispatch('challenge/updateChallenge', {
          challengeId,
          challenge: { ...challenge, messages: this.processChallengeDetailMessage(challengeId) },
        });
        if (shouldPushRoute) {
          this.$router.push({ name: 'challenges' });
        }
      } catch (err) {
        this.$log.error('Error: updateChallenge', err);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteChallenge(challengeId) {
      try {
        this.isDeleting = true;

        await this.$store.dispatch('challenge/deleteChallenge', challengeId);

        this.$router.push({ name: 'challenges' });
      } catch (err) {
        this.$log.error('Error: deleteChallenge', err);
      } finally {
        this.isDeleting = false;
      }
    },
    getTime(date) {
      const addZero = i => (i < 10 ? `0${i}` : i);

      const d = new Date(date);
      return `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
    },
    async addRuleHandler() {
      const { ruleName } = this;
      const { valueParams } = this.challenge.params;

      const ruleNameValidation = await this.$refs.ruleName.validate();
      // const ruleNameValidation = await validate(
      //   ruleName,
      //   'required',
      //   { name: this.$t('configs.ruleName') },
      // );
      this.$log.info('addRuleHandler manual validation', ruleNameValidation, ruleName);

      // if (this.getValueParamsAmount === 0) {
      // this.$log.info('addRuleHandler manual validation', ruleNameValidation, ruleName);
      // }
      // if (ruleNameValidation.valid) {
      // }
      if (ruleName && !valueParams[camelCase(ruleName)]) {
        this.$set(valueParams, camelCase(ruleName), {});
        this.ruleName = null;
        await this.$refs.ruleName.reset();
      }
    },
    handleInventoryVoucherSelect(offerId) {
      this.virtualGoods.push(
        this.getOffersWithInventoryVoucher.find(offer => offer.id === offerId)?.virtualGood,
      );
    },
    generateOptions(source) {
      return this[source]
        .slice()
        .sort()
        .map(el => [{ value: el, text: this.$t(`configs.${source}.${el}`) }])
        .flat();
    },
    generateOptionsFromObject(source) {
      return this[source]
        .slice()
        .map(el => [{ value: Object.keys(el)[0], text: Object.values(el)[0] }])
        .flat();
    },
    initVirtualGoodsResources(i, lang, key) {
      return this.virtualGoods?.[i]?.resources?.[lang]?.[key]
        ? undefined
        : this.setResourceValue(this.virtualGoods?.[i]?.resources, key, '', lang);
    },
    insertBroadcastName(event) {
      Object.keys(this.challenge.messages).forEach((broadcastKey) => {
        if (this.challenge.messages[broadcastKey]) {
          this.challenge.messages[broadcastKey].name = event;
        }
      });
    },
    getValue(target, key) {
      return target?.[key];
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
    getResourceValue(target, key, lang) {
      return target?.[lang]?.[key];
    },
    setResourceValue(target, key, value, lang) {
      this.$set(target, lang, { ...target[lang], [key]: value });
    },
  },
};
</script>
