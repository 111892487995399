import Vue from 'vue';
import Sentry from '@/mixins/sentry';

import App from './App.vue';

import router from './router';
import store from './store';
import i18n from './i18n';


import '@/assets/style.css';
import 'tailwindcss/tailwind.css';

import '@/logger';
import '@/mixins/globalComponents';

Vue.config.productionTip = false;

Sentry();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

