<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-sm">
      <cc-heading
        tag="h1"
        class="text-2xl pb-6"
        >
        {{ $t('pages.phoneVerification.title') }}
      </cc-heading>

      <cc-text
        :text="$t('pages.phoneVerification.body')"
        color="text-gray-600"
        class="pb-6"
        />
      <validation-observer ref="form">
        <form @submit.prevent="verifyToken">
          <cc-input
            id="one-time-code"
            v-model="tokenInput"
            name="one-time-code"
            autocomplete="one-time-code"
            :label="$t('commons.fieldNames.confirmationCode')"
            maxlength="6"
            class="mb-2"
            :validation-rules="`required|length:${6}`"
            />

          <cc-error-message
            v-if="errors[0]"
            :message="errors[0]"
            class="pb-4"
            />

          <div class="flex pt-2">
            <cc-button
              class="mr-2"
              variant="secondary"
              :text="$t('commons.back')"
              :has-min-width="false"
              @click.native="$router.push({ name: 'complete-registration'})"
              />
            <cc-button
              class="ml-2"
              :text="$t('commons.verify')"
              :is-loading="isLoading"
              :disabled="isLoading"
              :has-min-width="false"
              type="submit"
              />
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      token: null,
      errors: [],
    };
  },
  computed: {
    tokenInput: {
      get() {
        return this.token;
      },
      set(value) {
        const inputValue = value.replace(/\s/g, '');
        this.token = inputValue.toUpperCase();
      },
    },
  },
  methods: {
    async verifyToken() {
      try {
        this.errors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        await this.$store.dispatch('user/verifyToken', {
          phoneNumber: this.$route.params.phoneNumber,
          token: this.token,
          context: process.env.VUE_APP_CONTEXT,
        });

        await this.$store.dispatch('user/getUser');

        this.$router.push({ name: 'create-password' }).catch(() => {});
      } catch (err) {
        this.$log.error('Error: verifyToken', err);
        this.errors.push(this.$t('errors.genericError'));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
