import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin/message`;

export default {
  async broadcastMessage(payload) {
    return ApiService.securePost(`${resourceAdmin}/broadcast/dispatch`, payload);
  },

  async scheduleBroadcastMessage(payload) {
    return ApiService.securePost(`${resourceAdmin}/broadcast/schedule`, payload);
  },

  async updateScheduledMessage(payload) {
    return ApiService.put(`${resourceAdmin}/broadcast/${payload.id}`, payload);
  },

  async deleteScheduledMessage(messageId) {
    return ApiService.delete(`${resourceAdmin}/broadcast/${messageId}`);
  },

  async getAllBroadcastMessages() {
    return ApiService.get(`${resourceAdmin}/broadcast`);
  },

  async getBroadcastMessageById(broadcastId) {
    return ApiService.get(`${resourceAdmin}/broadcast/${broadcastId}`);
  },
};
