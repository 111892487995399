<template>
  <div
    class="relative"
    >
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-100 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
      >
      <div
        v-if="isOpen"
        class="absolute whitespace-no-wrap
        bg-white rounded-lg shadow-gray-200 z-10 border-gray-200 border shadow-2xl"
        :class="dropdownClass"
        >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    dropdownClass: {
      type: String,
      required: true,
      default: '',
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        document.addEventListener('click', this.close);
        document.addEventListener('keydown', this.handleEscape);
      } else {
        document.removeEventListener('click', this.close);
        document.removeEventListener('keydown', this.handleEscape);
      }
    },
  },
  created() {
    // this.$log.info('Created: dropdown');
  },
  methods: {
    handleEscape(e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        this.$log.info('Closing dropdown via ESC');
        this.$emit('close');
      }
    },
    close(e) {
      const { target } = e;
      if (!this.$el.contains(target)) {
        this.$log.info('Closing dropdown');
        this.$emit('close');
      }
    },
  },
};
</script>
