import Vue from 'vue';
// import * as utils from '@/mixins/utils';
import ChallengeService from '@/services/ChallengeService';
// import { getAccessToken, setAccessToken, removeAccessToken } from '@/services/SecurityService';
// import i18n from '@/i18n';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
  challenges: [],
});


const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};


const actions = {
  async getAllChallenges({ commit }) {
    try {
      const response = await ChallengeService.getAllChallenges();
      commit('setState', ['challenges', response]);

      // Vue.$log.info('Response: Vuex: getAllChallenges', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getAllChallenges', err);
      throw err;
    }
  },

  async getChallengeById(ctx, challengeId) {
    try {
      const response = await ChallengeService.getChallengeById(challengeId);

      // Vue.$log.info('Response: Vuex: getChallengeById', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getChallengeById', err);
      throw err;
    }
  },

  async createChallenge(context, challenge) {
    try {
      const response = await ChallengeService.createFullChallenge(challenge);

      Vue.$log.info('Response: Vuex: createChallenge', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: createChallenge', err);
      throw err;
    }
  },

  async updateChallenge(context, { challengeId, challenge }) {
    try {
      const response = await ChallengeService.updateFullChallenge(challengeId, challenge);

      Vue.$log.info('Response: Vuex: updateChallenge', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateChallenge', err);
      throw err;
    }
  },

  async deleteChallenge(context, challengeId) {
    try {
      const response = await ChallengeService.deleteChallenge(challengeId);

      Vue.$log.info('Response: Vuex: deleteChallenge', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteChallenge', err);
      throw err;
    }
  },
};


const getters = {
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
