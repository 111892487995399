<template>
  <p
    class="m-0"
    :class="[getColor, getClass, getTextAlign]"
    >
    <slot v-if="!text" />
    {{ text }}
  </p>
</template>

<script>
import commonProps from '@/mixins/commonProps';

// Valid variants
const variantProps = [
  'body',
  'body-light',
  'body-bold',
  'body-sm-plain',
  'body-sm',
  'body-sm-tight',
  'body-sm-bold',
  'body-xs',
  'body-xs-semibold',
  'body-lg',
  'body-lg-bold',
  'body-xl',
  'body-xl-bold',
  'body-5xl',
  'code',
  'code-sm',
  'code-xs',
];


const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  mixins: [commonProps],
  props: {
    variant: {
      type: String,
      required: false,
      default: 'body',
      validator: value => variantProps.includes(value),
    },
    color: {
      type: String,
      required: false,
      default: 'text-gray-800',
      validator: value => commonProps.methods.colorProps().includes(value),
    },
    align: {
      type: String,
      required: false,
      default: null,
      validator: value => alignProps.includes(value),
    },
  },
  computed: {
    getTextAlign() {
      return [
        // Left alignment is default, declared here only to override if needed
        this.align === 'left' ? 'text-left' : null,
        this.align === 'center' ? 'text-center' : null,
        this.align === 'right' ? 'text-right' : null,
      ];
    },
    getClass() {
      return [
        this.variant === 'body-5xl' ? 'font-body text-5xl font-medium' : null,
        this.variant === 'body-xl' ? 'font-body text-xl font-medium leading-3' : null,
        this.variant === 'body-xl-bold' ? 'font-body text-xl font-bold leading-6' : null,
        this.variant === 'body-lg' ? 'font-body text-lg font-medium leading-6' : null,
        this.variant === 'body-lg-bold' ? 'font-body text-lg font-bold leading-6' : null,
        this.variant === 'body' ? 'font-body text-base font-medium leading-6' : null,
        this.variant === 'code' ? 'font-mono text-base font-medium leading-6' : null,
        this.variant === 'body-bold' ? 'font-body font-bold leading-6' : null,
        this.variant === 'body-light' ? 'font-body font-light leading-6' : null,
        this.variant === 'body-sm-plain' ? 'font-body text-sm leading-6' : null,
        this.variant === 'body-sm' ? 'font-body text-sm font-medium leading-6' : null,
        this.variant === 'code-sm' ? 'font-mono text-sm font-medium leading-6' : null,
        this.variant === 'code-xs' ? 'font-mono text-xs font-medium leading-6' : null,
        this.variant === 'body-sm-tight' ? 'font-body text-sm font-medium leading-5' : null,
        this.variant === 'body-sm-bold' ? 'font-body text-sm font-bold leading-6' : null,
        this.variant === 'body-xs' ? 'font-body text-xs font-medium leading-6' : null,
        this.variant === 'body-xs-semibold' ? 'font-body text-xs font-semibold leading-6' : null,
      ];
    },
    getColor() {
      return [
        this.color ? this.color : null,
      ];
    },
  },
};
</script>
