<template>
  <img
    v-if="src || getFilePath"
    :src="src || getFilePath"
    class="img-max"
    importance="high"
    alt="image"
    rel="preload"
    >
  <div
    v-else-if="hasPlaceholder"
    :class="placeholderClass"
    />
</template>

<script>
import commonProps from '@/mixins/commonProps';

export default {
  mixins: [commonProps],
  computed: {
    getFilePath() {
      if (this.filePath) {
        const images = require.context('@/assets/', true, /\.png|.jpg$/);
        return images(`./${this.filePath}`);
      }
      return false;
    },
  },
};
</script>
