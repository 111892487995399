<template>
  <div class="" />
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  created() {
    // this.$router.push({ name: 'dashboard' }).catch(() => {});
  },
};
</script>
