<template>
  <div>
    <!-- User Segments & Available Tags -->
    <div
      v-if="!isReadOnlyMessage"
      id="available-tags"
      class="flex flex-col flex-wrap pt-12"
      >
      <cc-heading
        tag="h3"
        weight="semibold"
        class="flex-grow w-full capitalize"
        color="text-gray-600"
        :has-border="true"
        >
        {{ $t('commons.fieldNames.userSegments') }}
      </cc-heading>

      <div
        v-for="(tagClasses, key, i) in availableTagClasses"
        :id="key"
        :key="i"
        class="pb-8"
        >
        <cc-heading
          tag="h3"
          weight="semibold"
          class="flex-grow w-full capitalize pb-2"
          color="text-gray-600"
          >
          {{ key }}
        </cc-heading>


        <div class="flex flex-col">
          <div class="flex max-w-lg">
            <cc-select
              :id="`message-tag_${key}`"
              :options="generateOptionsForSegments(tagClasses)"
              class="w-full pr-2"
              :has-de-select="true"
              :disabled="isContextTagSelected"
              :value="getValue(tagSelections, key)"
              @input="setValue(tagSelections, key, $event)"
              />
            <cc-button
              :text="$t('commons.add')"
              :block="false"
              class="ml-3"
              :disabled="!getValue(tagSelections, key)
                || userTags.includes(`${key}:${getValue(tagSelections, key)}`)"
              variant="secondary"
              :has-min-width="false"
              @click.native="handleAvailableTagSelect(`${key}:${getValue(tagSelections, key)}`)"
              />
          </div>

          <div class="flex flex-row flex-wrap">
            <div
              v-for="(tag, index) in getMessageTags(key)"
              :key="index"
              class="pt-4 flex items-center"
              >
              <cc-text
                class="flex-grow bg-gray-100 pl-4 pr-2 py-2 rounded-lg break-all font-mono flex items-center"
                variant="code-sm"
                >
                {{ sentenceCase(tag.desc) }}
                <cc-button
                  :block="false"
                  svg="icons/icon-cross-thin"
                  svg-color="text-gray-500"
                  svg-align="left"
                  variant="plain"
                  class="bg-gray-100 hover:bg-gray-300 ml-3"
                  :has-min-width="false"
                  ruid="create-challenge__add-user-ids-button"
                  @click.native="spliceFromUserTags(`${key}:${tag.id}`)"
                  />
              </cc-text>


              <cc-text
                v-if="getMessageTags(key).length -1 !== index"
                class="px-3 uppercase flex-grow"
                variant="body-xs"
                color="text-gray-400"
                >
                {{ $t('commons.or') }}
              </cc-text>
            </div>
          </div>
        </div>

        <div
          v-if="availableTagClassesLength -1 !== i"
          class="flex items-center pt-8"
          >
          <div class="border-b border-gray-300 items-center flex-grow" />

          <cc-text
            class="px-3 uppercase"
            variant="body-xs"
            color="text-gray-400"
            >
            {{ $t('commons.and') }}
          </cc-text>

          <div class="border-b border-gray-300 flex-grow" />
        </div>
      </div>

      <!-- Context & Validation message -->
      <div>
        <div
          v-if="hasContextSelection"
          class="pb-4"
          >
          <div class="border-b border-gray-300 flex-grow mb-8" />
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full capitalize"
            color="text-gray-600"
            >
            Context
          </cc-heading>

          <div class="flex flex-wrap items-end">
            <span
              class="flex items-center"
              >
              <cc-check-box
                :text="sentenceCase(getContextTag.desc)"
                class="flex-shrink-0 my-2"
                :model-value="userTags.includes(`context:${getContextTag.id}`)"
                @change="handleContextTagCheckbox($event, `context:${getContextTag.id}`)"
                />
            </span>
          </div>
        </div>

        <validation-provider
          v-slot="{ errors }"
          ref="messageTags"
          :name="$t('commons.fieldNames.userSegments')"
          :rules="`${isTagSelected || hasUserIds ? '' : 'required'}`"
          mode="aggressive"
          >
          <cc-error-message
            v-if="errors && errors[0]"
            class="flex-grow w-full"
            :message="errors[0]"
            />
        </validation-provider>
      </div>
    </div>

    <!-- Readonly tags -->
    <div
      v-if="isReadOnlyMessage"
      class="flex flex-col flex-wrap pt-12"
      >
      <cc-heading
        tag="h3"
        weight="semibold"
        class="flex-grow w-full capitalize"
        color="text-gray-600"
        :has-border="true"
        >
        {{ $t('commons.fieldNames.userSegments') }}
      </cc-heading>

      <div
        v-for="(tag, i) in readOnlyTags"
        :key="i"
        class="flex flex-col max-w-lg pb-4"
        >
        <cc-text
          class="flex-grow bg-gray-100 p-3 rounded-lg font-mono"
          variant="code-sm"
          >
          {{ tag }}
        </cc-text>
      </div>

      <cc-text
        v-if="!readOnlyTags.length"
        color="text-gray-600"
        >
        {{ $t('pages.messages.noUserSegmentSelected') }}
      </cc-text>
    </div>

    <!-- User Ids -->
    <div
      v-if="hasUserIdSelection"
      class="flex flex-col flex-wrap pt-12"
      >
      <cc-heading
        tag="h3"
        weight="semibold"
        class="flex-grow w-full"
        color="text-gray-600"
        :has-border="true"
        >
        {{ $t('pages.createMessage.userIdList') }}
      </cc-heading>

      <div class="flex flex-col max-w-lg">
        <div class="flex items-end ">
          <cc-input
            ref="userIdInput"
            v-model="userIdToTarget"
            :label="$t('commons.fieldNames.userId')"
            class="w-full"
            name="user-id"
            :has-error-message="false"
            :disabled="isReadOnlyMessage"
            validation-ref="userIdToTarget"
            :validation-rules="`validUUID|isUnique:${isUniqueUUID}`"
            validation-interaction-mode="lazy"
            ruid="create-message__user-id__input"
            :placeholder="$t('pages.createMessage.userIdPlaceholder')"
            @keyup.enter.native="addUserIdToMessagePayload(userIdToTarget)"
            @errors="$event => userIdInputErrors = $event"
            />
          <cc-button
            :text="$t('commons.add')"
            :block="false"
            class="ml-3"
            variant="secondary"
            :has-min-width="false"
            :disabled="userIdToTarget.length === 0"
            ruid="create-message__add-user-id__button"
            @click.native="addUserIdToMessagePayload(userIdToTarget)"
            />
        </div>

        <cc-error-message
          v-if="userIdInputErrors && userIdInputErrors[0]"
          class="flex-grow w-full"
          :message="userIdInputErrors[0]"
          />

        <div
          v-for="(id, i) in userIds"
          :key="i"
          class="pt-4"
          >
          <div class="flex">
            <cc-text
              class="lowercase flex-grow bg-gray-100 p-3 rounded-lg font-mono"
              variant="code-sm"
              >
              {{ id }}
            </cc-text>

            <cc-button
              :block="false"
              svg="icons/icon-cross-thin"
              svg-color="text-gray-500"
              svg-align="left"
              variant="plain"
              class="ml-3 bg-gray-100 hover:bg-gray-300"
              :has-min-width="false"
              :disabled="isReadOnlyMessage"
              ruid="create-message__add-user-ids-button"
              @click.native="userIds.splice(i, 1)"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sentenceCase } from '@/mixins/utils';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    isReadOnlyMessage: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasContextSelection: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasUserIdSelection: {
      type: Boolean,
      required: false,
      default: true,
    },
    validateFields: {
      type: Boolean,
      required: false,
      default: true,
    },
    userTags: {
      type: Array,
      required: false,
      default: () => [],
    },
    userIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    tagClassesToSkip: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tagSelectionsObj: {},
      userIdToTarget: '',
      userIdInputErrors: [],
    };
  },
  computed: {
    ...mapState({
      tags: st => st.message.tags,
    }),
    readOnlyTags() {
      return this.userTags?.map((tag) => {
        const splittedTag = tag.split(':');
        const tagClass = this.sentenceCase(splittedTag[0]);
        const tagId = this.sentenceCase(splittedTag[1]);

        return `${tagClass}: ${tagId}`;
      });
    },
    isTagSelected() {
      if (this.validateFields) {
        return this.userTags?.length > 0;
      }
      return true;
    },
    hasUserIds() {
      return this.userIds?.length > 0;
    },
    isUniqueUUID() {
      return !this.userIds?.includes(this.userIdToTarget);
    },
    getContextTag() {
      return this.tags?.classes.context?.[0] || {};
    },
    availableTagClasses() {
      const tagsCopy = { ...this.tags.classes };

      this.tagClassesToSkip.forEach((tagClass) => {
        delete tagsCopy[tagClass];
      });
      // delete tagsCopy.context;

      return tagsCopy;
    },
    isContextTagSelected() {
      return this.userTags.includes('context:scc');
    },
    tagSelections() {
      return this.tagSelectionsObj;
    },
    availableTagClassesLength() {
      return Object.keys(this.availableTagClasses)?.length;
    },
  },
  methods: {
    sentenceCase,
    async addUserIdToMessagePayload(userId) {
      if (this.validateFields) {
        const success = await this.$refs.userIdInput.$refs.userIdToTarget.validate();

        if (!success.valid) {
          this.$log.info('userIdInput !success', success);
          return;
        }
      }

      if (userId.length !== 0) {
        this.userIds.push(userId);
        this.userIdToTarget = '';
        this.$refs.messageTags.reset();
        this.$emit('user-ids', this.userIds);
      }
    },
    handleAvailableTagSelect(tag) {
      this.userTags.push(tag);
      this.$emit('user-tags', this.userTags);
    },
    spliceFromUserTags(id) {
      const idIdx = this.userTags.findIndex(t => t === id);
      if (idIdx >= 0) {
        this.userTags.splice(idIdx, 1);
        this.$emit('user-tags', this.userTags);
      }
    },
    handleContextTagCheckbox(event, tag) {
      if (event) {
        // Use a local const as original `userTags` is a prop sent by the parent
        const userTags = [];
        this.tagSelectionsObj = {};
        userTags.push(tag);
        this.$emit('user-tags', userTags);
      } else {
        const indexToRemove = this.userTags.findIndex(e => e === tag);
        this.userTags.splice(indexToRemove, 1);
      }
    },
    getMessageTags(tagClass) {
      return this.userTags
        .map(
          tag => this.availableTagClasses[tagClass].find(t => t.id === tag.split(':')[1]) || false,
        )
        .filter(t => t);
    },
    generateOptionsForSegments(source) {
      return source
        .slice()
        .sort((a, b) => a?.id?.localeCompare(b?.id))
        .map(el => [
          { value: Object.values(el)[0], text: this.sentenceCase(Object.values(el)[1]) },
        ])
        .flat();
    },
    getValue(target, key) {
      return target?.[key];
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
  },
};
</script>

<style>
</style>
