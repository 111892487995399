<template>
  <div>
    <cc-section-table :title="$t('pages.invite.title')">
      <cc-text
        class="pb-3 flex-grow max-w-2xl"
        color="text-gray-600"
        >
        {{ $t('pages.invite.body') }}
      </cc-text>

      <validation-observer ref="form">
        <form
          class="max-w-xs w-full mt-5"
          @submit.prevent="inviteHandler"
          >
          <cc-input
            :value="email"
            class="mb-4"
            name="email"
            type="email"
            :placeholder="$t('pages.login.emailAddressPlaceholder')"
            autocomplete="email"
            validation-rules="required|email"
            :validation-name="$t('pages.login.emailAddressPlaceholder')"
            @input="e => email = e.toLowerCase()"
            />

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            class="mt-4"
            />

          <cc-button
            :text="$t('pages.invite.inviteUser')"
            :block="false"
            :disabled="isSubmitting"
            :is-loading="isSubmitting"
            class="mt-5"
            @click.native="inviteHandler(email, language)"
            />
        </form>
      </validation-observer>
    </cc-section-table>

    <cc-feedback-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      :feedback-heading="feedbackMessage"
      :timeout-duration="3000"
      @close="isFeedbackOpen = false"
      />
  </div>
</template>

<script>
import AdminService from '@/services/AdminService';
import validator from '@/mixins/validator';


export default {
  mixins: [validator],
  data() {
    return {
      feedbackMessage: this.$t('modals.successfulInvite.title'),
      isFeedbackOpen: false,
      isSubmitting: false,
      email: '',
      language: 'en',
      errors: [],
    };
  },
  methods: {
    async inviteHandler(email, language) {
      this.$log.info('invite handler');

      try {
        this.errors = [];

        this.$refs.form.reset();
        const { isValid } = await this.$refs.form.validateWithInfo();

        if (!isValid) {
          this.$log.info('Form !isValid', isValid);
          return;
        }

        this.isSubmitting = true;
        const response = await AdminService.inviteTechnicalUser({ email, language });

        this.email = '';
        this.$refs.form.reset();

        this.$log.info('inviteTechnicalUser', response);

        setTimeout(() => {
          this.isFeedbackOpen = true;
        }, 300);
      } catch (error) {
        this.$log.info('inviteTechnicalUser', error);

        this.errors.push(this.$t('errors.genericError'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
