<template>
  <div>
    <cc-section-table :title="$t('pages.apiThrottling.title')">
      <cc-text
        class="pb-3 flex-grow max-w-2xl"
        color="text-gray-600"
        >
        {{ $t('pages.apiThrottling.body') }}
      </cc-text>

      <validation-observer ref="form">
        <form
          class="max-w-xs w-full mt-5"
          @submit.prevent="deleteThrottleHandler(throttleKey, throttleGlobalKey)"
          >
          <cc-input
            v-model="throttleKey"
            class="mb-4"
            name="key"
            :placeholder="$t('pages.apiThrottling.throttleKeyPlaceholder')"
            autocomplete="none"
            :validation-name="$t('pages.apiThrottling.throttleKeyPlaceholder')"
            />
          <cc-input
            v-model="throttleGlobalKey"
            class="mb-4"
            name="globalKey"
            :placeholder="$t('pages.apiThrottling.throttleGlobalKeyPlaceholder')"
            autocomplete="none"
            :validation-name="$t('pages.apiThrottling.throttleGlobalKeyPlaceholder')"
            />

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            class="mt-4"
            />

          <cc-button
            :text="$t('pages.apiThrottling.removeThrottle')"
            :block="false"
            :disabled="isSubmitting || (!throttleKey && !throttleGlobalKey)"
            :is-loading="isSubmitting"
            class="mt-5"
            type="submit"
            />
        </form>
      </validation-observer>
    </cc-section-table>

    <cc-feedback-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      :feedback-heading="feedbackMessage"
      :timeout-duration="3000"
      @close="isFeedbackOpen = false"
      />
  </div>
</template>

<script>
import AdminService from '@/services/AdminService';
import validator from '@/mixins/validator';


export default {
  mixins: [validator],
  data() {
    return {
      feedbackMessage: this.$t('modals.successfulThrottleRemoval.title'),
      isFeedbackOpen: false,
      isSubmitting: false,
      throttleKey: '',
      throttleGlobalKey: '',
      errors: [],
    };
  },
  methods: {
    async deleteThrottleHandler(throttleKey, throttleGlobalKey) {
      this.$log.info('delete throttle handler');

      const keys = [throttleKey, throttleGlobalKey].filter(key => key);

      try {
        this.errors = [];

        this.$refs.form.reset();
        const { isValid } = await this.$refs.form.validateWithInfo();

        if (!isValid) {
          return;
        }

        this.isSubmitting = true;

        const promises = [];

        keys.forEach((key) => {
          promises.push(AdminService.deleteThrottle({ key }));
        });

        const response = await Promise.all(promises);

        this.$log.info('response', response);

        this.throttleKey = null;
        this.throttleGlobalKey = null;

        this.$refs.form.reset();


        setTimeout(() => {
          this.isFeedbackOpen = true;
        }, 300);
      } catch (error) {
        this.$log.info('deleteThrottleHandler', error);

        this.errors.push(this.$t('errors.genericError'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
