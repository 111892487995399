<template>
  <button
    :id="id"
    :data-ruid="ruid"
    :class="getButtonClass"
    :type="type"
    :disabled="disabled"
    >
    <cc-svg-base
      v-if="svgAlign === 'left' && !isLoading"
      :svg="svg"
      :class="[getSvgClass, text ? 'mr-2' : null]"
      />

    <div class="absolute flex justify-center w-5 h-5">
      <cc-svg-base
        svg="icons/icon-loading"
        class="absolute h-5 w-5 btn-transition loading-svg"
        :class="getLoadingClass"
        />
    </div>

    <cc-text
      :class="[getTextHoverColor, isLoading ? 'opacity-0' : 'opacity-100']"
      class="btn-transition whitespace-nowrap"
      :variant="weight"
      :color="getTextColor"
      >
      {{ text }}
    </cc-text>

    <cc-svg-base
      v-if="svgAlign === 'right' && !isLoading"
      :svg="svg"
      :class="[getSvgClass, text ? 'ml-2' : null]"
      />
  </button>
</template>

<script>
import commonProps from '@/mixins/commonProps';

// Valid variants
const variantProps = [
  'primary', //
  'primary-light',
  'secondary',
  'green-light',
  'green-lighter',
  'yellow',
  'red',
  'light',
  'plain',
  'transparent',
];

const weightProps = [
  'body',
  'body-bold',
];

export default {
  mixins: [commonProps],
  props: {
    variant: {
      type: String,
      required: false,
      default: 'primary',
      validator: value => variantProps.includes(value),
    },
    weight: {
      type: String,
      default: 'body',
      required: false,
      validator: value => weightProps.includes(value),
    },
    text: {
      type: [String, Function, Array],
      default: null,
      required: false,
    },
    align: {
      type: String,
      default: null,
      required: false,
    },
    hasMinWidth: {
      type: Boolean,
      default: true,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: 'button',
      required: false,
    },
    svgClass: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    getSvgClass() {
      return ['fill-current', 'inline', this.svgColor, this.svgClass];
    },
    getButtonAlign() {
      switch (this.align) {
        case 'left':
          return 'justify-start';
        case 'right':
          return 'justify-end';
        default:
          return 'justify-center';
      }
    },
    getLoadingClass() {
      return [
        this.isLoading ? 'opacity-100 animate-spin' : 'opacity-0',
        this.variant === 'primary' ? 'text-white' : '',
        this.variant === 'secondary' ? 'text-gray-500' : '',
        this.variant === 'red' ? 'text-white' : '',
      ];
    },
    getTextHoverColor() {
      switch (this.variant) {
        case 'primary':
          return '';
        case 'secondary':
          return '';
        case 'red':
          return '';
        case 'light':
          return '';
        case 'plain':
          return '';
        case 'transparent':
          return '';
        default:
          break;
      }
      return null;
    },
    getTextColor() {
      switch (this.variant) {
        case 'primary':
        case 'primary-light':
        case 'green-light':
        case 'red':
          return 'text-white';
        case 'green-lighter':
          return 'text-primary-darkest';
        case 'secondary':
        case 'light':
        case 'yellow':
          return 'text-gray-800';
        case 'plain':
          return '';
        case 'transparent':
          return 'text-primary-light';
        default:
          break;
      }
      return null;
    },
    getButtonClass() {
      return [
        'btn',
        'group',
        'btn-transition',
        'outline-none focus:outline-none',
        this.getButtonAlign,
        this.text ? 'px-3' : null,
        // this.svg && !this.text ? 'btn-icon rounded-md' : null,
        // this.svg && this.text ? 'px-3' : null,
        // this.svg ? 'p-1' : null,
        this.block ? 'block w-full' : '',
        this.disabled ? 'disabled:opacity-50 cursor-not-allowed' : '',
        this.hasMinWidth ? 'min-w-xxxs' : '',
        this.variant === 'primary' ? 'btn-primary' : null,
        this.variant === 'primary-light' ? 'btn-primary-light' : null,
        this.variant === 'secondary' ? 'btn-secondary' : null,
        this.variant === 'green-light' ? 'btn-green-light' : null,
        this.variant === 'green-lighter' ? 'btn-green-lighter' : null,
        this.variant === 'yellow' ? 'btn-yellow' : null,
        this.variant === 'red' ? 'btn-red' : null,
        this.variant === 'light' ? 'btn-light' : null,
        this.variant === 'plain' ? '' : null,
        this.variant === 'transparent' ? 'text-primary-light' : null,
        this.size === 'medium' ? 'py-3 px-3' : null,
        this.size === 'small' ? 'py-2 px-2' : null,
      ];
    },
  },
};
</script>

<style lang="postcss" scoped>
.btn {
  @apply flex rounded-md items-center relative;
}

.btn-transition {
  @apply transition-all ease-in-out duration-300;
}

/* .btn-medium {
  @apply py-3 px-3;
}

.btn-small {
  @apply py-2 px-2;
} */

.btn-primary {
  @apply bg-primary-dark border border-primary-dark;
}

.btn-primary:hover {
  @apply bg-primary-darkest border-primary-darkest;
}

.btn-primary-light {
  @apply bg-primary-lighter border border-primary-lighter;
}

.btn-secondary {
  @apply bg-gray-200 border border-gray-200;
}

.btn-secondary:hover {
  @apply bg-gray-300 border-gray-300;
}

.btn-green-light {
  @apply bg-secondary-green-light border border-secondary-green-light;
}

.btn-green-lighter {
  @apply bg-secondary-green-lighter border border-secondary-green-lighter;
}

.btn-yellow {
  @apply bg-secondary-yellow border border-secondary-yellow;
}

.btn-red {
  @apply bg-secondary-red border border-secondary-red;
}

.btn-red:hover {
  @apply bg-secondary-red border-secondary-red opacity-80;
}

.btn-light {
  @apply bg-gray-100 border border-gray-100;
}

.btn-light:hover {
  @apply bg-gray-200 border-gray-200;
}

.btn:focus,
.btn-icon:focus {
  @apply outline-none;
}

.btn-icon {
  @apply rounded-md;
}
</style>
