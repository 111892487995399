<template>
  <div
    class="bg-white rounded-lg"
    :class="getTableClass"
    >
    <div
      v-if="title || actions.length"
      class="flex items-center justify-between pb-8"
      >
      <cc-heading
        v-if="title"
        :tag="headingTag"
        weight="semibold"
        color="text-gray-600"
        >
        {{ title }}
      </cc-heading>

      <div class="flex pl-4">
        <cc-input
          v-if="hasSearch"
          v-model="queryHandler"
          variant="slim"
          bg-color="bg-gray-100"
          placeholder="Search..."
          />

        <cc-button
          v-for="(action, i) in actions"
          :key="i"
          :variant="action.variant"
          size="small"
          class="ml-4"
          :has-min-width="false"
          :ruid="action.dataRuid"
          :block="false"
          :svg="`icons/${action.icon}`"
          :text="action.text"
          svg-align="left"
          :svg-color="action.iconColor"
          @click.native="$emit(action.event)"
          />
      </div>
    </div>

    <span
      v-if="isLoading"
      class="flex flex-grow items-center"
      >
      <cc-loading bg-color="bg-white" />
    </span>

    <slot v-else />
  </div>
</template>

<script>
// Valid variants
const variantProps = [
  'regular', //
  'slim',
];

export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: 'regular',
      validator: value => variantProps.includes(value),
    },
    text: {
      type: String,
      default: null,
      required: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
    headingTag: {
      type: String,
      required: false,
      default: 'h2',
    },
    actions: {
      type: Array,
      default: () => [],
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    queryHandler: {
      get() {
        return this.query;
      },
      set(val) {
        this.query = val;
        this.$emit('search-query', this.query);
      },
    },
    getTableClass() {
      switch (this.variant) {
        case 'regular':
          return 'p-8 border border-gray-200';
        case 'slim':
          return '';
        default:
          break;
      }
      return null;
    },
  },
};
</script>

<style>
</style>
