<template>
  <div>
    <cc-wallet-manager
      :is-loading="isLoadingDataTable"
      :wallets="wallets"
      :section-title="$t('pages.greenCoins.walletTitle')"
      :section-body="$t('pages.greenCoins.walletBody')"
      :has-sponsor-wallet="false"
      :has-balance-threshold="false"
      @fetch-wallets="fetchWalletsAndTransactions"
      />

    <cc-section-table>
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        :title="$t('pages.greenCoins.title')"
        :actions="dataTableActions"
        :has-search="true"
        @search-query="($event) => (query = $event.toLowerCase())"
        @export="exportHandler"
        />
    </cc-section-table>
  </div>
</template>

<script>
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase } from '@/mixins/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      msg: '',
      query: '',
      isLoadingDataTable: false,
      wallets: null,
      transactions: null,
      walletsMap: null,
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
      ],
      dataTableActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.from'),
          value: 'from',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.to'),
          value: 'to',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.transactionId'),
          value: 'transactionId',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.date'),
          value: 'createdAt',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.amount'),
          value: 'amount',
          isCSVField: true,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user.user,
    }),
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    storeWallet() {
      return this.wallets?.find(w => w.type === 'STORE');
    },
    dataItems() {
      return this.transactions
        ?.filter((transaction) => {
          if (this.query) {
            return (
              transaction?.id?.toLowerCase().includes(this.query)
              || this.walletsMap[transaction?.meta?.accountAddressSender]
                ?.toLowerCase()
                .includes(this.query)
              || this.getToName(transaction, this.walletsMap)?.toLowerCase().includes(this.query)
            );
          }
          return transaction;
        })
        ?.map((transaction, index) => ({
          index: index + 1,
          from: this.walletsMap[transaction?.meta?.accountAddressSender] || '–',
          to: this.getToName(transaction, this.walletsMap),
          transactionId: transaction?.id || '–',
          createdAt: new Date(transaction?.createdAt).toLocaleString('en-US') || '–',
          amount: this.getAmountWithDirection(transaction),
        }));
    },
  },
  async created() {
    await this.fetchWalletsAndTransactions();
  },
  methods: {
    getToName(transaction, walletsMap) {
      return transaction.transactionType === 'REDEEM'
        ? transaction?.description?.split(':')?.[1]?.trim()
        : walletsMap[transaction?.meta?.accountAddressRecipient] || '–';
    },

    getAmountWithDirection(transaction) {
      if (transaction?.value === 0) {
        return transaction?.value;
      }
      if (transaction?.type === 'CREDIT') {
        return `+${transaction?.value}`;
      }
      if (transaction?.type === 'DEBIT') {
        return `-${transaction?.value}`;
      }
      return '–';
    },
    async fetchWalletsAndTransactions() {
      try {
        this.isLoadingDataTable = true;
        this.wallets = await this.$store.dispatch(
          'company/getWallets',
          this.$route.params.companyId,
        );

        if (this.storeWallet?.id) {
          const response = await this.$store.dispatch(
            'company/getTransactions',
            this.storeWallet?.id,
          );
          this.transactions = response.extracts.reverse();
          this.walletsMap = response.walletsMap;
        }
      } catch (err) {
        this.$log.error('Error: fetchWalletsAndTransactions', err);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyName = toKebapCase(this.getCompanyName(this.$route.params.companyId));
        const filename = generateTimeStampedFilename(`scc_green-coins_${companyName}`);

        this.dataItems.forEach((item) => {
          itemsFormatted.push({
            ...item,
            createdAt: item.createdAt.replace(/,/g, ''),
          });
        });

        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>

<style></style>
