<template>
  <div>
    <cc-heading
      tag="h3"
      weight="semibold"
      class="flex-grow w-full"
      color="text-gray-600"
      :has-border="true"
      >
      {{ $t('pages.createChallenge.subscriptionFilters') }}
    </cc-heading>

    <cc-text
      class="py-3 flex-grow max-w-2xl pb-6"
      color="text-gray-600"
      >
      {{ $t('pages.createChallenge.subscriptionFiltersBody') }}
    </cc-text>

    <cc-filter-builder
      ref="filterBuilder"
      :options="options"
      :is-first="true"
      @rule-update="emitFilters($event)"
      />


    <div
      v-if="false"
      class="flex mt-4"
      >
      <cc-code
        title="Fetched Query"
        class="flex-1 mr-2"
        >
        {{ fetchedQuery }}
      </cc-code>

      <cc-code
        title="JSON Path"
        class="flex-1 ml-2"
        >
        {{ jsonPath }}
      </cc-code>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rawQuery: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      options: {
        keys: [
          {
            name: '@.tripDetails.beginCity.country',
            id: '@.tripDetails.beginCity.country',
          },
          {
            name: '@.tripDetails.beginCity.locality',
            id: '@.tripDetails.beginCity.locality',
          },
          {
            name: '@.tripDetails.beginCity.postCode',
            id: '@.tripDetails.beginCity.postCode',
          },
          {
            name: '@.tripDetails.endCity.country',
            id: '@.tripDetails.endCity.country',
          },
          {
            name: '@.tripDetails.endCity.locality',
            id: '@.tripDetails.endCity.locality',
          },
          {
            name: '@.tripDetails.endCity.postCode',
            id: '@.tripDetails.endCity.postCode',
          },
          {
            name: '@.tripDetails.transitMode',
            id: '@.tripDetails.transitMode',
          },
          {
            name: '@.tripDetails.purpose',
            id: '@.tripDetails.purpose',
          },
        ],
        operators: [
          {
            name: this.$t('configs.modeFunctions.BIGGER_THEN'),
            id: '>',
          },
          {
            name: this.$t('configs.modeFunctions.IS'),
            id: '==',
          },
          {
            name: this.$t('configs.modeFunctions.SMALLER_THEN'),
            id: '<',
          },
          {
            name: this.$t('configs.modeFunctions.IN'),
            id: 'in',
          },
          {
            name: this.$t('configs.modeFunctions.NIN'),
            id: 'nin',
          },
        ],
        operatorsByKey: {
          '@.tripDetails.beginCity.country': ['==', 'in', 'nin'],
          '@.tripDetails.beginCity.locality': ['==', 'in', 'nin'],
          '@.tripDetails.endCity.country': ['==', 'in', 'nin'],
          '@.tripDetails.endCity.locality': ['==', 'in', 'nin'],
          '@.tripDetails.transitMode': ['==', 'in', 'nin'],
          '@.tripDetails.purpose': ['==', 'in', 'nin'],
        },
      },
      fetchedQuery: '',
      // filledQuery: '',
      jsonPath: '',
    };
  },
  mounted() {
    if (this.rawQuery) {
      this.$log.info('Subscription Filters: rawQuery available, will fillFormStatus', this.rawQuery);
      this.$refs.filterBuilder.fillFormStatus(this.rawQuery);
    }
  },
  methods: {
    emitFilters() {
      this.fetchQuery();
      this.$log.warn('Subscription Filters: Fetched Query', this.fetchedQuery);
      this.$emit('fetched-json-path', this.jsonPath);
      this.$emit('fetched-query', this.fetchedQuery);
    },

    fetchQuery() {
      this.fetchedQuery = this.$refs.filterBuilder.queryFormStatus();
      this.jsonPath = this.$refs.filterBuilder.wrapAsJsonPath(this.fetchedQuery);
    },

    // fillQuery() {
    //   if (this.filledQuery) {
    //     this.$log.info('Will fill query');
    //     const query = JSON.parse(this.filledQuery);
    //     this.$refs.filterBuilder.fillFormStatus(query);
    //   } else {
    //     this.$log.info('Did not fill query');
    //   }
    // },
  },
};
</script>

<style>
</style>
