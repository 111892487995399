import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin`;

export default {
  async getDrafts() {
    return ApiService.get(`${resourceAdmin}/draft`);
  },

  async getDraft(draftId) {
    return ApiService.get(`${resourceAdmin}/draft/${draftId}`);
  },

  async deleteDraft(draftId) {
    return ApiService.delete(`${resourceAdmin}/draft/${draftId}`);
  },

  async saveDraft(payload) {
    return ApiService.securePost(`${resourceAdmin}/draft`, payload);
  },

  async updateDraft(payload) {
    return ApiService.put(`${resourceAdmin}/draft/${payload.draftId}`, payload);
  },
};
