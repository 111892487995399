<template>
  <div class="flex">
    <div
      v-if="false"
      class="fixed z-10 flex w-full justify-start"
      >
      <cc-text
        class="bg-yellow-500"
        variant="code-xs"
        >
        route: {{ $route.name }}
        {{ [...$route.meta.isNavigationFor, ...$route.meta.isSubNavigationFor] }}
      </cc-text>
    </div>

    <router-view
      name="sidebar"
      class="w-64 h-screen flex-shrink-0 hidden md:flex flex-col justify-between overflow-y-auto"
      />

    <div class="w-full h-screen flex flex-col overflow-hidden outline-none">
      <router-view name="navigation" />
      <transition
        name="view-fade"
        mode="out-in"
        >
        <router-view class="bg-gray-100 flex-grow p-6 overflow-auto" />
      </transition>
    </div>

    <cc-feedback-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      :feedback-body="feedbackBody"
      :feedback-heading="feedbackHeading"
      :timeout-duration="5000"
      icon="icon-cross-circle"
      @close="isFeedbackOpen = false"
      />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFeedbackOpen: false,
      feedbackBody: null,
      feedbackHeading: null,
    };
  },
  computed: {
    previousRoutePath() {
      return this.$route.params?.previousRoute?.path;
    },
  },
  watch: {
    previousRoutePath(val) {
      if (val) {
        this.isFeedbackOpen = true;
        this.feedbackHeading = this.$t('commons.unauthorized');
        this.feedbackBody = `${this.$t('commons.pageIsNotAccessible')}: "${this.previousRoutePath}"`;
      }
    },
  },
};
</script>

<style></style>
