import Vue from 'vue';
import store from '@/store/index';
import VueRouter from 'vue-router';
import i18n from '@/i18n';

import { hasAccessToken } from '@/services/SecurityService';

import LayoutDashboard from '@/components/constructs/LayoutDashboard.vue';
import Sidebar from '@/components/constructs/Sidebar.vue';
import Navigation from '@/components/constructs/Navigation.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import Challenges from '@/views/Challenges/Challenges.vue';
import Challenge from '@/views/Challenges/Challenge.vue';

import Companies from '@/views/Companies/Companies.vue';
import Purchases from '@/views/Companies/Purchases.vue';
import Products from '@/views/Companies/Products.vue';
import Product from '@/views/Companies/Product.vue';
import StoreInformation from '@/views/Companies/StoreInformation.vue';
import GreenCoins from '@/views/Companies/GreenCoins.vue';
import SponsorWallet from '@/views/Companies/SponsorWallet.vue';
import Users from '@/views/Companies/Users.vue';
import PendingUsers from '@/views/Companies/PendingUsers.vue';
import OnboardingCodes from '@/views/Companies/OnboardingCodes.vue';

import Roles from '@/views/AccessManagement/Roles.vue';
import Invite from '@/views/AccessManagement/Invite.vue';
import APIThrottling from '@/views/AccessManagement/APIThrottling.vue';

import Messages from '@/views/Messages/Messages.vue';
import Drafts from '@/views/Messages/Drafts.vue';
import Scheduled from '@/views/Messages/Scheduled.vue';
import Message from '@/views/Messages/Message.vue';
// import Settings from '@/views/Settings/Settings.vue';
// import Help from '@/views/Help/Help.vue';

import Home from '@/views/Home.vue';
import Profile from '@/views/Onboarding/Profile.vue';
import Login from '@/views/Onboarding/Login.vue';
import ForgotPassword from '@/views/Onboarding/ForgotPassword.vue';
import ResetPassword from '@/views/Onboarding/ResetPassword.vue';
import CreatePassword from '@/views/Onboarding/CreatePassword.vue';
import ChangePassword from '@/views/Onboarding/ChangePassword.vue';
import CompleteRegistration from '@/views/Onboarding/CompleteRegistration.vue';
import PhoneVerification from '@/views/Onboarding/PhoneVerification.vue';

import EmailCheck from '@/views/Emails/EmailCheck.vue';
import EmailSuccess from '@/views/Emails/EmailSuccess.vue';
import EmailError from '@/views/Emails/EmailError.vue';
import EmailExpired from '@/views/Emails/EmailExpired.vue';

import NotAuthorized from '@/views/NotAuthorized.vue';
import NotAuthorizedChild from '@/views/NotAuthorizedChild.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import RolesManager from '../views/AccessManagement/RolesManager.vue';

Vue.use(VueRouter);

const getNavigationProps = (route, title) => {
  const getUserActions = store.getters['user/getUserActions'];

  return {
    dynamicTitle: route.meta.isNavigationFor.some(entry => getUserActions?.includes(entry))
      ? title
      : store.getters['company/getCompanyName'](route.params.companyId),
  };
};

const getCompaniesIfNecessary = async (to, from, next) => {
  Vue.$log.info('getCompaniesIfNecessary');

  const userActions = store.getters['user/getUserActions'];

  const allowedActions = [
    'UI_NAV_COMPANIES',
    'UI_SUB_NAV_COMPANY_PRODUCTS',
    'UI_SUB_NAV_COMPANY_PURCHASES',
    'UI_SUB_NAV_COMPANY_COMPANY_INFORMATION',
    'UI_SUB_NAV_COMPANY_GREEN_COINS',
    'UI_SUB_NAV_COMPANY_SPONSOR_WALLET',
    'UI_SUB_NAV_COMPANY_USERS',
  ];
  const hasCompanies = store.state.company.companies.length;

  Vue.$log.warn('hasCompanies', hasCompanies);

  try {
    if (allowedActions.some(entry => userActions.includes(entry))) {
      // Vue.$log.warn('Possibly Admin-like user');
      if (!hasCompanies) {
        Vue.$log.info('Will getAllCompanies');
        await store.dispatch('company/getAllCompanies');
      }
    } else {
      // Vue.$log.warn('Possibly Merchant-like user');
      const userCompanies = store?.state?.user?.user?.companies.map(company => ({ company }));
      store.commit('company/setState', ['companies', userCompanies]);
    }

    return next();
  } catch (err) {
    Vue.$log.error('Error: beforeEnter', to.name, err);

    return next();
  }
};

const routes = [
  // Home
  {
    path: '/',
    name: 'home',
    component: Home,
    props: true,
    beforeEnter: (to, from, next) => {
      // We might need to use beforeRouteEnter and swallow the error or a try/catch here
      next({ name: 'dashboard' });
    },
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
      requiresAuth: true,
    },
  },

  // Dashboard
  {
    path: '/dashboard',
    component: LayoutDashboard,
    props: true,
    meta: {
      icon: 'icon-dashboard',
      isNavigationFor: ['UI_NAV_DASHBOARD'],
      isNavigationItem: true,
      isSubNavigationFor: [],
      navigationLabel: 'pages.dashboard.navigationLabel',
      navigationTitle: 'pages.dashboard.navigationTitle',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        components: {
          default: Dashboard,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_SUB_NAV_DASHBOARD'],
          requiresAuth: true,
        },
      },
      {
        path: 'not-authorized',
        name: 'dashboard-not-authorized',
        components: {
          default: NotAuthorizedChild,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNotAuthorizedRoute: true,
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_NAV_DASHBOARD'],
          requiresAuth: true,
        },
      },
    ],
  },

  // Profile
  {
    path: '/profile',
    component: LayoutDashboard,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
      navigationLabel: 'pages.profile.navigationLabel',
      navigationTitle: 'pages.profile.navigationTitle',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'profile',
        components: {
          default: Profile,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNavigationFor: [],
          isSubNavigationFor: [],
          requiresAuth: true,
        },
      },
      {
        path: 'change-password',
        name: 'change-password',
        components: {
          default: ChangePassword,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          backTo: 'profile',
          hasBackButtonFor: [
            'UI_NAV_DASHBOARD',
            'UI_NAV_CHALLENGES',
            'UI_NAV_COMPANIES',
            'UI_NAV_MESSAGES',
            'UI_NAV_ACCESS_MANAGEMENT',
            'UI_SUB_NAV_COMPANY_PRODUCTS',
            'UI_SUB_NAV_COMPANY_PURCHASES',
            'UI_SUB_NAV_COMPANY_COMPANY_INFORMATION',
            'UI_SUB_NAV_COMPANY_GREEN_COINS',
            'UI_SUB_NAV_COMPANY_SPONSOR_WALLET',
            'UI_SUB_NAV_COMPANY_USERS',
            'UI_NAV_COMPANY_COMPANY_INFORMATION',
            'UI_NAV_COMPANY_GREEN_COINS',
            'UI_NAV_COMPANY_PRODUCTS',
            'UI_NAV_COMPANY_PURCHASES',
            'UI_NAV_COMPANY_SPONSOR_WALLET',
            'UI_NAV_COMPANY_USERS',
          ],
          isNavigationFor: [],
          isSubNavigationFor: [],
          navigationTitle: 'pages.changePassword.navigationTitle',
          requiresAuth: true,
        },
      },
    ],
  },

  // Challenges
  {
    path: '/challenges',
    component: LayoutDashboard,
    meta: {
      icon: 'icon-challenges',
      isNavigationFor: ['UI_NAV_CHALLENGES'],
      isNavigationItem: true,
      isSubNavigationFor: [],
      navigationLabel: 'pages.challenges.navigationLabel',
      navigationTitle: 'pages.challenges.navigationTitle',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'challenges',
        components: {
          default: Challenges,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_CHALLENGES'],
          navigationLabel: 'pages.challenges.navigationLabel',
          navigationTitle: 'pages.challenges.navigationTitle',
          requiresAuth: true,
        },
      },
      {
        path: 'not-authorized',
        name: 'challenges-not-authorized',
        components: {
          default: NotAuthorizedChild,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNotAuthorizedRoute: true,
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_NAV_CHALLENGES'],
          requiresAuth: true,
        },
      },
      {
        path: ':type?/:challengeId?',
        name: 'challenge',
        beforeEnter: async (to, from, next) => {
          Vue.$log.info('challenge: params', to.params);
          try {
            /**
             * Some refinement is necessary here.
             * We call very similar endpoints like `getAllWithTransactionCount`
             * `getWithWallets` `getWithActiveOffers`
             * We could reduce the dependency to 1
             */
            const hasCompanies = store.state.company.companies.length;
            await store.dispatch('message/getTags');

            if (!hasCompanies) {
              await store.dispatch('company/getAllCompanies');
            }

            if (to.params.challengeId) {
              const challenge = await store.dispatch(
                'challenge/getChallengeById',
                to.params.challengeId,
              );

              // Vue.$log.info('beforeEnter: challenge', challenge);

              // eslint-disable-next-line no-param-reassign
              to.params.challenge = challenge;
            }
            return next();
          } catch (err) {
            Vue.$log.error('Error: will proceed to challenges', err);

            return next({ name: 'challenges' });
          }
        },
        props: {
          default: false,
          navigation: route => ({
            dynamicTitle: route.params.challengeId
              ? route.params.challenge?.achievement?.resources?.en?.title
              || route.params.challenge?.achievement.name
              : i18n.t('pages.createChallenge.navigationTitle'),
          }),
        },
        components: {
          default: Challenge,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          backTo: 'challenges',
          hasBackButtonFor: ['UI_CHALLENGES_EDITOR'],
          isNavigationFor: [],
          isNavigationItem: false,
          isSubNavigationFor: ['UI_CHALLENGES_EDITOR'],
          navigationTitle: 'pages.createChallenge.navigationTitle',
          requiresAuth: true,
        },
      },
    ],
  },

  // Company or Companies
  {
    path: '/companies',
    component: LayoutDashboard,
    meta: {
      backTo: 'companies-overview',
      icon: 'icon-company-information',
      isNavigationFor: ['UI_NAV_COMPANIES'],
      isNavigationItem: true,
      isSubNavigationFor: [],
      navigationLabel: 'pages.companies.navigationLabel',
      navigationTitle: 'pages.companies.navigationTitle',
      requiresAuth: true,
    },
    beforeEnter: async (to, from, next) => getCompaniesIfNecessary(to, from, next),
    children: [
      {
        path: '',
        name: 'companies-overview',
        components: {
          default: Companies,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANIES'],
          requiresAuth: true,
          weight: 1,
        },
      },
      {
        path: ':companyId/purchases',
        name: 'purchases',
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.purchases.navigationLabel')),
        },
        components: {
          default: Purchases,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_PURCHASES'],
          hasSubNavigation: true,
          icon: 'icon-purchases',
          isNavigationFor: ['UI_NAV_COMPANY_PURCHASES'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_PURCHASES'],
          navigationLabel: 'pages.purchases.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/products',
        name: 'products',
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.products.navigationLabel')),
        },
        components: {
          default: Products,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_PRODUCTS'],
          hasSubNavigation: true,
          icon: 'icon-products',
          isNavigationFor: ['UI_NAV_COMPANY_PRODUCTS'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_PRODUCTS'],
          navigationLabel: 'pages.products.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/company-information',
        name: 'company-information',
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.companyInformation.navigationLabel')),
        },
        components: {
          default: StoreInformation,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_COMPANY_INFORMATION'],
          hasSubNavigation: true,
          icon: 'icon-company-information',
          isNavigationFor: ['UI_NAV_COMPANY_COMPANY_INFORMATION'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_COMPANY_INFORMATION'],
          navigationLabel: 'pages.companyInformation.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/green-coins',
        name: 'green-coins',
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.greenCoins.navigationLabel')),
        },
        components: {
          default: GreenCoins,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_GREEN_COINS'],
          hasSubNavigation: true,
          icon: 'icon-green-coins',
          isNavigationFor: ['UI_NAV_COMPANY_GREEN_COINS'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_GREEN_COINS'],
          navigationLabel: 'pages.greenCoins.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/sponsor-wallet',
        name: 'sponsor-wallet',
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.sponsorWallet.navigationLabel')),
        },
        components: {
          default: SponsorWallet,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_SPONSOR_WALLET'],
          hasSubNavigation: true,
          icon: 'icon-sponsor-wallet',
          isNavigationFor: ['UI_NAV_COMPANY_SPONSOR_WALLET'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_SPONSOR_WALLET'],
          navigationLabel: 'pages.sponsorWallet.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/users',
        name: 'users',
        props: {
          default: () => ({
            purpose: 'company',
          }),
          navigation: route => getNavigationProps(route, i18n.t('pages.users.navigationLabel')),
        },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will proceed', error);

            /**
             * @todo
             * Think of something to make these admin-like vs merchant-like api requests nicer
             * Move into component or do not reroute and allow routing to complete?
             */
            return next();
          }
        },
        components: {
          default: Users,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_USERS'],
          hasSubNavigation: true,
          icon: 'icon-user',
          isNavigationFor: ['UI_NAV_COMPANY_USERS'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_USERS'],
          navigationLabel: 'pages.users.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/pending-users',
        name: 'pendingUsers',
        props: {
          default: () => ({
            purpose: 'company',
          }),
          navigation: route => getNavigationProps(route, i18n.t('pages.pendingUsers.navigationLabel')),
        },
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will proceed', error);

            /**
             * @todo
             * Think of something to make these admin-like vs merchant-like api requests nicer
             * Move into component or do not reroute and allow routing to complete?
             */
            return next();
          }
        },
        components: {
          default: PendingUsers,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_USERS'],
          hasSubNavigation: true,
          icon: 'icon-user',
          isNavigationFor: ['UI_NAV_COMPANY_USERS'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_USERS'],
          navigationLabel: 'pages.pendingUsers.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/onboarding-codes',
        name: 'onboarding-codes',
        components: {
          default: OnboardingCodes,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        props: {
          default: false,
          navigation: route => getNavigationProps(route, i18n.t('pages.onboardingCodes.navigationLabel')),
        },
        meta: {
          hasBackButtonFor: ['UI_SUB_NAV_COMPANY_ONBOARDING_CODES'],
          hasSubNavigation: true,
          icon: 'icon-coupon',
          isNavigationFor: ['UI_NAV_COMPANY_ONBOARDING_CODES'],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_COMPANY_ONBOARDING_CODES'],
          navigationLabel: 'pages.onboardingCodes.navigationLabel',
          requiresAuth: true,
          weight: 2,
        },
      },
      {
        path: ':companyId/products/:type/:offerId?',
        name: 'product',
        beforeEnter: async (to, from, next) => {
          Vue.$log.info('Product: params', to.params);
          try {
            if (to.params.offerId) {
              const offer = await store.dispatch('company/getOffer', {
                companyId: to.params.companyId,
                offerId: to.params.offerId,
              });

              let voucherInventory = null;

              if (offer.virtualGood.type === 'INVENTORY_VOUCHER') {
                voucherInventory = await store.dispatch('company/getVouchers', {
                  virtualGoodId: offer.virtualGood.id,
                  csvFormat: false,
                });
              }

              // Vue.$log.info('beforeEnter: product', offer);
              // Vue.$log.info('beforeEnter: voucherInventory', voucherInventory);

              // eslint-disable-next-line no-param-reassign
              to.params.offer = offer;
              // eslint-disable-next-line no-param-reassign
              to.params.voucherInventory = voucherInventory;
            }
            return next();
          } catch (err) {
            Vue.$log.error('Error: will proceed to products', err);

            return next({ name: 'products' });
          }
        },
        props: {
          default: false,
          navigation: route => ({
            dynamicTitle: route.params.offerId
              ? route.params.offer?.virtualGood?.resources?.en?.title
              || route.params.offer?.virtualGood?.name
              : i18n.t('pages.product.navigationTitle'),
          }),
        },
        components: {
          default: Product,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          backTo: 'products',
          hasBackButtonFor: ['UI_COMPANY_EDITOR'],
          hasSubNavigation: false,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_COMPANY_EDITOR'],
          navigationTitle: 'pages.product.navigationTitle',
          requiresAuth: true,
          title: 'pages.product.title',
          weight: 0,
        },
      },
      {
        path: 'not-authorized',
        name: 'companies-not-authorized',
        components: {
          default: NotAuthorizedChild,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNotAuthorizedRoute: true,
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_NAV_COMPANIES'],
          requiresAuth: true,
          weight: 0,
        },
      },
    ],
  },

  // Messages
  {
    path: '/messages',
    component: LayoutDashboard,
    beforeEnter: (to, from, next) => {
      if (!store.state.common.featureFlags.messages) {
        return next({ name: 'dashboard' });
      }
      return next();
    },
    meta: {
      icon: 'icon-messages',
      isNavigationFor: ['UI_NAV_MESSAGES'],
      isNavigationItem: store.state.common.featureFlags.messages,
      isSubNavigationFor: [],
      navigationLabel: 'pages.messages.navigationLabel',
      navigationTitle: 'pages.messages.navigationTitle',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'messages',
        beforeEnter: async (to, from, next) => {
          try {
            /**
             * @todo
             * Think of something to make these admin-like vs merchant-like api requests nicer
             * Move into component or do not reroute and allow routing to complete?
             */
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will re-route', error);

            return next();
          }
        },
        components: {
          default: Messages,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_MESSAGES'],
          navigationLabel: 'pages.messages.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'drafts',
        name: 'drafts',
        components: {
          default: Drafts,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_DRAFTS'],
          navigationLabel: 'pages.messageDrafts.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'scheduled',
        name: 'scheduled',
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will re-route', error);

            return next({ name: 'dashboard' });
          }
        },
        components: {
          default: Scheduled,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_SCHEDULED'],
          navigationLabel: 'pages.messageScheduled.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'not-authorized',
        name: 'messages-not-authorized',
        components: {
          default: NotAuthorizedChild,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNotAuthorizedRoute: true,
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_NAV_MESSAGES'],
          requiresAuth: true,
        },
      },
      {
        path: ':type?/:messageId?',
        name: 'message',
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');

            let backTo;

            switch (to.params.type) {
              case 'draft-message':
                backTo = 'drafts';
                break;
              case 'scheduled-message':
                backTo = 'scheduled';
                break;
              default:
                backTo = 'messages';
                break;
            }

            // eslint-disable-next-line no-param-reassign
            to.meta.backTo = backTo;

            if (to.params.messageId) {
              const message = await store.dispatch(
                'message/getBroadcastMessageById',
                to.params.messageId,
              );

              // Vue.$log.info('beforeEnter: message', message);

              // eslint-disable-next-line no-param-reassign
              to.params.message = message;
            }
            return next();
          } catch (error) {
            Vue.$log.error('Error: will proceed to messages', error);

            return next({ name: 'messages' });
          }
        },
        props: {
          default: false,
          navigation: (route) => {
            if (route.params.type === 'draft-message') {
              return { dynamicTitle: i18n.t('pages.createMessage.navigationTitleDraftMessage') };
            }

            if (route.params.messageId) {
              return { dynamicTitle: route.params.message?.name };
            }

            return { dynamicTitle: i18n.t('pages.createMessage.navigationTitle') };
          },
        },
        components: {
          default: Message,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          backTo: 'messages',
          hasBackButtonFor: ['UI_MESSAGES_EDITOR'],
          isNavigationFor: [],
          isNavigationItem: false,
          isSubNavigationFor: ['UI_MESSAGES_EDITOR'],
          navigationTitle: 'pages.createMessage.navigationTitle',
          requiresAuth: true,
        },
      },
    ],
  },

  // Access Mangement
  {
    path: '/access-management',
    component: LayoutDashboard,
    beforeEnter: (to, from, next) => {
      if (!store.state.common.featureFlags.roles) {
        return next({ name: 'dashboard' });
      }
      return next();
    },
    meta: {
      icon: 'icon-access-management',
      isNavigationFor: ['UI_NAV_ACCESS_MANAGEMENT'],
      isNavigationItem: store.state.common.featureFlags.roles,
      isSubNavigationFor: [],
      navigationLabel: 'pages.accessManagement.navigationLabel',
      navigationTitle: 'pages.accessManagement.navigationTitle',
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'user-roles',
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will re-route', error);

            return next();
            // return next({ name: 'home' });
          }
        },
        components: {
          default: Users,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_ACCESS_MANAGEMENT_USERS'],
          navigationLabel: 'pages.users.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'roles',
        name: 'roles',
        beforeEnter: async (to, from, next) => {
          try {
            await store.dispatch('message/getTags');
            return next();
          } catch (error) {
            Vue.$log.error('Error: Will re-route', error);

            return next();
            // return next({ name: 'home' });
          }
        },
        components: {
          default: Roles,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_ACCESS_MANAGEMENT_ROLES'],
          navigationLabel: 'pages.roles.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'roles/:type?/:roleName?',
        name: 'roles-manager',
        beforeEnter: async (to, from, next) => {
          Vue.$log.info('role-manager: params', to.params);

          try {
            if (to.params.roleToUpdate) {
              // eslint-disable-next-line no-param-reassign
              to.params.role = to.params.roleToUpdate;
            }

            if (to.params.roleName && !to.params.roleToUpdate) {
              const role = await store.dispatch(
                'role/getRoleById',
                to.params.roleName,
              );

              Vue.$log.info('beforeEnter: role', role);

              // eslint-disable-next-line no-param-reassign
              to.params.role = role;
            }

            return next();
          } catch (err) {
            Vue.$log.error('Error: will proceed to roles', err);

            return next({ name: 'roles' });
          }
        },
        props: {
          default: false,
        },
        components: {
          default: RolesManager,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          backTo: 'roles',
          hasBackButtonFor: ['UI_NAV_ACCESS_MANAGEMENT'],
          hasSubNavigation: false,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_SUB_NAV_ACCESS_MANAGEMENT_ROLES'],
          navigationTitle: 'pages.rolesAndActionsManager.navigationTitle',
          requiresAuth: true,
          title: 'pages.product.title',
          weight: 0,
        },
      },
      {
        path: 'invite',
        name: 'invite',
        components: {
          default: Invite,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_ACCESS_MANAGEMENT_INVITE'],
          navigationLabel: 'pages.invite.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'api-throttling',
        name: 'api-throttling',
        components: {
          default: APIThrottling,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          hasSubNavigation: true,
          isNavigationFor: [],
          isNavigationItem: true,
          isSubNavigationFor: ['UI_SUB_NAV_ACCESS_MANAGEMENT_API_THROTTLING'],
          navigationLabel: 'pages.apiThrottling.navigationLabel',
          requiresAuth: true,
        },
      },
      {
        path: 'not-authorized',
        name: 'access-management-not-authorized',
        components: {
          default: NotAuthorizedChild,
          sidebar: Sidebar,
          navigation: Navigation,
        },
        meta: {
          isNotAuthorizedRoute: true,
          isNavigationItem: true,
          isNavigationFor: [],
          isSubNavigationFor: ['UI_NAV_ACCESS_MANAGEMENT'],
          requiresAuth: true,
        },
      },
    ],
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      anonymousOnly: true,
      isNavigationFor: [],
      isSubNavigationFor: [],
      title: 'pages.login.title',
    },
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      anonymousOnly: true,
      isNavigationFor: [],
      isSubNavigationFor: [],
      title: 'pages.forgotPassword.title',
    },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      anonymousOnly: true,
      isNavigationFor: [],
      isSubNavigationFor: [],
      title: 'pages.resetPassword.title',
    },
  },

  {
    path: '/create-password',
    name: 'create-password',
    component: CreatePassword,
    meta: {
      isMerchantOnboarding: true,
      isNavigationFor: ['UI_COMPANY_USER_ONBOARD'],
      isSubNavigationFor: [],
      requiresAuth: true,
    },
  },

  {
    path: '/complete-registration',
    name: 'complete-registration',
    component: CompleteRegistration,
    meta: {
      isMerchantOnboarding: true,
      isNavigationFor: ['UI_COMPANY_USER_ONBOARD'],
      isSubNavigationFor: [],
      requiresAuth: true,
    },
  },

  {
    path: '/phone-verification',
    name: 'phone-verification',
    component: PhoneVerification,
    meta: {
      isMerchantOnboarding: true,
      isNavigationFor: ['UI_COMPANY_USER_ONBOARD'],
      isSubNavigationFor: [],
      requiresAuth: true,
    },
  },

  {
    path: '/email-check',
    name: 'email-check',
    component: EmailCheck,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '/email-success',
    name: 'email-success',
    component: EmailSuccess,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '/email-expired',
    name: 'email-expired',
    component: EmailExpired,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '/email-error',
    name: 'email-error',
    component: EmailError,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: NotAuthorized,
    meta: {
      isNavigationFor: [],
      isNavigationItem: true,
      isNotAuthorizedRoute: true,
      isSubNavigationFor: [],
    },
  },

  {
    path: '/page-not-found',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '/.well-known/change-password',
    redirect: '/profile/change-password',
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },

  {
    path: '*',
    redirect: '/page-not-found',
    meta: {
      isNavigationFor: [],
      isSubNavigationFor: [],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  Vue.$log.info('Router: beforeEach', to);

  /**
   * Authenticate user if sessionId or jwt available
   * sessionId name is intentionally obscure and expects a jwt or base64(jwt)
   *
   */
  if ((to.query.sessionId || hasAccessToken()) && !store.state.user.isAuthenticated) {
    // Vue.$log.info('0');
    try {
      await store.dispatch('user/getUser', to.query.sessionId || sessionStorage.accessToken);
    } catch (err) {
      Vue.$log.error('Error: Not authorized');
    }
  }

  const [lastMatchedRoute] = to.matched.slice(-1);

  const { requiresAuth } = lastMatchedRoute.meta;
  const { anonymousOnly } = to.meta;

  // Vue.$log.info('lastMatchedRoute', lastMatchedRoute, lastMatchedRoute.meta.isNavigationFor);
  // Vue.$log.info('requiresAuth', requiresAuth, to.name);

  // Prepare all required actions for the route we are navigating to
  const requiredActions = [
    ...lastMatchedRoute.meta.isNavigationFor,
    ...lastMatchedRoute.meta.isSubNavigationFor,
  ].filter(action => action);

  // Vue.$log.warn(`Route:${lastMatchedRoute.name} requiredActions`, requiredActions);
  // Vue.$log.info('hasAccessToken', hasAccessToken());

  if (requiresAuth) {
    const userActions = store.getters['user/getUserActions'];

    // Check if user has required actions to access the route
    const isAuthorized = requiredActions?.length
      ? requiredActions?.some(entry => userActions?.includes(entry))
      : true;

    // Vue.$log.info('requiredActions', requiredActions);

    if (!store.state.user.isAuthenticated) {
      Vue.$log.warn('User !isAuthenticated');
      await store.dispatch('user/logout');

      return next({ name: 'login' });
    }

    if (!isAuthorized) {
      Vue.$log.warn('User !isAuthorized');

      const nextRoute = store.getters['user/fallbackRouteResolver'](
        router.options.routes,
        to,
        from,
        next,
      );

      Vue.$log.warn('BeforeEach: Next route for !isAuthorized attempt:', nextRoute);

      return next(nextRoute);
    }

    /**
     * Check if user has accepted both terms and conditions and has set account password
     */
    const isAgreementsAccepted = !!store.state?.user?.user?.metaPublic?.SCAgreementAcceptedAt
      && !!store.state?.user?.user?.metaPublic?.PFAgreementAcceptedAt;
    const requireNewPassword = store.state?.user?.user?.requireNewPassword;

    // Vue.$log.info('requireNewPassword', requireNewPassword);
    // Vue.$log.info('isAgreementsAccepted', isAgreementsAccepted);

    if (
      userActions?.includes('UI_COMPANY_USER_ONBOARD')
      && (requireNewPassword || !isAgreementsAccepted)
    ) {
      // Vue.$log.info('Either password is required or agreements not accepted');
      if (to.meta.isMerchantOnboarding) {
        return next();
      }
      // Vue.$log.info('Will route to complete-registration');
      return next({ name: 'complete-registration' });
    }

    /**
     * Prevent authenticated users from accessing merchant onboarding routes
     */
    if (to.meta.isMerchantOnboarding) {
      return next({ name: 'dashboard' });
    }

    // Vue.$log.warn('router.currentRoute', router.options.routes);

    // Vue.$log.info('Authenticated, proceed next()');
    return next();
  }

  if (anonymousOnly && store.state.user.isAuthenticated) {
    // Vue.$log.info('2');
    return next({ name: 'dashboard' });
  }

  // Vue.$log.info('last return');
  return next();
});

export default router;
