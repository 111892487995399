<template>
  <div class="flex flex-shrink-0 justify-center items-center">
    <!-- <div class="pr-6">
      <cc-svg-base
        svg="icons/icon-search"
        class="fill-current mr-4 text-gray-800"
        />
    </div> -->

    <div class="pr-4 flex flex-col justify-center">
      <cc-text
        variant="body"
        class="leading-none"
        >
        {{ userProfileName || '–' }}
      </cc-text>

      <cc-text
        v-if="getUserTitle"
        variant="body-light"
        color="text-gray-400"
        class="leading-none pt-1"
        >
        {{ getUserTitle }}
      </cc-text>
    </div>

    <div
      class="bg-primary-dark w-11 h-11 rounded-full flex
      justify-center items-center cursor-pointer select-none"
      @click.stop="toggleDropdown"
      >
      <cc-dropdown-linked
        :items="dropdownMenuItems"
        :is-open="isDropdownOpen"
        dropdown-class="right-0 -mr-8 top-8 origin-top-right min-w-32 py-2"
        @profile="profile"
        @logout="logout"
        @close="isDropdownOpen = false"
        />

      <cc-text
        color="text-white"
        variant="body-light"
        >
        ÖA
      </cc-text>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState({
      user: st => st.user.user,
    }),
    getUserTitle() {
      if (this.user?.isContextAdmin) {
        return this.$t('commons.admin');
      }

      if (this.user?.companies?.length) {
        return this.user.companies?.[0]?.resources?.en?.name || this.user.companies?.[0]?.name;
      }
      return null;
    },
    userProfileName() {
      return this.user?.name;
    },
    dropdownMenuItems() {
      return [
        {
          label: this.$t('pages.profile.navigationLabel'),
          event: 'profile',
        },
        {
          label: this.$t('commons.signOut'),
          event: 'logout',
        },
      ];
    },
  },
  methods: {
    toggleDropdown() {
      // this.$log.info('Toggle dropdown');
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    profile() {
      // this.$log.info('Dropdown: profile');

      this.isDropdownOpen = false;
      this.$router.push({ name: 'profile' }).catch(() => {});
    },
    async logout() {
      // this.$log.info('Dropdown: logout');

      this.isDropdownOpen = false;
      await this.$store.dispatch('user/logout');
      this.$router.push({ name: 'login' }).catch(() => {});
    },
  },
};
</script>

<style>
</style>
