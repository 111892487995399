import Vue from 'vue';
import MessageService from '@/services/MessageService';
import TagService from '@/services/TagService';
import i18n from '@/i18n';
import * as utils from '@/mixins/utils';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
  tags: [],
});

const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};

const actions = {
  async getTags({ commit }) {
    try {
      const response = await TagService.getTags();
      commit('setState', ['tags', response]);

      // Vue.$log.info('Response: Vuex: getTags', response);
      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getTags', err);
      throw err;
    }
  },

  async getAllBroadcastMessages() {
    try {
      const response = await MessageService.getAllBroadcastMessages();

      // Vue.$log.info('Response: Vuex: getAllBroadcastMessages', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getAllBroadcastMessages', err);
      throw err;
    }
  },

  async getBroadcastMessageById(ctx, broadcastId) {
    try {
      const response = await MessageService.getBroadcastMessageById(broadcastId);

      // Vue.$log.info('Response: Vuex: getBroadcastMessageById', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getBroadcastMessageById', err);
      throw err;
    }
  },

  async broadcastMessage(context, message) {
    try {
      const response = await MessageService.broadcastMessage(message);

      Vue.$log.info('Response: Vuex: broadcastMessage', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: broadcastMessage', err);
      throw err;
    }
  },

  async scheduleBroadcastMessage(context, message) {
    try {
      const response = await MessageService.scheduleBroadcastMessage(message);

      Vue.$log.info('Response: Vuex: scheduleBroadcastMessage', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: scheduleBroadcastMessage', err);
      throw err;
    }
  },

  async updateScheduledMessage(context, message) {
    try {
      const response = await MessageService.updateScheduledMessage(message);

      Vue.$log.info('Response: Vuex: updateScheduledMessage', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateScheduledMessage', err);
      throw err;
    }
  },

  async deleteScheduledMessage(context, messageId) {
    try {
      const response = await MessageService.deleteScheduledMessage(messageId);

      Vue.$log.info('Response: Vuex: deleteScheduledMessage', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteScheduledMessage', err);
      throw err;
    }
  },

  async cloneMessage(ctx, { message, messages }) {
    /**
     * Find the original object because the received `message` object parameter is a modified
     * version for data-table rendering purposes.
     */
    const p = messages.slice().find(m => m.id === message.id);

    const payload = { ...p };

    delete payload?.id;
    delete payload?.createdAt;
    delete payload?.context;
    delete payload?.status;

    const flatTags = [];
    let removedTags = [];

    // Remove empty keys in payload as backend returns them even if they werent used previously
    Object.keys(payload.payload).forEach(
      el => payload.payload[el] === null && delete payload.payload[el],
    );

    // Get a list of tags flattened from obj to arr
    Object.entries(ctx.state.tags.classes).forEach(([tagClass, tags]) => {
      tags.map(el => flatTags.push(`${tagClass}:${el.id}`));
    });

    // Save tags that are no longer available to be used in Message screen dialog
    removedTags = payload.userTags.filter(tag => !flatTags.includes(tag));

    // Remove tags that are no longer available
    payload.userTags = payload.userTags.filter(tag => flatTags.includes(tag));

    return {
      payload,
      removedTags,
    };
  },
};

const getters = {
  getTagsForDataTable: () => (message) => {
    if (message?.userTags?.length) {
      const tagCounts = {};

      message.userTags
        .map(el => utils.sentenceCase(el.split(':')[0]))
        .forEach((tag) => {
          tagCounts[tag] = (tagCounts[tag] || 0) + 1;
        });

      return Object.keys(tagCounts)
        .map(tag => `${tag} (${tagCounts[tag]})`)
        .join(' / ');
    }

    if (message?.userIds?.length) {
      return `${i18n.t('commons.fieldNames.userId')} (${message.userIds.length})`;
    }

    return '–';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
