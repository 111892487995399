.<template>
  <div
    class="my-8 border-b flex-grow"
    :class="borderColor"
    />
</template>

<script>
export default {
  props: {
    borderColor: {
      type: String,
      required: false,
      default: 'border-gray-300',
    },
  },
};
</script>
