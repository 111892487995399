<template>
  <a
    :href="href"
    :target="target"
    >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'AnchorBase',
  props: {
    href: {
      type: String,
      default: null,
      required: true,
    },
    target: {
      type: String,
      default: '_blank',
      required: false,
    },
  },
};
</script>
