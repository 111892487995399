<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col justify-center items-center p-4">
    <div class="card-blue max-w-sm w-full">
      <div class="pt-4 pb-8 flex justify-center">
        <cc-svg-base
          svg="illustration/email-error"
          />
      </div>

      <cc-heading
        tag="h1"
        variant="h2"
        class="font-semibold pb-2"
        color="text-white"
        align="center"
        >
        {{ $t('pages.email.expired.title') }}
      </cc-heading>
      <cc-text
        align="center"
        color="text-white"
        >
        {{ $t('pages.email.expired.body') }}
      </cc-text>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 'email-expired',
      type: null,
    };
  },
  created() {
    // this.type = this.$route?.query?.type || null;
  },
};
</script>

<style>

</style>
