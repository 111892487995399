<template>
  <div>
    <cc-heading
      tag="h3"
      weight="semibold"
      class="flex-grow w-full"
      color="text-gray-600"
      :has-border="true"
      >
      {{ heading }}
    </cc-heading>

    <div
      v-if="true"
      class="flex flex-col overflow-auto pb-8 mb-12"
      >
      <div
        v-if="!previewOnly"
        class="flex"
        >
        <div
          v-for="lang in supportedLangs"
          :key="lang"
          class="flex-shrink-0 w-96 mr-8 bg-gray-100 rounded-lg p-4"
          >
          <cc-heading
            tag="h5"
            class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
            >
            {{ $t(`commons.langs.${lang}`) }}
          </cc-heading>

          <div class="grid gap-6 grid-cols-1">
            <div
              v-if="image"
              class="flex justify-center"
              >
              <cc-image
                :has-placeholder="true"
                :data-ruid="`create-message__${messageType}__image-${lang}`"
                class="h-24 w-24 rounded-full"
                :src="getResourceValue(messageResources, 'imageUrl', lang)"
                />
            </div>

            <cc-select
              v-if="image"
              id="message-image"
              :label="$t('pages.createMessage.imageSelection')"
              :ruid="`create-message__${messageType}__image-url-${lang}`"
              :validation-id="`${messageType}-${lang}${$t('pages.createMessage.imageSelection')}`"
              :validation-rules="validationRules(lang)"
              :options="defaultImages"
              :disabled="disabled"
              :value="getResourceValue(messageResources, 'imageUrl', lang)"
              @input="setResourceValue(messageResources, 'imageUrl', $event, lang)"
              @change="setResourceValue(messageResources, 'imageUrl', $event, lang)"
              />

            <cc-input
              v-if="title"
              :label="$t('pages.createMessage.messageTitle')"
              :placeholder="$t('pages.createMessage.messageTitlePlaceholder')"
              :ruid="`create-message__${messageType}__title-${lang}`"
              :validation-id="`${messageType}-${lang}${$t('pages.createMessage.messageTitle')}`"
              :validation-rules="validationRules(lang)"
              :disabled="disabled"
              :value="getResourceValue(messageResources, 'title', lang)"
              @input="setResourceValue(messageResources, 'title', $event, lang)"
              />

            <cc-input
              v-if="bodyUrl"
              :label="$t('pages.createMessage.bodyUrl')"
              :placeholder="$t('pages.createMessage.bodyUrlPlaceholder')"
              :ruid="`create-message__${messageType}__body-url-${lang}`"
              :validation-id="`${messageType}-${lang}${$t('pages.createMessage.bodyUrl')}`"
              :validation-rules="validationRules(lang)"
              :disabled="disabled"
              :value="getResourceValue(messageResources, 'bodyUrl', lang)"
              @input="setResourceValue(messageResources, 'bodyUrl', $event, lang)"
              />

            <cc-text-area
              v-if="body"
              :label="$t('pages.createMessage.messageBody')"
              :label-right="getInboxMessageCharCounter(messageResources, lang, maxCharOnMessageBody)"
              :placeholder="$t('pages.createMessage.messageBodyPlaceholder')"
              :maxlength="maxCharOnMessageBody"
              :disabled="disabled"
              :ruid="`create-message__${messageType}__description-${lang}`"
              :validation-id="`${messageType}-${lang}${$t('pages.createMessage.messageBody')}`"
              :validation-rules="validationRules(lang)"
              :value="getResourceValue(messageResources, 'body', lang)"
              @input="setResourceValue(messageResources, 'body', $event, lang)"
              />
          </div>
        </div>
      </div>

      <cc-text
        v-if="previewOnly"
        class="pb-4"
        color="text-gray-600"
        >
        {{ $t('pages.challenges.dispatchedInboxMessage') }}
      </cc-text>

      <!-- Message card -->
      <div
        v-if="hasPreview"
        class="flex mt-4 items-start"
        >
        <div
          v-for="(lang, i) in supportedLangs"
          :key="lang + i"
          class="flex justify-start items-center flex-shrink-0 w-96 mr-8 bg-primary-dark
          rounded-2xl p-4 shadow-lg"
          >
          <div class="flex-shrink-0">
            <cc-image
              :has-placeholder="true"
              placeholder-class="bg-primary-light"
              :data-ruid="`create-message__${messageType}__image-${lang}`"
              class="h-12 w-12 rounded-full"
              :src="getResourceValue(messageResources, 'imageUrl', lang)"
              />
          </div>

          <div class="pl-4">
            <cc-text
              class="uppercase"
              variant="body-xs"
              color="text-primary-lightest"
              >
              {{ getPreviewMessageDate(lang) }}
            </cc-text>
            <cc-text
              variant="body-sm-tight"
              color="text-white"
              >
              {{ getResourceValue(messageResources, 'body', lang) }}
            </cc-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import defaultImages from '@/mixins/defaultImages';

export default {
  props: {
    messageType: {
      type: String,
      required: true,
      validator(value) {
        return ['inbox', 'push', 'mail', 'sms'].includes(value);
      },
    },
    messageResources: {
      type: Object,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    hasPreview: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: Boolean,
      required: false,
      default: true,
    },
    bodyUrl: {
      type: Boolean,
      required: false,
      default: false,
    },
    body: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      maxCharOnMessageBody: '400',
    };
  },
  computed: {
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
    }),
    defaultImages() {
      return defaultImages;
    },
  },
  methods: {
    validationRules(lang) {
      return lang === 'de' ? 'required' : null;
    },
    getPreviewMessageDate(lang) {
      const date = new Date(Date.now()).toLocaleDateString(lang, { month: 'long', day: 'numeric' });
      return date;
    },
    getMessageBodyLength(messageResources, lang) {
      return this.getResourceValue(messageResources, 'body', lang)?.length.toString() || '0';
    },
    getInboxMessageCharCounter(messageResources, lang, maxCharOnMessageBody) {
      return `${this.getMessageBodyLength(messageResources, lang)} / ${maxCharOnMessageBody}`;
    },
    getResourceValue(target, key, lang) {
      return target?.[lang]?.[key];
    },
    setResourceValue(target, key, value, lang) {
      this.$set(target, lang, { ...target[lang], [key]: value });
    },
  },
};
</script>

<style>

</style>
