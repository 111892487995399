<template>
  <div
    id="sidebar"
    class="bg-primary-darkest"
    >
    <div class="p-4">
      <div
        class="flex flex-col justify-center items-center pb-6 pt-4 mb-6 border-b border-gray-100 border-opacity-30"
        >
        <cc-svg-base
          svg="illustration/small-earth"
          class="mb-3"
          />
        <cc-heading
          tag="h3"
          variant="h3"
          color="text-white"
          align="center"
          >
          {{ $t('commons.appName') }}
        </cc-heading>
      </div>

      <ul class="list-none">
        <router-link
          v-for="item in navigationItems"
          :key="item.name"
          v-slot="{ href, navigate, isActive }"
          :to="{ name: item.name, params: { ...item.params } }"
          custom
          >
          <span class="">
            <!-- <div
              v-if="item.name === 'settings'"
              class="w-full pb-4 mb-6 border-b border-gray-100 border-opacity-30"
              /> -->
            <li
              class="rounded-xl mb-2 transition-all duration-500 ease-out hover:bg-primary-light hover:bg-opacity-30"
              :class="[isActive && 'bg-primary-light bg-opacity-30']"
              >
              <a
                :href="href"
                class="flex justify-items-center align-middle py-3"
                :data-ruid="`sidebar__navigation-item__${item.name}`"
                @click="navigate"
                >
                <cc-svg-base
                  v-if="item.icon"
                  tag="span"
                  :svg="`icons/${item.icon}`"
                  class="mx-3 text-white"
                  />
                <cc-text color="text-white">
                  {{ $t(item.navigationLabel) }}
                </cc-text>
              </a>
            </li>
          </span>
        </router-link>
      </ul>
      <!-- <cc-code>
        {{ navigationItems }}
      </cc-code> -->
    </div>

    <div class="p-4">
      <cc-text
        color="text-gray-100"
        variant="body-sm"
        class="opacity-50"
        >
        {{ $t('commons.version') }}
        {{ env.VERSION }} –
        {{ env.VUE_APP_ENV }}
      </cc-text>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      userCompanyId: null,
    };
  },
  computed: {
    ...mapState({
      user: st => st.user,
    }),
    ...mapGetters({
      getUserActions: 'user/getUserActions',
    }),
    env() {
      return process.env;
    },
    navigationItems() {
      const { routes } = this.$router.options;

      try {
        const items = [...routes, ...routes.flatMap(e => e.children)]
          .filter(
            route => route?.meta?.isNavigationFor?.some(entry => this.getUserActions?.includes(entry))
              && route?.meta?.isNavigationItem,
          )
          .map(route => ({
            name: route.children?.[0].name || route.name,
            path: route.path,
            navigationLabel: route.meta.navigationLabel,
            icon: route.meta.icon,
            params: { ...(this.userCompanyId && { companyId: this.userCompanyId }) },
          }));

        // this.$log.info('Generating navigation items', items);

        return items;
      } catch (error) {
        this.$log.info('navigationItems error:', error);
      }

      return [];
    },
  },
  created() {
    // this.$log.info('Created: Sidebar');
    this.userCompanyId = this.user.user?.companies?.[0]?.id;
  },
  methods: {
  },
};
</script>
