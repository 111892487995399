import ApiService from '@/services/ApiService';


const version = '/v1';
const resource = `${version}/user`;
const resourcePublic = `${version}/public/user`;

export default {
  // async authenticate() {
  //   return ApiService.get(`${resource}`);
  // },

  async login(payload) {
    return ApiService.post(`${resourcePublic}/login`, payload);
  },

  async getUser() {
    return ApiService.get(`${resource}`);
  },

  async getUserRoles() {
    return ApiService.get(`${resource}/roles`);
  },

  async updatePassword(payload) {
    return ApiService.securePost(`${resource}/updatePassword`, payload);
  },

  async forgotPassword(payload) {
    return ApiService.post(`${resourcePublic}/forgotPassword`, payload);
  },

  async confirmForgottenPassword(payload) {
    return ApiService.post(`${resourcePublic}/confirmForgottenPassword`, payload);
  },

  async updateProfile(payload) {
    return ApiService.securePost(`${resource}/profile`, payload);
  },

  async requestVerification(phoneNumber) {
    return ApiService.securePost(`${resource}/requestVerification/${encodeURIComponent(phoneNumber)}`);
  },

  async verifyToken(phoneNumber, token, context) {
    return ApiService.securePost(`${resource}/verifyToken/${encodeURIComponent(phoneNumber)}/${encodeURIComponent(token)}?context=${context}`);
  },
};
