import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getAccessToken: 'user/getAccessToken',
    }),
    webApp: () => `${process.env.VUE_APP_FRONTEND}`,
  },
  mounted() {
    this.$log.info('MOUNTED: addEventListener');
    window.addEventListener('message', this.receiveMessage);
  },
  destroyed() {
    this.$log.info('DESTROYED: removeEventListener');
    window.removeEventListener('message', this.receiveMessage);
  },
  methods: {
    sendMessageToClient(payload, message) {
      const sandboxFrame = this.$refs.sandbox;

      this.$log.info('sandboxFrame', !!sandboxFrame, message);

      // if (!sandboxFrame || !this.sandboxPayload) {
      //   this.$log.info('will return');
      // }

      sandboxFrame.contentWindow.postMessage({
        message: message || 'update',
        payload,
      }, '*');
    },
    receiveMessage(event) {
      if (event.origin.startsWith(process.env.VUE_APP_FRONTEND)) {
        // this.$log.warn('Host received:', event);

        if (event.data.message === 'SCC:auth') {
          this.$log.warn('Message: SCC:auth', this.getAccessToken);
          this.sendMessageToClient(this.getAccessToken, 'auth');
        }

        if (event.data.message === 'SCC:company') {
          this.$log.warn('Message: SCC:company');
          this.sendMessageToClient({
            company: this.companyProfile,
            offers: [this.offer],
          }, 'company');
        }
      }
    },
  },
};
