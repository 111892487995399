<template>
  <div>
    <cc-section-table
      :title="$t('pages.purchases.title')"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => query = $event.toLowerCase()"
      @export="exportHandler"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @row-click="handleRowClick($event)"
        />
    </cc-section-table>

    <cc-modal
      v-if="isOpen"
      :is-open="isOpen"
      transition-name="slide-up"
      @close="isOpen = false"
      >
      <template #body>
        <div class="max-w-lg">
          <cc-code>
            {{ rowContent }}
          </cc-code>
        </div>
      </template>
    </cc-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase } from '@/mixins/utils';

export default {
  data() {
    return {
      query: '',
      isOpen: false,
      isLoadingDataTable: false,
      companyReceipts: null,
      rowContent: null,
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.purchaseId'),
          value: 'purchaseId',
          isCSVField: true,
          // isSortable: false,
        },
        {
          text: this.$t('commons.fieldNames.date'),
          value: 'date',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.productName'),
          value: 'productName',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.greenCoins'),
          value: 'greenCoins',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.user'),
          value: 'user',
          isCSVField: true,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    dataItems() {
      return this.companyReceipts
        ?.filter((receipt) => {
          if (this.query) {
            return (
              receipt?.id?.toLowerCase().includes(this.query)
              || receipt?.userEmail?.toLowerCase().includes(this.query)
              || receipt?.offer?.virtualGood?.resources?.en?.title?.toLowerCase().includes(this.query)
            );
          }
          return receipt;
        })
        .map((receipt, index) => ({
          index: index + 1,
          purchaseId: receipt.id || '–',
          date: new Date(receipt.createdAt).toLocaleString('en-US'),
          productName:
            receipt?.offer?.virtualGood?.resources?.en?.title
            || receipt?.offer?.virtualGood?.name
            || '–',
          greenCoins: receipt?.offer?.price,
          user: receipt?.userEmail || '–',
        }));
    },
  },
  async created() {
    try {
      this.isLoadingDataTable = true;

      const response = await this.$store.dispatch(
        'company/getReceiptsByCompany',
        this.$route.params.companyId,
      );

      this.companyReceipts = response.reverse();

      // this.$log.info('companyReceipts', this.companyReceipts);
    } catch (err) {
      this.$log.error('Error: getReceiptsByCompany', err);
    } finally {
      this.isLoadingDataTable = false;
    }
  },
  methods: {
    handleRowClick(e) {
      this.$log.info('e', e);
      this.rowContent = e;
      this.isOpen = true;
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyName = toKebapCase(this.getCompanyName(this.$route.params.companyId));
        const filename = generateTimeStampedFilename(`scc_purchases_${companyName}`);

        this.dataItems.forEach((item) => {
          itemsFormatted.push({
            ...item,
            date: item.date.replace(/,/g, ''),
          });
        });
        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>
