<template>
  <div>
    <cc-section-table
      :title="$t('pages.companyInformation.title')"
      :is-loading="isLoading"
      >
      <validation-observer ref="form">
        <!-- Store internal name -->
        <div class="grid gap-6 grid-cols-1 mb-8 max-w-sm">
          <cc-input
            v-model="companyProfile.name"
            :label="$t('pages.companyInformation.internalName')"
            validation-rules="required"
            />
          <cc-input
            v-if="isContextAdmin"
            v-model="companyProfile.alias"
            :disabled="true"
            :label="$t('commons.companyAlias')"
            validation-rules="required"
            />
          <div class="flex items-center flex-wrap">
            <cc-check-box
              v-model="companyProfile.active"
              :text="$t('configs.active')"
              :label="$t('configs.active')"
              class="flex-shrink-0"
              />
          </div>
          <div class="flex items-center flex-wrap">
            <cc-check-box
              v-model="companyProfile.allowLeaderboard"
              :text="$t('configs.allowLeaderboard')"
              :label="$t('configs.allowLeaderboard')"
              class="flex-shrink-0"
              />
          </div>
        </div>

        <!-- Image upload hints -->
        <div class="bg-gray-100 max-w-lg p-3 rounded-lg mb-8 flex">
          <div class="pr-2">
            <cc-svg-base
              svg="icons/icon-info"
              class="inline text-gray-300"
              />
          </div>

          <cc-text
            variant="body-sm"
            color="text-gray-600"
            class="whitespace-pre-line"
            >
            <i18n path="pages.companyInformation.imageRequirements">
              <template #link>
                <a
                  class="underline"
                  href="https://www.tinypng.com/"
                  target="_blank"
                  >
                  www.tinypng.com
                </a>
              </template>
            </i18n>
          </cc-text>
        </div>

        <!-- Store profile resources -->
        <div
          v-if="true"
          class="flex overflow-auto pb-8 mb-12"
          >
          <div
            v-for="lang in supportedLangs"
            :key="lang"
            class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
            >
            <cc-heading
              tag="h5"
              class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
              >
              {{ $t(`commons.langs.${lang}`) }}
            </cc-heading>

            <div class="grid gap-6 grid-cols-1">
              <cc-image-upload
                :placeholder="$t('configs.selectLogo')"
                :images="
                  getResourceValue(resources, 'logo', lang)
                    ? [getResourceValue(resources, 'logo', lang)]
                    : []
                "
                @reset="setResourceValue(resources, 'logo', '', lang)"
                @upload-complete="
                  setResourceValue(resources, 'logo', $event[0], lang), $refs.storeLogo[1].reset()
                "
                />

              <validation-provider
                v-slot="{ errors }"
                ref="storeLogo"
                :name="$t('configs.selectLogo')"
                :vid="`${lang}-${$t('configs.selectLogo')}`"
                :rules="lang === 'de' && !hasCompanyLogo ? 'required' : null"
                >
                <cc-error-message
                  v-if="errors && errors[0]"
                  class="flex-grow w-full"
                  :message="errors[0]"
                  />
              </validation-provider>

              <cc-image-upload
                :placeholder="$t('configs.selectCoverImage')"
                :images="
                  getResourceValue(resources, 'images', lang)
                    ? [getResourceValue(resources, 'images', lang)]
                    : []
                "
                @reset="setResourceValue(resources, 'images', [], lang)"
                @upload-complete="
                  setResourceValue(resources, 'images', $event, lang), $refs.storeImage[1].reset()
                "
                />

              <validation-provider
                v-slot="{ errors }"
                ref="storeImage"
                :name="$t('configs.selectCoverImage')"
                :vid="`${lang}-${$t('configs.selectCoverImage')}`"
                :rules="lang === 'de' && !hasCompanyImage ? 'required' : null"
                >
                <cc-error-message
                  v-if="errors && errors[0]"
                  class="flex-grow w-full"
                  :message="errors[0]"
                  />
              </validation-provider>

              <cc-input
                :label="$t('pages.companyInformation.companyName')"
                :value="getResourceValue(resources, 'name', lang)"
                :validation-id="`${lang}-${$t('pages.companyInformation.companyName')}`"
                :validation-rules="lang === 'de' ? 'required' : null"
                @input="setResourceValue(resources, 'name', $event, lang)"
                />

              <cc-input
                :label="$t('pages.companyInformation.catchPhrase')"
                :value="getResourceValue(resources, 'catchPhrase', lang)"
                @input="setResourceValue(resources, 'catchPhrase', $event, lang)"
                />

              <cc-input
                v-if="isContextAdmin"
                :label="$t('pages.companyInformation.companyChallengeMessage')"
                :value="getResourceValue(resources, 'companyChallengeMessage', lang)"
                @input="setResourceValue(resources, 'companyChallengeMessage', $event, lang)"
                />
            </div>
          </div>
        </div>

        <!-- Product additional information -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full flex flex-col"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.additionalInformation') }}
          </cc-heading>

          <div
            v-for="(section, i) in storeDetails"
            :key="section.key"
            class="pb-20"
            >
            <div class="w-48">
              <cc-heading
                tag="h3"
                weight="semibold"
                class="flex-grow w-full flex flex-col"
                color="text-gray-600"
                :has-border="true"
                >
                {{ $t('pages.companyInformation.detailSection') }} {{ i + 1 }}
              </cc-heading>
            </div>

            <div class="flex overflow-y-hidden pb-8 mb-4">
              <div
                v-for="lang in supportedLangs"
                :key="lang"
                class="flex-shrink-0 w-80 mr-8 bg-gray-100 rounded-lg p-4"
                >
                <cc-heading
                  tag="h5"
                  class="bg-gray-200 inline-block capitalize mb-4 px-3 py-1 rounded-full"
                  >
                  {{ $t(`commons.langs.${lang}`) }}
                </cc-heading>

                <cc-input
                  v-if="false"
                  :label="$t('pages.product.sectionTitle')"
                  :value="storeResources[lang] && storeResources[lang].details[i].label"
                  class="pb-4"
                  @input="setValue(storeResources[lang].details[i], 'label', $event)"
                  />
                <cc-heading
                  tag="h4"
                  color="text-gray-600"
                  class="pb-1"
                  >
                  {{ $t('pages.product.sectionContent') }}
                </cc-heading>

                <div class="grid gap-6 grid-cols-1">
                  <cc-editor
                    :id="`editor-${lang}-${i}`"
                    :lang="lang"
                    :value="getEditorValue(lang, i)"
                    @input="editorJSInputHandler"
                    />
                </div>
              </div>
            </div>
            <cc-button
              variant="secondary"
              :block="false"
              :text="`${$t('pages.companyInformation.deleteSection')} ${i + 1}`"
              @click.native="deleteSectionHandler(i)"
              />
          </div>

          <cc-button
            :text="$t('pages.companyInformation.addNewSection')"
            :block="false"
            @click.native="addSectionHandler"
            />
        </div>

        <!-- Social links -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.socialLinks') }}
          </cc-heading>

          <div class="max-w-xl">
            <div
              v-for="(link, key, i) in links"
              :key="i"
              >
              <cc-input
                class="pb-4"
                :label="links[key].label"
                :validation-id="links[key].label"
                validation-rules="validURL"
                :value="getResourceValue(companyProfile.metaPublic.links, 'target', key)"
                @input="
                  setResourceValue(companyProfile.metaPublic.links, 'target', $event, key),
                  setResourceValue(companyProfile.metaPublic.links, 'label', link.label, key)
                "
                />
            </div>
          </div>
        </div>

        <!-- Purchase emails -->
        <div
          v-if="true"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.purchaseNotifications') }}
          </cc-heading>

          <div class="max-w-xl">
            <cc-input
              :label="$t('pages.companyInformation.emailAddresses')"
              :value="getResourceValue(companyProfile, 'notificationEmails', 'metaPublic')"
              @input="setResourceValue(companyProfile, 'notificationEmails', $event, 'metaPublic')"
              />
          </div>
          <cc-text
            class="pt-2 w-96 whitespace-pre-line"
            variant="body-sm"
            color="text-gray-600"
            >
            {{ $t('pages.companyInformation.purchaseNotificationsHint') }}
          </cc-text>
        </div>

        <!-- Onboard Code -->
        <div
          v-if="isContextAdmin"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.onboardCodeTitle') }}
          </cc-heading>

          <div class="max-w-xl">
            <cc-text
              class="pb-4 whitespace-pre-line"
              color="text-gray-600"
              >
              {{ $t('pages.companyInformation.onboardCodeHint') }}
            </cc-text>

            <cc-input
              v-model.trim="onboardCode"
              :label="$t('pages.companyInformation.onboardCode')"
              :validation-rules="onboardCode ? `alpha_num|length:${4}` : null"
              />

            <cc-error-message
              v-if="onboardingCodeErrors && onboardingCodeErrors[0]"
              class="flex-grow w-full"
              :message="onboardingCodeErrors[0]"
              />
          </div>
        </div>

        <!-- Email domain -->
        <div
          v-if="isContextAdmin"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.emailDomain') }}
          </cc-heading>

          <div class="max-w-xl">
            <cc-text
              class="pb-4 whitespace-pre-line"
              color="text-gray-600"
              >
              {{ $t('pages.companyInformation.emailDomainBody') }}
            </cc-text>

            <cc-email-domain
              class="max-w-sm"
              :domains="companyEmailDomains"
              :input-disabled="!companyProfile.alias"
              :errors="emailDomainErrors"
              @on-change="handleEmailDomainChange($event)"
              />
          </div>
        </div>

        <!-- Addresses -->
        <div
          v-if="isContextAdmin"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.addressList') }}
          </cc-heading>

          <div class="max-w-xl">
            <cc-text
              class="pb-4 whitespace-pre-line"
              color="text-gray-600"
              >
              {{ $t('pages.companyInformation.addressListBody') }}
            </cc-text>

            <cc-button
              :text="$t('pages.companyInformation.addCompanyAddress')"
              :block="false"
              @click.native="isNewAddressOpen = true"
              />
          </div>

          <div class="pt-8 flex gap-8 flex-wrap">
            <div
              v-for="(address, i) in companyProfile.addresses"
              :key="i"
              class="bg-gray-50 p-4 rounded-lg gap-4 flex flex-col"
              >
              <cc-company-address-item
                :key="address.geolocation"
                :has-remove="true"
                :has-map="true"
                :address="address"
                class="flex-shrink-0"
                @remove-address="(address) => removeAddress(address)"
                />

              <cc-error-message
                v-if="addressErrors.includes(address.id)"
                :message="$t('pages.companyInformation.invalidAddressError')"
                />
            </div>
          </div>
        </div>


        <cc-add-company-address-modal
          v-if="isNewAddressOpen"
          :is-open="isNewAddressOpen"
          @close="isNewAddressOpen = false"
          @add-address="(address) => addAddressHandler(address)"
          />

        <!-- Feature Tags -->
        <div
          v-if="isContextAdmin"
          class="py-12"
          >
          <cc-heading
            tag="h3"
            weight="semibold"
            class="flex-grow w-full"
            color="text-gray-600"
            :has-border="true"
            >
            {{ $t('pages.companyInformation.featureTags') }}
          </cc-heading>

          <div class="max-w-xl">
            <cc-text
              class="pb-4 whitespace-pre-line"
              color="text-gray-600"
              >
              {{ $t('pages.companyInformation.featureTagsBody') }}
            </cc-text>

            <cc-hint
              v-if="!companyProfile.alias"
              :hint="$t('pages.companyInformation.aliasHint')"
              class="mb-3 max-w-xl"
              />

            <cc-feature-tags
              class="max-w-sm"
              :tags="companyTags"
              :input-disabled="!companyProfile.alias"
              @add-tag="addFeatureTagsToCompany($event)"
              @delete-tag="deleteFeatureTagsFromCompany($event)"
              />
          </div>
        </div>
      </validation-observer>

      <!-- Action buttons -->
      <div class="flex flex-wrap justify-end pt-4">
        <cc-button
          v-if="isContextAdmin"
          :text="$t('commons.delete')"
          :block="false"
          variant="red"
          class="mr-4"
          @click.native="isDeleteConfirmationOpen = true"
          />
        <cc-button
          :text="$t('commons.save')"
          :block="false"
          :is-loading="isUpdating"
          :disabled="isUpdating"
          @click.native="updateCompany"
          />
      </div>

      <cc-error-message
        v-if="requestErrors.length"
        :message="requestErrors[0]"
        class="mr-2 mt-4 justify-end"
        />

      <!-- Delete store modal -->
      <cc-modal
        v-if="isDeleteConfirmationOpen"
        :is-open="isDeleteConfirmationOpen"
        transition-name="slide-up"
        :heading="$t('modals.deleteCompanyConfirmation.title')"
        @close="(isDeleteConfirmationOpen = false), (deleteRequestErrors = [])"
        >
        <template #body>
          <div class="max-w-md">
            {{ $t('modals.deleteCompanyConfirmation.body') }}
          </div>
        </template>

        <template #footer="{ close }">
          <cc-error-message
            v-if="deleteRequestErrors.length"
            :message="deleteRequestErrors[0]"
            class="mb-6 justify-center"
            padding="p-0"
            />

          <div class="grid grid-cols-2 gap-x-4">
            <cc-button
              variant="secondary"
              :text="$t('commons.cancel')"
              @click.native="close"
              />
            <cc-button
              variant="red"
              :text="$t('modals.deleteCompanyConfirmation.primaryButton')"
              :is-loading="isDeleting"
              :disabled="isDeleting"
              @click.native="deleteCompany($route.params.companyId)"
              />
          </div>
        </template>
      </cc-modal>

      <cc-code class="mt-36">
        {{ companyProfile }}
      </cc-code>
    </cc-section-table>

    <cc-section-table
      v-if="true"
      class="mt-8 flex flex-wrap-reverse"
      >
      <div class="md:pr-8">
        <iframe
          v-if="companyProducts && companyProducts.length"
          id="sandbox"
          ref="sandbox"
          :src="frontendURL"
          frameborder="0"
          width="375"
          height="812"
          class="shadow-gray-200 shadow-2xl rounded-xl"
          />
      </div>

      <div>
        <cc-heading
          tag="h2"
          weight="semibold"
          color="text-gray-600"
          class="pb-4"
          >
          {{ $t('pages.product.previewTitle') }}
        </cc-heading>

        <div class="bg-gray-100 max-w-lg p-3 rounded-lg mb-8 flex">
          <div class="pr-2">
            <cc-svg-base
              svg="icons/icon-info"
              class="inline text-gray-300"
              />
          </div>

          <cc-text
            v-if="companyProducts && !companyProducts.length"
            variant="body"
            color="text-gray-600"
            class="whitespace-pre-line"
            >
            {{ $t('pages.companyInformation.productIsRequired') }}
          </cc-text>

          <cc-text
            v-else
            variant="body"
            color="text-gray-600"
            class="whitespace-pre-line"
            >
            {{ $t('pages.companyInformation.companyUpdateHint') }}
          </cc-text>
        </div>

        <cc-button
          v-if="companyProducts && companyProducts.length"
          :text="$t('pages.product.updatePreview')"
          :block="false"
          class="mb-4"
          @click.native="handlePreview"
          />
      </div>
    </cc-section-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmptyObject } from '@/mixins/utils';
import { v4 as uuidv4 } from 'uuid';
import validator from '@/mixins/validator';
import sandbox from '@/mixins/sandbox';
import ccAddCompanyAddressModal from '@/views/Companies/AddCompanyAddressModal.vue';

export default {
  components: {
    ccAddCompanyAddressModal,
  },
  mixins: [validator, sandbox],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isDeleting: false,
      isDeleteConfirmationOpen: false,
      isNewAddressOpen: false,
      requestErrors: [],
      emailDomainErrors: [],
      onboardingCodeErrors: [],
      addressErrors: [],
      deleteRequestErrors: [],
      companyProducts: null,
      companyFeatureTags: [],
      companyProfile: {
        metaPublic: {
          notificationEmails: '',
          links: {},
          resources: {},
        },
        addresses: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
      isContextAdmin: 'user/isContextAdmin',
    }),
    onboardCode: {
      get() {
        return this.companyProfile.onboardCode;
      },
      set(value) {
        const inputValue = value.replace(/\s/g, '');
        this.companyProfile.onboardCode = inputValue.toUpperCase();
        if (inputValue.length === 0) {
          this.companyProfile.onboardCode = null;
        }
      },
    },
    companyTags() {
      return this.companyProfile?.tags?.map(t => t?.split(':')?.[1]);
    },
    companyEmailDomains() {
      return this.companyProfile.emailDomains;
    },
    offer() {
      return this.companyProducts[0];
    },
    frontendURL() {
      return process.env.VUE_APP_FRONTEND;
    },
    hasCompanyLogo() {
      return this.getResourceValue(this.resources, 'logo', 'de')?.length > 0;
    },
    hasCompanyImage() {
      return this.getResourceValue(this.resources, 'images', 'de')?.length > 0;
    },
    resources() {
      return this.companyProfile?.metaPublic?.resources;
    },
    storeLinks() {
      return this.companyProfile?.metaPublic?.links;
    },
    links() {
      return {
        web: {
          label: 'Website',
          target: '',
        },
        facebook: {
          label: 'Facebook',
          target: '',
        },
        instagram: {
          label: 'Instagram',
          target: '',
        },
        pinterest: {
          label: 'Pinterest',
          target: '',
        },
        tiktok: {
          label: 'TikTok',
          target: '',
        },
        youtube: {
          label: 'Youtube',
          target: '',
        },
      };
    },
    storeDetails() {
      return this.companyProfile.metaPublic?.resources?.en?.details;
    },
    storeResources() {
      return this.companyProfile.metaPublic?.resources;
    },
  },
  async created() {
    try {
      this.isLoading = true;
      this.companyProfile = await this.$store.dispatch('company/getCompany', {
        companyId: this.$route.params.companyId,
      });

      this.companyProducts = await this.$store.dispatch(
        'company/getOffersByCompany',
        this.$route.params.companyId,
      );

      if (!this.companyProfile.metaPublic) {
        this.$set(this.companyProfile, 'metaPublic', {
          resources: {},
        });
      }
      if (!this.companyProfile.metaPublic.links) {
        this.$set(this.companyProfile, 'metaPublic', {
          links: {},
          ...this.companyProfile.metaPublic,
        });
      }

      if (!this.companyProfile.metaPublic.resources) {
        this.$set(this.companyProfile, 'metaPublic', {
          resources: {},
          ...this.companyProfile.metaPublic,
        });
      }

      this.$log.info('companyProfile', this.companyProfile);
    } catch (err) {
      this.$log.error('Error: getCompany', err);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    removeAddress(address) {
      const index = this.companyProfile.addresses.findIndex(addr => addr.id === address.id);

      if (index !== -1) {
        this.companyProfile.addresses.splice(index, 1);
      }
    },
    addAddressHandler(address) {
      if (!this.companyProfile.addresses) {
        this.$set(this.companyProfile, 'addresses', []);
      }

      this.companyProfile.addresses.push(address);
    },
    async updateCompany() {
      try {
        this.requestErrors = [];
        this.addressErrors = [];
        this.emailDomainErrors = [];
        this.onboardingCodeErrors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isUpdating = true;

        this.companyProfile = await this.$store.dispatch('company/updateCompany', {
          companyId: this.$route.params.companyId,
          body: this.companyProfile,
        });

        if (this.isContextAdmin) {
          await this.$store.dispatch('company/getAllCompanies');
        }

        this.$log.info('Response: updateCompany', this.companyProfile);
      } catch (err) {
        if (err.code === 409) {
          const duplicateFields = err?.body?.errorList;
          if (duplicateFields?.length) {
            if (duplicateFields.includes('onboardCode')) {
              this.onboardingCodeErrors.push(this.$t('errors.existingOnboardingCodeWithoutButton'));
            }
            if (duplicateFields.includes('domain')) {
              this.emailDomainErrors.push(this.$t('errors.existedEmailDomain'));
            }
          }
        } else {
          this.requestErrors.push(this.$t('errors.genericError'));
        }

        if (err.body.errorList) {
          this.addressErrors = err.body.errorList;
          this.isAddressErrorModalOpen = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async deleteCompany(companyId) {
      // TODO: Currently only context admin is allowed to delete companies
      try {
        this.deleteRequestErrors = [];

        this.isDeleting = true;

        await this.$store.dispatch('company/deleteCompany', companyId);

        await this.$store.dispatch('company/getAllCompanies');

        this.$router.push({ name: 'companies-overview' }).catch(() => {});
      } catch (err) {
        this.deleteRequestErrors.push(this.$t('errors.genericError'));

        this.$log.error('Error: deleteCompany', err);
      } finally {
        this.isDeleting = false;
      }
    },
    async addFeatureTagsToCompany(tag) {
      try {
        const payload = {
          id: this.$route.params.companyId,
          tags: [tag],
        };

        await this.$store.dispatch('company/addFeatureTagsToCompany', payload);

        this.companyProfile = await this.$store.dispatch('company/getCompany', {
          companyId: this.$route.params.companyId,
        });
      } catch (error) {
        this.$log.info('Error: addFeatureTagsToCompany', error);
      }
    },
    async deleteFeatureTagsFromCompany(tag) {
      try {
        const payload = {
          id: this.$route.params.companyId,
          tags: [tag],
        };

        await this.$store.dispatch('company/deleteFeatureTagsFromCompany', payload);

        this.companyProfile = await this.$store.dispatch('company/getCompany', {
          companyId: this.$route.params.companyId,
        });
      } catch (error) {
        this.$log.info('Error: deleteFeatureTagsFromCompany', error);
      }
    },
    async handleEmailDomainChange(domains) {
      this.companyProfile.emailDomains = domains;
    },
    handlePreview() {
      this.sendMessageToClient(
        {
          company: this.companyProfile,
          offers: [this.companyProducts[0]],
        },
        'company',
      );
    },
    getEditorValue(lang, i) {
      return this.storeResources?.[lang]?.details?.[i]?.data || {};
    },
    deleteSectionHandler(i) {
      this.supportedLangs.forEach((lang) => {
        if (this.storeResources?.[lang]?.details[i]) {
          this.$log.info('deleting section', this.storeResources?.[lang]?.details, i);
          return this.storeResources?.[lang]?.details.splice(i, 1);
        }
        return false;
      });
    },
    addSectionHandler() {
      this.supportedLangs.forEach((lang) => {
        if (!this.storeResources?.[lang]) {
          this.$log.info('inserting empty lang', lang);
          this.$set(this.storeResources, lang, {});
        }

        if (!this.storeResources?.[lang]?.details?.length) {
          this.$log.info('inserting empty details');
          return this.$set(this.storeResources[lang], 'details', [
            { data: {}, key: uuidv4(), label: '' },
          ]);
        }

        if (this.storeResources?.[lang]?.details?.length) {
          this.$log.info('adding new section', lang, this.storeResources?.[lang]?.details);
          return this.storeResources?.[lang]?.details.push({
            data: {},
            key: uuidv4(),
            label: '',
          });
        }

        return false;
      });
    },
    editorJSInputHandler(payload) {
      const index = payload.id.split('-')[2];
      const lang = payload.id.split('-')[1];

      this.$log.info('payload', payload, index, lang);

      if (this.storeResources?.[lang]?.details?.[index]) {
        this.$log.info('Details exists, will set data', lang);
        this.$set(this.storeResources[lang].details[index], 'data', payload.data);
      }

      if (!payload.data.blocks.length) {
        this.$log.info('Empty blocks, will clear data');
        this.$set(this.storeResources[lang].details[index], 'data', {});
      }
    },
    getValue(target, key) {
      return this?.[target]?.[key];
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
    getResourceValue(target, key, lang) {
      return target?.[lang]?.[key];
    },
    setResourceValue(target, key, value, lang) {
      if (value.length === 0) {
        this.$log.info('Will delete key');

        this.$delete(target[lang], key);

        if (isEmptyObject(target[lang])) {
          this.$log.info('Will delete lang');
          this.$delete(target, lang);
        }
      } else {
        this.$set(target, lang, { ...target[lang], [key]: value });
      }
    },
  },
};
</script>
