import Vue from 'vue';
import DraftService from '@/services/DraftService';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
});

const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};

const actions = {
  async getDrafts() {
    try {
      const response = await DraftService.getDrafts();

      Vue.$log.info('Response: Vuex: getDrafts', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getDrafts', err);
      throw err;
    }
  },

  async getDraft(context, draftId) {
    try {
      const response = await DraftService.getDraft(draftId);

      Vue.$log.info('Response: Vuex: getDraft', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: getDraft', err);
      throw err;
    }
  },

  async deleteDraft(context, draftId) {
    try {
      const response = await DraftService.deleteDraft(draftId);

      Vue.$log.info('Response: Vuex: deleteDraft', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: deleteDraft', err);
      throw err;
    }
  },

  async saveDraft(context, payload) {
    try {
      const response = await DraftService.saveDraft(payload);

      Vue.$log.info('Response: Vuex: saveDraft', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: saveDraft', err);
      throw err;
    }
  },

  async updateDraft(context, payload) {
    try {
      const response = await DraftService.updateDraft(payload);

      Vue.$log.info('Response: Vuex: updateDraft', response);

      return response;
    } catch (err) {
      Vue.$log.error('Error: Vuex: updateDraft', err);
      throw err;
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
