<template>
  <cc-modal
    :is-open="isOpen"
    transition-name="slide-up"
    :timeout-duration="timeoutDuration"
    heading=""
    @close="$emit('close')"
    >
    <template #body>
      <div class="max-w-sm flex flex-col items-center">
        <cc-svg-base :svg="`icons/${icon}`" />

        <cc-heading
          tag="h2"
          class="pb-2 pt-6"
          >
          {{ feedbackHeading }}
        </cc-heading>

        <cc-text
          v-if="feedbackBody"
          class="break-all"
          >
          {{ feedbackBody }}
        </cc-text>
      </div>
    </template>

    <template #footer>
      <div />
    </template>
  </cc-modal>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'icon-checkmark-circle',
    },
    feedbackHeading: {
      type: String,
      required: false,
      default: '',
    },
    feedbackBody: {
      type: String,
      required: false,
      default: null,
    },
    timeoutDuration: {
      type: Number,
      default: null,
      required: false,
    },

  },
};
</script>

<style>

</style>
