const defaultImages = [
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/happy-earth.png',
    text: 'Happy earth',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/medal.png',
    text: 'Medal',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/co2-saver-of-the-week.png',
    text: 'CO2 Saver',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/walking.png',
    text: 'Walking',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/car-free.png',
    text: 'Car Free',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/king-earth.png',
    text: 'King Earth',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/muscle.png',
    text: 'Muscle',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/urban-cyclist.png',
    text: 'Urban Cyclist',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/0-gr-co2.png',
    text: '0 gr CO2',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/cdc-gold.png',
    text: 'CDC Gold',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/cdc-silver.png',
    text: 'CDC Silver',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/cdc-bronze.png',
    text: 'CDC Bronze',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/mobility-mix-1.png',
    text: 'Mobility Mix 1',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/mobility-mix-2.png',
    text: 'Mobility Mix 2',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/quarter-marathon.png',
    text: 'Quarter Marathon',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/walk-week-50k.png',
    text: 'Walk Week 50K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/walk-week-20k.png',
    text: 'Walk Week 20K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/walk-week-10k.png',
    text: 'Walk Week 10K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/bike-week-100k.png',
    text: 'Bike Week 100K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/bike-week-50k.png',
    text: 'Bike Week 50K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/bike-week-20k.png',
    text: 'Bike Week 20K',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/diemobiliar.png',
    text: 'Die Mobiliar',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/diepost.png',
    text: 'Die Post',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/migros.png',
    text: 'Migros',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/sbb.png',
    text: 'SBB',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/swisscom.png',
    text: 'Swisscom',
  },
  {
    value: 'https://d375st87gcum0p.cloudfront.net/images/badges/vcs.png',
    text: 'VCS',
  },
];

export default defaultImages;
