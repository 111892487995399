<template>
  <div class="max-w-md flex flex-col gap-4">
    <cc-input
      id="label"
      v-model="address.label"
      name="label"
      :label="$t('configs.label')"
      :placeholder="$t('configs.label')"
      validation-rules="required"
      :autofocus="hasFocusOnLabel"
      />
    <div class="flex w-full gap-4">
      <cc-input
        v-model="address.street"
        :label="$t('configs.street')"
        :placeholder="$t('configs.street')"
        class="w-full"
        validation-rules="required"
        />
      <cc-input
        v-model="address.number"
        :label="$t('configs.number')"
        class="max-w-32"
        :placeholder="$t('configs.number')"
        validation-rules="required"
        />
    </div>

    <div class="flex gap-4">
      <cc-input
        v-model="address.city"
        :label="$t('configs.city')"
        class="w-full"
        :placeholder="$t('configs.city')"
        validation-rules="required"
        />
      <cc-input
        v-model="address.zipCode"
        :label="$t('configs.zipCode')"
        :placeholder="$t('configs.zipCode')"
        validation-rules="required"
        />

      <cc-input
        v-model="address.country"
        :label="$t('configs.country')"
        class="w-full max-w-32"
        :placeholder="$t('configs.country')"
        validation-rules="required"
        />
    </div>

    <cc-button
      v-if="hasRemove"
      :text="$t('commons.remove')"
      variant="secondary"
      @click.native="$emit('remove-address', address)"
      />

    <div
      v-if="hasMap && address && address.geolocation"
      :id="address && address.id"
      class="h-48"
      />
    <div
      v-else-if="hasMap && address && !address.geolocation"
      class="h-48 flex items-center justify-center bg-gray-100 border border-gray-200 text-gray-500 rounded-md"
      >
      Please save to see the map
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    hasRemove: {
      type: Boolean,
      default: false,
    },
    hasFocusOnLabel: {
      type: Boolean,
      default: false,
    },
    hasMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapBoxToken: process.env.VUE_APP_MAPBOX_TOKEN,
    };
  },
  mounted() {
    if (this.hasMap && this.address?.geolocation) {
      const coordinates = this.address?.geolocation?.split(',');
      const flippedCoordinates = [coordinates?.[1], coordinates?.[0]];
      this.initMapbox(this.address.id, flippedCoordinates);
    }
  },
  methods: {
    initMapbox(containerId, coordinates) {
      mapboxgl.accessToken = this.mapBoxToken;
      const map = new mapboxgl.Map({
        container: containerId,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coordinates,
        zoom: 14,
      });
      map.on('load', () => {
        new mapboxgl.Marker({ color: '#0057df' }).setLngLat(coordinates).addTo(map);
      });
    },
  },
};
</script>

<style>
@import 'https://api.mapbox.com/mapbox-gl-js/v2.10.0/mapbox-gl.css';
</style>
