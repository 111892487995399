<template>
  <div>
    <cc-section-table :title="$t('pages.createMessage.title')">
      <div class="mb-8 border-b border-gray-300" />

      <validation-observer ref="form">
        <!-- Message Options -->
        <div class="pb-16">
          <div class="grid gap-6 grid-cols-1 pb-4 max-w-sm">
            <cc-input
              v-model="payload.name"
              :label="$t('pages.createMessage.internalName')"
              ruid="create-message__internal-name"
              :placeholder="$t('pages.createMessage.internalNamePlaceholder')"
              validation-rules="required"
              :disabled="isReadOnlyMessage"
              />
          </div>

          <!-- Delivery Methods -->
          <div class="pb-4">
            <div class="flex items-end flex-wrap">
              <cc-check-box
                :label="$t('commons.fieldNames.deliveryMethod')"
                ruid="create-message__send-message-as-inbox"
                :text="$t('configs.sendMessageAsInboxMessage')"
                class="flex-shrink-0 mr-4 my-2"
                :model-value="!isEmpty(payload.payload.inbox)"
                :disabled="isReadOnlyMessage"
                @change="handleDeliveryMethodSelection($event, 'inbox')"
                />
              <cc-check-box
                ruid="create-message__send-message-as-push"
                :text="$t('configs.sendMessageAsPushNotification')"
                class="flex-shrink-0 mr-4 my-2"
                :model-value="!isEmpty(payload.payload.push)"
                :disabled="isReadOnlyMessage"
                @change="handleDeliveryMethodSelection($event, 'push')"
                />
              <cc-check-box
                ruid="create-message__send-message-as-mail"
                :text="$t('configs.sendMessageAsEmail')"
                class="flex-shrink-0 mr-4 my-2"
                :model-value="!isEmpty(payload.payload.mail)"
                @change="handleDeliveryMethodSelection($event, 'mail')"
                />
            </div>

            <validation-provider
              v-slot="{ errors }"
              ref="deliveryMethod"
              :name="$t('commons.fieldNames.deliveryMethod')"
              :rules="`${isDeliveryMethodSelected ? '' : 'required'}`"
              mode="aggressive"
              >
              <cc-error-message
                v-if="errors && errors[0]"
                class="flex-grow w-full"
                :message="errors[0]"
                />
            </validation-provider>
          </div>

          <!-- Message Routes & Categories & Scheduling -->
          <div class="w-80 pr-4">
            <cc-select
              id="message-routes"
              v-model="getMessageHref"
              class="pb-4"
              ruid="create-message__message-routes"
              :label="$t('pages.createMessage.messageRouteLabel')"
              :options="generateOptions('mobileAppRoutes')"
              :has-de-select="true"
              :disabled="isReadOnlyMessage"
              />
            <cc-select
              id="delivery-tags"
              v-model="getDeliveryTag"
              ruid="create-message__delivery-tags"
              :label="$t('commons.fieldNames.deliveryTag')"
              class="pb-4"
              validation-rules="required"
              :options="generateOptions('deliveryTags')"
              :has-de-select="true"
              :disabled="isReadOnlyMessage"
              />

            <cc-check-box
              v-model="isMessageScheduled"
              :label="$t('commons.fieldNames.messageScheduling')"
              ruid="create-message__send-message-as-inbox"
              :text="$t('configs.isMessageScheduled')"
              class="flex-shrink-0 pb-4"
              :disabled="isReadOnlyMessage"
              />

            <cc-date-and-time-picker
              v-if="isMessageScheduled"
              class="pb-4"
              :disabled="isReadOnlyMessage"
              :date="payload.scheduledDate && payload.scheduledDate.split('T')[0]"
              :date-label="$t('commons.fieldNames.date')"
              :time="getTime(payload.scheduledDate)"
              :time-label="$t('commons.fieldNames.time')"
              @iso-format="($event) => (payload.scheduledDate = $event)"
              />
          </div>

          <cc-audience-selection
            :is-read-only-message="isReadOnlyMessage"
            :user-tags="payload.userTags"
            :user-ids="payload.userIds"
            :tag-classes-to-skip="['context', 'feature']"
            @user-tags="payload.userTags = $event"
            @user-ids="payload.userIds = $event"
            />

          <!-- Inbox Message resources -->
          <cc-message-composer
            v-if="!isEmpty(payload.payload.inbox)"
            id="inbox-message-composer"
            class="pt-8"
            message-type="inbox"
            :heading="$t('pages.createMessage.inboxMessage')"
            :disabled="isReadOnlyMessage"
            :message-resources="messageResources"
            />

          <!--  Mail Message resources -->
          <cc-message-composer
            v-if="!isEmpty(payload.payload.mail)"
            id="mail-message-composer"
            class="pt-8"
            message-type="mail"
            :heading="$t('pages.createMessage.mailMessage')"
            :image="false"
            :body-url="true"
            :body="false"
            :has-preview="false"
            :disabled="isReadOnlyMessage"
            :message-resources="mailResources"
            />

          <!-- Push Notification resources -->
          <cc-push-message-composer
            v-if="payload.payload.push"
            id="push-message-composer"
            class="pt-8"
            :disabled="isReadOnlyMessage"
            :push-resources="pushResources"
            :push-meta="pushMeta"
            />
        </div>
      </validation-observer>

      <!-- Action buttons -->
      <div class="flex flex-wrap justify-end">
        <cc-button
          v-if="isDraftMessage"
          variant="red"
          :text="$t('commons.delete')"
          :block="false"
          class="m-2"
          ruid="message__delete-draft-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="isDeleteConfirmationOpen = true"
          />

        <cc-button
          v-if="isScheduledMessage"
          variant="red"
          :text="$t('commons.delete')"
          :block="false"
          class="m-2"
          ruid="message__delete-scheduled-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="isScheduledDeleteConfirmationOpen = true"
          />

        <cc-button
          v-if="!isReadOnlyMessage"
          variant="secondary"
          :text="$t('commons.cancel')"
          :block="false"
          class="m-2"
          ruid="message__cancel-button"
          @click.native="handleCancel"
          />

        <cc-button
          v-if="!isDraftMessage && !isReadOnlyMessage && !isScheduledMessage"
          variant="secondary"
          :text="$t('commons.saveDraft')"
          :block="false"
          class="m-2"
          ruid="message__save-draft-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="saveDraft"
          />

        <cc-button
          v-if="isDraftMessage"
          variant="secondary"
          :text="$t('commons.updateDraft')"
          :block="false"
          class="m-2"
          ruid="message__update-draft-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="updateDraft"
          />

        <cc-button
          v-if="!isReadOnlyMessage && !isScheduledMessage"
          :text="isMessageScheduled ? $t('commons.schedule') : $t('commons.send')"
          class="m-2"
          :block="false"
          ruid="message__create-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="confirmBroadcastMessage"
          />

        <cc-button
          v-if="isScheduledMessage"
          :text="$t('commons.update')"
          class="m-2"
          :block="false"
          ruid="message__update-button"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="updateScheduledMessage"
          />
      </div>

      <cc-modal
        v-if="isConfirmOpen"
        :is-open="isConfirmOpen"
        transition-name="slide-up"
        :heading="$t('modals.confirmBroadcastMessage.title')"
        @close="isConfirmOpen = false"
        >
        <template #body>
          <div class="max-w-sm">
            <cc-text color="text-gray-600">
              {{ $t('modals.confirmBroadcastMessage.body') }}
            </cc-text>

            <ul
              v-if="isDeliveryMethodSelected"
              class="list-outside list-disc ml-5 pt-3"
              >
              <li
                v-if="payload.payload.push"
                class="text-primary-dark text-xl"
                >
                <cc-text color="text-gray-600">
                  {{ $t('modals.confirmBroadcastMessage.pushNotification') }}
                </cc-text>
              </li>

              <li
                v-if="payload.payload.inbox"
                class="text-primary-dark text-xl"
                >
                <cc-text
                  color="text-gray-600"
                  >
                  {{ $t('modals.confirmBroadcastMessage.inboxMessage') }}
                </cc-text>
              </li>
            </ul>
          </div>
        </template>

        <template #footer="{ close }">
          <div class="grid grid-cols-2 gap-x-4">
            <cc-button
              variant="secondary"
              :text="$t('commons.cancel')"
              :disabled="isLoading"
              @click.native="close"
              />
            <cc-button
              :text="$t('commons.continue')"
              :is-loading="isLoading"
              :disabled="isLoading"
              @click.native="broadcastMessage"
              />
          </div>
        </template>
      </cc-modal>

      <cc-modal
        v-if="isValidationFeedbackOpen"
        :is-open="isValidationFeedbackOpen"
        transition-name="slide-up"
        :heading="$t('modals.validationFailed.title')"
        @close="isValidationFeedbackOpen = false"
        >
        <template #body>
          <div class="max-w-sm">
            <cc-text color="text-gray-600">
              {{ $t('modals.validationFailed.body') }}
            </cc-text>
          </div>
        </template>

        <template #footer="{ close }">
          <div class="grid grid-cols-1 gap-x-4">
            <cc-button
              variant="secondary"
              :text="$t('commons.close')"
              @click.native="close"
              />
          </div>
        </template>
      </cc-modal>

      <cc-modal
        v-if="hasRemovedTags"
        :is-open="hasRemovedTags"
        transition-name="slide-up"
        :heading="$t('modals.hasRemovedTags.title')"
        @close="hasRemovedTags = false"
        >
        <template #body>
          <div class="max-w-md">
            {{ $t('modals.hasRemovedTags.body') }}

            <ul
              v-for="(tag, i) in $route.params.removedTags"
              :key="i"
              class="list-outside list-disc ml-5 pt-3"
              >
              <li
                class="text-primary-dark text-xl"
                >
                <cc-text
                  color="text-gray-600"
                  class="capitalize"
                  >
                  {{ tag }}
                </cc-text>
              </li>
            </ul>
          </div>
        </template>

        <template #footer="{ close }">
          <div class="grid grid-cols-1 gap-x-4">
            <cc-button
              variant="secondary"
              :text="$t('commons.close')"
              @click.native="close"
              />
          </div>
        </template>
      </cc-modal>

      <cc-delete-confirmation-modal
        v-if="isDeleteConfirmationOpen"
        :is-open="isDeleteConfirmationOpen"
        :heading="$t('modals.deleteDraftMessageConfirmation.title')"
        :body="$t('modals.deleteDraftMessageConfirmation.body')"
        @close="isDeleteConfirmationOpen = false"
        @granted-delete-confirmation="deleteDraft(payload.draftId)"
        />

      <cc-delete-confirmation-modal
        v-if="isScheduledDeleteConfirmationOpen"
        :is-open="isScheduledDeleteConfirmationOpen"
        :heading="$t('modals.deleteScheduledMessageConfirmation.title')"
        :body="$t('modals.deleteScheduledMessageConfirmation.body')"
        @close="isScheduledDeleteConfirmationOpen = false"
        @granted-delete-confirmation="deleteScheduled(payload.id)"
        />

      <cc-code class="mt-36">
        {{ payload }}
      </cc-code>
    </cc-section-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { cloneDeep, isEmpty } from 'lodash';
import { sentenceCase, removeEmptyKeysFromObject } from '@/mixins/utils';
import validator from '@/mixins/validator';
import mobileAppRoutes from '@/mixins/mobileAppRoutes';

/**
 * TODO: We might need delete empty language keys before posting the payload.
 * Maybe a watcher?
 */
export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      isValidationFeedbackOpen: false,
      isConfirmOpen: false,
      isDeleteConfirmationOpen: false,
      isScheduledDeleteConfirmationOpen: false,
      hasRemovedTags: false,
      shouldSendEmail: false,
      shouldSendInboxMessage: true,
      scheduledMessage: false,
      messageRoute: null,
      href: null,
      payload: {
        name: null,
        payload: {},
        meta: {},
        userTags: [],
        userIds: [],
        deliveryTag: null,
        scheduledDate: null,
      },
      deliveryTags: [
        //  TODO move hardcoded strings to locale file
        { 'general-information:app-updates': 'General Information: App Updates' }, //
        { 'challenge:new': 'Challenge: New Challenge' }, //
        { 'challenge:completion': 'Challenge: Challenge Success' },
        { 'newsfeed:climate-news': 'Newsfeed: Climate News' },
      ],
    };
  },
  computed: {
    ...mapState({
      tags: st => st.message.tags,
    }),
    ...mapGetters({
      supportedLangs: 'common/supportedLangs',
    }),
    mobileAppRoutes() {
      return mobileAppRoutes;
    },
    isReadOnlyMessage() {
      return !!(this.$route.params.type === 'message' && this.$route.params.messageId);
    },
    isDraftMessage() {
      return this.$route.params.type === 'draft-message';
    },
    isScheduledMessage() {
      return this.$route.params.type === 'scheduled-message';
    },
    isDeliveryMethodSelected() {
      return Object.keys(this.payload.payload).length > 0;
    },
    getMessageHref: {
      get() {
        const href = this.href?.split(':')[1];

        return href;
      },
      set(val) {
        if (val) {
          Object.keys(this.payload.payload)
            .filter(e => this.payload.payload[e])
            .forEach((messageType) => {
              this.payload.payload[messageType].meta.href = `internal:${val}`;
            });

          this.href = val ? `internal:${val}` : val;
        } else {
          Object.keys(this.payload.payload)
            .filter(e => this.payload.payload[e])
            .forEach((messageType) => {
              this.payload.payload[messageType].meta.href = null;
            });

          this.href = null;
        }
      },
    },
    getDeliveryTag: {
      get() {
        return this.payload?.deliveryTag;
      },
      set(val) {
        this.$set(this.payload, 'deliveryTag', val);
      },
    },
    isMessageScheduled: {
      get() {
        return this.scheduledMessage || !!this.payload.scheduledDate;
      },
      set(val) {
        this.scheduledMessage = val;
        if (!val) {
          this.$set(this.payload, 'scheduledDate', null);
        }
      },
    },
    meta() {
      return this.payload?.meta;
    },
    mailResources() {
      return this.payload?.payload?.mail?.resources;
    },
    messageResources() {
      return this.payload?.payload?.inbox?.resources;
    },
    pushResources() {
      return this.payload?.payload?.push?.resources;
    },
    pushMeta() {
      return this.payload?.payload?.push?.meta;
    },
  },
  created() {
    if (this.$route.params.message) {
      this.payload = cloneDeep(this.$route.params.message);
    }

    if (this.$route.params.removedTags?.length) {
      this.hasRemovedTags = true;
    }

    if (this.$route.params.payload) {
      this.payload = { ...this.$route.params.payload };

      // handle href separetly as its value stored outside of `this.payload`
      this.href = this.payload.payload[
        Object.keys(this.payload.payload).filter(e => this.payload.payload[e])?.[0]
      ]?.meta.href;
    }
  },
  methods: {
    isEmpty,
    sentenceCase,
    cloneDeep,
    removeEmptyKeysFromObject,
    handleCancel() {
      if (this.$route.params.type === 'draft-message') {
        this.$router.push({ name: 'drafts' });
      } else {
        this.$router.push({ name: 'messages' });
      }
    },
    async deleteScheduled(messageId) {
      try {
        this.isDeleting = true;

        await this.$store.dispatch('message/deleteScheduledMessage', messageId);

        this.$router.push({ name: 'scheduled' });
      } catch (err) {
        this.$log.error('Error: deleteDraft', err);
      } finally {
        this.isDeleting = false;
      }
    },
    async deleteDraft(draftId) {
      try {
        this.isDeleting = true;

        await this.$store.dispatch('draft/deleteDraft', draftId);

        this.$router.push({ name: 'drafts' });
      } catch (err) {
        this.$log.error('Error: deleteDraft', err);
      } finally {
        this.isDeleting = false;
      }
    },
    async updateScheduledMessage() {
      try {
        this.$log.info('Form has been submitted!');

        this.isLoading = true;

        const response = await this.$store.dispatch('message/updateScheduledMessage', this.payload);

        this.$log.info('Response: updateScheduledMessage', response);

        this.$router.push({ name: 'scheduled' });
      } catch (err) {
        this.$log.error('Error: updateScheduledMessage', err);
      } finally {
        this.isLoading = false;
      }
    },
    async updateDraft() {
      try {
        this.$log.info('Form has been submitted!');

        this.isLoading = true;

        const response = await this.$store.dispatch('draft/updateDraft', this.payload);

        this.$log.info('Response: updateDraft', response);

        this.$router.push({ name: 'drafts' });
      } catch (err) {
        this.$log.error('Error: updateDraft', err);
      } finally {
        this.isLoading = false;
      }
    },
    async saveDraft() {
      try {
        this.$log.info('Saving as Draft');

        const draftPayload = this.payload;

        this.isLoading = true;

        draftPayload.payload = this.removeEmptyKeysFromObject(draftPayload.payload);

        const response = await this.$store.dispatch('draft/saveDraft', draftPayload);

        this.$log.info('Response: saveDraft', response);

        this.$router.push({ name: 'drafts' });
      } catch (err) {
        this.$log.error('Error: saveDraft', err);
      } finally {
        this.isLoading = false;
      }
    },
    async broadcastMessage() {
      try {
        this.$log.info('Form has been submitted!');

        this.isLoading = true;

        const draftIdToDelete = this.payload.draftId;
        delete this.payload.draftId;

        let response;

        if (this.isMessageScheduled) {
          response = await this.$store.dispatch('message/scheduleBroadcastMessage', this.payload);
        } else {
          response = await this.$store.dispatch('message/broadcastMessage', this.payload);
        }

        if (draftIdToDelete) {
          await this.deleteDraft(draftIdToDelete);
        }

        this.$log.info('Response: broadcastMessage', response);

        this.$router.push({ name: this.isMessageScheduled ? 'scheduled' : 'messages' });
      } catch (err) {
        this.$log.error('Error: broadcastMessage', err);
      } finally {
        this.isLoading = false;
      }
    },
    async confirmBroadcastMessage() {
      const success = await this.$refs.form.validate();

      if (!success) {
        this.$log.info('Form !success', success);
        this.isValidationFeedbackOpen = true;
        return;
      }

      this.isConfirmOpen = true;
    },
    getTime(date) {
      const addZero = i => (i < 10 ? `0${i}` : i);

      const d = new Date(date);
      return `${addZero(d.getHours())}:${addZero(d.getMinutes())}`;
    },
    generateOptions(source) {
      return this[source]
        .slice()
        .map(el => [{ value: Object.keys(el)[0], text: Object.values(el)[0] }])
        .flat();
    },
    handleDeliveryMethodSelection(event, deliveryMethod) {
      if (event) {
        this.$set(this.payload.payload, deliveryMethod, {
          resources: {},
          meta: {
            href: this.href,
          },
        });
      } else {
        this.$delete(this.payload.payload, deliveryMethod);
      }
      this.$refs.deliveryMethod.reset();
    },
    getValue(target, key) {
      return target?.[key];
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
    getResourceValue(target, key, lang) {
      return target?.[lang]?.[key];
    },
    setResourceValue(target, key, value, lang) {
      this.$set(target, lang, { ...target[lang], [key]: value });
    },
  },
};
</script>

<style lang="postcss">
.android-notification-card {
  background: #474747;
  @apply flex flex-col justify-start items-start flex-shrink-0 w-96 mr-8 rounded-3xl p-4 shadow-sm;
}
.android-notification-title {
  @apply overflow-hidden w-64 whitespace-nowrap overflow-ellipsis tracking-tight text-gray-100;
}

.ios-notification-card {
  @apply flex justify-start items-center flex-shrink-0 w-96 mr-8 bg-gray-100 rounded-3xl p-4 shadow-sm;
}
.ios-notification-title {
  @apply leading-5 overflow-hidden w-64 whitespace-nowrap overflow-ellipsis tracking-tight;
}
</style>
