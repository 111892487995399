import Vue from 'vue';
import * as utils from '@/mixins/utils';
import UserService from '@/services/UserService';
import TagService from '@/services/TagService';
import { getAccessToken, setAccessToken, removeAccessToken } from '@/services/SecurityService';
import CompanyService from '@/services/CompanyService';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = () => ({
  isAuthenticated: false,
  id: null,
  roles: [],
  userRoles: [],
  user: null,
  /**
   * @todo no idea why this text is here in the store?!
   */
  passwordrules:
    'minlength: 8; required: lower; required: upper; required: digit; required: [-!#$%&()*+,./:;<=>?@~];',
});

const mutations = {
  setState(state, [prop, value]) {
    Vue.set(state, prop, value);
  },

  pushState(state, [prop, value]) {
    state[prop].push(value);
  },
};

const actions = {
  async getUser({ commit, dispatch }, jwt) {
    // const context = process.env.VUE_APP_CONTEXT;
    Vue.$log.warn('Starting getUser');

    let token = jwt || getAccessToken();

    token = utils.isBase64(token) ? atob(token) : token;

    if (!token) {
      throw Error('Not authorized');
    }

    try {
      // if (!state.isAuthenticated) {
      Vue.$log.info('Will getUser');

      setAccessToken(token);

      const user = await UserService.getUser();
      await dispatch('getUserRoles');

      commit('setState', ['user', user]);

      commit('setState', ['isAuthenticated', true]);

      commit('setState', ['id', utils.decodeAccessToken(token)?.sub]);

      if (utils.decodeAccessToken(token)?.roles) {
        Object.values(utils.decodeAccessToken(token)?.roles).forEach((role) => {
          commit('pushState', ['roles', role]);
        });
      } else {
        throw Error('Not authorized');
      }
      // }
    } catch (err) {
      dispatch('logout');

      Vue.$log.error('Error: getUser', err);
      throw err;
    }
    return false;
  },

  async login({ dispatch }, payload) {
    try {
      const jwt = await UserService.login(payload);
      await dispatch('getUser', jwt);
    } catch (err) {
      Vue.$log.error('Error: login', err);
      throw err;
    }
  },

  async logout({ commit }) {
    removeAccessToken();
    commit('setState', ['user', null]);
    commit('setState', ['isAuthenticated', false]);
    commit('setState', ['roles', []]);
    commit('setState', ['id', null]);
    commit('company/setState', ['companies', []], { root: true });
  },

  async updatePassword(ctx, password) {
    const response = await UserService.updatePassword(password);
    return Promise.resolve(response);
  },

  async forgotPassword({ state }, email) {
    Vue.$log.info('forgotPassword', state);

    const lang = 'en';

    const payload = {
      emailToAddress: email,
      lang,
    };

    const response = await UserService.forgotPassword(payload);
    return Promise.resolve(response);
  },

  async confirmForgottenPassword(ctx, payload) {
    const response = await UserService.confirmForgottenPassword(payload);
    return Promise.resolve(response);
  },

  async updateProfile(ctx, payload) {
    const response = await UserService.updateProfile(payload);
    return Promise.resolve(response);
  },

  async requestVerification(ctx, phoneNumber) {
    const response = await UserService.requestVerification(phoneNumber);
    return Promise.resolve(response);
  },

  async verifyToken(ctx, { phoneNumber, token, context }) {
    const response = await UserService.verifyToken(phoneNumber, token, context);
    return Promise.resolve(response);
  },

  async getUsersByTag(context, payload) {
    const response = await TagService.getUsersByTag(payload);
    return Promise.resolve(response);
  },

  async getPendingUsers(context, { companyId }) {
    const response = await CompanyService.getPendingUsers(companyId);
    return Promise.resolve(response);
  },

  async getUserRoles({ commit }) {
    const response = await UserService.getUserRoles();

    commit('setState', ['userRoles', response]);

    return Promise.resolve(response);
  },
};

const getters = {
  isAuthenticated: st => st.isAuthenticated,
  /**
   * @todo isContextAdmin to be removed once we implement in-page/per endpoint action checks
   */
  isContextAdmin: st => st.roles?.includes('ROLE_CONTEXT_ADMIN'),
  getAccessToken: () => getAccessToken(),
  getUserRoles: st => st.userRoles,
  getUserActions: st => [...new Set(st.userRoles?.flatMap(role => role.roleActions))] || [],
  // eslint-disable-next-line no-unused-vars
  fallbackRouteResolver: (st, getters) => (routes, to, from, next) => {
    Vue.$log.info('fallbackRouteResolver intended to', to);

    const userActions = getters.getUserActions;
    const companyId = to?.params?.companyId || st.user?.companies?.[0]?.id;

    const enrichingParams = {
      ...(companyId && { companyId }),
      ...(to && { previousRoute: to }),
    };

    const notAuthorizedParentRoute = routes.find(r => r.name === 'not-authorized');
    // Enrich route with previous route to inform user on landing
    notAuthorizedParentRoute.params = {
      ...notAuthorizedParentRoute.params,
      ...enrichingParams,
    };


    const hasRequiredParams = (child, toRoute) => child?.path
        .split('/')
        .filter(str => str[0] === ':' && str[str.length - 1] !== '?')
        .map(str => str.substring(1))
        .every((param) => {
          Vue.$log.info(
            `Checking param ${param} for child ${child && child.name}`,
            { child: child?.params },
            { to: toRoute?.params },
          );
          return Object.keys(child?.params || [])?.includes(param);
        });

    // Vue.$log.info('fallbackRouteResolver routes', routes);

    // Find parent route of the target route if available
    const parentRoute = routes?.find(r => r?.children?.find(c => c.name === to?.name));
    Vue.$log.info('parentRoute', parentRoute);


    /**
     * Find next possible child of the parent prioritizing incoming `to` while
     * considering weight, isNavigationItem, params it must have and actions
     * Weight of the route plays a key role that helps determining the fallback
     * of each previous route.
     */
    const nextPossibleChildRoute = parentRoute?.children
      ?.filter(child => child.name === to.name || child.meta.isNavigationItem)
      ?.sort((a, b) => b.meta.weight - a.meta.weight)
      ?.map(r => ({
        ...r,
        params: { ...r.params, ...enrichingParams },
      }))
      ?.filter(child => hasRequiredParams(child, to))
      ?.find(
        r => r.meta.isNavigationFor.some(entry => userActions.includes(entry))
          || r.meta.isSubNavigationFor.some(entry => userActions.includes(entry)),
      );

    Vue.$log.info('nextPossibleChildRoute', nextPossibleChildRoute);

    if (nextPossibleChildRoute) {
      return nextPossibleChildRoute;
    }

    /**
     * If we could not find a possible child of the parent,
     * we look for any possible next route regardless of its parent
     */
    const allowedChildRoutes = [...routes.flatMap(e => e.children)]
      ?.filter(r => r && r.meta.isNavigationItem)
      ?.sort((a, b) => b.meta.weight - a.meta.weight)
      ?.map(r => ({ ...r, params: { ...r.params, ...enrichingParams } }))
      ?.filter(child => hasRequiredParams(child, to))
      ?.find(
        r => r.meta.isNavigationFor.some(entry => userActions.includes(entry))
          || r.meta.isSubNavigationFor.some(entry => userActions.includes(entry)),
      );

    Vue.$log.info('allowedChildRoutes', allowedChildRoutes);
    Vue.$log.info('notAuthorizedParentRoute', notAuthorizedParentRoute);

    return allowedChildRoutes || notAuthorizedParentRoute;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
