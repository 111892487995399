<template>
  <div id="navigation">
    <div class="py-4 h-20 pr-14 flex items-center justify-between bg-white flex-shrink-0">
      <div class="flex items-center justify-center">
        <transition
          name="view-fade"
          mode="out-in"
          >
          <div
            :key="headingKey"
            class="flex justify-center items-center"
            >
            <cc-button
              v-if="hasBackButton"
              variant="plain"
              svg="icons/icon-arrow-left-tail"
              svg-color="text-gray-800"
              svg-align="left"
              :has-min-width="false"
              :block="false"
              class="ml-3"
              @click.native="
                $router
                  .push({
                    name: currentRouteMeta.backTo || parentRoute.meta.backTo,
                  })
                  .catch(() => {})
              "
              />
            <cc-heading
              tag="h1"
              weight="semibold"
              :data-ruid="`top-navigation__${$route.name}`"
              :class="hasBackButton ? 'pl-2' : 'px-14'"
              >
              {{
                dynamicTitle
                  ? dynamicTitle
                  : $t(currentRouteMeta.navigationTitle) || $t(parentRoute.meta.navigationTitle)
              }}
            </cc-heading>
          </div>
        </transition>
      </div>
      <!-- {{ subNavigationItems }} -->

      <cc-navigation-actions />
    </div>

    <div
      id="sub-navigation"
      class="mx-14 border-b border-gray-100"
      >
      <transition
        name="view-fade"
        mode="out-in"
        >
        <ul
          :key="headingKey"
          class="list-none"
          :class="!!subNavigationItems.length ? 'border-t' : null"
          >
          <router-link
            v-for="item in subNavigationItems"
            :key="`sub-nav-${item.path}`"
            v-slot="{ href, navigate, isExactActive }"
            :to="{ name: item.name }"
            tag="li"
            class="transition-all duration-500 ease-out hover:border-gray-800"
            custom
            >
            <a
              :href="href"
              :class="[isExactActive && 'border-gray-800']"
              class="py-4 mr-8 inline-block border-b-2 border-transparent"
              @click="navigate"
              >
              <cc-text color="text-gray-800">
                {{ $t(item.navigationLabel) }}
              </cc-text>
            </a>
          </router-link>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    dynamicTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      user: st => st.user,
      userRoles: st => st.user.roles,
    }),
    ...mapGetters({
      getUserActions: 'user/getUserActions',
    }),
    // We might need better handling as navigating from `not-authorized` to a route
    // with subNavigation causes the title to appear suddenly rather than fading it
    headingKey() {
      return (
        this.dynamicTitle
        || this.currentRouteMeta.navigationTitle
        || this.parentRoute.meta.navigationTitle
      );
    },
    hasBackButton() {
      return (
        this.currentRouteMeta?.hasBackButtonFor?.some(entry => this.getUserActions?.includes(entry))
        || this.parentRoute?.meta?.hasBackButtonFor?.some(entry => this.getUserActions?.includes(entry))
      );
    },
    parentRoute() {
      return this.$router.options.routes.find(
        route => route.path === this.$route.matched[0].path,
      );
    },
    currentRouteMeta() {
      return this.$route.meta;
    },
    subNavigationItems() {
      if (this.currentRouteMeta.hasSubNavigation) {
        const items = this.$router.options.routes
          .find(route => route.path === this.$route.matched[0].path)
          .children.filter(child => child?.meta
              ?.isSubNavigationFor
              ?.some(entry => this.getUserActions?.includes(entry))
              && child.meta.hasSubNavigation)
          .map(route => ({
            name: route.name,
            path: route.path,
            navigationLabel: route.meta.navigationLabel,
          }));

        // this.$log.info('Generating sub-navigation items', items);

        return items;
      }
      return [];
    },
  },
};
</script>
