<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t('modals.manageFeatureTags.title')"
    @close="$emit('close')"
    >
    <template #body>
      <div class="whitespace-pre-line max-w-sm">
        <p class="pb-4">
          {{ $tc('modals.manageFeatureTags.body', userIds.length, { amount: userIds.length}) }}
        </p>

        <cc-feature-tags
          :tags="insertedTags"
          @add-tag="insertedTags.push($event)"
          @delete-tag="deleteTag($event)"
          />

        <cc-error-message
          v-if="errors.length"
          :message="errors[0]"
          class="mt-4"
          />
      </div>
    </template>

    <template #footer="{ close }">
      <div class="grid grid-cols-3 gap-x-4">
        <cc-button
          variant="secondary"
          :text="$t('commons.close')"
          :has-min-width="false"
          :disabled="isRemovingTags || isAddingTags"
          @click.native="close"
          />
        <cc-button
          variant="red"
          :text="$t('commons.remove')"
          :has-min-width="false"
          :is-loading="isRemovingTags"
          :disabled="!insertedTags.length || isAddingTags || isRemovingTags"
          @click.native="deleteFeatureTagsFromUsers(userIds, insertedTags)"
          />
        <cc-button
          :text="$t('commons.add')"
          :has-min-width="false"
          :is-loading="isAddingTags"
          :disabled="!insertedTags.length || isRemovingTags || isAddingTags"
          @click.native="addFeatureTagsToUsers(userIds, insertedTags)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import TagService from '@/services/TagService';

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    userIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isAddingTags: false,
      isRemovingTags: false,
      insertedTags: [],
      errors: [],
    };
  },
  methods: {
    deleteTag(tagId) {
      const idIdx = this.insertedTags.findIndex(t => t === tagId);

      if (idIdx >= 0) {
        this.insertedTags.splice(idIdx, 1);
      }
    },
    async addFeatureTagsToUsers(userIds, tags) {
      try {
        this.errors = [];
        this.isAddingTags = true;

        await TagService.addFeatureTagsToUsers({ ids: userIds, tags });
        this.insertedTags = [];
        this.$emit('add-success');
      } catch (error) {
        this.$log.info('Error: addFeatureTagsToUsers', error);
        this.handleError(error);
      } finally {
        this.isAddingTags = false;
      }
    },
    async deleteFeatureTagsFromUsers(userIds, tags) {
      try {
        this.errors = [];
        this.isRemovingTags = true;

        await TagService.deleteFeatureTagsFromUsers({ ids: userIds, tags });

        this.insertedTags = [];
        this.$emit('delete-success');
      } catch (error) {
        this.$log.info('Error: deleteFeatureTagsFromUsers', error);
        this.handleError(error);
      } finally {
        this.isRemovingTags = false;
      }
    },
    handleError(error) {
      if (error?.response?.status === 403) {
        this.errors.push(this.$t('errors.missingActionsOrRoles'));
      } else {
        this.errors.push(this.$t('errors.genericError'));
      }
    },
  },
};
</script>

<style></style>
