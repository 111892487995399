// const tokenName = 'accessToken';

export function hasAccessToken() {
  return !!sessionStorage.getItem('accessToken');
}

export function getAccessToken() {
  return sessionStorage.getItem('accessToken');
}

export function setAccessToken(accessToken) {
  return sessionStorage.setItem('accessToken', accessToken);
}

export function removeAccessToken() {
  return sessionStorage.removeItem('accessToken');
}

export function authHeader() {
  const token = getAccessToken();

  return {
    Authorization: `Bearer ${token}`,
  };
}
