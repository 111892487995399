import ApiService from '@/services/ApiService';

const version = '/v1';
const resource = `${version}/admin/juoco`;

export default {
  async createFullChallenge(payload) {
    return ApiService.securePost(`${resource}/createFullChallenge`, payload);
  },

  async getAllChallenges() {
    return ApiService.get(`${resource}/getAllChallenges`);
  },

  async getChallengeById(challengeId) {
    return ApiService.get(`${resource}/getChallenge/${challengeId}`);
  },

  async deleteChallenge(challengeId) {
    return ApiService.delete(`${resource}/deleteChallenge?challengeId=${challengeId}`);
  },

  async updateFullChallenge(challengeId, payload) {
    return ApiService.put(`${resource}/updateFullChallenge/${challengeId}`, payload);
  },

};
