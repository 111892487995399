<template>
  <div v-if="hasSome.some(action => getUserActions.includes(action))">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    hasSome: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUserActions: 'user/getUserActions',
    }),
  },
};
</script>
