<template>
  <div>
    <cc-section-table :title="$t('pages.profile.title')">
      <validation-observer ref="form">
        <form @submit.prevent="updateProfile">
          <div class="grid gap-6 grid-cols-1 mb-8 max-w-sm">
            <cc-input
              v-model="userProfile.id"
              :label="$t('commons.fieldNames.userId')"
              :disabled="true"
              />

            <cc-input
              v-model="userProfile.email.address"
              :label="$t('commons.fieldNames.merchantEmail')"
              name="email"
              :disabled="true"
              />
            <cc-input
              v-model="userProfile.name"
              :label="$t('commons.fieldNames.merchantName')"
              name="name"
              validation-rules="required"
              />
            <cc-input
              v-model="userProfile.metaPrivate.address"
              :label="$t('commons.fieldNames.companyAddress')"
              validation-rules="required"
              />
            <cc-input
              v-model="userProfile.metaPrivate.city"
              :label="$t('commons.fieldNames.city')"
              name="address-level1"
              validation-rules="required"
              />
            <cc-input
              v-model="userProfile.metaPrivate.postalCode"
              :label="$t('commons.fieldNames.postalCode')"
              name="postal-code"
              validation-rules="required"
              />
          </div>

          <cc-button
            text="Change Password"
            variant="secondary"
            :block="false"
            @click.native="$router.push({ name: 'change-password' })"
            />


          <!-- Action buttons -->
          <div class="flex flex-col items-end flex-wrap justify-end pt-4">
            <cc-button
              :text="$t('commons.save')"
              :block="false"
              :is-loading="isLoading"
              :disabled="isLoading"
              @click.native="updateProfile"
              />
            <cc-error-message
              v-if="errors.length"
              :message="errors[0]"
              class="my-4"
              />
          </div>
        </form>
      </validation-observer>


      <cc-code class="mt-36">
        <!-- {{ user }} -->
        {{ userProfile }}
      </cc-code>
    </cc-section-table>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      userProfile: null,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user.user,
    }),
  },
  created() {
    this.userProfile = { ...this.user };

    if (!this.userProfile.metaPrivate) {
      this.$set(this.userProfile, 'metaPrivate', { });
    }
  },
  methods: {
    ...mapMutations({
      setState: 'user/setState',
    }),
    async updateProfile() {
      try {
        this.errors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        this.userProfile = await this.$store.dispatch('user/updateProfile', this.userProfile);

        this.setState(['user', this.userProfile]);

        this.userProfile = { ...this.user };

        this.$log.info('Response: updateProfile', this.userProfile);
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: updateProfile', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
