<template>
  <div>
    <cc-section-table
      :title="$t('pages.messages.title')"
      :actions="sectionActions"
      :has-search="false"
      @search-query="($event) => query = $event.toLowerCase()"
      @create-message="handleCreateMessage"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @row-click="handleRowClick"
        @clone="cloneMessage"
        >
        <template #name="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline"
            :data-ruid="item.id"
            >
            {{ item.name }}
          </cc-text>
        </template>

        <template #deliveryMethods="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline capitalize"
            :data-ruid="item.deliveryMethods"
            >
            {{ item.deliveryMethods }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>
  </div>
</template>

<script>
import { getDateAndTimeString, sentenceCase, getDeliveryMethods } from '@/mixins/utils';
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      isLoadingDataTable: false,
      sectionActions: [
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'create-message',
          dataRuid: 'messages__create-message',
          variant: 'primary',
          text: this.$t('pages.messages.newMessage'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.campaignName'),
          value: 'name',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.broadcastId'),
          value: 'id',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.createdAt'),
          value: 'createdAt',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.deliveryMethods'),
          value: 'deliveryMethods',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.category'),
          value: 'category',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.deliveryTag'),
          value: 'deliveryTag',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.messageLinksTo'),
          value: 'href',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.userSegments'),
          value: 'userTags',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
        },
      ],
      messages: [],
    };
  },
  computed: {
    ...mapState({
      tags: st => st.message.tags,
    }),
    ...mapGetters({
      getTagsForDataTable: 'message/getTagsForDataTable',
    }),
    dataItems() {
      /**
       * @todo at some point search input might become a necessity (query handler)
       */
      return this.messages
        ?.slice()
        .filter(message => message)
        .map((message, index) => ({
          index: index + 1,
          id: message?.id,
          name: message?.name,
          createdAt: this.getDateAndTimeString(message?.createdAt),
          href: this.sentenceCase(
            message.payload?.[
              Object.keys(message.payload).filter(e => message.payload[e])?.[0]
            ]?.meta?.href?.split(':')[1] || '–',
          ),
          category: this.sentenceCase(message?.deliveryTag?.split(':')[0]) || '–',
          deliveryTag: this.sentenceCase(message?.deliveryTag?.split(':')[1]) || '–',
          deliveryMethods: this.getDeliveryMethods(message) || '–',
          userTags: this.getTagsForDataTable(message),
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-clone',
              text: this.$t('commons.duplicate'),
              event: 'clone',
            },
            { icon: 'icon-arrow-right', event: 'row-click' },
          ],
          isRowClickable: true,
        }))
        .reverse();
    },
  },
  async created() {
    await this.getAllBroadcastMessages();
  },
  methods: {
    sentenceCase,
    getDeliveryMethods,
    getDateAndTimeString,
    async cloneMessage(message) {
      const { messages } = this;

      const { payload, removedTags } = await this.$store.dispatch('message/cloneMessage', {
        message,
        messages,
      });

      return this.$router
        .push({
          name: 'message',
          params: {
            type: 'clone-message',
            payload,
            removedTags,
          },
        })
        .catch(() => {});
    },
    handleRowClick(message) {
      this.$log.info('message', message.id);

      return this.$router
        .push({
          name: 'message',
          params: { type: 'message', messageId: message.id },
        })
        .catch(() => {});
    },
    handleCreateMessage() {
      return this.$router
        .push({
          name: 'message',
          params: { type: 'new-message' },
        })
        .catch(() => {});
    },
    async getAllBroadcastMessages() {
      try {
        this.isLoadingDataTable = true;

        const response = await this.$store.dispatch('message/getAllBroadcastMessages');

        // this.$log.info('getAllBroadcastMessages', response);

        this.messages = response?.slice().filter(m => m.status === 'SENT');
      } catch (err) {
        this.$log.error('Error: getAllBroadcastMessages', err);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
  },
};
</script>

<style>
</style>
