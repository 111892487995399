<template>
  <div class="flex flex-col">
    <div class="flex items-end ">
      <cc-input
        ref="domainInput"
        v-model.trim="domainModel"
        :label="$t('commons.fieldNames.emailDomain')"
        class="w-full"
        name="email-domain"
        validation-ref="emailDomain"
        :disabled="inputDisabled"
        :validation-rules="`isUnique:${hasUniqueDomain}`"
        :has-error-message="false"
        @keyup.enter.native="addDomain(domainInput)"
        @errors="$event => domainErrors = $event"
        />

      <cc-button
        :text="$t('commons.insert')"
        :block="false"
        class="ml-3"
        variant="secondary"
        :has-min-width="false"
        :disabled="!hasUniqueDomain || domainInput.length === 0 || isInvalidDomain"
        @click.native="addDomain(domainInput)"
        />
    </div>

    <cc-error-message
      v-if="domainErrors && domainErrors[0]"
      class="flex-grow w-full"
      :message="domainErrors[0]"
      />

    <div
      v-for="(domain, i) in domains"
      :key="i"
      class="pt-4"
      >
      <div class="flex">
        <cc-text
          class="lowercase flex-grow bg-gray-100 p-3 rounded-lg font-mono"
          variant="code-sm"
          >
          {{ domain }}
        </cc-text>

        <cc-button
          :block="false"
          svg="icons/icon-cross-thin"
          svg-color="text-gray-500"
          svg-align="left"
          variant="plain"
          class="ml-3 bg-gray-100 hover:bg-gray-300"
          :has-min-width="false"
          @click.native="deleteDomain(domain)"
          />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    domains: {
      type: Array,
      required: false,
      default: () => [],
    },
    domainClass: {
      type: String,
      required: false,
      default: 'feature',
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      domainInput: '',
      domainErrors: [],
    };
  },
  computed: {
    hasUniqueDomain() {
      return !this.domains.includes(this.domainInput);
    },
    isInvalidDomain() {
      return !this.isValidDomain(this.domainInput);
    },
    domainModel: {
      get() {
        return this.domainInput;
      },
      set(value) {
        const inputValue = value.replace(/\s/g, '');
        this.domainInput = inputValue.toLowerCase();
      },
    },
  },
  watch: {
    errors() {
      this.domainErrors = this.errors;
    },
  },
  methods: {
    isValidDomain(domain) {
      const regex = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;
      return regex.test(domain);
    },
    async addDomain(domain) {
      if (!domain || !this.isValidDomain(domain)) return;
      if (this.domains.includes(domain)) return;
      this.$emit('on-change', [...this.domains, domain]);
      this.domainInput = '';
    },
    async deleteDomain(domain) {
      this.$emit('on-change', this.domains.filter(d => d !== domain));
    },
  },
};
</script>
