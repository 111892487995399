<template>
  <div>
    <cc-section-table :title="$t('pages.changePassword.title')">
      <div class="max-w-xs">
        <validation-observer ref="form">
          <form @submit.prevent="updatePasswordHandler">
            <cc-input
              v-model="userProfile.email.address"
              class="mb-4"
              name="email"
              type="email"
              autocomplete="email"
              hidden
              />

            <cc-input
              id="new-password"
              v-model="password"
              :placeholder="$t('commons.newPassword')"
              type="password"
              class="pb-4"
              name="new-password"
              autocomplete="new-password"
              autocapitalize="off"
              autocorrect="off"
              :has-password-toggle="true"
              :validation-rules="`required|passwordComplexity|password:@${$t('commons.passwordRepeat')}`"
              :validation-name="$t('commons.password')"
              :passwordrules="user.passwordrules"
              autofocus
              />

            <cc-input
              id="confirm-new-password"
              v-model="passwordRepeat"
              :placeholder="$t('commons.passwordRepeat')"
              type="password"
              class="pb-4"
              name="confirm-new-password"
              autocomplete="new-password"
              autocapitalize="off"
              autocorrect="off"
              :has-password-toggle="true"
              validation-rules="required"
              :validation-name="$t('commons.passwordRepeat')"
              :passwordrules="user.passwordrules"
              />

            <cc-error-message
              v-if="errors.length"
              :message="errors[0]"
              />

            <cc-button
              class="mt-4"
              :has-min-width="false"
              :disabled="isLoading"
              :is-loading="isLoading"
              :text="$t('commons.save')"
              type="submit"
              />
          </form>
        </validation-observer>
      </div>
    </cc-section-table>

    <cc-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      transition-name="slide-up"
      :heading="$t('modals.changePasswordFeedback.title')"
      @close="$router.push({ name: 'profile' })"
      >
      <template #body>
        <div class="max-w-lg">
          {{ $t('modals.changePasswordFeedback.body') }}
        </div>
      </template>

      <template #footer="{ close }">
        <cc-button
          variant="secondary"
          :text="$t('modals.changePasswordFeedback.primaryButton')"
          @click.native="close"
          />
      </template>
    </cc-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      isFeedbackOpen: false,
      password: null,
      passwordRepeat: null,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user,
    }),
  },
  created() {
    this.userProfile = { ...this.user.user };
  },
  methods: {
    async updatePasswordHandler() {
      const { password, passwordRepeat } = this;
      const success = await this.$refs.form.validate();

      this.errors = [];

      if (!success) {
        this.$log.info('Form !success', success);
        return;
      }

      try {
        this.isLoading = true;

        if (password === passwordRepeat) {
          // await this.$store.dispatch('common/setTimeout', 5000);
          await this.$store.dispatch('user/updatePassword', { password });
          this.isFeedbackOpen = true;
        }
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: updatePasswordHandler', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
