<template>
  <div>
    <cc-section-table
      :title="$t(`pages.rolesAndActionsManager.${hasRole ? 'updateTitle' : 'createTitle'}`)"
      >
      <validation-observer ref="form">
        <div class="">
          <cc-text
            color="text-gray-600"
            class="pb-4 max-w-xl"
            >
            {{ $t('pages.rolesAndActionsManager.createBody') }}
          </cc-text>

          <cc-input
            id="name"
            v-model="name"
            name="name"
            :label="$t('pages.rolesAndActionsManager.name')"
            class="pb-6 max-w-xs"
            :disabled="hasRole"
            validation-rules="required"
            />

          <!-- Assigned Actions -->
          <div
            v-if="hasRole"
            class="pb-8"
            >
            <cc-heading
              tag="h2"
              weight="semibold"
              color="text-gray-600"
              class="pb-4"
              >
              {{ $t('pages.rolesAndActionsManager.assignedActionsTitle') }}
              {{ `(${currentRoleActions.length})` }}
            </cc-heading>

            <div
              v-if="currentRoleActions.length"
              class="flex flex-wrap"
              >
              <cc-chip
                v-for="(actionId, i) in currentRoleActions"
                :key="i"
                variant="secondary"
                :label="actionId"
                class="mr-2 mb-2 select-none"
                icon-class="order-last ml-2 cursor-pointer w-2.5 h-2.5"
                icon-path="icons/icon-close"
                @icon-click="updateRoleActions('removeActionsFromRole', actionId)"
                />
            </div>

            <cc-text
              v-else
              color="text-gray-600"
              >
              {{ $t('pages.rolesAndActionsManager.noActionsAssigned') }}
            </cc-text>
          </div>

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            class="mb-6 pt-0"
            />


          <cc-section-table
            v-if="hasRole"
            :title="`${$t('pages.rolesAndActionsManager.actionsTitle')}  (${
              dataItems && dataItems.length
            })`"
            :actions="sectionActions"
            :has-search="true"
            variant="slim"
            @search-query="query = $event.toLowerCase()"
            >
            <cc-data-table
              :headers="headers"
              :items="dataItems"
              :placeholder="$t('pages.rolesAndActionsManager.actionsPlaceholder')"
              :is-loading="isLoadingDataTable"
              @row-click="updateRoleActions('addActionsToRole', $event.actionId)"
              />
          </cc-section-table>


          <!-- Available actions -->
          <cc-button
            v-if="!hasRole"
            :text="$t('commons.create')"
            :is-loading="isLoading"
            :disabled="isLoading"
            :block="false"
            @click.native="createRole"
            />
        </div>
      </validation-observer>
    </cc-section-table>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { camelToUnderscore } from '@/mixins/utils';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      query: '',
      isLoading: false,
      isLoadingDataTable: false,
      roleName: null,
      updatedRole: null,
      errors: [],
      actions: [],
      sectionActions: [],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.name'),
          value: 'actionId',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.description'),
          value: 'actionDescription',
          isCSVField: true,
          class: 'w-auto',
        },
        {
          text: this.$t('commons.fieldNames.actions'),
          value: 'actions',
          class: 'w-24',
        },
      ],
    };
  },
  computed: {
    dataItems() {
      return this.availableActions
        ?.filter((action) => {
          if (this.query) {
            return (
              action?.actionId?.toLowerCase().includes(this.query)
              || action?.actionDescription?.includes(this.query)
            );
          }
          return action;
        })
        .sort((a, b) => a?.actionId.localeCompare(b?.actionId))
        .map((action, index) => ({
          index: index + 1,
          actionId: action.actionId || '–',
          actionDescription: action.actionDescription || '–',
          actions: [
            {
              iconOrder: 'order-last',
              icon: 'icon-add',
              text: this.$t('commons.add'),
              event: 'row-click',
            },
          ],
          isRowClickable: true,
        }));
    },
    hasRole() {
      return !this.isEmpty(this.currentRole);
    },
    role() {
      return this.$route.params?.role;
    },
    currentRole: {
      get() {
        return this.updatedRole || this.role;
      },
      set(value) {
        this.updatedRole = value;
      },
    },
    currentRoleActions() {
      return this.updatedRole?.roleActions || this.role?.roleActions;
    },
    availableActions() {
      return this.actions?.filter(
        action => !this.currentRole?.roleActions?.includes(action.actionId),
      );
    },
    name: {
      get() {
        return this.roleName || this.role?.name;
      },
      set(value) {
        this.errors = [];
        this.roleName = this.camelToUnderscore(value);
      },
    },
  },
  async created() {
    try {
      this.isLoadingSectionTable = true;
      this.$log.info('Created Roles And Actions Manager');

      this.actions = this.$route.params?.actions ?? (await this.$store.dispatch('role/getAllActions'));
    } catch (error) {
      this.$log.error('Error: Created Roles', error);
    } finally {
      this.isLoadingSectionTable = false;
    }
  },
  methods: {
    isEmpty,
    camelToUnderscore,
    async updateRoleActions(actionHandle, roleActions) {
      const { name } = this;

      try {
        this.errors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;


        this.currentRole = await this.$store.dispatch(`role/${actionHandle}`, {
          roleId: name,
          payload: { actions: [roleActions].filter(action => action) },
        });
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: updateRoleActions', actionHandle, err);
      } finally {
        this.isLoading = false;
      }
    },
    async createRole() {
      await this.updateRoleActions('addActionsToRole', this.currentRoleActions);
      this.$router
        .push({
          name: 'roles-manager',
          params: { type: 'role', roleName: this.name },
        })
        .catch(() => {});
    },
  },
};
</script>
