<template>
  <div>
    <cc-section-table
      :title="$t('pages.products.title')"
      :actions="sectionActions"
      :has-search="true"
      @create-product="handleCreateProduct"
      @search-query="($event) => query = $event.toLowerCase()"
      @export="exportHandler"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        @open="open"
        @row-click="handleRowClick"
        >
        <template #productName="{ item }">
          <span
            class="h-8 w-8 mr-3 bg-cover bg-center rounded-full overflow-hidden"
            :class="!item.imageUrl ? 'bg-gray-200' : ''"
            :style="item.imageUrl ? `background-image: url(${item.imageUrl})` : ''"
            />

          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline"
            >
            {{ item.productName }}
          </cc-text>
        </template>

        <template #category="{ item }">
          <cc-chip
            v-if="item.category.variant"
            variant="transparent"
            icon-class="mr-2"
            :label="item.category.value"
            :icon-path="`icons/icon-category-${item.category.variant}`"
            />
          <div v-else />
        </template>
      </cc-data-table>
    </cc-section-table>

    <cc-modal
      v-if="isOpen"
      :is-open="isOpen"
      transition-name="slide-up"
      @close="isOpen = false"
      >
      <template #body>
        <div class="max-w-lg">
          <cc-code>
            {{ rowContent }}
          </cc-code>
        </div>
      </template>
    </cc-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase } from '@/mixins/utils';

export default {
  data() {
    return {
      query: '',
      isOpen: false,
      isLoadingDataTable: false,
      companyProducts: null,
      companyReceipts: null,
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'create-product',
          variant: 'primary',
          text: this.$t('pages.products.newProduct'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.productName'),
          value: 'productName',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.productId'),
          value: 'productId',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.greenCoins'),
          value: 'greenCoins',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.stock'),
          value: 'stock',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.unitsSold'),
          value: 'unitsSold',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.published'),
          value: 'isPublished',
          isCSVField: true,
        },
        {
          text: this.$t('commons.fieldNames.category'),
          value: 'category',
          isCSVField: true,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user,
    }),
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
      isContextAdmin: 'user/isContextAdmin',
    }),
    dataItems() {
      return this.companyProducts
        ?.filter((product) => {
          if (this.query) {
            return product.id.toLowerCase().includes(this.query)
            || product.virtualGood?.name.toLowerCase().includes(this.query)
            || product?.virtualGood?.metaPublic?.categories[0].includes(this.query);
          }
          return product;
        })
        .filter(product => (this.isContextAdmin ? product : product.virtualGood.type === 'VOUCHER'))
        .map((product, index) => ({
          index: index + 1,
          productId: product.id,
          productName: product?.virtualGood?.resources?.en?.title || product.virtualGood?.name,
          greenCoins: product.price,
          stock: product.stock,
          imageUrl: product?.virtualGood?.resources?.de?.images?.[0],
          unitsSold: this.companyReceipts?.filter(receipt => receipt?.offer?.id === product?.id)?.length || '0',
          isPublished: this.$t(product.active ? 'commons.yes' : 'commons.no'),
          category: {
            variant: product?.virtualGood?.metaPublic?.categories?.[0] || null,
            value: product?.virtualGood?.metaPublic?.categories?.[0]
            ? this.$t(`commons.productCategories.${product?.virtualGood?.metaPublic?.categories?.[0]}`)
            : null,
          },
          actions: [
            {
              icon: 'icon-arrow-right',
              event: 'open',
            },
          ],
          isRowClickable: true,
        }));
    },
  },
  async created() {
    try {
      this.isLoadingDataTable = true;

      this.companyProducts = await this.$store.dispatch(
        'company/getOffersByCompany',
        this.$route.params.companyId,
      );

      this.companyReceipts = await this.$store.dispatch(
        'company/getReceiptsByCompany',
        this.$route.params.companyId,
      );

      // this.$log.info('companyProducts', this.companyProducts);
    } catch (err) {
      this.$log.error('Error: getOffersByCompany', err);
    } finally {
      this.isLoadingDataTable = false;
    }
  },
  methods: {
    open() {
      this.$log.info('Open');
    },
    handleRowClick(product) {
      this.$log.info('product', product.id);

      return this.$router.push({
        name: 'product',
        params: { type: 'product', offerId: product.productId },
      });
    },
    handleCreateProduct() {
      return this.$router.push({ name: 'product', params: { type: 'new-product' } }).catch(() => {});
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyName = toKebapCase(this.getCompanyName(this.$route.params.companyId));

        const filename = generateTimeStampedFilename(`scc_products_${companyName}`);

        this.dataItems.forEach((item, index) => {
          itemsFormatted.push({
            index: index + 1,
            productName: item.productName?.replace(/,/g, ''),
            productId: item.productId,
            greenCoins: item.greenCoins,
            stock: item.stock,
            unitsSold: item.unitsSold,
            isPublished: item.isPublished,
            category: item.category.variant,
          });
        });

        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>

<style>
</style>
