<template>
  <span class="text-secondary-red">
    {{ $t('commons.fieldNames.requiredSign') }}
    <span class="text-gray-400">({{ $t('commons.required') }})</span>
  </span>
</template>

<script>
export default {
  props: {
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
