<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-sm w-full">
      <cc-heading
        tag="h1"
        class="text-3xl pb-6"
        >
        {{ $t('pages.login.title') }}
      </cc-heading>

      <validation-observer ref="form">
        <form @submit.prevent="loginHandler">
          <cc-input
            :value="email"
            class="mb-4"
            name="email"
            type="email"
            :placeholder="$t('pages.login.emailAddressPlaceholder')"
            autocomplete="email"
            validation-rules="required|email"
            :validation-name="$t('pages.login.emailAddressPlaceholder')"
            autofocus
            @input="e => email = e.toLowerCase()"
            />

          <cc-input
            id="current-password"
            v-model="password"
            :placeholder="$t('pages.login.passwordPlaceholder')"
            class="mb-4 relative"
            type="password"
            autocomplete="current-password"
            validation-rules="required"
            :validation-name="$t('pages.login.passwordPlaceholder')"
            :has-password-toggle="true"
            />

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            class="mt-4"
            />

          <cc-button
            class="my-4"
            :has-min-width="false"
            :text="$t('pages.login.primaryButton')"
            :disabled="isLoading"
            :is-loading="isLoading"
            type="submit"
            />
        </form>
      </validation-observer>


      <cc-button
        variant="transparent"
        color="text-primary-light"
        :has-min-width="false"
        :text="$t('pages.login.secondaryButton')"
        @click.native="$router.push({ name: 'forgot-password' })"
        />
    </div>
  </div>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      email: null,
      password: null,
      errors: [],
    };
  },
  methods: {
    async loginHandler() {
      this.$log.info('loginHandler');

      const { email, password } = this;

      try {
        this.errors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        await this.$store.dispatch('user/login', { email, password, context: process.env.VUE_APP_CONTEXT });
        await this.$router.push({ name: 'dashboard' }).catch(() => {});
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: loginHandler', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
