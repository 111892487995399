<template>
  <cc-modal
    :is-open="isOpen"
    :heading="$t(`modals.inviteNewCompany.${invitee ? 'resendInviteTitle' : 'title'}`)"
    @close="$emit('close')"
    >
    <template #body="">
      <validation-observer ref="form">
        <div class="max-w-md">
          <cc-input
            id="name"
            v-model="name"
            name="name"
            :label="$t('modals.inviteNewCompany.name')"
            :placeholder="$t('modals.inviteNewCompany.namePlaceholder')"
            class="pb-4"
            :disabled="!!invitee"
            validation-rules="required"
            autofocus
            />
          <cc-input
            v-model="email"
            :label="$t('modals.inviteNewCompany.emailAddress')"
            :placeholder="$t('modals.inviteNewCompany.emailAddressPlaceholder')"
            :disabled="!!invitee"
            validation-rules="required|email"
            />
          <!-- <cc-input
            v-if="!invitee"
            v-model="onboardingCode"
            class="pt-4"
            :label="$t('modals.inviteNewCompany.onboardingCode')"
            validation-rules="required"
            /> -->
          <cc-input
            v-if="!invitee"
            v-model.trim="alias"
            :label="$t('commons.companyAlias')"
            class="pt-4"
            validation-rules="required|onlyLowerCaseLettersOrNumbers"
            />
        </div>
      </validation-observer>

      <cc-error-message
        v-if="errors.length"
        :message="errors[0]"
        class="mt-4"
        />
    </template>

    <template #footer="{ close }">
      <div class="grid grid-cols-2 gap-x-4">
        <cc-button
          variant="secondary"
          :text="$t('commons.cancel')"
          @click.native="close"
          />
        <cc-button
          :text="$t('commons.send')"
          :is-loading="isLoading"
          :disabled="isLoading"
          @click.native="invite(close)"
          />
      </div>
    </template>
  </cc-modal>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    invitee: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      email: null,
      name: null,
      errors: [],
      alias: null,
    };
  },
  created() {
    this.$log.info('created', this.invitee);

    if (this.invitee) {
      this.email = this.invitee.email;
      this.name = this.invitee.name;
      this.alias = this.invitee.alias;
    }
  },
  methods: {
    async invite(close) {
      const { email, name } = this;

      try {
        this.errors = [];
        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        await this.$store.dispatch('company/invite', {
          email, //
          name,
          companyId: this.invitee?.id,
          // onboardingCode,
          // if `resend` then uses `/resend-invite` endpoint
          resend: !!this.invitee?.id,
          alias: this.alias,
        });

        this.$emit('success');

        close();
      } catch (err) {
        if (err?.response?.status >= 400 && err?.response?.status < 500) {
          this.errors.push(this.$t('errors.invalidEmailOrOnboardingCode'));
        } else {
          this.errors.push(this.$t('errors.genericError'));
        }

        this.$log.error('Error: invite', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
