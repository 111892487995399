<template>
  <div>
    <cc-section-table
      v-if="true"
      :title="$t('pages.pendingUsers.title')"
      :actions="sectionActions"
      :has-search="true"
      @search-query="($event) => (query = $event.toLowerCase())"
      @export="exportHandler"
      >
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        >
        <template #roles="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline capitalize"
            :data-ruid="item.roles"
            >
            {{ item.roles }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>
  </div>
</template>

<script>
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase, sentenceCase } from '@/mixins/utils';
import { mapGetters } from 'vuex';

export default {
  props: {
    purpose: {
      type: String,
      required: false,
      default: 'roles',
    },
  },
  data() {
    return {
      query: '',
      isLoadingDataTable: false,
      isFeatureTagsManagerModalOpen: false,
      isUserRoleManagerOpen: false,
      userToManage: null,
      tagSelectionsObj: {},
      greenPointsData: [],
      rolesAndActions: [],
      users: [],
      userSelection: {},
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          isVisible: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.userId'),
          value: 'userId',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.email'),
          value: 'email',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.pendingEmail'),
          value: 'pendingEmail',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.createdAt'),
          value: 'createdAt',
          isCSVField: true,
          isVisible: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    dataItems() {
      return (this.users || [])
        .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
        .map((user, index) => ({
          index: index + 1,
          userId: user.id || '–',
          email: user?.email?.address || '–',
          pendingEmail: user?.email?.pendingAddress || '–',
          createdAt: new Date(user?.createdAt).toLocaleString('en-US') || '–',
        }));
    },
  },
  async created() {
      await this.getPendingUsers();
  },
  methods: {
    sentenceCase,
    async getPendingUsers(tagSelections = {}, withLoading = true) {
      this.$log.info('Will getPendingUsers', tagSelections);
      try {
        this.isLoadingDataTable = withLoading;
        if (this.$route?.params?.companyId) {
          const response = await this.$store.dispatch('user/getPendingUsers', { companyId: this.$route?.params?.companyId });
          this.users = response || [];
        }
      } catch (error) {
        this.$log.error('Error: getPendingUsers', error);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyNameOrTag = toKebapCase(
          this.getCompanyName(this.$route.params.companyId) || Object.keys(this.tagSelections)?.[0],
        );
        const filename = generateTimeStampedFilename(`scc_pending_users_of_${companyNameOrTag}`);

        this.dataItems.forEach((item) => {
          // Remove some properties that should not be in the CSV
          // eslint-disable-next-line no-param-reassign
          ['originalPayload', 'isRowClickable', 'actions'].forEach(e => delete item[e]);

          itemsFormatted.push({
            ...item,
            createdAt: item.createdAt.replace(/,/g, ''),
          });
        });
        this.$log.info('headers', headersFormatted);
        this.$log.info('items', itemsFormatted);
        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>

<style></style>
