<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-sm w-full">
      <cc-heading
        tag="h1"
        class="text-3xl pb-6"
        >
        {{ $t('pages.forgotPassword.title') }}
      </cc-heading>
      <validation-observer ref="form">
        <form @submit.prevent="forgotPasswordHandler">
          <cc-text
            class="pb-4"
            color="text-gray-600"
            >
            {{ $t('pages.forgotPassword.body') }}
          </cc-text>

          <cc-input
            :value="email"
            class="mb-4 lowercase"
            name="email"
            type="email"
            :placeholder="$t('pages.login.emailAddressPlaceholder')"
            autocomplete="email"
            validation-rules="required"
            :validation-name="$t('pages.login.emailAddressPlaceholder')"
            autofocus
            @input="e => email = e.toLowerCase()"
            />

          <cc-error-message
            v-if="errors.length"
            :message="errors[0]"
            padding="p-0"
            />

          <cc-button
            class="my-4"
            :has-min-width="false"
            :disabled="isLoading"
            :is-loading="isLoading"
            :text="$t('pages.forgotPassword.primaryButton')"
            type="submit"
            />
        </form>
      </validation-observer>

      <cc-button
        variant="transparent"
        color="text-primary-light"
        :has-min-width="false"
        :text="$t('pages.forgotPassword.secondaryButton')"
        @click.native="$router.push({ name: 'login' })"
        />
    </div>


    <cc-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      transition-name="slide-up"
      :heading="$t('modals.forgotPasswordFeedback.title')"
      @close="$router.push({ name: 'login' })"
      >
      <template #body>
        <div class="max-w-lg">
          {{ $t('modals.forgotPasswordFeedback.body') }}
        </div>
      </template>

      <template #footer="{ close }">
        <cc-button
          variant="secondary"
          :text="$t('modals.forgotPasswordFeedback.primaryButton')"
          @click.native="close"
          />
      </template>
    </cc-modal>
  </div>
</template>

<script>
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      isFeedbackOpen: false,
      email: null,
      errors: [],
    };
  },
  methods: {
    async forgotPasswordHandler() {
      try {
        this.errors = [];

        const success = await this.$refs.form.validate();

        if (!success) {
          this.$log.info('Form !success', success);
          return;
        }

        this.isLoading = true;

        this.$log.info('forgotPasswordHandler');
        await this.$store.dispatch('user/forgotPassword', this.email);
        this.isFeedbackOpen = true;
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: loginHandler', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
