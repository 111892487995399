<template>
  <div
    class="and-or-template p-2 rounded-lg border border-gray-200 overflow-hidden"
    :class="isFirst ? 'and-or-first' : '' "
    >
    <div class="flex">
      <!-- and-or-switch -->
      <div
        class="flex flex-col items-center group pr-4 pl-2"
        :class="hasRulesOrGroups && 'pt-14'"
        >
        <div
          v-if="hasRulesOrGroups"
          class="flex flex-col items-center px-6 flex-grow w-full"
          >
          <span
            class="bg-gray-200 h-0.5 w-full rounded-lg transition ease-out duration-500"
            :class="isAnd ? 'group-hover:bg-primary-dark' : 'group-hover:bg-primary-lighter'"
            />
          <div
            class="w-0.5 bg-gray-200 flex-grow rounded-lg transition ease-out duration-500"
            :class="isAnd ? 'group-hover:bg-primary-dark' : 'group-hover:bg-primary-lighter'"
            />
        </div>

        <cc-button
          class="min-w-16 self-center my-2 uppercase"
          :variant="isAnd ? 'primary' : 'primary-light'"
          :block="false"
          size="small"
          weight="body-bold"
          :text="isAnd ? $t('commons.and') : $t('commons.or')"
          :has-min-width="false"
          @click.native="toggleIsAnd"
          />

        <div
          v-if="hasRulesOrGroups"
          class="flex flex-col-reverse items-center px-6 flex-grow w-full"
          >
          <span
            class="bg-gray-200 h-0.5 w-full rounded-lg transition ease-out duration-500"
            :class="isAnd ? 'group-hover:bg-primary-dark' : 'group-hover:bg-primary-lighter'"
            />
          <div
            class="w-0.5 bg-gray-200 flex-grow rounded-lg transition ease-out duration-500"
            :class="isAnd ? 'group-hover:bg-primary-dark' : 'group-hover:bg-primary-lighter'"
            />
        </div>
      </div>

      <div>
        <!-- Add/Remove Buttons -->
        <div class="flex flex-wrap mt-2">
          <cc-button
            class="mr-4"
            variant="secondary"
            size="small"
            :block="false"
            :has-min-width="false"
            :text="$t('configs.addNewFilter')"
            @click.native="addRule"
            />

          <cc-button
            class="mr-4"
            variant="secondary"
            size="small"
            :block="false"
            :has-min-width="false"
            :text="$t('configs.addNewGroup')"
            @click.native="addGroup"
            />

          <cc-button
            v-if="!isFirst"
            variant="secondary"
            size="small"
            :block="false"
            :has-min-width="false"
            :text="$t('configs.removeGroup')"
            @click.native="deleteSelf()"
            />
        </div>

        <cc-filter-builder-rule
          v-for="(rule, index) in rules"
          ref="rules"
          :key="rule"
          class="mr-2 mt-4 mb-2"
          :options="options"
          :rule-id="rule"
          @rule-update="$emit('rule-update')"
          @delete-rule="deleteRule(index)"
          />
        <cc-filter-builder
          v-for="(group, index) in groups"
          ref="groups"
          :key="group"
          class="mr-2 mt-4 mb-2"
          :options="options"
          @rule-update="$emit('rule-update')"
          @delete-group="deleteGroup(index)"
          />
      </div>
    </div>
  </div>
</template>

<script>
import ccFilterBuilderRule from '@/components/constructs/FilterBuilderRule.vue';

export default {
  components: {
    ccFilterBuilderRule,
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAnd: true,
      groups: [],
      rules: [],
    };
  },
  computed: {
    hasRules() {
      return !!this.rules?.length;
    },
    hasGroups() {
      return !!this.groups?.length;
    },
    hasRulesOrGroups() {
      return this.hasRules || this.hasGroups;
    },
  },
  updated() {
    this.$log.info('Filter Builder updated');
    this.$emit('rule-update');
  },
  mounted() {
    if (!this.isFirst) {
      this.addRule();
    }
  },
  methods: {
    toggleIsAnd() {
      this.isAnd = !this.isAnd;
    },
    clickAnd() {
      this.isAnd = false;
    },

    clickOr() {
      this.isAnd = true;
    },

    addRule() {
      const id = this.generateId();
      this.$log.info('Rule id', id);
      this.rules.push(id);
    },

    addGroup() {
      const id = this.generateId();
      this.$log.info('Group id', id);
      this.groups.push(id);
    },

    deleteSelf() {
      this.$emit('delete-group');
    },

    deleteRule(index) {
      this.rules.splice(index, 1);
    },

    deleteGroup(index) {
      this.groups.splice(index, 1);
    },

    wrapAsJsonPath(json) {
      const expressionBody = this.jsonExpressionMapToJsonPath(json);

      if (expressionBody) {
        return `$[*][?(${expressionBody})]`;
      }

      return null;
    },

    jsonExpressionMapToJsonPath(json) {
      const conditionJoin = { AND: '&&', OR: '||' };
      return this.assemblyRules(json.rules, conditionJoin[json.condition]);
    },

    assemblyRules(rules, joinWith) {
      const result = rules
        .map((rule) => {
          if (rule.condition) {
            return `(${this.jsonExpressionMapToJsonPath(rule)})`;
          }
          return `${rule.key} ${rule.operator} ${this.prepareStringEscaping(rule.value)}`;
        })
        .join(` ${joinWith} `);

      this.$log.info('assemblyRules', result);
      return result;
    },

    prepareStringEscaping(value) {
      if (typeof value === 'string' && value[0] === '[' && value[value.length - 1] === ']') {
        this.$log.info('preparing string', value);

        const res = `[${value
          .trim()
          .substring(1, value.length - 1)
          ?.split(',')
          .map(s => this.prepareStringEscaping(s.trim()))}]`;

        this.$log.info('prepared string', res);
        return res;
      }

      if (Array.isArray(value)) {
        this.$log.info('preparing array', value);

        const res = value.filter(e => e)?.length
          ? `[${value?.map(val => this.prepareStringEscaping(val))}]`
          : null;

        this.$log.info('prepared array', value);
        return res;
      }

      if (
        // eslint-disable-next-line no-restricted-globals
        !isNaN(value)
        || (value[0] === '"' && value[value.length - 1] === '"')
        || (value[0] === "'" && value[value.length - 1] === "'")
      ) {
        this.$log.info('preparing number', value);
        return value || null;
      }

      this.$log.info('preparing plain', value);
      return `"${value}"`;
    },

    queryFormStatus() {
      const query = {};
      const rules = this.$refs.rules || {};
      const groups = this.$refs.groups || {};
      let i;
      let j;

      query.condition = this.isAnd ? 'AND' : 'OR';
      query.rules = [];

      for (i = 0; i < rules.length; i += 1) {
        query.rules.push(rules[i].queryFormStatus());
      }

      for (j = 0; j < groups.length; j += 1) {
        query.rules[query.rules.length] = groups[j].queryFormStatus();
      }

      return query;
    },

    fillFormStatus(data) {
      let i;
      let len;
      const group = this;
      group.rules = [];
      group.groups = [];

      const joinValueArray = (rule) => {
        if (Array.isArray(rule.value)) {
          // eslint-disable-next-line no-param-reassign
          rule.value = rule.value.join(', ');
          return rule;
        }
        return rule;
      };

      if (data) {
        group.isAnd = /and/i.test(data.condition);
        len = data.rules.length;
        for (i = 0; i < len; i += 1) {
          if (data.rules[i].condition) {
            group.groups.push(group.generateId());
            // eslint-disable-next-line func-names, no-shadow
            (function (i, index) {
              group.$nextTick(() => {
                group.$refs.groups[index].fillFormStatus(joinValueArray(data.rules[i]));
              });
            }(i, group.groups.length - 1));
          } else {
            group.rules.push(group.generateId());
            // eslint-disable-next-line func-names, no-shadow
            (function (i, index) {
              group.$nextTick(() => {
                group.$refs.rules[index].fillRuleStatus(joinValueArray(data.rules[i]));
              });
            }(i, group.rules.length - 1));
          }
        }
      }
    },

    generateId() {
      return 'xxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = (Math.random() * 16) | 0;
        // eslint-disable-next-line no-bitwise, no-mixed-operators, eqeqeq
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
};
</script>

<style lang="postcss">
</style>
