export function toKebapCase(str) {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
}

export function camelCase(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function getBrowserLanguage() {
  return window.navigator.language.split('-')[0].toLowerCase();
}

export function truncateString(str, maxCharLength) {
  const truncatedStr = str?.substring(0, maxCharLength);

  if (str?.length > maxCharLength) {
    return `${truncatedStr}...`;
  }

  return str;
}

export function camelToUnderscore(key) {
   const result = key?.replace(/[^a-zA-Z0-9]+(.)/g, ' $1');
   return result?.split(' ')?.join('_')?.toUpperCase();
}

/**
 * @todo move this to message store as getter
 */
export function getDeliveryMethods(message) {
  return Object.keys(message?.payload || {})
    ?.filter(e => message?.payload?.[e])
    ?.join(' / ');
}

export function exportJSON(payload, filename) {
  if (!payload) {
    throw Error('Missing payload');
  }

  const jsonObject = JSON.stringify(payload, null, 2);

  const filenameWithExtension = `${filename}.json` || 'export.json';

  const blob = new Blob([jsonObject], { type: 'application/json;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filenameWithExtension);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filenameWithExtension);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function removeEmptyKeysFromObject(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value));
}

export function decodeAccessToken(accessToken) {
  return JSON.parse(atob(accessToken.split('.')[1]));
}

export function isBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function convertMilliseconds(timeFrame, duration) {
  const ms = 1000;
  const minute = 60;
  const hour = 60;
  const day = 24;
  const week = 7;

  if (timeFrame && duration) {
    switch (timeFrame) {
      case 'second':
        return duration / ms;
      case 'minute':
        return duration / minute / ms;
      case 'hour':
        return duration / hour / minute / ms;
      case 'day':
        return duration / day / hour / minute / ms;
      case 'week':
        return duration / week / day / hour / minute / ms;
      default:
        return null;
    }
  }
  return null;
}

export function getTimeFrame(duration) {
  const ms = 1000;
  const minute = 60;
  const hour = 60;
  const day = 24;
  // const week = 7;

  switch (!!duration) {
    case duration / ms < 60:
      return 'second';
    case duration / minute / ms <= 60:
      return 'minute';
    case duration / hour / minute / ms <= 24:
      return 'hour';
    case duration / day / hour / minute / ms >= 1:
      return 'day';
    // case duration / week / day / hour / minute / ms >= 1:
    //   return 'week';
    default:
      return null;
  }
}

export function getMilliseconds(timeFrame, duration) {
  const ms = 1000;
  const minute = 60;
  const hour = 60;
  const day = 24;
  const week = 7;

  if (timeFrame && duration) {
    switch (timeFrame) {
      case 'second':
        return duration * ms;
      case 'minute':
        return duration * minute * ms;
      case 'hour':
        return duration * hour * minute * ms;
      case 'day':
        return duration * day * hour * minute * ms;
      case 'week':
        return duration * week * day * hour * minute * ms;
      default:
        return null;
    }
  }
  return null;
}

export function formatDuration(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(milliseconds / 60000);
  const hours = Math.floor(milliseconds / 3600000);
  const days = Math.floor(milliseconds / 86400000);
  const weeks = Math.floor(milliseconds / 604800000);

  if (milliseconds % 604800000 === 0) {
    return { duration: weeks, timeFrame: 'week' };
  }
  if (milliseconds % 86400000 === 0) {
    return { duration: days, timeFrame: 'day' };
  }
  if (milliseconds % 3600000 === 0) {
    return { duration: hours, timeFrame: 'hour' };
  }
  if (milliseconds % 60000 === 0) {
    return { duration: minutes, timeFrame: 'minute' };
  }
  if (milliseconds % 1000 === 0) {
    return { duration: seconds, timeFrame: 'second' };
  }
  return { duration: milliseconds, timeFrame: 'millisecond' };
}


export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function isValidIBANNumber(input) {
  /*
   * Returns 1 if the IBAN is valid
   * Returns FALSE if the IBAN's length is not as should be
   * (for CY the IBAN Should be 28 chars long starting with CY )
   * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
   */
  function mod97(string) {
    let checksum = string.slice(0, 2);
    let fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
  }

  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };
  // keep only alphanumeric characters
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '');
  // match and capture (1) the country code, (2) the check digits, and (3) the rest
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(
    /[A-Z]/g,
    letter => letter.charCodeAt(0) - 55,
  );
  // final check
  return mod97(digits);
}

export function sentenceCase(string) {
  return string ? string?.[0]?.toUpperCase() + string?.slice(1) : null;
}

export function getDateAndTimeString(timestamp) {
  const separator = '/';
  const date = new Date(timestamp).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const time = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${date} ${separator} ${time}`;
}

// Array
// Sorted array of objects by createdAt which latest is at top
export function sortArrayByCreatedAt(array) {
  return array.sort((a, b) => {
    if (a.createdAt === null) {
      return 1;
    }
    if (b.createdAt === null) {
      return -1;
    }
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
}

export function generateRandomAlphaNumericString() {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < 6; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
