<template>
  <div class="flex flex-col">
    <div class="flex items-end ">
      <cc-input
        ref="tagId"
        v-model.trim="tagIdModel"
        :label="$t('commons.fieldNames.tagId')"
        class="w-full"
        name="tag-id"
        validation-ref="tagId"
        :disabled="inputDisabled"
        :validation-rules="`isUnique:${hasUniqueTag}`"
        :has-error-message="false"
        @keyup.enter.native="addTag(tagId)"
        @errors="$event => tagIdErrors = $event"
        />

      <cc-button
        :text="$t('commons.insert')"
        :block="false"
        class="ml-3"
        variant="secondary"
        :has-min-width="false"
        :disabled="!hasUniqueTag || tagId.length === 0"
        @click.native="addTag(tagId)"
        />
    </div>

    <cc-error-message
      v-if="tagIdErrors && tagIdErrors[0]"
      class="flex-grow w-full"
      :message="tagIdErrors[0]"
      />

    <div
      v-for="(id, i) in tags"
      :key="i"
      class="pt-4"
      >
      <div class="flex">
        <cc-text
          class="lowercase flex-grow bg-gray-100 p-3 rounded-lg font-mono"
          variant="code-sm"
          >
          {{ id }}
        </cc-text>

        <cc-button
          :block="false"
          svg="icons/icon-cross-thin"
          svg-color="text-gray-500"
          svg-align="left"
          variant="plain"
          class="ml-3 bg-gray-100 hover:bg-gray-300"
          :has-min-width="false"
          @click.native="deleteTag(id)"
          />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    tagClass: {
      type: String,
      required: false,
      default: 'feature',
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tagId: '',
      tagIdErrors: [],
    };
  },
  computed: {
    hasUniqueTag() {
      return !this.tags.includes(this.tagId);
    },
    tagIdModel: {
      get() {
        return this.tagId;
      },
      set(value) {
        const inputValue = value.replace(/\s/g, '');
        this.tagId = inputValue.toLowerCase();
      },
    },
  },
  methods: {
    async addTag(tagId) {
      const success = await this.$refs.tagId.$refs.tagId.validate();

      if (!success.valid) {
        this.$log.info('addTag !success', success);
        return;
      }

      if (tagId.length !== 0) {
        this.$emit('add-tag', tagId);
        this.tagId = '';
      }
    },
    async deleteTag(tagId) {
      this.$emit('delete-tag', tagId);
    },
  },
};
</script>
