<template>
  <component
    :is="getTag"
    :class="[getClass, getColor, getTextAlign, getFontWeight]"
    >
    <slot />
    <cc-required-label v-if="isRequired" />

    <div
      v-if="hasBorder"
      class="pt-4 mb-4 border-b border-gray-300"
      />
  </component>
</template>

<script>
import commonProps from '@/mixins/commonProps';

// Valid variants
const variantProps = [
  'h1', //
  'h2',
  'h3',
  'h4',
  'h5',
];


const weightProps = [
  'light', //
  'normal',
  'medium',
  'semibold',
  'bold',
  'extrabold',
  'black',
];


const alignProps = [
  'left', //
  'center',
  'right',
];

export default {
  props: {
    variant: {
      type: String,
      required: false,
      default: null,
      validator: value => variantProps.includes(value),
    },
    color: {
      type: String,
      required: false,
      default: 'text-gray-800',
      validator: value => commonProps.methods.colorProps().includes(value),
    },
    align: {
      type: String,
      required: false,
      default: null,
      validator: value => alignProps.includes(value),
    },
    weight: {
      type: String,
      required: false,
      default: 'normal',
      validator: value => weightProps.includes(value),
    },
    tag: {
      type: String,
      required: false,
      default: 'h3',
    },
    hasBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getFontWeight() {
      return [
        `font-${this.weight}`,
      ];
    },
    getTextAlign() {
      return [
        // Left alignment is default, declared here only to override if needed
        this.align === 'left' ? 'text-left' : null,
        this.align === 'center' ? 'text-center' : null,
        this.align === 'right' ? 'text-right' : null,
      ];
    },
    getClass() {
      return [
        this.variant === 'h1' ? 'h1' : null,
        this.variant === 'h2' ? 'h2' : null,
        this.variant === 'h3' ? 'h3' : null,
        this.variant === 'h4' ? 'h4' : null,
        this.variant === 'h5' ? 'h5' : null,
      ];
    },
    getColor() {
      return [
        this.color ? this.color : null,
      ];
    },
    getTag() {
      return this.tag;
    },
  },
};
</script>
