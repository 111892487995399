<template>
  <div class="overflow-x-auto overflow-y-auto">
    <div class="flex flex-col">
      <div
        v-if="title || actions.length"
        class="flex items-center justify-between pb-8"
        >
        <cc-heading
          v-if="title"
          tag="h2"
          weight="semibold"
          color="text-gray-600"
          >
          {{ title }}
        </cc-heading>

        <div class="flex pl-4">
          <cc-input
            v-if="hasSearch"
            v-model="queryHandler"
            variant="slim"
            bg-color="bg-gray-100"
            placeholder="Search..."
            />

          <cc-button
            v-for="(action, i) in actions"
            :key="i"
            :variant="action.variant"
            size="small"
            class="ml-4"
            :has-min-width="false"
            :ruid="action.dataRuid"
            :block="false"
            :svg="`icons/${action.icon}`"
            :text="action.text"
            svg-align="right"
            :svg-color="action.iconColor"
            @click.native="$emit(action.event)"
            />


        <!-- <div
          class="p-2 bg-primary-dark rounded-lg cursor-pointer
          hover:bg-primary-darker transition ease-out duration-500 flex items-center justify-center"
          >
          <cc-svg-base
            svg="icons/icon-plus-thin"
            class="text-white"
            />
        </div> -->
        </div>
      </div>

      <div class="">
        <div class="inline-block min-w-full overflow-hidden">
          <span
            v-if="isLoading"
            class="flex flex-grow items-center"
            >
            <cc-loading bg-color="bg-white" />
          </span>


          <div
            v-else-if="!items.length"
            class="flex flex-grow items-center"
            >
            <div class="py-4 items-center flex flex-col flex-grow">
              <cc-text
                :text="placeholder || $t('commons.noDataAvailable')"
                color="text-gray-500"
                />
            </div>
          </div>

          <table
            v-else
            class="min-w-full relative"
            >
            <!-- Header -->
            <thead class="relative">
              <tr>
                <th
                  v-for="(header, i) in headers.filter(h => h.isVisible === undefined ? h : h.isVisible)"
                  :key="header.value + i"
                  scope="col"
                  class="py-2 pr-6 whitespace-nowrap"
                  :class="i === 0 ? 'pl-4' : null"
                  >
                  <cc-check-box
                    v-if="header.value === 'select'"
                    id="data-table__header__select"
                    class="flex-shrink-0"
                    :has-border="false"
                    :is-dashed="atLeastOneItemSelected"
                    :model-value="isAllItemsSelected"
                    @change="handleHeaderSelection($event, items, selectKey)"
                    @click.native.stop
                    />

                  <cc-text
                    variant="body-sm-bold"
                    color="text-gray-400"
                    align="left"
                    >
                    {{ header.text }}
                  </cc-text>
                </th>
              </tr>
            </thead>

            <!-- Body -->
            <tbody class="">
              <tr
                v-for="(item, index) in items"
                :key="`row-${index}`"
                class="hover:bg-gray-100 transition ease-out duration-500"
                :class="item.isRowClickable ? 'cursor-pointer' : null"
                @click="item.isRowClickable ? $emit('row-click', item) : null"
                >
                <td
                  v-for="(header, i) in headers.filter(h => h.isVisible === undefined ? h : h.isVisible)"
                  :key="`cell-${i}`"
                  class="py-4 pr-6 whitespace-nowrap border-gray-100"
                  :class="[header.class, index !== items.length - 1 ? 'border-b' : null, i === 0 ? 'pl-4' : null]"
                  >
                  <div class="flex items-center">
                    <slot
                      :name="header.value"
                      :item="item"
                      >
                      <cc-check-box
                        v-if="header.value === 'select'"
                        class="flex-shrink-0"
                        :has-border="false"
                        :model-value="!!selectedItems[item[selectKey] || index]"
                        @change="handleSelection($event, selectedItems, selectKey || index, item)"
                        @click.native.stop
                        />


                      <cc-text
                        v-if="header.value !== 'actions'"
                        variant="body-sm"
                        color="text-gray-700"
                        class="inline"
                        >
                        {{ items[index][header.value] }}
                      </cc-text>

                      <template v-else-if="item.actions && item.actions.length">
                        <div
                          v-for="(action, k) in item.actions.filter(e => e)"
                          :key="k"
                          class="flex flex-grow group pr-2"
                          :class="action.class"
                          >
                          <cc-svg-base
                            v-if="action.icon"
                            :svg="`icons/${action.icon}`"
                            :class="action.iconOrder"
                            class="text-gray-400 cursor-pointer transition-colors duration-700
                          group-hover:text-primary-darker"
                            @click.native.stop="item.isRowClickable && !action.event ? null : $emit(action.event, item)"
                            />
                          <cc-text
                            v-if="action.text"
                            variant="body-sm"
                            color="text-gray-700"
                            class="px-1 inline cursor-pointer transition-all duration-700
                        group-hover:text-primary-darker select-none"
                            @click.native.stop="$emit(action.event, item)"
                            >
                            {{ action.text }}
                          </cc-text>
                        </div>
                      </template>
                    </slot>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonProps from '@/mixins/commonProps';

export default {
  mixins: [commonProps],
  props: {
   title: {
      type: String,
      default: null,
      required: false,
    },
    actions: {
      type: Array,
      default: () => [],
      required: false,
    },
    headers: {
      type: Array,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectKey: {
      type: String,
      required: false,
      default: null,
    },
    selectedItems: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    queryHandler: {
      get() {
        return this.query;
      },
      set(val) {
        this.query = val;
        this.$emit('search-query', this.query);
      },
    },
    allItemsForSelection() {
    return this.items.reduce((ac, item) => ({ ...ac, [item[this.selectKey]]: {} }), {});
    },
    isAllItemsSelected() {
      return Object.keys(this.selectedItems).length === Object.keys(this.items).length;
    },
    atLeastOneItemSelected() {
      return Object.keys(this.selectedItems).length > 0;
    },
  },
  methods: {
    handleSelection(event, selectedItems, index, item) {
      if (event) {
        this.$emit('select', { ...selectedItems, [item[index]]: {} });
      } else {
        const selectedItemsCopy = { ...selectedItems };
        delete selectedItemsCopy[item[index]];

        this.$emit('select', { ...selectedItemsCopy });
      }
    },
    handleHeaderSelection(event) {
      if (event) {
        this.$emit('select', this.allItemsForSelection);
      } else {
        this.$emit('select', {});
      }
    },
  },
};
</script>
