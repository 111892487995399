import ApiService from '@/services/ApiService';

const version = '/v1';
const resourceAdmin = `${version}/admin`;
const resource = `${version}`;


export default {
  async uploadFile(payload, isAdmin) {
    const formData = new FormData();

    formData.append('file', payload);
    formData.append('tags', '');

    return ApiService.doSecureFileUpload(`${isAdmin ? resourceAdmin : resource}/file`, formData);
  },
};
