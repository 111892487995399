<template>
  <div
    class="max-w-lg p-3 rounded-lg flex"
    :class="bgColor"
    >
    <div class="pr-2">
      <cc-svg-base
        svg="icons/icon-info"
        class="inline text-gray-300"
        />
    </div>

    <cc-text
      :variant="textVariant"
      color="text-gray-600"
      class="whitespace-pre-line"
      >
      {{ hint }}
    </cc-text>
  </div>
</template>

<script>
export default {
  props: {
    hint: {
      type: String,
      required: true,
    },
    textVariant: {
      type: String,
      required: false,
      default: 'body-sm',
    },
    bgColor: {
      type: String,
      required: false,
      default: 'bg-gray-100',
    },
  },
};
</script>

<style>

</style>
