<template>
  <div>
    <cc-section-table>
      <cc-data-table
        :headers="headers"
        :items="dataItems"
        :is-loading="isLoadingDataTable"
        :title="$t('pages.onboardingCodes.title')"
        :actions="sectionActions"
        :has-search="true"
        @remove="($event) => ((isDeleteConfirmationOpen = true), (onboardingCodeToDelete = $event))"
        @search-query="($event) => (query = $event.toLowerCase())"
        @create-code="isNewCodeModalOpen = true"
        @export="exportHandler"
        >
        <template #code="{ item }">
          <cc-text
            variant="body-sm"
            color="text-gray-700"
            class="inline capitalize"
            >
            {{ item.code }}
          </cc-text>
        </template>
      </cc-data-table>
    </cc-section-table>
    <cc-delete-confirmation-modal
      v-if="isDeleteConfirmationOpen"
      :is-open="isDeleteConfirmationOpen"
      :heading="$t('modals.deleteOnboardingCodeConfirmation.title')"
      :body="$t('modals.deleteOnboardingCodeConfirmation.body', { onboardingCode: onboardingCodeToDelete.code})"
      :error-message="deleteRequestErrors[0]"
      :is-deleting="isDeleting"
      @close="(isDeleteConfirmationOpen = false), (deleteRequestErrors = [])"
      @granted-delete-confirmation="(close) => deleteOnboardingCode(onboardingCodeToDelete.id, close)"
      />
    <cc-onboarding-code-modal
      v-if="isNewCodeModalOpen"
      :is-open="isNewCodeModalOpen"
      :onboarding-codes="dataItems"
      @close="(isNewCodeModalOpen = false), (invitee = null)"
      @success="createCodeSuccessHandler"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { exportCSVFile, generateTimeStampedFilename, formatHeaders } from '@/mixins/exportCSV';
import { toKebapCase } from '@/mixins/utils';
import ccOnboardingCodeModal from '@/views/Companies/OnboardingCodeModal.vue';

export default {
  components: {
    ccOnboardingCodeModal,
  },
  data() {
    return {
      query: '',
      isOpen: false,
      isLoadingDataTable: false,
      isNewCodeModalOpen: false,
      isDeleteConfirmationOpen: false,
      onboardingCodeToDelete: null,
      isDeleting: false,
      companyOnboardingCodes: null,
      deleteRequestErrors: [],
      sectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('commons.export'),
        },
        {
          icon: 'icon-plus-thin',
          iconColor: 'text-white',
          event: 'create-code',
          variant: 'primary',
          text: this.$t('pages.onboardingCodes.newCode'),
        },
      ],
      headers: [
        {
          text: this.$t('commons.fieldNames.numberSign'),
          value: 'index',
          isCSVField: true,
          isVisible: true,
          class: 'w-16',
        },
        {
          text: this.$t('commons.fieldNames.companyOnboardingCode'),
          value: 'code',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: this.$t('commons.fieldNames.department'),
          value: 'department',
          isCSVField: true,
          isVisible: true,
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    };
  },
  computed: {

    ...mapGetters({
      getCompanyName: 'company/getCompanyName',
    }),
    dataItems() {
      this.$log.info(`onboardingCodes: ${this.companyOnboardingCodes}`);
      return this.companyOnboardingCodes
        ?.filter((onboardingCode) => {
          if (this.query) {
            return (
              onboardingCode?.onboardingCode?.toLowerCase().includes(this.query)
              || onboardingCode?.department?.toLowerCase().includes(this.query)
            );
          }
          return onboardingCode;
        })
        .map((onboardingCode, index) => ({
          index: index + 1,
          id: onboardingCode?.id || '–',
          code: onboardingCode?.onboardingCode || '–',
          department: onboardingCode?.department || '–',
          actions: [
            {
              icon: 'icon-delete',
              event: 'remove',
            },
          ],
        }));
    },
  },
  async created() {
    if (!this.companies?.length) {
      await this.getAllOnboardingCodes();
    }
  },
  methods: {
    async deleteOnboardingCode(codeId, close) {
      try {
        this.deleteRequestErrors = [];
        this.isDeleting = true;

        this.$log.info(`Delete onboardingCode ${codeId}`);

        const payload = {
          companyId: this.$route.params.companyId,
          onboardingCodeId: codeId,
        };

        await this.$store.dispatch('company/deleteOnboardingCode', payload);

        await this.getAllOnboardingCodes();
        close();
      } catch (err) {
        this.$log.info(err);

        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: deleteOnboardingCode', err);
      } finally {
        this.isDeleting = false;
      }
    },
    async getAllOnboardingCodes() {
      try {
      this.isLoadingDataTable = true;

      this.companyOnboardingCodes = await this.$store.dispatch(
        'company/getOnboardingCodes',
        this.$route.params.companyId,
      );

      this.$log.info('companyOnboardingCodes', this.companyOnboardingCodes);
    } catch (err) {
      this.$log.error('Error: getOnboardingCodes', err);

      this.isLoadingDataTable = false;
    } finally {
      this.isLoadingDataTable = false;
    }
    },
    async createCodeSuccessHandler() {
      await this.getAllOnboardingCodes();
    },
    handleCreateCode() {
      this.$log.info('handleCreateCode');
      this.isNewCodeModalOpen = true;
    },
    exportHandler() {
      try {
        let itemsFormatted = [];
        let headersFormatted = formatHeaders(this.headers);

        const companyName = toKebapCase(this.getCompanyName(this.$route.params.companyId));

        const filename = generateTimeStampedFilename(`scc_onboardingCodes_${companyName}`);

        this.dataItems.forEach((item, index) => {
          itemsFormatted.push({
            index: index + 1,
            onboardingCode: item.code,
            department: item.department,
          });
        });

        exportCSVFile(headersFormatted, itemsFormatted, filename);

        itemsFormatted = [];
        headersFormatted = [];
      } catch (error) {
        this.$log.info('CSV Export Error', error);
      }
    },
  },
};
</script>
