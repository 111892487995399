<template>
  <div class="flex items-center flex-wrap">
    <cc-input
      v-if="hasDate"
      v-model="getDate"
      ruid="create-challenge__expires-date"
      :label="dateLabel"
      :validation-rules="hasValidation ? 'required' : null"
      type="date"
      class="w-48"
      :disabled="disabled"
      :class="hasTime ? 'pr-4' : null"
      :has-error-message="false"
      @errors="$event => errors = $event"
      />
    <cc-input
      v-if="hasTime"
      v-model="getTime"
      ruid="create-challenge__expires-time"
      class="w-28"
      :disabled="!getDate || disabled"
      :label="timeLabel"
      type="time"
      />
    <cc-error-message
      v-if="errors && errors[0]"
      class="flex-grow w-full"
      :message="errors[0]"
      />
  </div>
</template>

<script>
export default {
  props: {
    hasDate: {
      type: Boolean,
      default: true,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    hasTime: {
      type: Boolean,
      default: true,
    },
    time: {
      type: String,
      required: false,
      default: '00:00',
    },
    dateLabel: {
      type: String,
      required: true,
      default: null,
    },
    timeLabel: {
      type: String,
      required: true,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasValidation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    getDate: {
      get() {
        return this.date;
      },
      set(val) {
        if (val) {
          this.$emit('iso-format', new Date(`${val} ${this.getTime}`).toISOString());
        } else {
          this.$emit('iso-format', null);
        }
      },
    },
    getTime: {
      get() {
        return this.time;
      },
      set(val) {
        if (val) {
          this.$emit('iso-format', new Date(`${this.getDate} ${val}`).toISOString());
        }
      },
    },
  },
};
</script>

<style>
</style>
