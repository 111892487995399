<template>
  <div class="flex flex-wrap sm:flex-nowrap">
    <!-- Value A -->
    <div class="flex flex-col bg-gray-100 p-4 items-start rounded-lg mb-4">
      <cc-heading class="pb-4 w-full">
        {{ $t('configs.valueA') }}
      </cc-heading>

      <cc-select
        id="value-a-aggregation"
        v-model="valueAAggregation"
        :ruid="`create-challenge__rule-${index}__value-a-aggregation`"
        :label="$t('configs.aggregation')"
        :has-de-select="false"
        :options="generateOptions('aggregationFunctions')"
        class="pb-4"
        />

      <cc-select
        id="value-a-metric"
        v-model="valueAMetric"
        :ruid="`create-challenge__rule-${index}__value-a-metric`"
        :label="$t('configs.metric')"
        :options="generateOptionsFromObject('metrics')"
        :disabled="valueAAggregation === 'SUM' || isATransportTypeSelected"
        :validation-rules="valueAAggregation === 'SUM' || isATransportTypeSelected ? null : 'required'"
        class="pb-4"
        />

      <cc-select
        id="value-a-transport-type"
        v-model="valueATransportType"
        :ruid="`create-challenge__rule-${index}__value-a-transport-type`"
        :label="$t('configs.transportType')"
        :options="generateOptions('transportTypes')"
        :multiple="valueAAggregation === 'SUM'"
        :disabled="isAMetricSelected"
        :validation-rules="isAMetricSelected ? null : 'required'"
        />

      <cc-input
        v-if="valueARequired.includes(modeFunction)"
        v-model="valueA"
        :ruid="`create-challenge__rule-${index}__value-a-value`"
        :label="getValueAInputLabel"
        class="w-40 mr-2 pt-4"
        type="number"
        validation-rules="min_value:0"
        :validation-name="$t('configs.valueA')"
        align="text-center"
        min="0"
        minlength="0"
        step="1"
        />
    </div>

    <!-- Mode -->
    <div class="flex flex-col p-4 mb-4">
      <cc-heading
        tag="h3"
        class="pb-4"
        >
        {{ $t('configs.ruleName') }}: {{ name }}
      </cc-heading>

      <cc-select
        id="mode-functions"
        v-model="modeFunction"
        :ruid="`create-challenge__rule-${index}__mode-functions`"
        :label="$t('configs.mode')"
        validation-rules="required"
        :options="generateOptions('modeFunctions')"
        class="pb-4"
        />
      <cc-button
        :text="$t('commons.delete')"
        variant="secondary"
        :ruid="`create-challenge__rule-${index}__delete`"
        :has-min-width="false"
        :block="false"
        @click.native="$emit('delete', name)"
        />
    </div>

    <!-- Value B -->
    <div
      v-if="valueBExpressionRequired.includes(modeFunction)"
      class="flex bg-gray-100 p-4 flex-col items-start rounded-lg mb-4"
      >
      <cc-heading class="pb-4 w-full">
        {{ $t('configs.valueB') }}
      </cc-heading>

      <cc-select
        id="value-b-aggregation"
        v-model="valueBAggregation"
        :ruid="`create-challenge__rule-${index}__value-b-aggregation`"
        :label="$t('configs.aggregation')"
        :has-de-select="false"
        :options="generateOptions('aggregationFunctions')"
        class="pb-4"
        />

      <cc-select
        id="value-b-metric"
        v-model="valueBMetric"
        :ruid="`create-challenge__rule-${index}__value-b-metric`"
        :label="$t('configs.metric')"
        :options="generateOptionsFromObject('metrics')"
        :disabled="valueBAggregation === 'SUM' || isBTransportTypeSelected"
        :validation-rules="valueBAggregation === 'SUM' || isBTransportTypeSelected ? null : 'required'"
        class="pb-4"
        />

      <cc-select
        id="value-b-transport-type"
        v-model="valueBTransportType"
        :ruid="`create-challenge__rule-${index}__value-b-transport-type`"
        :label="$t('configs.transportType')"
        :options="generateOptions('transportTypes')"
        :multiple="valueBAggregation === 'SUM'"
        :disabled="isBMetricSelected"
        :validation-rules="isBMetricSelected ? null : 'required'"
        />
    </div>


    <!-- <cc-code
      v-if="true"
      class="my-8 w-full"
      title="generateRule"
      >
      {{ generateRule() }}
    </cc-code> -->

    <cc-code
      v-if="false"
      class="my-8 w-full"
      title="Prop rule"
      >
      {{ rule }}
    </cc-code>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    rule: {
      type: Object,
      required: false,
      default: () => ({
        // valueA: 0,
        // valueAExpression: null,
        mode: 'BIGGER_THEN',
        // valueBExpression: null,
      }),
    },
  },
  data() {
    return {
      valueARequired: ['MAX', 'MIN'],
      valueBExpressionRequired: ['BIGGER_THEN', 'SMALLER_THEN', 'EQUALS'],
      valueAAggregationValue: 'none',
      valueBAggregationValue: 'none',
      aggregationFunctions: ['none', 'SUM'],
      modeFunctions: [
        'BIGGER_THEN', //
        'SMALLER_THEN',
        'EQUALS',
        'MAX',
        'MIN',
      ],
      metrics: [
        {
          text: 'co2',
          value: 'co2',
        },
        {
          text: 'co2Accumulator',
          value: '$.accumulator.co2',
        },
        {
          text: 'co2PreviousAccumulator',
          value: '$.previousAccumulator.co2',
        },
        {
          text: 'kwh',
          value: 'kwh',
        },
        {
          text: 'kwhAccumulator',
          value: '$.accumulator.kwh',
        },
        {
          text: 'kwhPreviousAccumulator',
          value: '$.previousAccumulator.kwh',
        },
        {
          text: 'kwhIfElectric',
          value: 'kwhIfElectric',
        },
        {
          text: 'kwhIfElectricAccumulator',
          value: '$.accumulator.kwhIfElectric',
        },
        {
          text: 'kwhIfElectricPreviousAccumulator',
          value: '$.previousAccumulator.kwhIfElectric',
        },
        {
          text: 'co2IfElectric',
          value: 'co2IfElectric',
        },
        {
          text: 'co2IfElectricAccumulator',
          value: '$.accumulator.co2IfElectric',
        },
        {
          text: 'co2IfElectricPreviousAccumulator',
          value: '$.previousAccumulator.co2IfElectric',
        },
        {
          text: 'distanceInMeters',
          value: 'distanceInMeters',
        },
        {
          text: 'distanceInMetersAccumulator',
          value: '$.accumulator.distanceInMeters',
        },
        {
          text: 'distanceInMetersPreviousAccumulator',
          value: '$.previousAccumulator.distanceInMeters',
        },
      ],
      transportTypes: [
        'car',
        'boat',
        'bus',
        'airplane',
        'coach',
        'subway',
        'light_rail',
        'tram',
        'regional_train',
        'train',
        'walk',
        'bicycle',
      ],
    };
  },
  computed: {
    valueAAggregation: {
      get() {
        if (this.rule?.valueAExpression?.slice(0, 5) === '$.sum') {
          return 'SUM';
        }

        return this.valueAAggregationValue;
      },
      set(value) {
        // this.$log.info('valueAAggregation', value);

        this.$delete(this.rule, 'valueAExpression');

        this.valueAAggregationValue = value;
      },
    },
    valueBAggregation: {
      get() {
        if (this.rule?.valueBExpression?.slice(0, 5) === '$.sum') {
          return 'SUM';
        }

        return this.valueBAggregationValue;
      },
      set(value) {
        // this.$log.info('valueBAggregation', value);

        this.$delete(this.rule, 'valueBExpression');

        this.valueBAggregationValue = value;
      },
    },
    valueATransportType: {
      get() {
        // this.$log.info('valueATransportType: 1', this.rule.valueAExpression);

        if (!this.rule.valueAExpression && this.valueAAggregation === 'SUM') {
          return [];
        }

        if (
          !this.rule.valueAExpression
          || this.metrics.find(metric => metric.value === this.rule.valueAExpression)?.value
        ) {
          // this.$log.info('valueATransportType: 2', this.rule.valueAExpression);
          return null;
        }

        if (this.rule.valueAExpression && this.valueAAggregation === 'none') {
          const transportType = this.rule?.valueAExpression?.split('.')[
              this.rule?.valueAExpression?.split('.').length - 1
            ];

          // this.$log.info('valueATransportType: 2', transportType);

          return transportType;
        }

        if (this.rule.valueAExpression && this.valueAAggregation === 'SUM') {
          const transportTypes = this.rule?.valueAExpression
            .replace(/\$\.transitModeDistance.|\$\.sum|[(),]/g, '')
            .split(' ');

          // this.$log.info('valueATransportType: 3', transportTypes);

          return transportTypes;
        }

        return this.rule.valueAExpression || [];
      },
      set(value) {
        if (value?.length && this.valueAAggregation === 'none') {
          this.$log.info('valueAExpression: 1');

          return this.$set(this.rule, 'valueAExpression', `$.transitModeDistance.${value}`);
        }

        if (value?.length && this.valueAAggregation === 'SUM') {
          // this.$log.info('valueAExpression: 2');

          const strPaths = value?.map(el => `$.transitModeDistance.${el}`)?.join(', ');
          const str = `$.sum(${strPaths})`;

          return this.$set(this.rule, 'valueAExpression', str);
        }

        return this.$delete(this.rule, 'valueAExpression');
      },
    },
    valueBTransportType: {
      get() {
        // this.$log.info('valueATransportType: 1', this.rule.valueAExpression);

        if (!this.rule.valueBExpression && this.valueBAggregation === 'SUM') {
          return [];
        }

        if (
          !this.rule.valueBExpression
          || this.metrics.find(metric => metric.value === this.rule.valueBExpression)?.value
        ) {
          this.$log.info('valueBTransportType: 2', this.rule.valueBExpression);
          return null;
        }

        if (this.rule.valueBExpression && this.valueBAggregation === 'none') {
          const transportType = this.rule?.valueBExpression?.split('.')[
              this.rule?.valueBExpression?.split('.').length - 1
            ];

          // this.$log.info('valueBTransportType: 2', transportType);

          return transportType;
        }

        if (this.rule.valueBExpression && this.valueBAggregation === 'SUM') {
          const transportTypes = this.rule?.valueBExpression
            .replace(/\$\.transitModeDistance.|\$\.sum|[(),]/g, '')
            .split(' ');

          // this.$log.info('valueBTransportType: 3');

          return transportTypes;
        }

        return this.rule.valueBExpression || [];
      },
      set(value) {
        if (value?.length && this.valueBAggregation === 'none') {
          // this.$log.info('valueAExpression: 1');

          return this.$set(this.rule, 'valueBExpression', `$.transitModeDistance.${value}`);
        }

        if (value?.length && this.valueBAggregation === 'SUM') {
          // this.$log.info('valueAExpression: 2');
          const strPaths = value?.map(el => `$.transitModeDistance.${el}`)?.join(', ');
          const str = `$.sum(${strPaths})`;

          return this.$set(this.rule, 'valueBExpression', str);
        }

        return this.$delete(this.rule, 'valueBExpression');
      },
    },
    valueAMetric: {
      get() {
        // this.$log.info('valueAMetric: get()', this.rule.valueAExpression);
        if (
          this.metrics.find(metric => metric.value === this.rule.valueAExpression)?.value
          && this.valueAAggregation === 'none'
        ) {
          return this.metrics.find(metric => metric.value === this.rule.valueAExpression)?.value;
        }
        return null;
      },
      set(value) {
        this.$log.info(
          'valueAMetric: set()',
          value,
          this.metrics.find(metric => metric.value === value)?.value,
        );

        if (value?.length > 0 && this.valueAAggregation === 'none') {
          // this.$log.info('valueAMetric: 2 > set()', value);

          return this.$set(
            this.rule,
            'valueAExpression',
            this.metrics.find(metric => metric.value === value)?.value,
          );
        }

        return this.$delete(this.rule, 'valueAExpression');
      },
    },
    valueBMetric: {
      get() {
        // this.$log.info('valueBMetric: get()', this.rule.valueBExpression);

        if (
          this.metrics.find(metric => metric.value === this.rule.valueBExpression)?.value
          && this.valueBAggregation === 'none'
        ) {
          return this.metrics.find(metric => metric.value === this.rule.valueBExpression)?.value;
        }
        return null;
      },
      set(value) {
        this.$log.info(
          'valueBMetric: set()',
          value,
          this.metrics.find(metric => metric.value === value)?.value,
        );

        if (value?.length > 0 && this.valueBAggregation === 'none') {
          // this.$log.info('valueBMetric: 2', value);

          return this.$set(
            this.rule,
            'valueBExpression',
            this.metrics.find(metric => metric.value === value)?.value,
          );
        }

        return this.$delete(this.rule, 'valueBExpression');
      },
    },
    valueA: {
      get() {
        // this.$log.info('valueA', this.rule?.valueA);
        return this.rule?.valueA;
      },
      set(value) {
        // this.$log.info('valueA', value.length);

        if (value && (this.modeFunction === 'MAX' || this.modeFunction === 'MIN')) {
          return this.$set(this.rule, 'valueA', parseFloat(value));
        }

        return this.$delete(this.rule, 'valueA');
      },
    },
    modeFunction: {
      get() {
        return this.rule.mode;
      },
      set(value) {
        // this.$log.info('modeFunction', value);

        if (!this.valueARequired.includes(value)) {
          this.$delete(this.rule, 'valueA');
        }

        if (this.valueARequired.includes(value)) {
          this.$delete(this.rule, 'valueBExpression');
          this.$set(this.rule, 'valueA', 0);
        }

        if (value?.length === 0) {
          return this.$delete(this.rule, 'mode');
        }

        return this.$set(this.rule, 'mode', value);
      },
    },
    isATransportTypeSelected() {
      this.$log.info('this.rule?.valueAExpression', this.rule?.valueAExpression);
      return (
        this.rule?.valueAExpression?.includes('$.sum')
        || this.rule?.valueAExpression?.includes('$.transitModeDistance')
      );
    },
    isBTransportTypeSelected() {
      this.$log.info('this.rule?.valueBExpression', this.rule?.valueBExpression);

      return (
        this.rule?.valueBExpression?.includes('$.sum')
        || this.rule?.valueBExpression?.includes('$.transitModeDistance')
      );
    },
    isAMetricSelected() {
      return !!this.metrics.find(metric => metric.value === this.rule?.valueAExpression)?.value;
    },
    isBMetricSelected() {
      return !!this.metrics.find(metric => metric.value === this.rule?.valueBExpression)?.value;
    },
    getValueAInputLabel() {
      if (this.isATransportTypeSelected) {
        return `${this.$t('configs.valueA')} ${this.$t('configs.inMeters')}`;
      }
      return this.$t('configs.valueA');
    },
  },
  methods: {
    generateOptions(source) {
      return this[source]
        .slice()
        .sort()
        .map(el => [{ value: el, text: this.$t(`configs.${source}.${el}`) }])
        .flat();
    },
    generateOptionsFromObject(source) {
      return this[source]
        .slice()
        .map(el => [{ value: el.value, text: this.$t(`configs.${source}.${el.text}`) }])
        .flat();
    },
  },
};
</script>
