<template>
  <div
    :class="bgColor || 'bg-gray-100'"
    class="mx-auto text-primary py-4
    rounded-md h-full justify-center items-center flex flex-col w-full"
    >
    <div>
      <div class="flex flex-row items-center px-4">
        <cc-svg-base
          svg="icons/icon-loading"
          class="animate-spin h-5 w-5 mr-4 text-primary-dark loading-svg"
          />
        <cc-text
          variant="body-bold"
          color="text-primary-dark"
          >
          {{ $t('commons.loading') }}
        </cc-text>
      </div>
    </div>
  </div>
</template>

<script>
import commonProps from '@/mixins/commonProps';

export default {
  mixins: [commonProps],
};
</script>

<style lang="postcss">
.loading-svg circle {
  @apply opacity-25;
}
.loading-svg path {
  @apply opacity-75;
}
</style>
