<template>
  <div>
    <cc-section-table
      title="Not Authorized"
      class="mb-4"
      >
      <cc-code
        title=""
        >
        <div class="py-3">
          <span class="py-3 border-b-2 border-t-2 border-gray-200">
            $route.fullPath: {{ $route.fullPath }}
          </span>
        </div>
        <div
          v-if="intentedPreviousRoute"
          class="py-3"
          >
          <span class="py-3 border-b-2 border-t-2 border-gray-200">
            Intended Previous Route: {{ intentedPreviousRoute }}
          </span>
        </div>
      </cc-code>
    </cc-section-table>
  </div>
</template>

<script>
export default {
  computed: {
    intentedPreviousRoute() {
      return this.$route?.params?.previousRoute?.path;
    },
  },
  created() {
    this.$log.info('Not Authorized Child', this.$route.params);
  },
};
</script>
