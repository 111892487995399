var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"navigation"}},[_c('div',{staticClass:"py-4 h-20 pr-14 flex items-center justify-between bg-white flex-shrink-0"},[_c('div',{staticClass:"flex items-center justify-center"},[_c('transition',{attrs:{"name":"view-fade","mode":"out-in"}},[_c('div',{key:_vm.headingKey,staticClass:"flex justify-center items-center"},[(_vm.hasBackButton)?_c('cc-button',{staticClass:"ml-3",attrs:{"variant":"plain","svg":"icons/icon-arrow-left-tail","svg-color":"text-gray-800","svg-align":"left","has-min-width":false,"block":false},nativeOn:{"click":function($event){_vm.$router
                .push({
                  name: _vm.currentRouteMeta.backTo || _vm.parentRoute.meta.backTo,
                })
                .catch(function () {})}}}):_vm._e(),_c('cc-heading',{class:_vm.hasBackButton ? 'pl-2' : 'px-14',attrs:{"tag":"h1","weight":"semibold","data-ruid":("top-navigation__" + (_vm.$route.name))}},[_vm._v(" "+_vm._s(_vm.dynamicTitle ? _vm.dynamicTitle : _vm.$t(_vm.currentRouteMeta.navigationTitle) || _vm.$t(_vm.parentRoute.meta.navigationTitle))+" ")])],1)])],1),_c('cc-navigation-actions')],1),_c('div',{staticClass:"mx-14 border-b border-gray-100",attrs:{"id":"sub-navigation"}},[_c('transition',{attrs:{"name":"view-fade","mode":"out-in"}},[_c('ul',{key:_vm.headingKey,staticClass:"list-none",class:!!_vm.subNavigationItems.length ? 'border-t' : null},_vm._l((_vm.subNavigationItems),function(item){return _c('router-link',{key:("sub-nav-" + (item.path)),staticClass:"transition-all duration-500 ease-out hover:border-gray-800",attrs:{"to":{ name: item.name },"tag":"li","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"py-4 mr-8 inline-block border-b-2 border-transparent",class:[isExactActive && 'border-gray-800'],attrs:{"href":href},on:{"click":navigate}},[_c('cc-text',{attrs:{"color":"text-gray-800"}},[_vm._v(" "+_vm._s(_vm.$t(item.navigationLabel))+" ")])],1)]}}],null,true)})}),1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }