<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 flex items-center justify-center z-10"
    >
    <transition
      v-if="showBackdrop"
      name="modal-back-drop"
      appear
      @after-leave="$emit('close'), showBackdrop = true, showContent = true"
      >
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        >
        <div
          class="min-h-screen bg-black opacity-50"
          @click="canBackdropDismiss ? close() : undefined"
          />
      </div>
    </transition>

    <transition
      v-if="showContent"
      :name="transitionName"
      appear
      class=""
      >
      <div
        class="z-20 overflow-hidden p-6 flex items-center"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        >
        <div
          class="flex flex-col bg-white p-10 rounded-lg max-h-full min-w-80"
          >
          <slot
            name="header"
            :close="close"
            >
            <cc-heading
              tag="h1"
              variant="h1"
              class="border-b border-gray-200 pb-4 mb-8"
              >
              {{ heading }}
            </cc-heading>
          </slot>

          <slot
            name="body"
            :close="close"
            >
            {{ body }}
          </slot>

          <div class="border-t border-gray-200 pt-8 mt-8">
            <slot
              name="footer"
              :close="close"
              >
              <cc-button
                text="Close"
                @click.native="close"
                />
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    canBackdropDismiss: {
      type: Boolean,
      default: true,
      required: false,
    },
    heading: {
      type: String,
      default: 'Modal title',
      required: false,
    },
    body: {
      type: String,
      default: 'Simple body text',
      required: false,
    },
    timeoutDuration: {
      type: Number,
      default: null,
      required: false,
    },
    transitionName: {
      type: String,
      default: 'scale-in',
      required: false,
    },
  },
  data() {
    return {
      showContent: true,
      showBackdrop: true,
    };
  },
  created() {
    this.$log.info('Created modal');

    const onEscape = (e) => {
      if (this.isOpen && e.keyCode === 27) {
        this.close();
      }
    };

    if (this.timeoutDuration) {
      setTimeout(() => {
        this.close();
      }, this.timeoutDuration);
    }

    document.addEventListener('keydown', onEscape);

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },
  destroyed() {
    this.$log.info('Destroyed modal');
  },
  methods: {
    close() {
      this.showContent = false;
      this.showBackdrop = false;
    },
  },
};
</script>

<style>

</style>
