<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col items-center p-4">
    <div class="pt-16 pb-16">
      <cc-svg-base
        svg="illustration/earth-with-logo"
        />
    </div>

    <div class="card max-w-sm w-full">
      <cc-heading
        tag="h1"
        class="text-3xl pb-6"
        >
        {{ $t('pages.resetPassword.title') }}
      </cc-heading>

      <div class="">
        <validation-observer ref="form">
          <form @submit.prevent="resetPasswordHandler">
            <cc-input
              class="mb-4"
              type="text"
              autocomplete="email"
              hidden
              />

            <cc-input
              id="new-password"
              v-model="password"
              :placeholder="$t('commons.password')"
              type="password"
              autocomplete="new-password"
              autocapitalize="off"
              autocorrect="off"
              class="mb-4 relative"
              :has-password-toggle="true"
              :validation-rules="`required|passwordComplexity|password:@${$t('commons.passwordRepeat')}`"
              :validation-name="$t('commons.password')"
              autofocus
              />

            <cc-input
              id="confirm-new-password"
              v-model="passwordRepeat"
              :placeholder="$t('commons.passwordRepeat')"
              type="password"
              autocomplete="new-password"
              autocapitalize="off"
              autocorrect="off"
              class="mb-4 relative"
              :has-password-toggle="true"
              validation-rules="required"
              :validation-name="$t('commons.passwordRepeat')"
              />

            <cc-error-message
              v-if="errors.length"
              :message="errors[0]"
              class="mt-4"
              />

            <cc-button
              class="mt-4"
              :has-min-width="false"
              :disabled="isLoading"
              :is-loading="isLoading"
              :text="$t('pages.resetPassword.primaryButton')"
              type="submit"
              />
          </form>
        </validation-observer>
      </div>
    </div>

    <cc-modal
      v-if="isFeedbackOpen"
      :is-open="isFeedbackOpen"
      transition-name="slide-up"
      :heading="$t('modals.resetPasswordFeedback.title')"
      @close="$router.push({ name: 'login' })"
      >
      <template #body>
        <div class="max-w-lg">
          {{ $t('modals.resetPasswordFeedback.body') }}
        </div>
      </template>

      <template #footer="{ close }">
        <cc-button
          variant="secondary"
          :text="$t('modals.resetPasswordFeedback.primaryButton')"
          @click.native="close"
          />
      </template>
    </cc-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      isLoading: false,
      isFeedbackOpen: false,
      password: null,
      passwordRepeat: null,
      token: this.$route?.query?.token,
      errors: [],
    };
  },
  computed: {
    ...mapState({
      user: st => st.user.user,
    }),
  },
  created() {},
  methods: {
    async resetPasswordHandler() {
      const {
        password, //
        passwordRepeat,
        token,
      } = this;

      const success = await this.$refs.form.validate();

      if (!success) {
        this.$log.info('Form !success', success);
        return;
      }

      try {
        this.isLoading = true;
        this.errors = [];

        if (password === passwordRepeat) {
          await this.$store.dispatch('user/confirmForgottenPassword', { password, token });
          this.isFeedbackOpen = true;
        }
      } catch (err) {
        this.errors.push(this.$t('errors.genericError'));

        this.$log.error('Error: resetPasswordHandler', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
