<template>
  <div class="relative">
    <cc-button
      :text="$t('commons.toggleColumns')"
      variant="plain"
      class="border border-gray-300 py-3 text-gray-600 min-w-32 justify-start"
      :block="false"
      svg="icons/icon-dropdown-arrow"
      svg-class="w-2 h-2 absolute top-0 right-0 mx-4 my-5 pointer-events-none"
      svg-align="right"
      align="left"
      @click.native.stop="toggleDropdown"
      />
    <cc-dropdown
      :is-open="isDropdownOpen"
      dropdown-class="right-0 mt-2 origin-top-right min-w-32 w-56 px-3 py-2 max-h-64 overflow-y-auto"
      class=""
      @close="isDropdownOpen = false"
      >
      <cc-check-box
        v-for="(item, i) in list"
        :key="i"
        v-model="list[i].isVisible"
        :text="item.text"
        class="flex-shrink-0 py-1"
        />
    </cc-dropdown>
  </div>
</template>


<script>
/**
 * @todo
 * Component still needs to become more generic
 * v-model is coupled to the list structure
 */
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
  },
  methods: {
    toggleDropdown() {
      // this.$log.info('Toggle dropdown');
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    setValue(target, key, value) {
      this.$set(target, key, value);
    },
  },
};
</script>

<style></style>
