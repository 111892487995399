<template>
  <code
    v-if="forceDisplay || appState.isDev || appState.isDevelopment"
    class="rounded-lg border border-gray-200 block text-xs bg-gray-100 p-6 break-all whitespace-pre-wrap"
    >
    <p
      v-if="title"
      class="pb-6"
      >
      {{ title }}
    </p>
    <slot />
  </code>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'JSON',
    },
    forceDisplay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
    }),
  },
};
</script>

<style>

</style>
