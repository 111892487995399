<template>
  <div>
    <cc-wallet-manager
      v-if="isContextAdmin"
      :is-loading="isLoadingDataTable"
      :wallets="platformWallets"
      :has-sponsor-wallet="false"
      :has-store-wallet="true"
      :has-balance-threshold="false"
      :section-title="$t('pages.dashboard.storeWalletTitle')"
      :section-body="$t('pages.dashboard.storeWalletBody')"
      :can-redeem="false"
      :can-transfer="false"
      />

    <cc-wallet-manager
      v-if="isContextAdmin"
      :is-loading="isLoadingDataTable"
      :wallets="platformWallets"
      :has-sponsor-wallet="true"
      :has-store-wallet="false"
      :has-balance-threshold="true"
      :section-title="$t('pages.dashboard.sponsorWalletTitle')"
      :section-body="$t('pages.dashboard.sponsorWalletBody')"
      :can-redeem="false"
      :can-transfer="false"
      />

    <cc-check-actions
      v-if="!appState.isProd"
      :has-some="['META_GET_RASTROASSURED_LOGS']"
      >
      <cc-assured-logs env="staging" />
      <cc-assured-logs env="production" />
    </cc-check-actions>

    <cc-section-table
      v-if="!isContextAdmin"
      :title="$t('pages.dashboard.title')"
      >
      <!-- {{ user.roles }} -->
    </cc-section-table>

    <cc-section-table
      v-if="appState.isDev || appState.isDevelopment"
      title="User Roles"
      class="my-4"
      >
      <cc-code>
        {{ getUserRoles }}
      </cc-code>
    </cc-section-table>

    <cc-section-table
      v-if="appState.isDev || appState.isDevelopment"
      title="User Actions"
      class="mb-4"
      >
      <cc-code>
        {{ getUserActions.sort() }}
      </cc-code>
    </cc-section-table>

    <cc-section-table
      v-if="isContextAdmin && emissionsTable.table"
      :is-loading="isLoadingEmissionsTable"
      :actions="emissionsSectionActions"
      :title="$t('pages.dashboard.emissionsTable')"
      @export="exportJSON(emissionsTable, 'emissions')"
      >
      <cc-code :force-display="true">
        {{ emissionsTable.table }}
      </cc-code>
    </cc-section-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AdminService from '@/services/AdminService';
import { exportJSON } from '@/mixins/utils';

import ccAssuredLogs from '@/components/AssuredLogs.vue';

export default {
  components: {
    ccAssuredLogs,
  },
  data() {
    return {
      isLoadingDataTable: false,
      isLoadingEmissionsTable: false,
      platformWallets: [],
      emissionsTable: {},
      emissionsSectionActions: [
        {
          icon: 'icon-export',
          iconColor: 'text-gray-500',
          event: 'export',
          variant: 'light',
          text: this.$t('pages.dashboard.exportEmissionsTable'),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      appState: st => st.common.appState,
      user: st => st.user,
    }),
    ...mapGetters({
      isContextAdmin: 'user/isContextAdmin',
      getUserRoles: 'user/getUserRoles',
      getUserActions: 'user/getUserActions',
    }),
    walletId() {
      return this.platformWallets?.id;
    },
    walletBalance() {
      return this.platformWallets?.balance;
    },
    storeWallet() {
      return this.platformWallets?.find(w => w.type === 'STORE');
    },
    storeBalance() {
      return this.storeWallet?.balance || 0;
    },
    sponsorWallet() {
      return this.platformWallets?.find(w => w.type === 'SPONSOR');
    },
    sponsorBalance() {
      return this.sponsorWallet?.balance || 0;
    },
  },
  async mounted() {
    await this.getPlatformWallets();
    if (this.isContextAdmin) {
      await this.getEmissionsTable();
    }
  },
  methods: {
    exportJSON,
    async getPlatformWallets() {
      try {
        if (this.isContextAdmin) {
          this.isLoadingDataTable = true;
          this.platformWallets = await this.$store.dispatch('company/getPlatformWallets');

          // this.platformWallets = response.find(
          //   wallet => wallet.currencyId === process.env.VUE_APP_SPONSOR_CURRENCY_ID,
          // );
        }
      } catch (err) {
        this.$log.error('Error: getPlatformWallets', err);
      } finally {
        this.isLoadingDataTable = false;
      }
    },
    async getEmissionsTable() {
      try {
        this.isLoadingEmissionsTable = true;
        this.emissionsTable = await AdminService.getEmissionsTable();
      } catch (error) {
        this.$log.info('getEmissionsTable', error);
      } finally {
        this.isLoadingEmissionsTable = false;
      }
    },

  },
};
</script>

<style></style>
