<template>
  <div class="bg-primary-darkest min-h-screen flex flex-col justify-center items-center p-4">
    <div class="card-blue max-w-sm w-full">
      <div class="pt-4 pb-8 flex justify-center">
        <cc-svg-base svg="illustration/email-success" />
      </div>

      <cc-heading
        tag="h1"
        variant="h2"
        class="font-semibold"
        color="text-white"
        align="center"
        >
        {{ $t('pages.email.check.title') }}
      </cc-heading>

      <cc-text
        align="center"
        color="text-white"
        class="pt-5 pb-2"
        >
        {{ $t('pages.email.check.body') }}
      </cc-text>

      <cc-button
        :text="$t('pages.email.check.primaryButton')"
        class="mt-5"
        variant="green-lighter"
        :disabled="!bermudaRightSide"
        @click.native="handleVerify"
        />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: 'email-check',
      bermudaRightSide: null,
    };
  },
  created() {
    this.bermudaRightSide = this.$route?.query?.x || null;
  },
  methods: {
    async handleVerify() {
      try {
        const bermudaUrl = process.env.VUE_APP_BERMUDA_URL;
        const { bermudaRightSide } = this;

        if (!bermudaRightSide) {
          return;
        }

        window.location.href = `${bermudaUrl}/${bermudaRightSide}`;
      } catch (error) {
        this.$log.error('Error: EmailCheck', error);
      }
    },
  },
};
</script>
