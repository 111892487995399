<template>
  <div class="flex flex-col flex-1 justify-between p-4 rounded-lg bg-gray-100">
    <div class="flex justify-between items-center flex-wrap pb-4">
      <cc-text
        :text="walletTitle"
        variant="body-lg-bold"
        color="text-primary-darker"
        class="flex-grow"
        />
    </div>


    <div class="flex flex-wrap sm:flex-nowrap align-bottom items-baseline pb-4">
      <cc-text
        variant="body-5xl"
        color="text-gray-700"
        class="pr-2 group-hover:text-white transition-colors duration-500 ease-out"
        >
        {{ balance && balance.toLocaleString() }}
      </cc-text>

      <cc-text
        color="text-gray-400"
        :text="$t('commons.fieldNames.greenCoins')"
        />
    </div>

    <div class="">
      <cc-text
        :text="$t('commons.fieldNames.walletId')"
        color="text-gray-400"
        />

      <cc-text
        class="pt-1 break-all"
        color="text-gray-700"
        >
        {{ walletId }}
      </cc-text>
    </div>

    <div
      v-if="hasButtonOne || hasButtonTwo"
      class="flex flex-wrap"
      >
      <cc-button
        v-if="hasButtonOne"
        :text="buttonOneLabel"
        size="small"
        :class="hasButtonTwo ? 'mr-4' : null"
        class="mt-4"
        :has-min-width="false"
        :block="false"
        :disabled="isButtonOneDisabled"
        color="text-primary-dark"
        @click.native="$emit('click:button-one')"
        />

      <cc-button
        v-if="hasButtonTwo"
        :text="buttonTwoLabel"
        size="small"
        variant="secondary"
        :has-min-width="false"
        class="mt-4"
        :block="false"
        :is-loading="isButtonTwoLoading"
        :disabled="isButtonTwoDisabled"
        color="text-primary-dark"
        @click.native="$emit('click:button-two')"
        />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balance: {
      type: Number,
      required: true,
    },
    canTransfer: {
      type: Boolean,
      required: false,
      default: true,
    },
    walletTitle: {
      type: String,
      required: true,
    },
    buttonOneLabel: {
      type: String,
      required: true,
      default: null,
    },
    buttonTwoLabel: {
      type: String,
      required: false,
      default: null,
    },
    isButtonOneDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonTwoDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isButtonTwoLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasButtonOne: {
      type: Boolean,
      required: true,
    },
    hasButtonTwo: {
      type: Boolean,
      required: true,
      default: false,
    },
    wallet: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    walletId() {
      return this.wallet?.id;
    },
  },
};
</script>

<style>

</style>
